import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../../styles/LoanApplication.module.scss";
import phoneGreenImg from "../../assets/images/phone-green.png";
import phoneBlueImg from "../../assets/images/phone.png";
import phoneOrangeImg from "../../assets/images/phone-orange.png";
import phonePurpleImg from "../../assets/images/phone-purple.png";
import {
  infoArrayB1,
  infoArrayB2,
  infoArrayB3,
  infoArrayB4,
  infoArrayB5,
  infoArrayB6,
  infoArrayB7,
  infoArrayB8,
  infoArrayB9,
  infoArrayB10,
  infoArrayB11,
  infoArrayD1,
  infoArrayD2,
  infoArrayD3,
  infoArrayD4,
  infoArrayD5,
  infoArrayD6,
  infoArrayD7,
  infoArrayC1,
  infoArrayC2,
  infoArrayC3,
  infoArrayC4,
  infoArrayS1,
  infoArrayS2,
} from "../../data/LoanApplication";
import classNames from "classnames";
const LoanApplication = ({ title }) => {
  const location = useLocation().pathname;
  const finalArray =
    location === "/bridgingloansblogs/blog1"
      ? infoArrayB1
      : location === "/bridgingloansblogs/blog2"
      ? infoArrayB2
      : location === "/bridgingloansblogs/blog3"
      ? infoArrayB3
      : location === "/bridgingloansblogs/blog4"
      ? infoArrayB4
      : location === "/bridgingloansblogs/blog5"
      ? infoArrayB5
      : location === "/bridgingloansblogs/blog6"
      ? infoArrayB6
      : location === "/bridgingloansblogs/blog7"
      ? infoArrayB7
      : location === "/bridgingloansblogs/blog8"
      ? infoArrayB8
      : location === "/bridgingloansblogs/blog9"
      ? infoArrayB9
      : location === "/bridgingloansblogs/blog10"
      ? infoArrayB10
      : location === "/bridgingloansblogs/blog11"
      ? infoArrayB11
      : location === "/developmentfinanceblogs/blog1"
      ? infoArrayD1
      : location === "/developmentfinanceblogs/blog2"
      ? infoArrayD2
      : location === "/developmentfinanceblogs/blog3"
      ? infoArrayD3
      : location === "/developmentfinanceblogs/blog4"
      ? infoArrayD4
      : location === "/developmentfinanceblogs/blog5"
      ? infoArrayD5
      : location === "/developmentfinanceblogs/blog6"
      ? infoArrayD6
      : location === "/developmentfinanceblogs/blog7"
      ? infoArrayD7
      : location === "/casestudies1"
      ? infoArrayC1
      : location === "/casestudies2"
      ? infoArrayC2
      : location === "/casestudies3"
      ? infoArrayC3
      : location === "/casestudies4"
      ? infoArrayC4
      : location === "/stoprepossessionsblogs/blog1"
      ? infoArrayS1
      : location === "/stoprepossessionsblogs/blog1"
      ? infoArrayS2
      : "";
  const Heading =
    location === "/bridgingloansblogs/blog1"
      ? "Why Work with Bridging 365?"
      : location === "/bridgingloansblogs/blog2"
      ? "Bridging Loan Applications for People with Bad Credit"
      : location === "/bridgingloansblogs/blog3"
      ? "Bridging Loans for All Purposes"
      : location === "/bridgingloansblogs/blog4"
      ? "Why Use Bridging 365?"
      : location === "/bridgingloansblogs/blog5"
      ? "Why Work with Bridging 365?"
      : location === "/bridgingloansblogs/blog6"
      ? "Secure Funding through Bridging 365"
      : location === "/bridgingloansblogs/blog7"
      ? "Bridging Loans for Adverse Credit"
      : location === "/bridgingloansblogs/blog8"
      ? "Speak to Bridging 365 About a Commercial Bridging Loan"
      : location === "/bridgingloansblogs/blog9"
      ? "Bridging 365 Provides Fast Bridging Loans"
      : location === "/bridgingloansblogs/blog10"
      ? "Bridging Loans for Inheritance Tax"
      : location === "/bridgingloansblogs/blog11"
      ? "Contact Bridging 365 for Difficult Bridging Loan Solutions"
      : location === "/developmentfinanceblogs/blog1"
      ? "Development Funding and Bridging Loans from Bridging365"
      : location === "/developmentfinanceblogs/blog2"
      ? "Development Finance from Bridging365"
      : location === "/developmentfinanceblogs/blog3"
      ? "Contact Bridging 365 for Development Finance with Bad Credit"
      : location === "/developmentfinanceblogs/blog4"
      ? "Development Finance from Bridging365"
      : location === "/developmentfinanceblogs/blog5"
      ? "Development Finance Expertise from Bridging365"
      : location === "/developmentfinanceblogs/blog6"
      ? "Bridging Loan Advice from Specialists in the Field"
      : location === "/developmentfinanceblogs/blog7"
      ? "Development Finance Expertise from Bridging365"
      : location === "/casestudies1"
      ? "Bridging Loans for Bankruptcy"
      : location === "/casestudies2"
      ? "Helping find Bridging Loans for Over 60s"
      : location === "/casestudies3"
      ? "Splitting Title Deeds in a Commercial Interest"
      : location === "/casestudies4"
      ? "Access to Unregulated Bridging Loan Funders"
      : location === "/stoprepossessionsblogs/blog1"
      ? "What are Your Next Steps ?"
      : location === "/stoprepossessionsblogs/blog2"
      ? "Benefits of Bridging Finance to Prevent Repossession of Your Home"
      : "";
  return (
    <div className={styles.loanApplication}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        {Heading}
      </p>
      <div
        className={classNames(
          styles.content,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <div className={styles.left}>
          {finalArray.map((item) => {
            return <p>{item.para}</p>;
          })}
        </div>
        <div
          className={classNames(
            styles.right,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : ""
          )}
        >
          <p className={styles.rightHeader}>Confused by the options?</p>
          <p className={styles.rightText}>
            Choosing the right Bridge for you can be really tricky. At Bridging
            365 our expert advisers guide you through the process. Simple and
            efficient.
          </p>
          <Link
            to="#"
            className={classNames(
              styles.link,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            Start Online
          </Link>
          <p className={styles.callText}>Call free from mobile or landline</p>
          <p
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            {" "}
            <img
              src={
                title === "Bridging Loans"
                  ? phoneGreenImg
                  : title === "Development Finance"
                  ? phoneBlueImg
                  : title === "Stop Repossessions"
                  ? phoneOrangeImg
                  : title === "Resources"
                  ? phonePurpleImg
                  : ""
              }
              alt=""
            />{" "}
            0208 146 9888
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanApplication;
