import React from "react";
import styles from "../styles/DealsComponent.module.scss";
import checkedGreenImg from "../assets/images/checked-green.png";
import checkedBlueImg from "../assets/images/checked-blue.png";
import checkedOrangeImg from "../assets/images/checked-orange.png";
import checkedPurpleImg from "../assets/images/checked-purple.png";
import classNames from "classnames";

const DealsComponent = ({ title }) => {
  const dealsArray1 = [
    {
      id: 1,
      title: "We’ll aim to complete the loan or rebridge in 10 Days or less",
    },
    {
      id: 2,
      title: "We'll handle all the paperwork & make the process hassle-free",
    },
    {
      id: 3,
      title: "LTV up to 85% with a typical rate of 0.75%.",
    },
    {
      id: 4,
      title: "All fees can be added to the new loan",
    },
  ];
  const dealsArray2 = [
    {
      id: 1,
      title: "Up to 95% LTC (Loan to Costs)",
    },
    {
      id: 2,
      title: "Up to 100% of the cost of development (paid in arrears)",
    },
    {
      id: 3,
      title: "Up to 75% GDV (Gross Development Value)",
    },
    {
      id: 4,
      title: "Rates starting at 4.8% pa ",
    },
  ];
  const dealsArray3 = [
    {
      id: 1,
      title: "Deal direct with the lenders",
    },
    {
      id: 2,
      title:
        "No valuations and no lengthy legal process all for the sake of speed",
    },
    {
      id: 3,
      title: "Your credit profile is irrelevant ",
    },
    {
      id: 4,
      title: "Up to 85% Loan to Value",
    },
  ];
  const dealsArray4 = [
    {
      id: 1,
      title: "Speed - Funds with you in as little as 24 hours",
    },
    {
      id: 2,
      title: "Reliability - We say YES to the cases others won’t or can’t",
    },
    {
      id: 3,
      title: "Flexibility - We have access to a wide range of funding lines",
    },
  ];
  const finalArray =
    title === "Bridging Loans"
      ? dealsArray1
      : title === "Development Finance"
      ? dealsArray2
      : title === "Stop Repossessions"
      ? dealsArray3
      : title === "Re-Bridging Finance"
      ? dealsArray4
      : "";
  return (
    <div className={styles.Deals}>
      <p className={styles.dealsHeader}>
        {" "}
        {title === "Bridging Loans"
          ? "Bridging or Re-Bridging"
          : title === "Development Finance"
          ? "Less ordinary, more extraordinary"
          : title === "Stop Repossessions"
          ? "Say no to the men at the doors..!"
          : title === "Re-Bridging Finance"
          ? "Switch to a better deal"
          : ""}{" "}
      </p>
      <p
        className={classNames(
          styles.dealsSubHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Re-Bridging Finance"
            ? styles.reSources
            : ""
        )}
      >
        {title === "Bridging Loans"
          ? "Need More Time - Let Us Rebridge - No Fuss, No Hassel"
          : title === "Development Finance"
          ? "Exceptional solutions, speed and service"
          : title === "Stop Repossessions"
          ? "Here is when it counts, with loans done in 24 to 48 hours"
          : title === "Re-Bridging Finance"
          ? "From everyday property purchases to complex portfolio cases "
          : ""}{" "}
      </p>
      <div className={styles.dealsList}>
        {finalArray.map((item) => {
          return (
            <p className={styles.dealItem}>
              <img
                src={
                  title === "Bridging Loans"
                    ? checkedGreenImg
                    : title === "Development Finance"
                    ? checkedBlueImg
                    : title === "Stop Repossessions"
                    ? checkedOrangeImg
                    : title === "Re-Bridging Finance"
                    ? checkedPurpleImg
                    : ""
                }
                alt=""
              />{" "}
              {item.title}
            </p>
          );
        })}
        {/* <p className={styles.dealItem}>
          <img
            src={
              title === "Bridging Loans"
                ? checkedGreenImg
                : title === "Development Finance"
                ? checkedBlueImg
                : title === "Stop Repossessions"
                ? checkedOrangeImg
                : title === "Resources"
                ? checkedPurpleImg
                : ""
            }
            alt=""
          />{" "}
          We’ll aim to complete the loan or rebridge in 10 Days or less
        </p>
        <p className={styles.dealItem}>
          <img
            src={
              title === "Bridging Loans"
                ? checkedGreenImg
                : title === "Development Finance"
                ? checkedBlueImg
                : title === "Stop Repossessions"
                ? checkedOrangeImg
                : title === "Resources"
                ? checkedPurpleImg
                : ""
            }
            alt=""
          />{" "}
          We'll handle all the paperwork & make the process hassle-free
        </p>
        <p className={styles.dealItem}>
          <img
            src={
              title === "Bridging Loans"
                ? checkedGreenImg
                : title === "Development Finance"
                ? checkedBlueImg
                : title === "Stop Repossessions"
                ? checkedOrangeImg
                : title === "Resources"
                ? checkedPurpleImg
                : ""
            }
            alt=""
          />{" "}
          LTV up to 85% with a typical rate of 0.75%.
        </p>
        <p className={styles.dealItem}>
          <img
            src={
              title === "Bridging Loans"
                ? checkedGreenImg
                : title === "Development Finance"
                ? checkedBlueImg
                : title === "Stop Repossessions"
                ? checkedOrangeImg
                : title === "Resources"
                ? checkedPurpleImg
                : ""
            }
            alt=""
          />{" "}
          All fees can be added to the new loan
        </p> */}
      </div>
    </div>
  );
};

export default DealsComponent;
