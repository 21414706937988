import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const ProtectedRoute = (props) => {
    const location = useLocation().pathname;
    const {Component}=props;
    const navigate = useNavigate()
    useEffect(()=>{
        const isLoggedIn = localStorage.getItem("loggedIn");
        const role = localStorage.getItem("role");
        if(location==="/dashboard" || location==="/admin"){
            if(!isLoggedIn && role !=="admin"){
                navigate("/")
            }
        }
        if(location==="/signin"){
            if(isLoggedIn){
                navigate("/")
            } else{
                navigate("/signin")
            }
        }
        if(location==="/form"){
            if(!isLoggedIn){
                navigate("/")
            }
        }
   },[location,navigate])
  return (
    <div>
        <Component/>
    </div>
  )
}

export default ProtectedRoute