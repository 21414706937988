import { useState, useEffect } from "react";
import styles from "../../FormPage.module.scss";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import securityGif from "../../../../assets/images/security.gif";
import securitypng from "../../../../assets/images/security.png";
import securityGif1 from "../../../../assets/images/About_security1.gif";

const AboutSecurity = ({ formdata, setFormdata, setSecurityProgress }) => {
  const blurHandler = () => {
    if (
      formdata.purpose &&
      formdata.howMuchToBorrow &&
      formdata.numOfMonthsLoanFor &&
      formdata.monthlyOrTermPay &&
      formdata.howPaybackLoan &&
      formdata.addressLine1 &&
      formdata.city &&
      formdata.postcode &&
      formdata.propertyValuation
    ) {
      setShowCalc(true);
    }
  };

  const [showCalc, setShowCalc] = useState(true);

  useEffect(() => {
    const values = [
      "addressLine1",
      "addressLine2",
      "city",
      "postcode",
      "propertyValuation",
      "propertyDescription",
      "numberOfBedrooms",
      "numberOfFloors",
      "isExCouncilProperty",
      "isStandardConstruction",
      "constructionDescription",
      "tenureOfPropertyAnswer",
      "assetDescription",
      "addAnotherProperty",
    ];

    let score = 0;
    for (let i = 0; i < values.length; i++) {
      if (formdata[values[i]] !== "") {
        score += 1;
      }
    }

    setSecurityProgress((score / values.length) * 100);
  }, [formdata, setSecurityProgress]);

  return (
    <div className={styles.section} id="section2">
    c
      <form action="" className={styles.form1}>
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              Address of the security{" "}
            </span>
          </label>
          <div className={styles.answerBlock}>
            {" "}
            <input
              type="text"
              placeholder="Enter your address"
              className={styles.answer}
              value={formdata.addressLine1}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  addressLine1: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              Address Line 2 (optional)
            </span>
          </label>
          <div className={styles.answerBlock}>
            {" "}
            <input
              type="text"
              placeholder="Enter your address"
              className={styles.answer}
              value={formdata.addressLine2}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  addressLine2: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              Which city or town is the property located in?{" "}
            </span>
          </label>
          <div className={styles.answerBlock}>
            {" "}
            <input
              type="text"
              placeholder="Enter city or town name"
              className={styles.answer}
              value={formdata.city}
              onChange={(e) => {
                setFormdata({ ...formdata, city: e.target.value });
              }}
            />
          </div>
        </div>
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}> What is the postcode?</span>
          </label>
          <div className={styles.answerBlock}>
            <input
              type="text"
              placeholder="Enter postcode"
              className={styles.answer}
              value={formdata.postcode}
              onChange={(e) => {
                setFormdata({ ...formdata, postcode: e.target.value });
              }}
            />
          </div>
        </div>
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              What is the estimated property valuation?
            </span>
          </label>
          <div className={styles.answerBlock}>
            <NumberFormat
              placeholder="Enter valuation in GBP"
              className={styles.answer}
              value={formdata.propertyValuation}
              thousandSeparator={true}
              onChange={(e) => {
                blurHandler();
                setFormdata({
                  ...formdata,
                  propertyValuation: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              Please select the best description of the property below
            </span>
          </label>
          <div className={styles.answerBlock}>
            {" "}
            <select
              name=""
              id=""
              className={styles.answer}
              value={formdata.propertyDescription}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  propertyDescription: e.target.value,
                });
              }}
            >
              <option value="" selected disabled>
                select option
              </option>
              <option value="House">House</option>
              <option value="Flat">Flat</option>
              <option value="HMO (House in Multiple Occupation)">
                HMO (House in Multiple Occupation)
              </option>
              <option value="Commercial property">Commercial property</option>
              <option value="Land with or without planning permission">
                Land with or without planning permission
              </option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              How many bedrooms does the property have?
            </span>
          </label>
          <div className={styles.answerBlock}>
            {" "}
            <input
              type="number"
              placeholder="Number of bedrooms"
              className={styles.answer}
              value={formdata.numberOfBedrooms}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  numberOfBedrooms: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              How many floors are in the building?
            </span>
          </label>
          <div className={styles.answerBlock}>
            {" "}
            <input
              type="number"
              placeholder="Enter number of floors"
              className={styles.answer}
              value={formdata.numberOfFloors}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  numberOfFloors: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              Is it an ex-council property?
            </span>
          </label>
          <div className={styles.answerBlock}>
            {" "}
            <select
              name=""
              id=""
              className={styles.answer}
              value={formdata.isExCouncilProperty}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  isExCouncilProperty: e.target.value,
                });
              }}
            >
              <option value="" selected disabled>
                select option
              </option>
              <option value="yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              Is property of standard construction? Please select below and tell
              us more.
            </span>
          </label>
          <div className={styles.answerBlock}>
            <select
              name=""
              id=""
              className={styles.answer}
              value={formdata.isStandardConstruction}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  isStandardConstruction: e.target.value,
                });
              }}
            >
              <option value="" selected disabled>
                select option
              </option>
              <option value="yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          {/* <div className={styles.answerBlock}></div> */}

          <div className={styles.answerBlock}>
            {" "}
            <textarea
              name=""
              placeholder="Tell us more"
              id=""
              cols="40"
              rows="5"
              className={classNames(styles.answer, styles.textarea)}
              value={formdata.constructionDescription}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  constructionDescription: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </div>
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              What is the tenure of the property?
            </span>
          </label>
        </div>

        <div className={styles.inputBox}>
          <div className={styles.answerBlock}>
            <select
              name=""
              id=""
              className={styles.answer}
              value={formdata.tenureOfPropertyAnswer}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  tenureOfPropertyAnswer: e.target.value,
                });
              }}
            >
              <option value="" selected disabled>
                select lease type
              </option>
              <option value="Freehold">Freehold</option>
              <option value="Leasehold">Leasehold</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        {formdata.tenureOfPropertyAnswer === "Other" && (
          <div className={styles.inputBox}>
            <div className={styles.answerBlock}>
              <textarea
                name=""
                id=""
                value={formdata.otherLeaseInfo}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    otherLeaseInfo: e.target.value,
                  });
                }}
                className={classNames(styles.answer, styles.textarea)}
                cols="40"
                rows="5"
                placeholder="Lease description"
              ></textarea>
            </div>
          </div>
        )}
        {formdata.tenureOfPropertyAnswer === "Leasehold" && (
          <div className={styles.inputBox}>
            <div className={styles.answerBlock}>
              <input
                type="number"
                placeholder="Number of years remanining on the lease"
                className={styles.answer}
                value={formdata.remainingYearsForLease}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    return false;
                  }
                  setFormdata({
                    ...formdata,
                    remainingYearsForLease: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        )}
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              Please describe the asset:
            </span>
          </label>
          <div className={styles.answerBlock}>
            {" "}
            <textarea
              name=""
              placeholder="Describe asset"
              id=""
              cols="40"
              rows="5"
              className={classNames(styles.answer, styles.textarea)}
              value={formdata.assetDescription}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  assetDescription: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </div>
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section2)}
          >
            <span className={styles.questionText}>
              {" "}
              Would you like to add another property?
            </span>
          </label>
          <div className={styles.answerBlock}>
            <select
              name=""
              id=""
              className={styles.answer}
              value={formdata.addAnotherProperty}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  addAnotherProperty: e.target.value,
                });
              }}
            >
              <option value="" selected disabled>
                select option
              </option>
              <option value="yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AboutSecurity;
