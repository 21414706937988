import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerB11 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Difficult Bridging Loan{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li> Single Property Rates of 75% LTV</li>
          <li>Multiple Properties Offers Potential 100% LTV</li>
          <li>Rates Start from 0.75% per month</li>
          <li>3-Hour Application Decision and 48-Hour Funding in Some Cases</li>
          <li>169 Funders to Provide Options for Funding for all Cases</li>
        </ul>
        <div className={styles.text}>
          <p>
            If you have been finding it difficult to find a bridging loan for
            your specific circumstances, worry no more. Bridging 365 has the
            experience, skill-set and connections to a vast panel of funders
            that enables us to offer a service like no other. For a difficult
            bridging loan situation can become a simple and straightforward one
            when working with us to secure your funding future.
          </p>
          <p>
            Bridging 365 has experience in assisting numerous clients with
            difficult bridging loan requirements. We have helped our clients
            with financing to help with the following situations:
          </p>
          <ul>
            <li>
              Help to prevent a house repossession order from going through
            </li>
            <li>
              {" "}
              Assistance in paying off large tax bills for business purposes
            </li>
            <li>Commercial growth and development for individual companies</li>
            <li>
              Fund construction costs for residential and commercial building
              developments
            </li>
            <li>
              Plug the funding gap for property purchases stuck in a chain
            </li>
            <li>Fund properties purchased at auction</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerB11;
