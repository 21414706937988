import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainer1 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
              ? styles.sRepo
              : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
        )}
      >
        100% Development Funding
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
        )}
      >
        <ul>

          <li>Funding for residential and commercial property developers and builders
          </li>
          <li>Development funding from £25,000 up to £50 million
          </li>
          <li>Quick application decisions (Usually within a 3-hour period)
          </li>
          <li>Flexible terms offered (from 1 to 36-months
          </li>
          <li>100% development and construction funding options available
          </li>
          <li>Up to 85% GDV and 100% of land costs with additional security
          </li>
          <li>95% Loan to Cost (LTC)
          </li>
        </ul>
        <div className={styles.text}>

          <p> <strong>Development Funding Options</strong> <br />
            We understand how important it is to garner as much funding as possible when putting together a development funding stack for an upcoming project. Acquiring 100% development funding is desirable in many cases, and it is a realistic possibility with Bridging365.</p>
          <p>With that in mind, our credit team has worked tirelessly to make connections with a vast array of development loan lenders and funders with specialities in myriad industries and situations. What we can do is find the perfect fit for your specific development funding application, offering up to 100% of land cost and 85% of GDV (Gross Development Value), with an option for 100% of all property development costs as long as the total amount you borrow is less than 85% of the GDV.</p>
          <p> <strong>Large Development Funding Facilities (Development loans £10m and above)</strong> <br />

The larger the development project, the more important it is to secure the right level, and type, of funding to secure the sustainability of the entire process. Our team can work with you from start to finish, putting in place the exact lending stream that can help you put together a stellar plan of action that fits your strict deadlines, plans and budgetary requirements without delay. </p>
          <p>To qualify for the lower rates offered with a large development loan, the minimum amount borrowed has to be in excess of a £10 million pounds.  Alongside the reduced rates there is often an extension in the term length and options to mitigate any monthly repayments by adding that amount to the loan itself.  The option to apply for 100% of development is available in certain scenarios, however to achieve competitive rates it is important to keep the LTC to a reasonable level.</p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainer1;
