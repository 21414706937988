import { useEffect } from "react";
import styles from "../../DevFinanceForm.module.scss";
import Question from "../Question";
import { AnswerDropdown, AnswerTextarea } from "../Answer";
import AddProperty from "../AddProperty";
import securityGif from "../../../../assets/images/security.gif";
import securitypng from "../../../../assets/images/security.png";
import securityGif1 from "../../../../assets/images/About_security1.gif";

const AboutSecurity = ({ formData, setFormData, setSecurityProgress }) => {
  useEffect(() => {
    const values = ["address1", "additionalSecurityDetails"];

    let score = 0;
    for (let i = 0; i < values.length; i++) {
      if (typeof formData[values[i]] === "object") {
        const result = Object.values(formData[values[i]]).filter((val) => val);

        let count = 0;
        result.forEach((v) => {
          if (v) count++;
        });

        score += count * 0.16;
      } else if (formData[values[i]] != 0 && formData[values[i]] != "") {
        score += 1;
      }
    }

    setSecurityProgress((score / values.length) * 100);
  }, [formData, setSecurityProgress]);

  return (
    <section id="section2">
      <div className={styles.section} id="section2">
        <div className={styles.banner} style={{ backgroundColor: "#673ab7" }}>
          <span style={{ padding: "1rem 0rem 1rem 2rem" }}>
            {" "}
            <img className={styles.formHeader} src={securityGif1} />
          </span>
          <p
            className={styles.head}
            style={{ flex: "1 1 100%", textAlign: "center" }}
          >
            ABOUT THE SECURITY{" "}
            <div style={{ color: "#4caf50" }}>SECTION 2 OF 5</div>{" "}
          </p>
          <span style={{ padding: "1rem 2rem 1rem 0rem" }}>
            {" "}
            <img className={styles.formHeader} src={securitypng} />
          </span>
        </div>

        <div className={styles.gifContainer}>
          <img src={securityGif} alt="" className={styles.gif} />
        </div>
        <p
          className={styles.text}
          style={{ backgroundColor: "#673ab7", marginBottom: "2rem" }}
        >
          Now we understand how much you’re looking to borrow, can you now tell
          us about the asset you are borrowing against?
        </p>
      </div>
      <AddProperty
        name="address1"
        formData={formData}
        setFormData={setFormData}
        setSecurityProgress={setSecurityProgress}
      />
      <div className={styles.inputBox}>
        <Question
          question="Would you like to add another property?"
          section="section2"
        />
        <AnswerDropdown
          options={["Yes", "No"]}
          value={formData.hasAnotherProperty}
          onChange={(e) => {
            setFormData({
              ...formData,
              hasAnotherProperty: e.target.value,
            });
          }}
        />
      </div>
      {formData.hasAnotherProperty === "Yes" && (
        <AddProperty
          name="address2"
          formData={formData}
          setFormData={setFormData}
          setSecurityProgress={setSecurityProgress}
        />
      )}
      <div className={styles.inputBox}>
        <Question
          question="Details of any additional security being offered?"
          section="section2"
        />
        <AnswerTextarea
          placeholder="Details"
          value={formData.additionalSecurityDetails}
          onChange={(e) => {
            setFormData({
              ...formData,
              additionalSecurityDetails: e.target.value,
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <div className={styles.heading}>
          If the borrower is limited liability vehical is it likely that we will
          require personal gaurentees from directors or partners
        </div>
        <Question question="Who will be borrower?" section="section2" />
        <AnswerDropdown
          options={["Person", "Company"]}
          value={formData.borrower}
          onChange={(e) => {
            setFormData({ ...formData, borrower: e.target.value });
          }}
        />
      </div>
    </section>
  );
};

export default AboutSecurity;
