import React, { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import styles from "./UserDashBoard.module.scss"
import { BiUserCircle } from 'react-icons/bi';
import { FaWpforms } from 'react-icons/fa';
import { GrDocumentUpload } from 'react-icons/gr';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { GrDocumentVerified } from 'react-icons/gr';
const UserDashBoard = () => {
  useEffect(() => {
    document.title = "Bridging 365 | User";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.userDashBoard}>
      <div className={styles.left}>
        <ul>
          <li> <NavLink to="/user/profile" className={(navData) => (navData.isActive ? styles.active : '')}><BiUserCircle className={styles.icon} /> Profile</NavLink> </li>
          <li> <NavLink to="/user/forms" className={(navData) => (navData.isActive ? styles.active : '')}><HiOutlineDocumentText className={styles.icon} /> Forms</NavLink> </li>
          <li> <NavLink to="/user/upload-documents" className={(navData) => (navData.isActive ? styles.active : '')}><GrDocumentUpload className={styles.icon} />Upload Documents</NavLink> </li>
          <li> <NavLink to="/user/uploaded-documents" className={(navData) => (navData.isActive ? styles.active : '')}><FaWpforms className={styles.icon} />Uploaded Documents</NavLink> </li>
          <li> <NavLink to="/user/accepted-documents" className={(navData) => (navData.isActive ? styles.active : '')}><GrDocumentVerified className={styles.icon} />Documents Guide </NavLink> </li>
        </ul>
      </div>
      <div className={styles.right}>
        <Outlet />
      </div>
    </div>
  );
};
export default UserDashBoard;
