import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/LoanApplication.module.scss";
import phoneGreenImg from "../../assets/images/phone-green.png";
import phoneBlueImg from "../../assets/images/phone.png";
import phoneOrangeImg from "../../assets/images/phone-orange.png";
import classNames from "classnames";
const LoanApplication5 = ({ title }) => {
  return (
    <div className={styles.loanApplication}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
Development Finance Expertise from Bridging365      </p>
      <div
        className={classNames(
          styles.content,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <div className={styles.left}>
          <p>
          The process of applying for development finance has never been easier. We have a simple development finance form that you can fill out in a matter of minutes, here on this very website. If you would like to speak in more detail with a member of our team, we can certainly help. With access to our vast network of funders, Bridging365 has all the tools to help you put together a successful first development project. 

          </p>
          <p>
          We appreciate that the first time is often the hardest, and that’s why we’ve put in place a service that matches the expectations of our clients, helping them succeed and build the strong foundations for a successful property development portfolio. Our rates start from 0.75% and we can offer up to 100% LTV where additional security, other than the primary property or plot of land, is available.

          </p>
          <p>
          Contact our team today and we’ll happily put together some advice and guidance on development loans for a first time developer.

          </p>
          {/* <p>
            Bridging Finance Benefits <br /> The biggest benefit to bridging
            finance is that it is an acceptable form of lending for individuals
            who have previously suffered due to a poor credit file. Although
            there are higher costs associated than with a loan through
            traditional funders, the ability to acquire credit in dire financial
            circumstances provides hope for a brighter future to those who could
            previously see none.
          </p>
          <p>
            Bridging finance ensures that you are not limited because of bad
            credit, helping to prevent a repossession notice against a property,
            allowing you to purchase a property at auction or take on board
            another short-term opportunity, and allowing you to purchase a new
            home whilst waiting for your current property to sell.
          </p>
          <p>
            It doesn’t have to be difficult to acquire credit if you have bad
            credit, or an adverse credit history. At Bridging 365 we have a vast
            panel of funders offering a variety of bridging loan solutions to
            those applicants with bad credit. We have a strong history of
            helping those who are unfortunately in financial difficulty and
            require some assistance in short-term finances.
          </p>
          <p>
            Contact our customer service team and we’ll be happy to discuss your
            circumstances in full and put together some options for your future
            financial situation, including bridging loans to help plug
            short-term holes in your finances. We can help stop a repossession
            order in its tracks, and in some cases won’t even need to perform a
            credit check. Rates start from as low as 0.75% per month.
          </p> */}
        </div>
        <div
          className={classNames(
            styles.right,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : ""
          )}
        >
          <p className={styles.rightHeader}>Confused by the options?</p>
          <p className={styles.rightText}>
            Choosing the right Bridge for you can be really tricky. At Bridging 365
            our expert advisers guide you through the process. Simple and
            efficient.
          </p>
          <Link
            to="#"
            className={classNames(
              styles.link,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            Start Online
          </Link>
          <p className={styles.callText}>Call free from mobile or landline</p>
          <p
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            {" "}
            <img
              src={
                title === "Bridging Loans"
                  ? phoneGreenImg
                  : title === "Development Finance"
                  ? phoneBlueImg
                  : title === "Stop Repossessions"
                  ? phoneOrangeImg
                  : ""
              }
              alt=""
            />{" "}
            0208 146 9888
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanApplication5;
