import React, { useState } from "react";
import styles from "../styles/Contact.module.scss";
import { AiTwotoneMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import contact from "../assets/images/Contact us.gif";
import NewsLetterSignUp from "../components/NewsLetterSignUp";
import loading from "../assets/images/loading.gif";
import { database } from "../FirebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import Contact_us from "./animations/Contact_us";

const Contact = () => {
  const initialValue = {
    name: "",
    phone: "",
    email: "",
    message: "",
  };
  const [contactInfo, setContactInfo] = useState(initialValue);
  const [showLoading, setShowLoading] = useState(false);
  const collectionRef = collection(database, "contacts");

  const btnHandler = () => {
    if (
      !contactInfo.name ||
      !contactInfo.phone ||
      !contactInfo.email ||
      !contactInfo.message
    ) {
      alert("please fill all the deatils");
      return;
    } else if (contactInfo.name.length < 5) {
      alert("Name must contain minimum 5 characters");
      return;
    } else if (!contactInfo.email.match(/[a-z0-9]+@[a-z]+.[a-z]{2,3}/)) {
      alert("Please enter valid email address");
      return;
    } else if (contactInfo.message.length < 20) {
      alert("Message must contain minimum 20 character");
      return;
    } else {
      setShowLoading(true);
      addDoc(collectionRef, contactInfo)
        .then((response) => {
          console.log(response);
          alert("We received your message. Thank you ! ");
          setShowLoading(false);
          setContactInfo(initialValue);
        })
        .catch((error) => {
          console.log(error);
          alert("Oops !! Something went wrong. ");
          setShowLoading(false);
        });
    }
    console.log(contactInfo);
  };
  return (
    <div className={styles.contact}>
      <div className={styles.section1}>
        <div className={styles.left}>
          <form action="" className={styles.form}>
            <p className={styles.header}>Contact us</p>
            <p className={styles.subHead}>
            We’d love to hear from you. Give us a call on 0208 146 9888 or fill out a few details below and we’ll be in touch.
            </p>
            <input
              type="text"
              value={contactInfo.name}
              onChange={(e) =>
                setContactInfo({ ...contactInfo, name: e.target.value })
              }
              placeholder="Enter your name"
            />
            <input
              type="number"
              value={contactInfo.phone}
              onChange={(e) => {
                if (e.target.value.length > 10) {
                  return false;
                }
                setContactInfo({ ...contactInfo, phone: e.target.value });
              }}
              placeholder="Enter your phone number"
            />
            <input
              type="email"
              value={contactInfo.email}
              placeholder="Enter your email"
              onChange={(e) =>
                setContactInfo({ ...contactInfo, email: e.target.value })
              }
            />
            <textarea
              name=""
              value={contactInfo.message}
              placeholder="Enter your message"
              onChange={(e) =>
                setContactInfo({ ...contactInfo, message: e.target.value })
              }
              id=""
              cols="30"
              rows="5"
            ></textarea>
            <input type="button" value="Submit" onClick={btnHandler} />
          </form>
        </div>
        <div className={styles.right}>
          <Contact_us/>
        </div>
      </div>
      <div className={styles.section2}>
        <div className={styles.card}>
          <a href="mailto:someone@example.com">
            <AiTwotoneMail className={styles.icon} />
          </a>
          <p>admin@bridging365.com</p>
        </div>
        <div className={styles.card}>
          <a href="tel:0208 146 9888">
            <BsFillTelephoneFill className={styles.icon} />
          </a>
          <p>0208 146 9888</p>
        </div>
        <div className={styles.card}>
          <a href="/#">
            <ImLocation className={styles.icon} />
          </a>
          <p>30 Churchill Place, Canary Wharf London, E14 5EU</p>
        </div>
      </div>
      <div className={styles.section3}>
        <NewsLetterSignUp />
      </div>
      {showLoading && (
        <img src={loading} className={styles.loadingImg} alt="" srcset="" />
      )}
    </div>
  );
};

export default Contact;
