import React, { useState } from "react";
import styles from "../CalculatorPage.module.scss";
import loading from "../../../assets/images/loading.gif";
import { useNavigate } from "react-router-dom";
const BridgingLoanCalculator = () => {
  const rolledUpPaymentData = [
    {
      label: "Your Requested Loan Amount",
      value: 123,
    },
    {
      label: "Total Amount to Payback",
      value: 1233,
    },
    {
      label: "Maximum Loan Available",
      value: 123,
    },
    {
      label: "Total Amount to Payback",
      value: 123,
    },
  ];
  const servicedLoanPaymentData = [
    {
      label: "Your Requested Loan Amount",
      value: 123,
    },
    {
      label: "Monthly Payment Due",
      value: 1233,
    },
    {
      label: "Total Amount to Payback",
      value: 123,
    },
    {
      label: "Maximum Loan Available",
      value: 123,
    },
    {
      label: "Monthly Payment Due",
      value: 123,
    },
    {
      label: "Total Amount to Payback",
      value: 123,
    },
  ];
  const [showResults, setShowResults] = useState(false);
  const initialValue = {
    valuation: "",
    loanAmount: "",
    term: "",
    interestRate: "",
  };
  const [input, setInput] = useState(initialValue);
  const [showLoading, setShowLoading] = useState(false);
  const viewResultHandler = () => {
    if (
      !input.valuation ||
      !input.loanAmount ||
      !input.term ||
      !input.interestRate
    ) {
      alert("Please enter all the inputs");
      return;
    }
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
      setShowResults(true);
    }, 1000);
  };
  const resetHandler = () => {
    setInput(initialValue);
    setShowResults(false);
  };
  const inputValueChangeHandle = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const maxBorrowLimit = input.valuation * 0.75;
  const navigate = useNavigate();
  const btnHandler = () => {
    navigate("/form");
  };
  return (
    <div className={styles.bridgingLoanCalculator}>
      <div className={styles.top}>
        <p className={styles.header}>Bridging Loan calculator</p>
        <div className={styles.inputs}>
          <div className={styles.inputBlock}>
            <label htmlFor="">Property Valuation</label>
            <input
              type="number"
              name="valuation"
              value={input.valuation}
              onChange={(e) => inputValueChangeHandle(e)}
              placeholder="Enter amount in £"
            />
          </div>
          <div className={styles.inputBlock}>
            <label htmlFor="">Loan Amount £</label>
            <input
              type="number"
              name="loanAmount"
              value={input.loanAmount}
              onChange={(e) => inputValueChangeHandle(e)}
              placeholder="Enter amount in £"
            />
          </div>
          <div className={styles.inputBlock}>
            <label htmlFor="">Term in months</label>
            <input
              type="number"
              name="term"
              value={input.term}
              onChange={(e) => inputValueChangeHandle(e)}
              placeholder="Enter in months"
            />
          </div>
          <div className={styles.inputBlock}>
            <label htmlFor="">Interest rate</label>
            <input
              type="number"
              name="interestRate"
              value={input.interestRate}
              onChange={(e) => inputValueChangeHandle(e)}
              placeholder="Enter interest rate"
            />
          </div>
        </div>
        <div className={styles.btns}>
          <button
            onClick={viewResultHandler}
            className={showResults ? styles.disabled : ""}
            disabled={showResults}
          >
            View results
          </button>
          <button
            onClick={resetHandler}
            disabled={!showResults}
            className={!showResults ? styles.disabled : ""}
          >
            Reset
          </button>
        </div>
      </div>
      {showResults && (
        <div className={styles.bottom}>
          <div className={styles.bottomLeft}>
            <p className={styles.bottomHead}>Rolled Up (No Monthly Payments)</p>

            <div className={styles.result}>
              {rolledUpPaymentData.map((item, key) => (
                <div className={styles.resultItem} key={key}>
                  <p className={styles.label}> {item.label}</p>
                  <p className={styles.value}>{item.value}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.bottomCenter}>
            <p>Based on your valuation, your maximum borrow </p>
            <p className={styles.number}>£ {maxBorrowLimit}</p>
            <button onClick={btnHandler}>Apply Now</button>
          </div>
          <div className={styles.bottomRight}>
            <p className={styles.bottomHead}>
              Serviced Loans (Make a monthly Payment)
            </p>

            <div className={styles.result}>
              {servicedLoanPaymentData.map((item, key) => (
                <div className={styles.resultItem} key={key}>
                  <p className={styles.label}>{item.label}</p>
                  <p className={styles.value}>{item.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className={styles.loadingContainer}>
        {showLoading && (
          <img src={loading} className={styles.loadingImg} alt="" />
        )}
      </div>
    </div>
  );
};

export default BridgingLoanCalculator;
