import React, { useEffect } from "react";
import styles from "../DevelopmentFinanceBlogs/DevelopmentFinanceBlogs.module.scss";
import Footer from "../../components/Footer";
import { useParams } from "react-router-dom";
import NewsContainer from "../../components/NewsContainer";
import CompareContainerC1 from "../../components/CompareContainer/CompareContainerC1";
import MatchingMortgagesC1 from "../../components/MatchingMortgages/MatchingMortgagesC1";
import LoanApplicationC1 from "../../components/LoanApplication/LoanApplicationC1";
import HeroSection from "../../components/HeroSection/HeroSection";
import TestimonialsContainer from "../../components/TestimonialsContainer";
import CallAdviser from "../../components/CallAdviser";
const CaseStudies1 = () => {
  const { blogId } = useParams();
  console.log(blogId);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.developmentFinanceBlogs}>
      <HeroSection title="Resources" />
      <CompareContainerC1 title="Resources" />
      <CallAdviser title="Resources" />
      <MatchingMortgagesC1 title="Resources" />
      <NewsContainer title="Resources" />
      <LoanApplicationC1 title="Resources" />
      <TestimonialsContainer title="Resources" />
      <Footer title="Resources" />
    </div>
  );
};

export default CaseStudies1;
