import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerC4 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : title === "Resources"
          ? styles.reSources
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        Bridging Loan for Paying of Sibling in an Inherited Property{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <ul>
          <li>
            Quick Decision on all Inherited Property Bridging Loan Applications
          </li>
          <li>Loans from £25k up to £50m</li>
          <li>Access to 13 Funders for Unregulated Bridging Loans</li>
          <li>Rates from 0.29%</li>
          <li>
            Access to Funds in order to Pay Sibling for Inherited Property
          </li>
        </ul>
        <div className={styles.text}>
          <p>
            When a family member dies it is always a difficult and emotional
            time. If there is a property that has been left in a will to other
            family members, time is of the essence in terms of sorting out
            inheritance tax and other associated components of an estate. Should
            you be given ownership an inherited property with siblings, or other
            family members, the best case scenario might be to pay your sibling
            in order to uncomplicated matters. From there you can clear
            inheritance tax debts, sell the property and manage the finances of
            the situation much more clearly.
          </p>
          <p>
            A bridging loan can be used to pay a sibling in an inherited
            property, and comes at a time where grieving family and friends need
            some assistance more than ever. ed has to be in excess of a £10
            million pounds. Alongside the reduced rates there is often an
            extension in the term length and options to mitigate any monthly
            repayments by adding that amount to the loan itself. The option to
            apply for 100% of development is available in certain scenarios,
            however to achieve competitive rates it is important to keep the LTC
            to a reasonable level
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerC4;
