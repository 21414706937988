import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesB5 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Flexible Rates and an Understanding of Your Situation{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          How have we achieved that? Well, it’s taken quite a few years of
          dedicated work and a commitment to always strive to improve, adding
          high quality funding lines, including many specialist and niche
          funders, to our panel. It now stands at 169, with over 90 offering
          unregulated loans and even 13 funders that specialise in providing
          bridging loans for the purposes of inherited properties.
        </p>
        <p>
          This is where we feel we can offer you something more than just the
          comfort of a traditional high street lender such as Barclays, with a
          bridging loan service that is truly bespoke to your situation, the
          finances you require and your history. Some of our funders do not even
          require a credit check, and we accept applications from those with
          bankruptcy notices on their files, poor credit and those looking for
          short-term funding to prevent a home from being repossessed due to
          missed mortgage payments. We really can cater for all situations and
          scenarios and have the track record that delivers to our customers.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesB5;
