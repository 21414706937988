import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/LoanApplication.module.scss";
import phoneGreenImg from "../../assets/images/phone-green.png";
import phoneBlueImg from "../../assets/images/phone.png";
import phoneOrangeImg from "../../assets/images/phone-orange.png";
import classNames from "classnames";
const LoanApplication3 = ({ title }) => {
  return (
    <div className={styles.loanApplication}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Contact Bridging 365 for Development Finance with Bad Credit{" "}
      </p>
      <div
        className={classNames(
          styles.content,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <div className={styles.left}>
          <p>
            Development finance ,bad Credit. You might not think that those two
            phrases go together and traditionally you’d be right. Bridging 365
            has the answers however, to ensure that even a developer with bad
            credit can acquire the finance required to fund a development
            project. We offer up to 75% GDV and 100% construction and land costs
            where applicable, and our expertise in the area gives us the proven
            track record that supplies you with the tools you need to get the
            job done and to maximise your experience and profits.
          </p>
          <p>
            Call our friendly customer service team today for more information,
            or fill out the simple-to-use development finance application form
            on this site and we’ll be in touch shortly.
          </p>
        </div>
        <div
          className={classNames(
            styles.right,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : ""
          )}
        >
          <p className={styles.rightHeader}>Confused by the options?</p>
          <p className={styles.rightText}>
            Choosing the right Bridge for you can be really tricky. At Bridging
            365 our expert advisers guide you through the process. Simple and
            efficient.
          </p>
          <Link
            to="#"
            className={classNames(
              styles.link,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            Start Online
          </Link>
          <p className={styles.callText}>Call free from mobile or landline</p>
          <p
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            {" "}
            <img
              src={
                title === "Bridging Loans"
                  ? phoneGreenImg
                  : title === "Development Finance"
                  ? phoneBlueImg
                  : title === "Stop Repossessions"
                  ? phoneOrangeImg
                  : ""
              }
              alt=""
            />{" "}
            0208 146 9888
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanApplication3;
