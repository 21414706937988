import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesB8 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Expand Your Business with Commercial Bridging Loans{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          Where commercial interests are concerned, time is very much of the
          essence. For targets to be met, both in the short and the long-term,
          and for growth to continue on a consistent and constant level,
          developments cannot afford to be restricted and delayed.
        </p>
        <p>
          <strong>
            Types of properties considered for a commercial bridging loan
            include:
          </strong>
        </p>
        <p>
          <ul>
            <li>
              Commercial Properties (with Residential Flats attached), including
              retail shops, garages, public houses, cafes, restaurants, garden
              centres, B&Bs, and industrial units
            </li>
            <li>
              Catering and Hospitality (with Residential Flats attached),
              including cafes, restaurants, takeaways
            </li>
            <li>
              Apartment Complexes including multiple flats on a single title,
              multiple flats with split titles the objective, HMO (Houses with
              multiple occupancy)
            </li>
            <li>
              Residential Care Properties including nursery homes with live-in
              carers
            </li>
            <li>
              Professional Properties such as GP surgeries, Veterinary
              surgeries, Farms (including those with owner accommodation),
              Private Members Clubs, and Country Houses
            </li>
            <li>
              Educational Establishments including private schools and colleges
              (including boarding schools), as well as places of religious
              education
            </li>
            <li>Mixed Use Properties including Charity organisations</li>
          </ul>
        </p>
        <p>
          Bridging 365 has commercial bridging funders who understand this and
          are here to work with you. If you are looking to arrange short term
          commercial lending to purchase a commercial premise, then we have
          funders who rates start at 0.75% per month and can help you expand
          your holdings with a short term commercial bridging loan. A business
          owner might be looking to purchase a new property for commercial use,
          either to move premises or to expand. There are many risks involved
          with expanding as a business and taking on new staff, new premises and
          infrastructure. The most successful businesses are those that lower
          the associated risks and look to take on board opportunities that
          assist with the smooth transition.
        </p>
        <p>
          <strong>A Clear Exit Strategy Will Assist Growth</strong>
          Commercial bridging loans provide that peace of mind, even in the most
          awkward and time-sensitive commercial transaction. One example could
          be that the property you have purchased has been bought at auction and
          requires extensive renovation before it is ready for use.
        </p>
        <p>
          <strong>
            What if you don’t have the finances in place to ensure this takes
            place to a high standard and in a short period of time?
          </strong>
          Don’t worry, as a fast commercial bridging loan can help you
          transition smoothly. It works by lowering the risk to the purchasing
          party if the initial costs are not yet in place. As long as a business
          has a strong plan that includes the future funds to cover a bridging
          loan, it can provide the short-term means to make the move, purchase
          the property and renovate without first having to wait for the funds
          to be released. Waiting for even a short few days can cost a business
          even more in time and money, and restrict the potential for growth in
          the medium-term. As well as organising a fast-short term commercial
          bridging loan, we would look to help you gain longer-term funding by
          arranging three commercial loans for you. As we are responsible
          commercial bridging loan brokers, we would have helped you start the
          process of long term commercial funding at the start of the bridging
          process, working in tandem to ensure a smooth transaction, saving you
          time and money.
        </p>
        <p>
          <strong>Using Bridging Loans in Commercial Developments</strong> In
          the majority of commercial developments, property developers will use
          bridging or development finance in order to fund the beginning of, and
          the continuation of projects, ensuring completion over a shorter
          period of time than if they were to wait for the entire funds to be in
          position before commencing with the build or renovation. Depending on
          the size of the loan our rates would start at 0.75% for commercial
          bridging finance and we could spread the loan over 36-months if
          needed. However, most clients prefer and 18-month development
          commercial loan. However, the loan is tailored for your specific needs
          so please do call or contact us at Bridging 365 to see how we can
          help. As there are a number of exit strategies for the commercial
          bridging loan, you can either sell on completion of the project, once
          a sale has been made on the property in question, you can pay off the
          secured commercial bridging finance or if a longer-term investment
          opportunity can be made through moving the bridging loan into a
          commercial mortgage we would help find a long-term funder to exit the
          bridging loan.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesB8;
