import Question from "../Question";
import { AnswerText, AnswerDropdown } from "../Answer";
import styles from "../../DevFinanceForm.module.scss";

const AddDirector = ({ formData, setFormData, name, setDirectorProgress }) => {
  return (
    <>
      <div className={styles.inputBox}>
        <Question
          question="What is the Director's first name?"
          section="section4"
        />
        <AnswerText
          type="text"
          placeholder="Name"
          value={formData[name].firstName}
          onChange={(e) => {
            if (!formData[name].firstName)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                firstName: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="What is the Director's last name?"
          section="section4"
        />
        <AnswerText
          type="text"
          placeholder="Address"
          value={formData[name].lastName}
          onChange={(e) => {
            if (!formData[name].lastName)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                lastName: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question question="What is your title?" section="section4" />
        <AnswerText
          type="text"
          placeholder="Address Line 2"
          value={formData[name].title}
          onChange={(e) => {
            if (!formData[name].title)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                title: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question question="When were you born?" section="section4" />
        <AnswerText
          type="text"
          placeholder="City or Town"
          value={formData[name].birthYear}
          onChange={(e) => {
            if (!formData[name].birthYear)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                birthYear: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="1st line of the Director's address"
          section="section4"
        />
        <AnswerText
          type="text"
          placeholder="Postcode"
          value={formData[name].address1}
          onChange={(e) => {
            if (!formData[name].address1)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                address1: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Enter the Director's address line 2 (optional)"
          section="section4"
        />
        <AnswerText
          type="text"
          placeholder="Postcode"
          value={formData[name].address2}
          onChange={(e) => {
            if (!formData[name].address2)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                address2: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question question="Town or City" section="section4" />
        <AnswerText
          type="text"
          placeholder="Postcode"
          value={formData[name].townOrCity}
          onChange={(e) => {
            if (!formData[name].townOrCity)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                townOrCity: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question question="Postcode?" section="section4" />
        <AnswerText
          type="text"
          placeholder="Postcode"
          value={formData[name].postcode}
          onChange={(e) => {
            if (!formData[name].postcode)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                postcode: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="What is the Best Number To Contact You?"
          section="section4"
        />
        <AnswerText
          type="text"
          placeholder="Postcode"
          value={formData[name].contact}
          onChange={(e) => {
            if (!formData[name].contact)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                contact: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question question="Enter the Director’s Email" section="section4" />
        <AnswerText
          type="text"
          placeholder="Postcode"
          value={formData[name].email}
          onChange={(e) => {
            if (!formData[name].email)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                email: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question question="Director's % Share holding ?" section="section4" />
        <AnswerText
          type="text"
          placeholder="Postcode"
          value={formData[name].shareHolding}
          onChange={(e) => {
            if (!formData[name].shareHolding)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                shareHolding: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="What is the Director's Domicile?"
          section="section4"
        />
        <AnswerText
          type="text"
          placeholder="Postcode"
          value={formData[name].domicile}
          onChange={(e) => {
            if (!formData[name].domicile)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                domicile: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Have you had any CCJ's or the Defaults in the last 6 years?"
          section="section4"
        />
        <AnswerText
          type="text"
          placeholder="Postcode"
          value={formData[name].hadCCJ}
          onChange={(e) => {
            if (!formData[name].hadCCJ)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                hadCCJ: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Have you ever been declared bankrupt?"
          section="section4"
        />
        <AnswerText
          type="text"
          placeholder="Postcode"
          value={formData[name].everBankrupt}
          onChange={(e) => {
            if (!formData[name].everBankrupt)
              setDirectorProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                everBankrupt: e.target.value,
              },
            });
          }}
        />
      </div>
    </>
  );
};

export default AddDirector;
