import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesC1 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        How Have We Helped Over 60s with a Bridging Loan?{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <p>
          If you have a specific reason behind wishing to secure bridging
          finance, you might be wondering what we have done in the past to help
          people over the age of 60 with their needs. Below are just three
          examples of clients we have massively helped at a time where
          traditional high street funders would no longer offer them credit and
          funding as they would have in previous years.
        </p>
        <p>
          <strong>Bridging Loan to Purchase a Property </strong>
          For people over the age of 60 it can be extremely difficult to find
          the funding to purchase a property. Seeking out a mortgage can be
          difficult, but we have helped clients who were stuck in a property
          chain, but had found that dream home to make the final move they
          planned to ever make. Instead of missing out, we helped them secure
          financing that pushed through the purchase of the new home, whilst the
          current property was sold.
        </p>
        <p>
          {" "}
          <strong>
            Bridging Finance to Develop a House Bought at Auction{" "}
          </strong>
          Another client came to us with an idea to buy a house at auction in
          order to develop it and build up a nest egg for family members. We put
          them in touch with one of our bridging funders in order to put forward
          the short-term cash that would ensure a house bought at auction could
          go through in a short-space of time, allowing for the time for funding
          to be found in the new property as it was being developed.
        </p>
        <p>
          {" "}
          <strong>Property Refurbishment Funding </strong>
          Another pensioner came to us looking for bridging finance to help
          refurbish the home she was currently living. It required modifications
          for her and her partner later in life, as well as renovating to add an
          extension for family members to move into. We helped them find the
          funding to put in motion all construction costs, which they could then
          utilise to refinance the property at a later date.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesC1;
