import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerB6 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Bridging Loan Example{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          There are a number of bridging loan example scenarios to consider when
          putting together an application for funding. The following situations
          have all been worked on successfully by our team, helping clients
          secure the short-term funding they require to ensure that an
          opportunity is not missed, or an urgent financial situation is solved
          before the worst case scenario takes place.
        </p>
        <p>
          If you require bridging finance for the following situations, Bridging
          365 has the experience, know-how, and access to a large and varied
          panel of funders to ensure that we can help with:
        </p>
        <ul>
          <li> Property Purchase in a Chain Scenario</li>
          <li>Property Purchased at Auction</li>
          <li>
            Property Refurbishment Projects with a View to a Sale or Refinancing
          </li>
          <li>Clearing Mortgage Arrears to Halt Repossession Order</li>
          <li>Payment of Tax Bills</li>
          <li>Commercial Development Project</li>
          <li>Business Expansion</li>
        </ul>
        <div className={styles.text}>
          {/* <p>For many landlords and property developers the best route towards creating an effective and legitimate property portfolio is to take one property and turn it into multiple properties. Over recent years Houses of Multiple Occupation (or HMOs) have become more popular amongst landlords, as houses are converted from one property into multiple flats and apartments. This offers great benefit to the landlord in terms of potential rental yield and selling potential, but how difficult is it to acquire financing that will help you convert a property into a HMO?</p> */}
        </div>
      </div>
    </div>
  );
};

export default CompareContainerB6;
