import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../styles/Guide.module.scss";
import {
  blogListArray1,
  blogListArray2,
  blogListArray3,
  blogListArray4,
} from "../data/guide";
const Guide = ({ title }) => {
  useEffect(() => {
    document.title = "Bridging 365 | Development Finance";
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation().pathname;
  const [finalArray, setFinalArray] = useState([]);
  const [heading, setHeading] = useState("");

  useEffect(() => {
    setFinalArray(
      location === "/bridgingloans/guide"
        ? blogListArray1
        : location === "/developmentfinance/guide"
          ? blogListArray2
          : location === "/casestudies/guide"
            ? blogListArray3
            : location === "/stoprepossessions/guide"
              ? blogListArray4
              : blogListArray1
    );
    setHeading(
      location === "/bridgingloans/guide"
        ? "Bridging Loans"
        : location === "/developmentfinance/guide"
          ? "Development Finance"
          : location === "/casestudies/guide"
            ? "Case studies"
            : location === "/stoprepossessions/guide"
              ? "Stop Reposessions"
              : location === "/rebridgingloans/guide"
                ? "Rebridging Loans"
                : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames(
        styles.guide,
        heading === "Bridging Loans"
          ? styles.bLoans
          : heading === "Development Fianance"
            ? styles.dFinance
            : heading === "Stop Repossessions"
              ? styles.sRepo
              : heading === "Case studies"
                ? styles.reSources
                : styles.bLoans
      )}
    >
      <p className={styles.topText}>
        {" "}
        Buying a house for the first time can be daunting. Although we're here
        to make it as simple and stress free as possible, it’s important that
        you understand how it works. We hope you'll find our expert mortgage
        guides and calculators a helpful starting point. Once you're ready to
        discuss your mortgage requirements, our qualified mortgage advisers are
        on hand to help you 7 days a week. No need to make an appointment – our
        award-winning, fee free mortgage advice is just a phone call away.
      </p>
      <div className={styles.content}>
        <div className={styles.left}>
          <p
            onClick={() => {
              setFinalArray(blogListArray1);
              setHeading("Bridging Loans");
              window.scrollTo(0, 0);
            }}
            className={classNames(styles.section, styles.bLoans, finalArray === blogListArray1 ? styles.selected : null)}
          >
            Bridging Loans
          </p>
          <p
            onClick={() => {
              setFinalArray(blogListArray2);
              setHeading("Development Fianance");
              window.scrollTo(0, 0);
            }}
            className={classNames(styles.section, styles.dFinance, finalArray === blogListArray2 ? styles.selected : null)}
          >
            Development Finance
          </p>
          <p
            onClick={() => {
              setFinalArray(blogListArray4);
              setHeading("Stop Repossessions");
              window.scrollTo(0, 0);
            }}
            className={classNames(styles.section, styles.sRepo, finalArray === blogListArray4 ? styles.selected : null)}
          >
            Stop Repossession
          </p>
          <p
            onClick={() => {
              setFinalArray(blogListArray3);
              setHeading("Case studies");
              window.scrollTo(0, 0);
            }}
            className={classNames(styles.section, styles.caseStudies, finalArray === blogListArray3 ? styles.selected : null)}
          >
            Case Studies
          </p>
        </div>
        <div className={styles.right}>
          <p className={styles.rightHead}>
            {" "}
            <strong>
              {" "}
              {heading} Blogs
              {finalArray.length === 0 ? "(To be added soon)" : ""}{" "}
            </strong>{" "}
          </p>
          {finalArray.map((item) => {
            return (
              <div className={styles.blogTitleList}>
                <Link
                  className={
                    item.section === "bLoans"
                      ? styles.bLoans
                      : item.section === "dFinanace"
                        ? styles.dFinanace
                        : item.section === "caseStudies"
                          ? styles.caseStudies
                          : item.section === "sRepo"
                            ? styles.sRepo
                            : ""
                  }
                  to={item.path}
                >
                  {item.title}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Guide;
