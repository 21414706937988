import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesB2 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Do I Qualify for a Bridging Loan with Bad Credit?{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          There could be a number of reasons why you have a bad credit score on
          your file. Don’t worry though; we have access to a number of funders
          who can assist, even if you have suffered bad credit for the following
          reasons:
        </p>
        <p>
          <strong>CCJ or Defaults </strong>
          Unfortunately, you might not even know that you have a CCJ against
          your name until you apply for credit. At Bridging 365 we have a panel
          of bad credit specialist bridging funders who understand that just
          because you have a CCJ, it does not mean you are not able to borrow
        </p>
        <p>
          {" "}
          <strong> Mortgage Repayments</strong>
          It is a similar situation for missed mortgage repayments, but this
          does not necessarily affect your chances of securing a short term
          bridging loan to stop a repossession order. Whether you require a
          regulated bridging loan for bad credit or a unregulated bridging loan,
          we are here to help, with access to 22 regulated bridging funders and
          over 90 unregulated funders, we will find a funder to help you save
          your house.
        </p>
        <p>
          {" "}
          <strong>Bankruptcy Notice</strong>
          Even if you have been declared within the last 6-years it is possible
          to successfully apply for a bridging loan as an ex-bankrupt through
          our vast panel of funders.
        </p>
        <p>
          {" "}
          <strong> Debt Management Issues </strong>
          It is understandable that at certain times in life some financial
          assistance is required through a debt management plan. This can affect
          your chances of getting a bridging loan with an IVA or debt management
          plan, but at Bridging 365 we have you covered. We have access to
          funders who will lend bridging finance to those who are in a debt
          management plan or IVA. It is important to note that not all debt
          management companies will allow the patrons to have a bridging loan
          with the IVA or debt management plan. Please contact us to see how we
          can help.
        </p>
        <p>
          {" "}
          <strong>Missed, Unsecured or Secured Loan Repayments </strong>
          If you intend to pay off your missed loan repayments with the bridging
          finance then we have funders for you. All funders will not consider
          lending you any more money if you are not willing to settle your
          existing debts, which is just sensible. If you have arrears on your
          monthly debt and wish to get a bridging loan to purchase a new
          property or to consolidate your current debts so you can remortgage
          later, this will be considered by our funder panel. We can help you
          get a bridging loan even if you have missed your monthly payments and
          have current arrears.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesB2;
