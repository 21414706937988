import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgages6 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
Bridging Finance for Split Deeds in Multiple Scenarios      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
        Here are some examples of applicants we have helped to split deeds on shared property ownership:
        </p>
        
        <p> <strong>Why would we suggest bridging finance for a first time developer?</strong> </p>
        <p><strong>Speed of Application –</strong>  When it comes to development finance, you need to know how quickly you can get the funds you need to start a project. That’s why we aim to make a speedy decision on all applications for bridging and development finance, even for first time developers.</p>
        <p><strong>Divorce or Break-Up – </strong> Sometimes applicants will come to us in search of short-term financing that can help them to secure his or hers ex-partner’s share of the home they bought together. </p>
        <p> <strong>Business Partnership Uncoupling  –</strong>  In business partnerships can fall apart as easily as they came together in the first place, and it doesn’t always have to be a dramatic falling out. In order to move on, business owners need to resolve shared property deeds as quickly and efficiently as possible.</p>
        <p> <strong> Splitting Inherited Property Deeds  –</strong> With inherited property that has been left to multiple children of the deceased there is a matter of urgency attached. Inheritance tax and other associated bills have to be paid within a set time, with bridging finance allowing for this to be achieved without losing part of the valuation on the property if sold too quickly. </p>
      <p>All of the above situations if left unresolved can become intolerable, especially if you are living with an ex-partner and neither of you can afford to leave and find somewhere else to live. Our solution is to offer you hope where traditional lending sources might not be able to help you. </p>
      <p>Our bridging loans help to cover the cost of buying out an ex-partner in a home or business, or to help buy a new property, all with an end game and exit strategy in place.</p>
      </div>
    </div>
  );
};

export default MatchingMortgages6;
