import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesB3 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Non-Regulated and Regulated Bridging Loans{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          {" "}
          <strong> Regulated Bridging Loans </strong>A regulated loan is the
          responsibility of the FCA and is awarded to those applicants who are
          looking for a first, second, or third charge on an existing property.
        </p>
        <p>
          <strong>Unregulated Bridging Finance </strong>
          An unregulated loan is given to those seeking finance for inherited
          property purposes, such as paying off inheritance tax bills, or
          splitting title deeds with family members. We have access to 13
          Bridging 365 on our panel that can offer this type of bridging loan.
        </p>
        <p>
          {" "}
          <strong>Vast Panel of Bridging 365</strong>
          What we have always strived to achieve here at Bridging 365 is to keep
          our fingers on the pulse of the market. When a new lender that fits
          our high standards becomes clear on the market, we endeavour to add
          them to our roster. You might have come here looking for Bridging
          loans from Nationwide, but what you might end up with instead, is in
          fact a much better deal than you were counting on.
        </p>
        <p>
          If you intend to pay off your missed loan repayments with the bridging
          finance then we have funders for you. All funders will not consider
          lending you any more money if you are not willing to settle your
          existing debts, which is just sensible. If you have arrears on your
          monthly debt and wish to get a bridging loan to purchase a new
          property or to consolidate your current debts so you can remortgage
          later, this will be considered by our funder panel. We can help you
          get a bridging loan even if you have missed your monthly payments and
          have current arrears.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesB3;
