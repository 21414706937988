import React from "react";
import { useRef, useState } from "react";
import styles from "../styles/FAQCard.module.scss";
import { AiFillPlusSquare, AiFillMinusSquare } from "react-icons/ai";
const FAQCard = (props) => {
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");
  const { faqsList, idx, section } = props;
  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(`${answerElH + 20}px`);
  };
  return (
    <div>
      <div className={styles.faqCard} key={idx} onClick={handleOpenAnswer}>
        <h4
          className={
            section === "bLoans"
              ? styles.bLoans
              : section === "dFinance"
              ? styles.dFinance
              : section === "sRepo"
              ? styles.sRepo
              : section === "reBridging"
              ? styles.reBridging
              : ""
          }
        >
          <span>
            <strong>{faqsList.q}</strong>
          </span>
          <span>
            {state ? (
              <AiFillMinusSquare className={styles.icon} />
            ) : (
              <AiFillPlusSquare className={styles.icon} />
            )}
          </span>
        </h4>
        <div
          ref={answerElRef}
          className={styles.answerContainer}
          style={state ? { height: answerH } : { height: "0px" }}
        >
          <div>
            <p dangerouslySetInnerHTML={{__html: faqsList.a}}></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQCard;
