import { useEffect } from "react";
import styles from "../../DevFinanceForm.module.scss";
import Question from "../Question";
import { AnswerText } from "../Answer";
import companysvg from "../../../../assets/images/company.png";
import companyGif1 from "../../../../assets/images/About_company1.gif";
import companyGif from "../../../../assets/images/company.gif";

const AboutCompany = ({ formData, setFormData, setCompanyProgress }) => {
  useEffect(() => {
    const values = ["personBorrower", "companyBorrower"];

    let score = 0;
    for (let i = 0; i < values.length; i++) {
      const result = Object.values(formData[values[i]]).filter((val) => val);

      let count = 0;
      result.forEach((v) => {
        if (v) count++;
      });

      if (formData.borrower === "Company") {
        score += count * 0.16;
      } else {
        score += count * 0.2;
      }
    }

    setCompanyProgress((score / 1) * 100);
  }, [formData, setCompanyProgress]);

  useEffect(() => {
    setFormData({
      ...formData,
      personBorrower: {
        ...formData.personBorrower,
        name: "",
        address: "",
        address2: "",
        cityOrTown: "",
        postcode: "",
      },
      companyBorrower: {
        name: "",
        registration_no: "",
        address: "",
        address2: "",
        cityOrTown: "",
        postcode: "",
      },
    });
    setCompanyProgress(0);
  }, [formData.borrower]);

  return (
    <section id="section3">
      {formData.borrower != "" && (
        <div className={styles.section} id="section3">
          <div className={styles.banner} style={{ backgroundColor: "#4caf50" }}>
            <span style={{ padding: "1rem 0rem 1rem 2rem" }}>
              {" "}
              <img className={styles.formHeader} src={companyGif1} />
            </span>
            <p
              className={styles.head}
              style={{ flex: "1 1 100%", textAlign: "center" }}
            >
              ABOUT THE COMPANY{" "}
              <div style={{ color: "#002d72" }}>SECTION 3 OF 5</div>{" "}
            </p>
            <span style={{ padding: "1rem 2rem 1rem 0rem" }}>
              {" "}
              <img
                className={styles.formHeader}
                style={{ width: "80%" }}
                src={companysvg}
              />
            </span>
          </div>

          <div className={styles.gifContainer}>
            <img src={companyGif} alt="" className={styles.gif} />
          </div>
          <p
            className={styles.text}
            style={{ backgroundColor: "#4caf50", marginBottom: "2rem" }}
          >
            Thanks for telling us about how much you want to borrow and the
            security asset. Now would you mind telling us about the company we
            will be lending to?
          </p>
        </div>
      )}

      {formData.borrower === "Person" && (
        <>
          <div className={styles.inputBox}>
            <Question question="What is your name?" section="section3" />
            <AnswerText
              type="text"
              placeholder="Name"
              value={formData.personBorrower.name}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  personBorrower: {
                    ...formData.personBorrower,
                    name: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className={styles.inputBox}>
            <Question question="What is your address?" section="section3" />
            <AnswerText
              type="text"
              placeholder="Address"
              value={formData.personBorrower.address}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  personBorrower: {
                    ...formData.personBorrower,
                    address: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className={styles.inputBox}>
            <Question question="Address Line 2 (optional)" section="section3" />
            <AnswerText
              type="text"
              placeholder="Address Line 2"
              value={formData.personBorrower.address2}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  personBorrower: {
                    ...formData.personBorrower,
                    address2: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className={styles.inputBox}>
            <Question question="City or Town" section="section3" />
            <AnswerText
              type="text"
              placeholder="City or Town"
              value={formData.personBorrower.cityOrTown}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  personBorrower: {
                    ...formData.personBorrower,
                    cityOrTown: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className={styles.inputBox}>
            <Question question="Postcode" section="section3" />
            <AnswerText
              type="text"
              placeholder="Postcode"
              value={formData.personBorrower.postcode}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  personBorrower: {
                    ...formData.personBorrower,
                    postcode: e.target.value,
                  },
                });
              }}
            />
          </div>
        </>
      )}
      {formData.borrower === "Company" && (
        <>
          <div className={styles.inputBox}>
            <Question question="What is the name of the company you are borrowing from?" />
            <AnswerText
              type="text"
              placeholder="Company name"
              value={formData.companyBorrower.name}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  companyBorrower: {
                    ...formData.companyBorrower,
                    name: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className={styles.inputBox}>
            <Question question="What is the company registration number?" />
            <AnswerText
              type="text"
              placeholder="Company Registration Number"
              value={formData.companyBorrower.registration_no}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  companyBorrower: {
                    ...formData.companyBorrower,
                    registration_no: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className={styles.inputBox}>
            <Question question="What is the registered company address?" />
            <AnswerText
              type="text"
              placeholder="Company Registered Address"
              value={formData.companyBorrower.address}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  companyBorrower: {
                    ...formData.companyBorrower,
                    address: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className={styles.inputBox}>
            <Question question="Address line 2 (optional)" />
            <AnswerText
              type="text"
              placeholder="Address Line 2 (optional)"
              value={formData.companyBorrower.address2}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  companyBorrower: {
                    ...formData.companyBorrower,
                    address2: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className={styles.inputBox}>
            <Question question="City or Town" />
            <AnswerText
              type="text"
              placeholder="City or Town"
              value={formData.companyBorrower.cityOrTown}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  companyBorrower: {
                    ...formData.companyBorrower,
                    cityOrTown: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className={styles.inputBox}>
            <Question question="Postcode" />
            <AnswerText
              type="text"
              placeholder="Postcode"
              value={formData.companyBorrower.postcode}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  companyBorrower: {
                    ...formData.companyBorrower,
                    postcode: e.target.value,
                  },
                });
              }}
            />
          </div>
        </>
      )}
    </section>
  );
};

export default AboutCompany;
