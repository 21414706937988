import { useEffect } from "react";
import styles from "../../DevFinanceForm.module.scss";
import Question from "../Question";
import { AnswerDropdown } from "../Answer";
import AddDirector from "../AddDirector";
import directorpng from "../../../../assets/images/director.png";
import directorGif from "../../../../assets/images/director.gif";
import directorGif1 from "../../../../assets/images/About_director1.gif";

const AboutDirector = ({ formData, setFormData, setDirectorProgress }) => {
  useEffect(() => {
    const values = [
      "firstName",
      "lastName",
      "title",
      "birthYear",
      "address1",
      "address2",
      "townOrCity",
      "postcode",
      "contact",
      "email",
      "shareHolding",
      "domicile",
      "hadCCJ",
      "everBankrupt",
    ];

    let score = 0;
    for (let i = 0; i < values.length; i++) {
      if (typeof formData.director1[values[i]] === "object") {
        const result = Object.values(formData.director1[values[i]]).filter((val) => val);

        let count = 0;
        result.forEach((v) => {
          if (v) count++;
        });

        score += count * 0.16;
      } else if (formData.director1[values[i]] != 0 && formData.director1[values[i]] != "") {
        score += 1;
      }
    }
    setDirectorProgress((score / values.length) * 100);
  }, [formData, setDirectorProgress]);

  return (
    <section id="section4">
      {formData.borrower === "Company" && (
        <>
          <div className={styles.section} id="section4">
            <div
              className={styles.banner}
              style={{ backgroundColor: "#042c74" }}
            >
              <span style={{ padding: "1rem 0rem 1rem 2rem" }}>
                {" "}
                <img className={styles.formHeader} src={directorGif1} />
              </span>
              <p
                className={styles.head}
                style={{ flex: "1 1 100%", textAlign: "center" }}
              >
                ABOUT THE DIRECTOR{" "}
                <div style={{ color: "#4caf50" }}>SECTION 4 OF 5</div>{" "}
              </p>
              <span style={{ padding: "1rem 2rem 1rem 0rem" }}>
                {" "}
                <img className={styles.formHeader} src={directorpng} />
              </span>
            </div>

            <div className={styles.gifContainer}>
              <img src={directorGif} alt="" className={styles.gif} />
            </div>
            <p
              className={styles.text}
              style={{ backgroundColor: "#002d72", marginBottom: "2rem" }}
            >
              We're getting excited as we are close to the end, now can you tell
              us about yourself?
            </p>
          </div>
          <AddDirector
            formData={formData}
            setFormData={setFormData}
            name="director1"
            setDirectorProgress={setDirectorProgress}
          />
          <div className={styles.inputBox}>
            <Question
              question="Would you like to add another Director?"
              section="section4"
            />
            <AnswerDropdown
              options={["Yes", "No"]}
              value={formData.addAnotherDirector}
              onChange={(e) =>
                setFormData((formData) => ({
                  ...formData,
                  addAnotherDirector: e.target.value,
                }))
              }
            />
          </div>
          {formData.addAnotherDirector == "Yes" && (
            <AddDirector
              formData={formData}
              setFormData={setFormData}
              name="director2"
              setDirectorProgress={setDirectorProgress}
            />
          )}
        </>
      )}
    </section>
  );
};

export default AboutDirector;
