import React, { useEffect } from "react";
import PageNotFound from "../PageNotFound/PageNotFound";
const CaseStudies = () => {
  useEffect(() => {
    document.title = "Bridging 365 | Re-Bridging Finance";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <PageNotFound />
    </div>
  );
};

export default CaseStudies;
