import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesB7 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Are you Looking for Bridging Finance?{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          If you need bridging loans for bad credit, there are a few situations
          that we have experience in helping clients to secure the right
          financial deal to help plug a short-term gap and enable a transaction
          to take place.
        </p>
        <p>
          <strong>Property Chain</strong>
          We can help provide you the funds to go through with a property
          purchase even if you are currently stuck in a chain and are reliant on
          others to sell a property before you can move.
        </p>
        <p>
          <strong> Auction Property</strong>
          Having bad credit shouldn’t prevent you from having the money in place
          to secure a property transaction at auction.
        </p>
        <p>
          <strong> Stop a Repossession Order </strong>
          In urgent cases a bridging loan can be used to clear mortgage arrears
          and halt a court order to repossess your home, with a view to either
          refinancing or selling the property in question.
        </p>
        <p>
          <strong>Commercial Bridging Loans </strong>
          For commercial developments ranging from single structure premises, to
          larger multi-residential complexes, we have connections with lenders
          that can help fund construction.
        </p>
        <p>
          <strong>Refurbishment Projects </strong>
          At certain times a property requires complete renovation and
          refurbishment before it is ready to be sold on, or habitable in order
          to be leased to tenants. With bad credit, we can provide you the
          funding to complete projects with future financial reward in mind.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesB7;
