import React, { useEffect } from "react";
import styles from "./EditForm.module.scss";
import { AiFillSave } from "react-icons/ai";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import loading from "../../assets/images/loading.gif"
import { useState } from "react";
import axios from 'axios';
import * as myConstClass from '../../components/FileWithConstants';
import $ from 'jquery';


const EditForm = () => {
    const { state } = useLocation();
    console.log(state);
    const navigate = useNavigate();
    const goBackHandler = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        id: state[0].loanform_id,
        userName: state[0].userName,
        userEmail: state[0].userEmail,
        userContact: state[0].userContact,
        propertyUse: state[0].propertyUse,
        whenMoneyNeeded: state[0].whenMoneyNeeded,
        submittedOnDate: state[0].submittedOnDate,
        submittedOnTime: state[0].submittedOnTime,
        purpose: state[0].purpose,
        currentLoanFromBridgingLender: state[0].currentLoanFromBridgingLender,
        whenLoanCompleted: state[0].whenLoanCompleted,
        howMuchToBorrow: state[0].howMuchToBorrow,
        numOfMonthsLoanFor: state[0].numOfMonthsLoanFor,
        monthlyOrTermPay: state[0].monthlyOrTermPay,
        howPaybackLoan: state[0].howPaybackLoan,
        addressLine1: state[0].addressLine1,
        addressLine2: state[0].addressLine2,
        city: state[0].city,
        postcode: state[0].postcode,
        propertyValuation: state[0].propertyValuation,
        propertyDescription: state[0].propertyDescription,
        numberOfBedrooms: state[0].numberOfBedrooms,
        numberOfFloors: state[0].numberOfFloors,
        isExCouncilProperty: state[0].isExCouncilProperty,
        isStandardConstruction: state[0].isStandardConstruction,
        constructionDescription: state[0].constructionDescription,
        tenureOfProperty: state[0].tenureOfProperty,
        assetDescription: state[0].assetDescription,
        addAnotherProperty: state[0].addAnotherProperty,
        nameOfCompayForBorrowingIn: state[0].nameOfCompayForBorrowingIn,
        companyRegistrationNumber: state[0].companyRegistrationNumber,
        companyAddress1: state[0].companyAddress1,
        companyAddress2: state[0].companyAddress2,
        companyCity: state[0].companyCity,
        companyPostCode: state[0].companyPostCode,
        directorFirstName: state[0].directorFirstName,
        directorLastName: state[0].directorLastName,
        directorTitle: state[0].directorTitle,
        directorDateOfBirth: state[0].directorDateOfBirth,
        directorAddress1: state[0].directorAddress1,
        directorAddress2: state[0].directorAddress2,
        directorTown: state[0].directorTown,
        directorPostCode: state[0].directorPostCode,
        directorNumber: state[0].directorNumber,
        defaultInSixYears: state[0].defaultInSixYears,
        everDeclaredBankrupt: state[0].everDeclaredBankrupt,
        otherMsg: state[0].otherMsg
    })
    // write code here to update formdata object using setFormData function above
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const saveFormHandler = (formId) => {
        

        setShowLoading(true);
        setTimeout(() => {
            console.log("formid", formId);
            console.log("formData", formData);


                console.log(JSON.stringify(formData));
                const formdataupdate = myConstClass.BASE_URL + "" + myConstClass.FORMDATAUPDATE;
                var saveDataFormLater = $.ajax({
                  type: 'POST',
                  url: formdataupdate,
                  data: formData,
                  dataType: "json",
                  success: function (resultData) {
                    console.log(resultData);
                    
                  }
                });


            setShowLoading(false);
        }, 1000);

    }
    const [showLoading, setShowLoading] = useState(false)
    const { formId } = useParams();
    return (
        <div className={styles.editForm}>
            <div className={styles.top}>
                <p>
                    Form details for form id : <strong>{formId}</strong>{" "}
                </p>
                <div>

                    <span onClick={() => saveFormHandler(formId)}>

                        <AiFillSave />
                    </span>
                    <span onClick={goBackHandler}>
                        <RiArrowGoBackFill />
                    </span>
                </div>
            </div>
            <div className={styles.bottom}>
                <p className={styles.tableHead}>About the person</p>
                <table id="form-table" >
                    {/* <caption className={styles.tableCaption}>About the person</caption> */}
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td>  <input type="text" defaultValue={state[0].userName}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        userName: e.target.value,
                                    });
                                }} /></td>

                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>  <input type="email" defaultValue={state[0].userEmail} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    userEmail: e.target.value,
                                });
                            }} /></td>

                        </tr>
                        <tr>
                            <td>Contact</td>
                            <td>  <input type="number" defaultValue={state[0].userContact} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    userContact: e.target.value,
                                });
                            }} /></td>

                        </tr>
                        <tr>
                            <td>Form submitted on </td>
                            <td>
                                {state[0].submittedOnDate} | {state[0].submittedOnTime}{" "}
                            </td>
                        </tr>

                        <tr>
                            <td>Will you live in the property or is it an investment?</td>
                            {/* <td>  <input type="text" defaultValue={state[0].propertyUse} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    propertyUse: e.target.value,
                                });
                            }} /></td> */}
                            <td>
                                <select
                                    name=""
                                    id=""
                                    className={styles.answer}
                                    defaultValue={formData.propertyUse}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            propertyUse: e.target.value,
                                        })
                                    }
                                >
                                    <option value="" disabled>
                                        select option
                                    </option>
                                    <option value="Investment" selected={state[0].propertyUse === "Investment"} >It is an investment</option>
                                    <option value="ForLiving" selected={state[0].propertyUse === "ForLiving"}>
                                        I'll be living in the property
                                    </option>
                                </select>
                            </td>

                        </tr>
                        <tr>
                            <td>How quickly do you need the money?</td>

                            <td>
                                <select
                                    name=""
                                    id=""
                                    className={styles.answer}
                                    defaultValue={state[0].whenMoneyNeeded}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            whenMoneyNeeded: e.target.value,
                                        })
                                    }
                                >
                                    <option value="" disabled>
                                        select option
                                    </option>
                                    <option value="Urgently" selected={state[0].whenMoneyNeeded === "Urgently"}>Urgently within days</option>
                                    <option value="InFewWeeks" selected={state[0].whenMoneyNeeded === "InFewWeeks"}>
                                        Required within a few weeks but less than 3 months
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p className={styles.tableHead}>About the loan</p>
                <table>
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>What is the purpose of the bridging loan?</td>

                            {/* <td>  <input type="text" defaultValue={state[0].purpose} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    purpose: e.target.value,
                                });
                            }} /></td> */}
                            <td>
                                <select
                                    name=""
                                    id=""
                                    className={styles.answer}
                                    defaultValue={state[0].purpose}
                                    onChange={(e) => {
                                        setFormData({ ...formData, purpose: e.target.value });
                                    }}
                                >
                                    <option
                                        value=""
                                        selected
                                        disabled
                                        className={styles.option}
                                    >
                                        select option
                                    </option>
                                    <option value="NewBridge" selected={state[0].purpose === "Purchase"} className={styles.option}>
                                        New Bridge
                                    </option>
                                    <option value="Refinance" selected={state[0].purpose === "Refinance"} className={styles.option}>
                                        Refinance
                                    </option>
                                    <option value="DevelopmentFinance" selected={state[0].purpose === "Refinance"} className={styles.option}>
                                        Development Finance
                                    </option>
                                    <option value="CapitalRaise" selected={state[0].purpose === "CapitalRaise"} className={styles.option}>
                                        Capital raise- home improvements
                                    </option>
                                    <option value="Stop Reposessions" selected={state[0].purpose === "Stop Reposessions"} className={styles.option}>
                                        Stop a repossession
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Is your current loan from a bridging lender?</td>

                            {/* <td>  <input type="text" defaultValue={state[0].currentLoanFromBridgingLender} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    currentLoanFromBridgingLender: e.target.value,
                                });
                            }} /></td> */}
                            <td>
                                <select
                                    name=""
                                    id=""
                                    className={styles.answer}
                                    defaultValue={state[0].currentLoanFromBridgingLender}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            currentLoanFromBridgingLender: e.target.value,
                                        });
                                    }}
                                >
                                    <option
                                        value=""
                                        selected
                                        disabled
                                        className={styles.option}
                                    >
                                        select option
                                    </option>
                                    <option value="Yes" selected={state[0].currentLoanFromBridgingLender === "Yes"} className={styles.option}>
                                        Yes
                                    </option>
                                    <option value="No" selected={state[0].currentLoanFromBridgingLender === "No"} className={styles.option}>
                                        No
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>When do you need the loan to be completed by?</td>

                            {/* <td>  <input type="text" defaultValue={state[0].whenLoanCompleted} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    whenLoanCompleted: e.target.value,
                                });
                            }} /></td> */}
                            <td>
                                <select
                                    name=""
                                    id=""
                                    className={styles.answer}
                                    defaultValue={state[0].whenLoanCompleted}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            whenLoanCompleted: e.target.value,
                                        });
                                    }}
                                >
                                    <option
                                        value=""
                                        selected
                                        disabled
                                        className={styles.option}
                                    >
                                        select option
                                    </option>
                                    <option value="Under 48 hours" selected={state[0].whenLoanCompleted === "Under 48 hours"} className={styles.option}>
                                        under 48 hours
                                    </option>
                                    <option value="Under a week" selected={state[0].whenLoanCompleted === "Under a week"} className={styles.option}>
                                        under a week
                                    </option>
                                    <option value="Under a month" selected={state[0].whenLoanCompleted === "Under a month"} className={styles.option}>
                                        under a month
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>How much would you like to borrow?</td>

                            <td>  <input type="number" defaultValue={state[0].howMuchToBorrow} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    howMuchToBorrow: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Number of months you would like the loan for</td>
                            {/* <td>  <input type="text" defaultValue={state[0].numOfMonthsLoanFor} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    numOfMonthsLoanFor: e.target.value,
                                });
                            }} /></td> */}
                            <td>
                                <select
                                    name=""
                                    id=""
                                    className={styles.answer}
                                    defaultValue={state[0].numOfMonthsLoanFor}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            numOfMonthsLoanFor: e.target.value,
                                        });
                                    }}
                                >
                                    <option value="" selected disabled>
                                        select option
                                    </option>
                                    <option value="3" selected={state[0].numOfMonthsLoanFor === "3"}>3 months</option>
                                    <option value="4" selected={state[0].numOfMonthsLoanFor === "4"}>4 months</option>
                                    <option value="5" selected={state[0].numOfMonthsLoanFor === "5"}>5 months</option>
                                    <option value="6" selected={state[0].numOfMonthsLoanFor === "6"}>6 months</option>
                                    <option value="7" selected={state[0].numOfMonthsLoanFor === "7"}>7 months</option>
                                    <option value="8" selected={state[0].numOfMonthsLoanFor === "8"}>8 months</option>
                                    <option value="9" selected={state[0].numOfMonthsLoanFor === "9"}>9 months</option>
                                    <option value="10" selected={state[0].numOfMonthsLoanFor === "10"}>10 months</option>
                                    <option value="11" selected={state[0].numOfMonthsLoanFor === "11"}>11 months</option>
                                    <option value="12" selected={state[0].numOfMonthsLoanFor === "12"}>12 months</option>
                                    <option value="13" selected={state[0].numOfMonthsLoanFor === "13"}>13 months</option>
                                    <option value="14" selected={state[0].numOfMonthsLoanFor === "14"}>14 months</option>
                                    <option value="15" selected={state[0].numOfMonthsLoanFor === "15"}>15 months</option>
                                    <option value="16" selected={state[0].numOfMonthsLoanFor === "16"}>16 months</option>
                                    <option value="17" selected={state[0].numOfMonthsLoanFor === "17"}>17 months</option>
                                    <option value="18" selected={state[0].numOfMonthsLoanFor === "18"}>18 months</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {" "}
                                Would you like to make monthly payments or simply pay the full
                                balance due on exiting the loan?
                            </td>

                            {/* <td>  <input type="text" defaultValue={state[0].monthlyOrTermPay} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    monthlyOrTermPay: e.target.value,
                                });
                            }} /></td> */}
                            <td>
                                <select
                                    name=""
                                    id=""
                                    className={styles.answer}
                                    defaultValue={state[0].monthlyOrTermPay}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            monthlyOrTermPay: e.target.value,
                                        });
                                    }}
                                >
                                    <option
                                        value=""
                                        selected
                                        disabled
                                        className={styles.option}
                                    >
                                        select option
                                    </option>
                                    <option value="Every month" selected={state[0].whenLoanCompleted === "Every month"} className={styles.option}>
                                        I'd like to pay the interest due every month
                                    </option>
                                    <option value="At the end" selected={state[0].whenLoanCompleted === "At the end"} className={styles.option}>
                                        I'd like to make one payment at the end of the loan
                                    </option>

                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>How will you be paying the loan back?</td>

                            <td>  <input type="text" defaultValue={state[0].howPaybackLoan} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    howPaybackLoan: e.target.value,
                                });
                            }} /></td>
                        </tr>
                    </tbody>
                </table>
                <p className={styles.tableHead}>About the Security</p>
                <table>
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> Address Line 1 (optional)</td>

                            <td>  <input type="text" defaultValue={state[0].addressLine1} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    addressLine1: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Address Line 2</td>
                            <td>  <input type="text" defaultValue={state[0].addressLine2} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    addressLine2: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> Which city or town is the property located in?</td>

                            <td>  <input type="text" defaultValue={state[0].city} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    city: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> What is the postcode ?</td>

                            <td>  <input type="text" defaultValue={state[0].postcode} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    postcode: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> What is the estimated property valuation ?</td>

                            <td>  <input type="number" defaultValue={state[0].propertyValuation} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    propertyValuation: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> Please select the best description of the property below</td>

                            <td>  <textarea type="text" defaultValue={state[0].propertyDescription} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    propertyDescription: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>How many bedrooms does the property have ?</td>

                            <td>  <input type="number" defaultValue={state[0].numberOfBedrooms} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    numberOfBedrooms: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> How many floors are in the building ?</td>

                            <td>  <input type="number" defaultValue={state[0].numberOfFloors} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    numberOfFloors: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> Is it an ex-council property ?</td>

                            <td>  <input type="text" defaultValue={state[0].isExCouncilProperty} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    isExCouncilProperty: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>
                                Is property of standard construction? Please select below and
                                tell us more.
                            </td>

                            <td>  <input type="text" defaultValue={state[0].isStandardConstruction} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    isStandardConstruction: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Additional information about standard construction</td>

                            <td>  <textarea type="text" defaultValue={state[0].constructionDescription} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    constructionDescription: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> Do you know the tenure of the property?</td>

                            <td>  <input type="text" defaultValue={state[0].tenureOfProperty} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    tenureOfProperty: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Please describe the asset:</td>

                            <td>  <textarea type="text" defaultValue={state[0].assetDescription} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    assetDescription: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> Would you like to add another property ?</td>
                            <td>{state[0].addAnotherProperty}</td>
                        </tr>
                    </tbody>
                </table>
                <p className={styles.tableHead}>About the Company</p>
                <table>
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>What is the name of the company you are borrowing in ?</td>

                            <td>  <input type="text" defaultValue={state[0].nameOfCompayForBorrowingIn} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    nameOfCompayForBorrowingIn: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>What is the company registration number ?</td>

                            <td>  <input type="text" defaultValue={state[0].companyRegistrationNumber} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    companyRegistrationNumber: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>What is the registered company address ?</td>
                            <td>  <input type="text" defaultValue={state[0].companyAddress1} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    companyAddress1: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Address Line 2</td>

                            <td>  <input type="text" defaultValue={state[0].companyAddress2} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    companyAddress2: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>City or Town</td>

                            <td>  <input type="text" defaultValue={state[0].companyCity} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    companyCity: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Postcode ?</td>

                            <td>  <input type="text" defaultValue={state[0].companyPostCode} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    companyPostCode: e.target.value,
                                });
                            }} /></td>
                        </tr>
                    </tbody>
                </table>
                <p className={styles.tableHead}>About the Director</p>
                <table>
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>What is Your First Name ?</td>

                            <td>  <input type="text" defaultValue={state[0].directorFirstName} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    directorFirstName: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> What is Your Last Name ?</td>

                            <td>  <input type="text" defaultValue={state[0].directorLastName} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    directorLastName: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> What is Your Title</td>

                            <td>  <input type="text" defaultValue={state[0].directorTitle} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    directorTitle: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> When Were You Born ?</td>

                            <td>  <input type="date" defaultValue={state[0].directorDateOfBirth} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    directorDateOfBirth: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Address Line 1</td>

                            <td>  <input type="text" defaultValue={state[0].directorAddress1} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    directorAddress1: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Address Line 2</td>

                            <td>  <input type="text" defaultValue={state[0].directorAddress2} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    directorAddress2: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Town or City</td>

                            <td>  <input type="text" defaultValue={state[0].directorTown} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    directorTown: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>Postal code ?</td>

                            <td>  <input type="text" defaultValue={state[0].directorPostCode} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    directorPostCode: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td>What is the Best Number To Contact You ?</td>

                            <td>  <input type="number" defaultValue={state[0].directorNumber} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    directorNumber: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> Have you had any CCJ's or the Defaults in the last 6 years ?</td>

                            <td>  <input type="text" defaultValue={state[0].defaultInSixYears} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    defaultInSixYears: e.target.value,
                                });
                            }} /></td>
                        </tr>
                        <tr>
                            <td> Have you ever been declared bankrupt ?</td>

                            <td>  <input type="text" defaultValue={state[0].everDeclaredBankrupt} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    everDeclaredBankrupt: e.target.value,
                                });
                            }} /></td>
                        </tr>
                    </tbody>
                </table>
                <p className={styles.tableHead}>Additional Information</p>
                <table>
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Please tell us anything else that is relevant to the
                                application?
                            </td>
                            <td>  <textarea type="text" defaultValue={state[0].otherMsg} onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    otherMsg: e.target.value,
                                });
                            }} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={styles.loadingContainer}>
                {showLoading && (
                    <img src={loading} className={styles.loadingImg} alt="" />
                )}
            </div>
        </div>
    );
};

export default EditForm;
