import classNames from "classnames";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../styles/FeaturesContainer.module.scss";
const FeaturesContainer = () => {
  const featuresArray = [
    {
      id: 1,
      heading: "BRIDGING LOANS",
      subHead1: "Rates From 0.44% p/m ",
      subHead2: "10 Days To Complete",
      subHead3: "3-24 Month Terms",
      link: "/bridgingloans",
      imgUrl: "./images/bLoan.gif"
    },
    {
      id: 2,
      heading: "DEVELOPMENT FINANCE",
      subHead1: "Rates From 0.34% p/m",
      subHead2: "95% LTC & 75% LTGDV",
      subHead3: "Available all over the UK",
      link: "/developmentfinance",
      imgUrl: "./images/dFinance.png"
    },
    {
      id: 3,
      heading: "STOP REPOSSESSIONS",
      subHead1: "Rates From 0.85% p/m",
      subHead2: "Completed in 24-48hrs",
      subHead3: "Up to 85% LTV",
      link: "/stoprepossessions",
      imgUrl: "./images/sRepo.png"
    },
    {
      id: 4,
      heading: "AUCTION FINANCE",
      subHead1: "Rates From 0.44% p/m ",
      subHead2: "Up to 85% LTV",
      subHead3: "Completed in 5 days",
      link: "/pagenotfound",
      imgUrl: "./images/aFinance.gif"
    },
  ];
  const navigate = useNavigate();
  let mql = window.matchMedia("(max-width:600px)");
  const navigationHandler = (id) => {
    if (!mql.matches) {
      const path = id === 1 ? "/bridgingloans" : id === 2 ? "/developmentfinance" : id === 3 ? "/stoprepossessions" : id === 4 ? "/rebridgingloans" : "#";
      navigate(path);
    }
  }
  return (
    <div className={styles.featuresContainer}>
      <p className={styles.header}>SERVICES WE PROVIDE</p>
      <div className={styles.features}>
        {featuresArray.map((feature) => {
          return (
            <div
              onClick={() => navigationHandler(feature.id)}
              className={classNames(
                styles.feature,
                feature.id === 1
                  ? styles.bLoans
                  : feature.id === 2
                    ? styles.dFinance
                    : feature.id === 3
                      ? styles.sRepo
                      : feature.id === 4
                        ? styles.reSources
                        : ""
              )}
            >
              <p className={styles.head}>{feature.heading} </p>
              <div className={styles.content}>
                <ul className={styles.subhead}>
                  <li>{feature.subHead1}</li>
                  <li>{feature.subHead2}</li>
                  <li>{feature.subHead3}</li>
                </ul>
                <img className={styles.gif} src={feature.imgUrl} alt="" />

              </div>

              <Link
                to={feature.link}
                className={classNames(
                  styles.link,
                  feature.id === 1
                    ? styles.bLoans
                    : feature.id === 2
                      ? styles.dFinance
                      : feature.id === 3
                        ? styles.sRepo
                        : feature.id === 4
                          ? styles.reSources
                          : ""
                )}
              >
                Learn more
              </Link>
            </div>
          );
        })}
      </div>
    </div >
  );
};
export default FeaturesContainer;
