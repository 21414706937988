import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesC1 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        What Can We Help Bankrupt People Achieve with a Bridging Loan?{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <p>
          Our bridging loan service offers those with a bankruptcy notice on
          their records with the chance to acquire funding for a range of
          causes. Even if you have been declared bankrupt within the last
          6-years, we believe you should still have the right to acquire funding
          where possible. Our vast panel of funders allow us to find the right
          fit for your needs, even with the most adverse credit or bankruptcy
          scenario.
        </p>
        <p>
          We have helped bankrupt people gain bridging loans to help them in a
          wide variety of situations, securing the funding that was right for
          that specific situation. If you require short-term funding but are
          bankrupt, or have been bankrupt in the past, our team can help.
        </p>
        <p>
          {" "}
          <strong>Case Study 1 -</strong> One example of how our bridging loans
          have helped a bankrupt person is when a client came to use stuck in a
          property chain. Requiring funds to purchase the property in question,
          whilst their current home was waiting to be sold, our panel of funders
          helped where traditional lending streams could not.
        </p>
        <p>
          {" "}
          <strong>Case Study 2 –</strong> Another example was when a person who
          has been bankrupt was looking to rebuild a life through the purchasing
          of a house at auction. They needed to know how much they could
          realistically borrow in order to pay for a property at auction without
          worrying about missing out on the deadline to pay.
        </p>
        <p>
          {" "}
          <strong>Case Study 3 –</strong> A client came to us in severe
          financial difficulty having recently been declared bankrupt. There was
          still hope to prevent the repossession of the family home, and our
          bridging loan panel helped them pay off that debt, and save some time.
        </p>
        <p>
          {" "}
          <strong> Case Study 4 –</strong> One client called us looking for
          bridging finance to help refurbish the home they lived in. Having been
          declared bankrupt 4 years ago, it was difficult to find the funding
          that would help cover the material and construction costs that would
          help complete the job to a high standard. Our panel helped them
          achieve their dreams.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesC1;
