import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainer5 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Development Loans First Time Developer
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
         <li>        Development Finance from £25k up to £50 million
</li>
         <li>Quick Application and Funding Process
</li>
         <li>Competitive Rates through our Panel of 169 Funders
</li>
         <li>100% Development and Construction Costs where Additional Security is Available
</li>
         <li>Bridging Finance for First Time Developers
</li>
         
        </ul>
        <div className={styles.text}>

<p>If you are a first time developer it is vital that you understand the type of development that you are undertaking, in order to work out the best financing route to go down.</p>
<p> <strong> Is the project a light refurbishment?</strong>  <br />
In these cases, developments are aesthetic in nature and involve internal work on walls, ceilings and floors, as well as extensive redecoration of the interior and exterior of the property structure’s façade. A refurbishment bridging loan is the best option for this type of property development project.</p>
<p> <strong> Or is it a heavy refurbishment project? </strong><br />
In addition to any aesthetic renovations, a heavy refurbishment entails structural changes to the property, all plumbing and electrical work, any demolition and rebuilding works. Bridging finance is suitable for these types of renovation projects also.</p>
<p> <strong>Are you working from the ground up?</strong>   <br />
For property development where an empty plot of land is bought, or a derelict and uninhabitable property that is to be demolish or converted, development finance is more suitable as a requirement to find funding for construction and development costs. </p>
</div>
      </div>
    </div>
  );
};

export default CompareContainer5;
