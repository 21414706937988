import React, { useEffect, useState } from "react";
import styles from "../../../FormPage/FormPage.module.scss";
import axios from "axios";
import * as myConstClass from "../../../../components/FileWithConstants";
import Greencheck from "../../../../assets/images/green_tick.gif";
import Formimg from "../../../../assets/images/form_front_page.gif";

const InitialForm = ({
  step,
  setStep,
  intialSubmitHandler,
  formdata,
  setFormdata,
}) => {
  const userId = localStorage.getItem("userId");
  const login_emailid = localStorage.getItem("login_emailid");

  if (login_emailid != "" || login_emailid != null) {
    const loginurl = myConstClass.BASE_URL + "" + myConstClass.LOGIN;
    let formData = new FormData();
    formData.append("login_emailid", login_emailid);

    var first_name,
      last_name,
      contact,
      email,
      alternate_no,
      address,
      country,
      state,
      city,
      postalcode,
      profile_image,
      alternate_no;
    axios.post(loginurl, formData).then((res) => {
      first_name = res.data.data.first_name;
      last_name = res.data.data.last_name;

      if (first_name == null || last_name == null) {
        first_name = res.data.data.name;
        last_name = "";
      }

      document.getElementById("fullname").defaultValue =
        first_name + " " + last_name;
      formdata.userName = first_name + " " + last_name;
      contact = res.data.data.contact;
      document.getElementById("contact").defaultValue = contact;
      formdata.userContact = contact;
      email = res.data.data.email;
      document.getElementById("email").defaultValue = email;
      formdata.userEmail = email;
    });
  } else {
  }

  const closeModal = () => {
    setStep("final");
    window.scrollTo(0, 0);
    // setShowLoginPopup(false);
  };
  const closePopup = () => {
    // setShowLoginPopup(false);
    window.scrollTo(0, 0);
  };
  return (
    <div className={styles.formPage}>
      <div className={styles.left}></div>
      <div
        className={styles.center}
        style={{ borderLeft: "transparent", borderRight: "transparent" }}
      >
        {
          <div className={styles.section} id="section4">
            {/* <img src={logo} className={styles.logoImg} alt="" /> */}

            <p
              className={styles.text}
              style={{
                backgroundColor: "#4caf50",
                fontFamily: "Nunito,sans-serif",
                fontSize: "1.8rem",
                fontWeight: "bolder",
                padding: "1rem",
              }}
            >
              DEVELOPMENT FINANCE APPLICATION
            </p>

            <div
              className={styles.text}
              style={{
                backgroundColor: "transparent",
                color: "#042c74",
                padding: "0 1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>
                <img src={Formimg} alt="" className={styles.checkbox} />
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                    marginTop: "0.5rem",
                  }}
                >
                  <img
                    src={Greencheck}
                    alt=""
                    style={{ paddingRight: 6, width: "30px", height: "30px" }}
                  />
                  No Credit Checks
                </p>
                <p
                  style={{
                    display: "flex",
                    marginBottom: "0.5rem",
                    marginTop: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Greencheck}
                    alt=""
                    style={{ paddingRight: 6, width: "30px", height: "30px" }}
                  />
                  No Commitment
                </p>
                <p
                  style={{
                    display: "flex",
                    marginBottom: "0.5rem",
                    marginTop: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Greencheck}
                    alt=""
                    style={{ paddingRight: 6, width: "30px", height: "30px" }}
                  />
                  No Applicatioin fee
                </p>
              </div>
            </div>

            <p
              className={styles.text}
              style={{
                backgroundColor: "#4caf50",
              }}
            >
              With rates starting at 0.35% p/m
            </p>
            <p
              className={styles.text}
              style={{
                backgroundColor: "transparent",
                color: "#042c74",
                fontWeight: "bold",
                marginBottom: "0rem",
                lineHeight: "2rem",
              }}
            >
              Let's begin an application
              <br /> & get you an offer in 10 minutes.
            </p>

            <p
              style={{
                height: "0.2rem",
                width: "100%",
                marginBottom: "2rem",
                backgroundImage:
                  "linear-gradient(90deg, #ffffff,#002D72, #ffffff)",
              }}
            ></p>

            {/* We'll have the decision in principle ready for you in the next 10 minutes. */}

            <form action="" className={styles.form1}>
              <div className={styles.inputBox}>
                <label htmlFor="" className={styles.question}>
                  <span className={styles.questionText}>
                    {" "}
                    What is your full name?
                  </span>
                </label>
                <div className={styles.answerBlock}>
                  <input
                    id="fullname"
                    type="text"
                    placeholder="Enter your full name"
                    className={styles.answer}
                    defaultValue={formdata.name}
                    onChange={(e) =>
                      setFormdata({
                        ...formdata,
                        userName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="" className={styles.question}>
                  <span className={styles.questionText}>
                    {" "}
                    What is the best number to contact you?
                  </span>
                </label>
                <div className={styles.answerBlock}>
                  {" "}
                  <input
                    id="contact"
                    type="number"
                    placeholder="Contact number"
                    className={styles.answer}
                    defaultValue={formdata.contact}
                    onChange={(e) =>
                      setFormdata({
                        ...formdata,
                        userContact: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="" className={styles.question}>
                  <span className={styles.questionText}>
                    {" "}
                    And your email address?
                  </span>
                </label>
                <div className={styles.answerBlock}>
                  <input
                    id="email"
                    type="email"
                    placeholder="Enter email"
                    className={styles.answer}
                    defaultValue={formdata.email}
                    onChange={(e) =>
                      setFormdata({
                        ...formdata,
                        userEmail: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="" className={styles.question}>
                  <span className={styles.questionText}>
                    {" "}
                    Will you live in the property or is it an investment?
                  </span>
                </label>
                <div className={styles.answerBlock}>
                  {" "}
                  <select
                    name=""
                    id=""
                    className={styles.answer}
                    value={formdata.propertyUse}
                    onChange={(e) =>
                      setFormdata({
                        ...formdata,
                        propertyUse: e.target.value,
                      })
                    }
                  >
                    <option value="" selected disabled>
                      select option
                    </option>
                    <option value="Investment">It is an investment</option>
                    <option value="ForLiving">
                      I'll be living in the property
                    </option>
                  </select>
                </div>
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="" className={styles.question}>
                  <span className={styles.questionText}>
                    {" "}
                    How quickly do you need the money?
                  </span>
                </label>
                <div className={styles.answerBlock}>
                  {" "}
                  <select
                    name=""
                    id=""
                    className={styles.answer}
                    value={formdata.whenMoneyNeeded}
                    onChange={(e) =>
                      setFormdata({
                        ...formdata,
                        whenMoneyNeeded: e.target.value,
                      })
                    }
                  >
                    <option value="" selected disabled>
                      select option
                    </option>
                    <option value="Urgently">Urgently within days</option>
                    <option value="InFewWeeks">
                      Required within a few weeks but less than 3 months
                    </option>
                  </select>
                </div>
              </div>
            </form>
            <div className={styles.btns}>
              <button
                onClick={intialSubmitHandler}
                className={styles.submitBtn}
              >
                Let's begin
              </button>
            </div>
          </div>
        }
      </div>

      <div className={styles.right}></div>
    </div>
  );
};

export default InitialForm;
