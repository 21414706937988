import React, { useEffect } from "react";
import NewsContainer from "../../components/NewsContainer";
import TestimonialsContainer from "../../components/TestimonialsContainer";
import Footer from "../../components/Footer";
import CompareContainerS1 from "../../components/CompareContainer/CompareContainerS1";
import LoanApplicationS1 from "../../components/LoanApplication/LoanApplicationS1";
import HeroSection from "../../components/HeroSection/HeroSection";
import CallAdviser from "../../components/CallAdviser";
import MatchingMortgagesS1 from "../../components/MatchingMortgages/MatchingMortgagesS1";
const StopRepoBlogs1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeroSection title="Stop Repossessions" />
      <CompareContainerS1 title="Stop Repossessions" />
      <CallAdviser title="Stop Repossessions" />
      <MatchingMortgagesS1 title="Stop Repossessions" />
      <NewsContainer title="Stop Repossessions" />
      <LoanApplicationS1 title="Stop Repossessions" />
      <TestimonialsContainer title="Stop Repossessions" />
      <Footer title="Stop Repossessions" />
    </div>
  );
};
export default StopRepoBlogs1;
