import React, { useEffect } from "react";
import ContactComponent from "../../components/ContactComponent";
import FeaturesContainer from "../../components/FeaturesContainer";
import Footer from "../../components/Footer";
import HeroSection from "../../components/HeroSection";
import NewsContainer from "../../components/NewsContainer";
import StepsContainer from "../../components/StepsContainer";
import SubHeroSection from "../../components/SubHeroSection";
import TestimonialsContainer from "../../components/TestimonialsContainer";
import ToolsContainer from "../../components/ToolsContainer";
import styles from "./Home.module.scss";
const Home = () => {
  useEffect(() => {
    document.title = "Bridging 365";
    window.scrollTo(0, 0);
  }, []);
  let mql = window.matchMedia("(max-width:500px)");
  return (
    <div className={styles.home}>
      <HeroSection />
      {mql.matches ? <FeaturesContainer /> : <StepsContainer />}

      <SubHeroSection />
      {mql.matches ? <StepsContainer /> : <FeaturesContainer />}
      <NewsContainer />
      <ToolsContainer title="home" />
      <TestimonialsContainer />
      <ContactComponent />
      <Footer />
    </div>
  );
};

export default Home;
