import styles from "../../FormPage.module.scss";
import classNames from "classnames";
import { useEffect } from "react";

const AboutCompany = ({ formdata, setFormdata, setCompanyProgress }) => {
  useEffect(() => {
    const values = [
      "nameOfCompayForBorrowingIn",
      "companyRegistrationNumber",
      "companyAddress1",
      "companyAddress2",
      "companyCity",
      "companyPostCode",
    ];

    let score = 0;
    for (let i = 0; i < values.length; i++) {
      if (formdata[values[i]] !== "") {
        score += 1;
      }
    }

    setCompanyProgress((score / values.length) * 100);
  }, [formdata, setCompanyProgress]);

  return (
    <form action="" className={styles.form1}>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section3)}
        >
          <span className={styles.questionText}>
            {" "}
            What is the name of the company you are borrowing from?{" "}
          </span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <input
            type="text"
            placeholder="Company name"
            className={styles.answer}
            value={formdata.nameOfCompayForBorrowingIn}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                nameOfCompayForBorrowingIn: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section3)}
        >
          <span className={styles.questionText}>
            {" "}
            What is the company registration number?
          </span>
        </label>
        <div className={styles.answerBlock}>
          <input
            type="text"
            placeholder="Company number"
            className={styles.answer}
            value={formdata.companyRegistrationNumber}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                companyRegistrationNumber: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section3)}
        >
          <span className={styles.questionText}>
            {" "}
            What is the registered company address?
          </span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <input
            type="text"
            placeholder="Company address"
            className={styles.answer}
            value={formdata.companyAddress1}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                companyAddress1: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section3)}
        >
          <span className={styles.questionText}>
            {" "}
            Address Line 2 (optional)
          </span>
        </label>
        <div className={styles.answerBlock}>
          <input
            type="text"
            placeholder="Enter Address"
            className={styles.answer}
            value={formdata.companyAddress2}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                companyAddress2: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section3)}
        >
          <span className={styles.questionText}> City or Town</span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <input
            type="text"
            placeholder="Enter City or Town"
            className={styles.answer}
            value={formdata.companyCity}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                companyCity: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section3)}
        >
          <span className={styles.questionText}> Postcode?</span>
        </label>
        <div className={styles.answerBlock}>
          <input
            type="text"
            placeholder="Postcode?"
            className={styles.answer}
            value={formdata.companyPostCode}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                companyPostCode: e.target.value,
              });
            }}
          />
        </div>
      </div>
    </form>
  );
};

export default AboutCompany;
