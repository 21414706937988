import { useEffect } from "react";
import styles from "../../DevFinanceForm.module.scss";
import Question from "../Question";
import { AnswerText } from "../Answer";
import otherGif from "../../../../assets/images/other1.gif";
import othersvg from "../../../../assets/images/other1.png";
import otherGif1 from "../../../../assets/images/other.gif";

const AdditionalInfo = ({
  formData,
  setFormData,
  setAdditionalInfoProgress,
}) => {
  useEffect(() => {
    const values = [
      "contractorName",
      "contractorLastTurnOver",
      "contractorLastProfit",
      "contractorWebsite",
      "architectName",
      "solicitorName",
    ];

    let score = 0;
    for (let i = 0; i < values.length; i++) {
      if (typeof formData[values[i]] === "object") {
        const result = Object.values(formData[values[i]]).filter((val) => val);

        let count = 0;
        result.forEach((v) => {
          if (v) count++;
        });

        score += count * 0.16;
      } else if (formData[values[i]] != 0 && formData[values[i]] != "") {
        score += 1;
      }
    }

    setAdditionalInfoProgress((score / values.length) * 100);
  }, [formData, setAdditionalInfoProgress]);

  return (
    <>
      <div className={styles.section} id="section5">
        <div className={styles.banner} style={{ backgroundColor: "#f8ab17" }}>
          <span style={{ padding: "1rem 0rem 1rem 2rem" }}>
            <img className={styles.formHeader} src={otherGif1} />
          </span>
          <p
            className={styles.head}
            style={{ flex: "1 1 100%", textAlign: "center" }}
          >
            ANYTHING ELSE <div style={{ color: "#002d72" }}>SECTION 5 OF 5</div>{" "}
          </p>
          <span style={{ padding: "1rem 2rem 1rem 0rem" }}>
            <img
              className={styles.formHeader}
              style={{ width: "80%" }}
              src={othersvg}
            />
          </span>
        </div>
        <div className={styles.gifContainer}>
          <img src={otherGif} alt="" className={styles.gif} />
        </div>
        <p className={styles.text} style={{ marginBottom: "2rem" }}>
          For this last section, here's your chance to tell us anything else you
          think we need to know about you or the application.
        </p>
      </div>
      <div className={styles.inputBox}>
        <Question
          question="What is the contractor’s name?"
          section="section5"
        />
        <AnswerText
          value={formData.contractorName}
          onChange={(e) => {
            if (!formData.contractorName)
              setAdditionalInfoProgress((progress) => progress + 1);
            setFormData({ ...formData, contractorName: e.target.value });
          }}
          placeholder="Contractor's Name"
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="What is the contractors last years turnover?"
          section="section5"
        />
        <AnswerText
          value={formData.contractorLastTurnOver}
          onChange={(e) => {
            if (!formData.contractorLastTurnOver)
              setAdditionalInfoProgress((progress) => progress + 1);
            setFormData({
              ...formData,
              contractorLastTurnOver: e.target.value,
            });
          }}
          placeholder="Last Year Turnover"
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="What is the contractors last year profit?"
          section="section5"
        />
        <AnswerText
          value={formData.contractorLastProfit}
          onChange={(e) => {
            if (!formData.contractorLastProfit)
              setAdditionalInfoProgress((progress) => progress + 1);

            setFormData({
              ...formData,
              contractorLastProfit: e.target.value,
            });
          }}
          placeholder="Last Year Profit"
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="What is the contractors website address?"
          section="section5"
        />
        <AnswerText
          value={formData.contractorWebsite}
          onChange={(e) => {
            if (!formData.contractorWebsite)
              setAdditionalInfoProgress((progress) => progress + 1);
            setFormData({ ...formData, contractorWebsite: e.target.value });
          }}
          placeholder="Contractor's Website Address"
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="What is the name of you architect?"
          section="section5"
        />
        <AnswerText
          value={formData.architectName}
          onChange={(e) => {
            if (!formData.architectName)
              setAdditionalInfoProgress((progress) => progress + 1);
            setFormData({ ...formData, architectName: e.target.value });
          }}
          placeholder="Architect's Name"
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="What is the name of you solicitor?"
          section="section5"
        />
        <AnswerText
          value={formData.solicitorName}
          onChange={(e) => {
            if (!formData.solicitorName)
              setAdditionalInfoProgress((progress) => progress + 1);
            setFormData({ ...formData, solicitorName: e.target.value });
          }}
          placeholder="Solicitor's Name"
        />
      </div>
    </>
  );
};

export default AdditionalInfo;
