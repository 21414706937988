import React, { useEffect } from "react";
import FAQCard from "../../components/FAQCard";
import styles from "../../styles/FAQ.module.scss";
import Footer from "../../components/Footer";
const CookiesPolicy = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const finalListArray = [
        {
            q: "How do cookies work and what are they used for?",
            a: "<ul> <li>Cookies are small text files that websites can put on your computer to improve your experience and give the website owner vital information. As long as they are strictly required for our site to function, according to the law, we may store cookies on your device. All other cookie kinds require your consent, though. We may use a variety of cookies, all of which are detailed in the Cookie Declaration and our Cookie Policy, if you give us your consent. These consist of: </li> <li>• Cookies that help us understand how you arrived at our website, the pages you visit while you're there.</li> <li>• Cookies that let us recognise you when you come back.</li> <li>Please be aware that if you agree to receive cookies from us and then fill out a form on our website with personal information, we will be able to link your visits to our website to that information. You can change your cookie options at any moment to revoke your authorization.</li></ul>",
        },
        {
            q: "Third party cookies ",
            a: `<ul>We occasionally include links to external websites on our own or incorporate external content into our pages. These third-party websites may also set cookies on your computer if you visit their content.
            </ul> `,
        },
        {
            q: "Blocking or deleting cookies",
            a: `<ul><li>By following the instructions provided at www.allaboutcookies.org, you can block or remove cookies that have already been downloaded to your computer.</li>

            <li>In our Privacy Policy, you may find out more about who we are, how to get in touch with us, and how we handle personal data.</li>
            
            </ul> `,
        },
        {
            q: "Cookies we use",
            a: `<ul>A cookie is a small amount of data (a text file) that a website asks your browser to save on your device when you visit it in order to remember things about you, including your preferred language or login details. These cookies are first-party cookies because they were placed by us. For our advertising and marketing initiatives, we also utilise third-party cookies, which are cookies from a domain other than the domain of the website you are viewing. In more detail, we do the following things with cookies and other tracking tools:
            <li>
            <li><b>Strictly Necessary Cookies</b>
            <li>These cookies cannot be disabled in our systems since they are required for the website to work. They are often only set in reaction to your actions, such as selecting your privacy settings, logging in, or completing forms, which constitute a request for services. Some features of the website won't function if you set your browser to block or notify you about these cookies. No personally identifiable data is saved in these cookies.
            <li>
            <li><b>Targeting Cookies</b>
            <li>These cookies might be placed by our advertising partners through our website. They might be employed by those businesses to create a profile of your interests and present you with pertinent advertisements on other websites. They rely on uniquely identifying your browser and internet device instead of directly storing personal information. Your exposure to targeted advertising will be reduced if you refuse to accept these cookies.
            </li>
            </ul> `,
        },
        {
            q: "Manage cookies",
            a: `<ul>Please select the shield icon in the lower left corner of the screen to manage your cookie options.
            </ul> `,
        },
       
    ];

    return (
        <>
            <div>
                <section className={styles.faqsSecondary}>
                    <div className={styles.faqsHeader}>
                        <h1>Cookies policy</h1>

                    </div>

                    <div className={styles.faqsContainer}>
                        {finalListArray &&
                            finalListArray.map((item, idx) => (
                                <FAQCard idx={idx} faqsList={item} />
                            ))}
                    </div>
                </section>
            </div>
            <Footer />
        </>

    );
};

export default CookiesPolicy;
