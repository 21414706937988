// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyCRk94ewOfOyRXdZrUGNnquu2qAeoFlyi4",
//   authDomain: "bridging365-f4e43.firebaseapp.com",
//   projectId: "bridging365-f4e43",
//   storageBucket: "bridging365-f4e43.appspot.com",
//   messagingSenderId: "444028048243",
//   appId: "1:444028048243:web:10e3090cf6a37106062b06",
//   measurementId: "G-1QR53ZWQ8P",
// };
const firebaseConfig = {
  apiKey: "AIzaSyCxg1sbhKU9tGiGKHXqOULWeDj3xIh_BcA",
  authDomain: "briding365.firebaseapp.com",
  projectId: "briding365",
  storageBucket: "briding365.appspot.com",
  messagingSenderId: "593493139750",
  appId: "1:593493139750:web:3129bae2ccbc5e658221e7",
  measurementId: "G-0BHY1PT28J"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const database = getFirestore(app);
export const createUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = database.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email } = user;
    const { name, contact } = additionalData;

    try {
      await userRef.set({
        name,
        contact,
        email,
        createdAt: new Date(),
      });
    } catch (error) {
      console.log("Error in creating user", error);
    }
  }
};
