export const calculateInterest = ({ sum, formData }) => {
  let percentage;

  const { loanDuration, experienceAsDeveloper } = formData;
  const expierences = [
    "First Time Developer",
    "Less than 5 projects of various types",
    "Less than 5 projects of similar size",
    "5+ various projects",
    "5+ projects like this",
  ];

  if (experienceAsDeveloper === expierences[0]) {
    percentage = 1;
  } else if (experienceAsDeveloper === expierences[1]) {
    percentage = 0.75;
  } else if (experienceAsDeveloper === expierences[2]) {
    percentage = 0.85;
  } else if (experienceAsDeveloper === expierences[3]) {
    percentage = 0.5;
  } else if (experienceAsDeveloper === expierences[4]) {
    percentage = 0.65;
  }

  const interest = sum * (percentage / 100) * loanDuration;

  console.log("interests: ", interest);

  return interest;
};
