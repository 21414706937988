import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgages3 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
      Experts in Development Finance
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
        In most cases we can help our clients with up to 75% Gross Development Value (GDV) and 100% of the land costs if additional security other than a single property or plot of land can be provided. Staggered payments throughout the duration of the loan are provided to necessitate the construction process, but we are flexible and in some cases we have been able to provide 100% of the total construction costs upfront as well.

        </p>
        <p>
        Our team understands how to help a development project in the best possible way, and we’ll put together an extensive plan of action based on the specific valuations of the project and the land/property in question, the length of the requested finance, and the LTV. Our lending panel is then utilised to put together the right package for your needs, cutting out wasted time and putting the ball in motion ASAP.

        </p>
        <p>
          <strong>Specialists in Bad Credit Bridging Finance</strong> <br />
          We’ve got a successful track record in the last two decades of helping out those applicants for credit that have been turned away elsewhere. If you have poor credit, or even been issued with a CCJ or bankruptcy notice, our large panel of 169 funders provides us with the choices to match you with the lending stream that suits your specific needs and goals. Each case is different to the next and that’s why we take all of our client’s applications on a case by case basis to ensure that the bridging loan or development finance that they receive gives them the best chance to succeed.
        </p>
        {/* <div className={styles.qandA}>
          <p className={styles.question}>
            Do I Qualify for a Bridging Loan with Bad Credit?
          </p>
          <p className={styles.answer}>
            There could be a number of reasons why you have a bad credit score
            on your file. Don’t worry though; we have access to a number of
            funders who can assist, even if you have suffered bad credit for the
            following reasons:
          </p>
        </div>
        <div className={styles.qandA}>
          <p className={styles.question}>CCJ or Defaults </p>
          <p className={styles.answer}>
            Unfortunately, you might not even know that you have a CCJ against
            your name until you apply for credit. At Bridging 365 we have a
            panel of bad credit specialist bridging funders who understand that
            just because you have a CCJ, it does not mean you are not able to
            borrow.
          </p>
        </div>
        <div className={styles.qandA}>
          <p className={styles.question}>Mortgage Repayments </p>
          <p className={styles.answer}>
            It is a similar situation for missed mortgage repayments, but this
            does not necessarily affect your chances of securing a short term
            bridging loan to stop a repossession order. Whether you require a
            regulated bridging loan for bad credit or a unregulated bridging
            loan, we are here to help, with access to 22 regulated bridging
            funders and over 90 unregulated funders, we will find a funder to
            help you save your house.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default MatchingMortgages3;
