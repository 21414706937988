import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainer3 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Development Finance Bad Credit
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li>        Adverse Credit No Hindrance to Development Finance
</li>
          <li>£25,000 - £50 million Loans Available
</li>
          <li>Competitive Rates from a Wide Range of Funders
</li>
          <li>Up to 100% Development Costs in Some Cases
</li>
          <li>Up to 75% GDV
</li>
        
        </ul>
        <div className={styles.text}>

<p> Development finance is used to secure funding for myriad projects involving construction and development. It covers both commercial and residential properties and can fund the following projects:</p>
<ul>
  <li>  New-Build Residential Homes
</li>
  <li>New-Build Residential Apartment Complexes
</li>
  <li>Renovation/Refurbishment of Existing Homes
</li>
  <li>Construction of Commercial and Retail Properties
</li>
  <li>Development of Large-Scale Hotel Projects
</li>
  <li>Building of Large Industrial Projects
</li>
</ul>
<p>Development finance is a way in which construction projects can get underway. If you are a developer you’ll know that you require the upfront funds to secure building materials, construction crews and to cover multiple other costs</p>
<p> <strong> But what happens if your business has a poor credit history?  or
You are a first time developer and you personally have a bad credit score? </strong></p>
<p>Bridging 365 has the means with which to help you secure development finances with bad credit. Whether you are looking to renovate and convert an existing property, work on a single structure or put together a large development project spanning multiple residential and commercial buildings valuing into the millions, we have the lending panel in place to help.</p>
</div>
      </div>
    </div>
  );
};

export default CompareContainer3;
