import React, { useState } from 'react'
import styles from "./calculator.scss";

function Bridgingcalculator() {
    const [la,setLa]=useState(0);
    const [property,setProperty]=useState(0);
    const [month,setMonth]=useState(12);
    const [rate,setRate]=useState(0);
    const [a,setA]=useState("False")
    const arrangementfee=(2/100)*la;
    const pta= (0.85)*(property);
    const irr= (rate/100)*month;
    const monthlyir= (rate/100);
    const ira=la*irr;
    const irrr=la*monthlyir;
    const monthlyira=la*monthlyir*3;
    const fees=arrangementfee+ira;
    const monthlyfees=arrangementfee+monthlyira;
    const nla=la-fees;
    const monthlynla=la-monthlyfees;
  return (
    <>
    <form className="grid grid-cols-1 select-none md:grid-cols-4 h-full gap-10 md:gap-4 p-6 overflow-hidden">
      <div>
        <label className="inputlabel">Property Value</label>
        <div className="inputcls">
          <span className="il">£</span>
          <input
            type="number"
            value={property}
            onChange={(event) => setProperty(parseFloat(event.target.value))}
            className="i"
            placeholder="0"
          />
        </div>
      </div>
      <div>
        <label className="inputlabel">Loan Amount Required</label>
        <div className="inputcls">
          <span className="il">£</span>
          <input
            type="number"
            value={la}
            onChange={(event) => setLa(parseFloat(event.target.value))}
            className="i"
            placeholder="0"
          />
        </div>
      </div>
      <div>
        <label className="inputlabel">Term in Months</label>
        <input
          type="number"
          value={month}
          onChange={(event) => setMonth(parseInt(event.target.value))}
          className="in mt-2"
          placeholder="0"
        />
      </div>
      <div>
        <label className="inputlabel">Monthly Interest rate</label>
        <div className="inputcls">
          <input
            type="number"
            value={rate}
            onChange={(event) => setRate(parseFloat(event.target.value))}
            className="in"
            placeholder="0"
          />
          <span className="ir">%</span>
        </div>
      </div>
    </form>
    <div className="flex flex-row space-x-6 justify-end p-6 pt-0">
      <button
        onClick={() => setA("True")}
        className="bg-blue-600 rounded-md p-2 text-white px-6 text-sm"
      >
        View Results
      </button>
      <button
        onClick={() =>
          setA("False") &
          setLa(0) &
          setMonth(12) &
          setProperty(0) &
          setRate(0)
        }
        className="bg-blues border border-blue-600 rounded-md p-2 px-6 text-sm"
      >
        Reset
      </button>
    </div>
    {a === "True" && la >= pta && (
      <p className="text-md p-2 m-6 text-center font-bold rounded-md bg-blues">
        "Loan Amount is not Applicable"
      </p>
    )}
    {a === "True" && la < pta && (
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 p-6">
        <div>
          <p className="bg-blue-600 rounded-md p-2 mb-4 w-full md:w-5/6 text-white md:px-6 text-md">
            Rolled Up (No Monthly Payments)
          </p>
          <div className="outputlabel">
            <label className="text-md p-1 font-medium">
              Gross Loan Amount
            </label>
            <div className="outputcls">
              <span className="il">£</span>
              <input type="number" value={la} className="i" placeholder="0" />
            </div>
          </div>
          <div className="outputlabel">
            <label className="text-md p-1 font-medium">Net Loan Amount</label>
            <div className="outputcls">
              <span className="il">£</span>
              <input
                type="number"
                value={nla}
                className="i"
                placeholder="0"
              />
            </div>
          </div>
          <div className="outputlabel">
            <label className="text-md p-1 font-medium">
              Maximum Loan Available
            </label>
            <div className="outputcls">
              <span className="il">£</span>
              <input
                type="number"
                value={pta}
                className="i"
                placeholder="0"
              />
            </div>
          </div>
          <div className="outputlabel">
            <label className="text-md p-1 font-medium">
              Total Amount to Payback
            </label>
            <div className="outputcls">
              <span className="il">£</span>
              <input
                type="number"
                value={nla}
                className="i"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-end">
          <p className="bg-blue-600 rounded-md p-2 mb-4 w-full md:w-5/6 text-white md:px-6 text-md">
            Service Loans (Make a Monthly Payment)
          </p>
          <div className="outputlabel">
            <label className="text-md p-1 font-medium">
              Gross Loan Amount
            </label>
            <div className="outputcls">
              <span className="il">£</span>
              <input type="number" value={la} className="i" placeholder="0" />
            </div>
          </div>
          <div className="outputlabel">
            <label className="text-md p-1 font-medium">Net Loan Amount</label>
            <div className="outputcls">
              <span className="il">£</span>
              <input
                type="number"
                value={monthlynla}
                className="i"
                placeholder="0"
              />
            </div>
          </div>
          <div className="outputlabel">
            <label className="text-md p-1 font-medium">
              Maximum Loan Available
            </label>
            <div className="outputcls">
              <span className="il">£</span>
              <input
                type="number"
                value={pta}
                className="i"
                placeholder="0"
              />
            </div>
          </div>
          <div className="outputlabel">
            <label className="text-md p-1 font-medium">
              Monthly Payment Due
            </label>
            <div className="outputcls">
              <span className="il">£</span>
              <input
                type="number"
                value={irrr}
                className="i"
                placeholder="0"
              />
            </div>
          </div>
          <div className="outputlabel">
            <label className="text-md p-1 font-medium">
              Total Amount to Payback
            </label>
            <div className="outputcls">
              <span className="il">£</span>
              <input
                type="number"
                value={monthlynla}
                className="i"
                placeholder="0"
              />
            </div>
          </div>
        </div>
      </div>
    )}
  </>
  )
}

export default Bridgingcalculator