export const defaultState = {
  experienceAsDeveloper: "",
  typeOfDevelopment: "",
  endDevelopment: "",
  casesThatApply: [],
  residential: {
    noOfFlats: "",
    noOfHouses: "",
    noOfHousesAndFlats: "",
  },
  mixedUse: {
    noOfFlats: "",
    noOfHouses: "",
    noOfHousesAndFlats: "",
  },
  housesOrFlats: "",
  preSaleAgreement: "",
  preLetAgreement: "",
  developmentProposition: "",
  GDV: "",
  noOfMonths: "",
  constructionCost: {
    actualCost: "",
    fundingRequired: "",
  },
  loanDuration: "",
  landPurchasePrice: {
    actualCost: "",
    fundingRequired: "",
  },
  professionalCosts: {
    actualCost: "",
    fundingRequired: "",
  },
  purchasesRelatedFees: {
    actualCost: "",
    fundingRequired: "",
  },
  totalActualCost: "",
  profit: "",
  developersMargin: "",
  currentFinance: "",
  lenderName: "",
  loanExpiry: "",
  totalOutstandingBalance: "",
  exitStrategy: "",
  noOfLettableRooms: "",
  hasPlanningConsent: "",
  isHMOIssued: "",
  calculatedInterest: "",
  fundingFees: "",
  totalActualCost: "",
  totalFundingRequired: "",
  profit: "",
  developersMargin: "",
  LTC: "",
  loan: "",
  interest: "",
  address1: {
    addressOfSecurity: "",
    addresssLine2: "",
    propertyCityOrTown: "",
    postcode: "",
    propertyTenure: "",
    assetDescription: "",
  },
  address2: {
    addressOfSecurity: "",
    addresssLine2: "",
    propertyCityOrTown: "",
    postcode: "",
    propertyTenure: "",
    assetDescription: "",
  },
  hasAnotherProperty: "",
  additionalSecurityDetails: "",
  borrower: "",
  personBorrower: {
    name: "",
    address: "",
    address2: "",
    cityOrTown: "",
    postcode: "",
  },
  companyBorrower: {
    name: "",
    registration_no: "",
    address: "",
    address2: "",
    cityOrTown: "",
    postcode: "",
  },
  director1: {
    firstName: "",
    lastName: "",
    title: "",
    birthYear: "",
    address1: "",
    address2: "",
    townOrCity: "",
    postcode: "",
    contact: "",
    email: "",
    shareHolding: "",
    domicile: "",
    hadCCJ: "",
    everBankrupt: "",
  },
  addAnotherDirector: "",
  director2: {
    firstName: "",
    lastName: "",
    title: "",
    birthYear: "",
    address1: "",
    address2: "",
    townOrCity: "",
    postcode: "",
    contact: "",
    email: "",
    shareHolding: "",
    domicile: "",
    hadCCJ: "",
    everBankrupt: "",
  },
  contractorName: '',
  contractorLastTurnOver: '',
  contractorLastProfit: '',
  contractorWebsite: '',
  architectName: '',
  solicitorName: '',
  
};
