import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/LoanApplication.module.scss";
import phoneGreenImg from "../../assets/images/phone-green.png";
import phoneBlueImg from "../../assets/images/phone.png";
import phoneOrangeImg from "../../assets/images/phone-orange.png";
import classNames from "classnames";
const LoanApplication7 = ({ title }) => {
  return (
    <div className={styles.loanApplication}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
Development Finance Expertise from Bridging365      </p>
      <div
        className={classNames(
          styles.content,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <div className={styles.left}>
          <p>
          What this means for a landlord looking to convert a house into flats, is that funds can be released quickly for their needs. This could be solely for construction and renovation costs, or encompass the purchasing of the property/plot, as well as construction costs. 

          </p>
          <p>
          Bridging Ltd 365 has access to a large panel of bridging finance funders. This means that we can offer you the chance to connect with funding that can help transform a home into a selection of flats to let out to tenants. You might wish to lease the flats out to students, young professionals or families, but we can ensure you can do so with a faster turnaround than would have been possible through traditional lending streams. Contact us today and we’ll be happy to provide you with more information about how we can help you convert your home into flats with a bridging loan.

          </p>
      
        </div>
        <div
          className={classNames(
            styles.right,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : ""
          )}
        >
          <p className={styles.rightHeader}>Confused by the options?</p>
          <p className={styles.rightText}>
            Choosing the right Bridge for you can be really tricky. At Bridging 365
            our expert advisers guide you through the process. Simple and
            efficient.
          </p>
          <Link
            to="#"
            className={classNames(
              styles.link,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            Start Online
          </Link>
          <p className={styles.callText}>Call free from mobile or landline</p>
          <p
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            {" "}
            <img
              src={
                title === "Bridging Loans"
                  ? phoneGreenImg
                  : title === "Development Finance"
                  ? phoneBlueImg
                  : title === "Stop Repossessions"
                  ? phoneOrangeImg
                  : ""
              }
              alt=""
            />{" "}
            0208 146 9888
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanApplication7;
