import React, { useEffect, useState } from "react";
import FAQ from "../../components/FAQ";
import Footer from "../../components/Footer";
import styles from "./FAQPage.module.scss";
const FAQPage = () => {
  useEffect(() => {
    document.title = "Bridging 365 | Stop Repossessions";
    window.scrollTo(0, 0);
  }, []);
  const [section, setSection] = useState("");
  const clickHandler = (value) => {
    setSection(value);
  };
  return (
    <div
      className={
        section === "bLoans"
          ? styles.bLoans
          : section === "dFinance"
          ? styles.dFinance
          : section === "sRepo"
          ? styles.sRepo
          : section === "reBridging"
          ? styles.reBridging
          : ""
      }
    >
      <FAQ onClick={clickHandler} />
      <Footer />
    </div>
  );
};

export default FAQPage;
