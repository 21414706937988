import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesB9 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Fast Turnaround on Bridging Finance Application{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          What we pride ourselves on is the ability to provide a full and frank
          assessment of each individual application for a bridging loan, and in
          the most urgent cases match requirements up with the right lender for
          that specific client. The majority of applications are decided upon
          within 3-hours, and we can then deliver finance within a 48-hour
          period if absolutely necessary and for urgent cases, such as to assist
          with house repossessions or for auction finance.
        </p>
        <p>
          <strong>Flexible Approach to a Fast Bridging Loan</strong> When it
          comes to bridging loans, we have access to a large panel, offering
          flexible bridging loan for both commercial and residential investment.
          We have a range of flexible repayment options, dependent on the
          bespoke agreements with each individual client and the lender in
          question. We provide bridging finance for a number of property
          portfolio projects, as well as for refurbishment projects and other
          emergency financial funds
        </p>
        <p>
          <strong>Benefits of a Vast Panel of Bridging 365</strong> It is
          imperative that there are options from multiple Bridging 365 in order
          to choose the right type of finance for any given project, and we have
          access to 169 Bridging 365 with many different types of experience and
          specialities. Each situation is different and we wanted to be able to
          offer our clients a genuinely bespoke service, with options available.
          Even those with bad credit can be helped to acquire a bridging loan,
          and it is our connections with many different types of Bridging 365,
          ensuring that we can follow through with a claim to provide funds
          within 48-hours in the most urgent cases, without compromising price
          and security of the situation.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesB9;
