import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/LoanApplication.module.scss";
import phoneGreenImg from "../../assets/images/phone-green.png";
import phoneBlueImg from "../../assets/images/phone.png";
import phoneOrangeImg from "../../assets/images/phone-orange.png";
import phonePurpleImg from "../../assets/images/phone-purple.png";
import classNames from "classnames";
const LoanApplicationC2 = ({ title }) => {
  return (
    <div className={styles.loanApplication}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        Helping find Bridging Loans for Over 60s{" "}
      </p>
      <div
        className={classNames(
          styles.content,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <div className={styles.left}>
          <p>
            What we set out to do at Bridging 365 was create a stable of
            bridging loan funders that was wide and varied. That way, when it
            came to opening our doors to borrowers, we could be confident that
            we had the match in mind for each specific need.
          </p>
          <p>
            People aged 60 and over will struggle traditionally to find credit
            and large finance deals due to the traditional funders, such as the
            high street banks, viewing that age group as ‘high risk’. With our
            extensive network of funders, that has been built-up over a number
            of years, we have the ability to help find bridging loans for over
            60s, utilising the specific funders in that niche category. Our
            rates start at 0.75% per month, with 85% LTV, and up to 100% LTV
            where additional security is applicable.
          </p>
          <p>
            If you are over 60 and need funding through a bridging loan, there
            is a simple to use application form on this very website. For more
            in-depth analysis and information relating to your specific needs,
            you can find contact information in order to speak with our friendly
            team.
          </p>
        </div>
        <div
          className={classNames(
            styles.right,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : title === "Resources"
              ? styles.reSources
              : ""
          )}
        >
          <p className={styles.rightHeader}>Confused by the options?</p>
          <p className={styles.rightText}>
            Choosing the right Bridge for you can be really tricky. At Bridging
            365 our expert advisers guide you through the process. Simple and
            efficient.
          </p>
          <Link
            to="#"
            className={classNames(
              styles.link,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Resources"
                ? styles.reSources
                : ""
            )}
          >
            Start Online
          </Link>
          <p className={styles.callText}>Call free from mobile or landline</p>
          <p
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Resources"
                ? styles.reSources
                : ""
            )}
          >
            {" "}
            <img
              src={
                title === "Bridging Loans"
                  ? phoneGreenImg
                  : title === "Development Finance"
                  ? phoneBlueImg
                  : title === "Stop Repossessions"
                  ? phoneOrangeImg
                  : title === "Resources"
                  ? phonePurpleImg
                  : ""
              }
              alt=""
            />{" "}
            0208 146 9888
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanApplicationC2;
