import React, { useEffect } from "react";
import styles from "./FormDetails.module.scss";
import { AiOutlineDownload } from "react-icons/ai";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
const FormDetails = () => {
  const { state } = useLocation();
  console.log(state);
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate("/dashboard");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  const downLoadTableHandler = (name) => {
    const doc = new jsPDF();
    // doc.autoTable({ html: "#form-table" });
    // doc.text("Form submission data");

    autoTable(doc, {
      head: [["Question", "Answer"]],
      body: [
        ["Name", state[0].userName],
        ["Email", state[0].userEmail],
        ["Contact", state[0].userContact],
        ["Email", state[0].userEmail],
        [
          "Form submitted on",
          `${state[0].submittedOnDate} | ${state[0].submittedOnTime}`,
        ],
        [
          "Will you live in the property or is it an investment?",
          state[0].propertyUse,
        ],
        ["How quickly do you need the money?", state[0].whenMoneyNeeded],
        ["What is the purpose of the bridging loan?", state[0].purpose],
        [
          "Is your current loan from a bridging lender?",
          state[0].currentLoanFromBridgingLender
            ? state[0].currentLoanFromBridgingLender
            : "NA",
        ],
        [
          "When do you need the loan to be completed by?",
          state[0].whenLoanCompleted ? state[0].whenLoanCompleted : "NA",
        ],
        ["How much would you like to borrow?", state[0].howMuchToBorrow],
        [
          "Number of months you would like the loan for",
          state[0].numOfMonthsLoanFor,
        ],
        [
          "Would you like to make monthly payments or simply pay the full balance due on exiting the loan? ",
          state[0].monthlyOrTermPay,
        ],
        ["How will you be paying the loan back?", state[0].howPaybackLoan],
        ["Address Line 1", state[0].addressLine1],
        ["Address Line 2", state[0].addressLine2],
        ["Which city or town is the property located in ?", state[0].city],
        ["What is the postcode ?", state[0].postcode],
        [
          "What is the estimated property valuation ?",
          state[0].propertyValuation,
        ],
        [
          "Best description of the property below",
          state[0].propertyDescription,
        ],
        [
          "How many bedrooms does the property have ?",
          state[0].numberOfBedrooms,
        ],
        ["How many floors are in the building ?", state[0].numberOfFloors],
        ["Is it an ex-council property ?", state[0].isExCouncilProperty],
        [
          "Is property of standard construction? Please select below and tell us more.",
          state[0].isStandardConstruction,
        ],
        [
          "Additional information about standard construction",
          state[0].constructionDescription,
        ],
        ["Do you know the tenure of the property ?", state[0].tenureOfProperty],
        ["Please describe the asset", state[0].assetDescription],
        [
          "Would you like to add another property ?",
          state[0].addAnotherProperty,
        ],
        [
          "What is the name of the company you are borrowing in ?",
          state[0].nameOfCompayForBorrowingIn,
        ],
        [
          "What is the company registration number ?",
          state[0].companyRegistrationNumber,
        ],
        ["What is the registered company address ?", state[0].companyAddress1],
        ["Address Line 2", state[0].companyAddress2],
        ["City or Town", state[0].companyCity],
        ["Postcode ?", state[0].companyPostCode],
        ["What is Your First Name ?", state[0].directorFirstName],
        ["What is Your Last Name ?", state[0].directorLastName],
        ["What is Your Title", state[0].directorTitle],
        ["When Were You Born ?", state[0].directorDateOfBirth],
        ["Address Line 1", state[0].directorAddress1],
        ["Address Line 2", state[0].directorAddress2],
        ["Town or City", state[0].directorTown],
        ["Postal code ?", state[0].directorPostCode],
        ["What is the Best Number To Contact You ?", state[0].directorNumber],
        [
          "Have you had any CCJ's or the Defaults in the last 6 years ?",
          state[0].defaultInSixYears,
        ],
        [
          "Have you ever been declared bankrupt ?",
          state[0].everDeclaredBankrupt,
        ],
        [
          "Please tell us anything else that is relevant to the application?  ",
          state[0].otherMsg,
        ],
      ],
    });
    doc.save(`form_details-${name}.pdf`);
  };
  return (
    <div className={styles.formDetails}>
      <div className={styles.top}>
        <p>
          Form details for form id : <strong>{state[0].id}</strong>{" "}
        </p>
        <div>
          <span onClick={() => downLoadTableHandler(state[0].userName)}>
            <AiOutlineDownload />
          </span>
          <span onClick={goBackHandler}>
            <RiArrowGoBackFill />
          </span>
        </div>
      </div>
      <div className={styles.bottom}>
        <p className={styles.tableHead}>About the person</p>
        <table id="form-table">
          {/* <caption className={styles.tableCaption}>About the person</caption> */}
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{state[0].userName}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{state[0].userEmail}</td>
            </tr>
            <tr>
              <td>Contact</td>
              <td>{state[0].userContact}</td>
            </tr>
            <tr>
              <td>Form submitted on </td>
              <td>
                {state[0].submittedOnDate} | {state[0].submittedOnTime}{" "}
              </td>
            </tr>

            <tr>
              <td>Will you live in the property or is it an investment?</td>
              <td>{state[0].propertyUse}</td>
            </tr>
            <tr>
              <td>How quickly do you need the money?</td>
              <td>{state[0].whenMoneyNeeded}</td>
            </tr>
          </tbody>
        </table>
        <p className={styles.tableHead}>About the loan</p>
        <table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>What is the purpose of the bridging loan?</td>
              <td>{state[0].purpose}</td>
            </tr>
            <tr>
              <td>Is your current loan from a bridging lender?</td>
              <td>
                {state[0].currentLoanFromBridgingLender
                  ? state[0].currentLoanFromBridgingLender
                  : "NA"}
              </td>
            </tr>
            <tr>
              <td>When do you need the loan to be completed by?</td>
              <td>
                {state[0].whenLoanCompleted ? state[0].whenLoanCompleted : "NA"}
              </td>
            </tr>
            <tr>
              <td>How much would you like to borrow?</td>
              <td>{state[0].howMuchToBorrow}</td>
            </tr>
            <tr>
              <td>Number of months you would like the loan for</td>
              <td>{state[0].numOfMonthsLoanFor}</td>
            </tr>
            <tr>
              <td>
                {" "}
                Would you like to make monthly payments or simply pay the full
                balance due on exiting the loan?
              </td>
              <td>{state[0].monthlyOrTermPay}</td>
            </tr>
            <tr>
              <td>How will you be paying the loan back?</td>
              <td>{state[0].howPaybackLoan}</td>
            </tr>
          </tbody>
        </table>
        <p className={styles.tableHead}>About the Security</p>
        <table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Address Line 1 (optional)</td>
              <td>{state[0].addressLine1}</td>
            </tr>
            <tr>
              <td>Address Line 2</td>
              <td>{state[0].addressLine2}</td>
            </tr>
            <tr>
              <td> Which city or town is the property located in?</td>
              <td>{state[0].city}</td>
            </tr>
            <tr>
              <td> What is the postcode ?</td>
              <td>{state[0].postcode}</td>
            </tr>
            <tr>
              <td> What is the estimated property valuation ?</td>
              <td>{state[0].propertyValuation}</td>
            </tr>
            <tr>
              <td> Please select the best description of the property below</td>
              <td>{state[0].propertyDescription}</td>
            </tr>
            <tr>
              <td>How many bedrooms does the property have ?</td>
              <td>{state[0].numberOfBedrooms}</td>
            </tr>
            <tr>
              <td> How many floors are in the building ?</td>
              <td>{state[0].numberOfFloors}</td>
            </tr>
            <tr>
              <td> Is it an ex-council property ?</td>
              <td>{state[0].isExCouncilProperty}</td>
            </tr>
            <tr>
              <td>
                Is property of standard construction? Please select below and
                tell us more.
              </td>
              <td>{state[0].isStandardConstruction}</td>
            </tr>
            <tr>
              <td>Additional information about standard construction</td>
              <td>{state[0].constructionDescription}</td>
            </tr>
            <tr>
              <td> Do you know the tenure of the property?</td>
              <td>{state[0].tenureOfProperty}</td>
            </tr>
            <tr>
              <td>Please describe the asset:</td>
              <td>{state[0].assetDescription}</td>
            </tr>
            <tr>
              <td> Would you like to add another property ?</td>
              <td>{state[0].addAnotherProperty}</td>
            </tr>
          </tbody>
        </table>
        <p className={styles.tableHead}>About the Company</p>
        <table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>What is the name of the company you are borrowing in ?</td>
              <td>{state[0].nameOfCompayForBorrowingIn}</td>
            </tr>
            <tr>
              <td>What is the company registration number ?</td>
              <td>{state[0].companyRegistrationNumber}</td>
            </tr>
            <tr>
              <td>What is the registered company address ?</td>
              <td>{state[0].companyAddress1}</td>
            </tr>
            <tr>
              <td>Address Line 2</td>
              <td>{state[0].companyAddress2}</td>
            </tr>
            <tr>
              <td>City or Town</td>
              <td>{state[0].companyCity}</td>
            </tr>
            <tr>
              <td>Postcode ?</td>
              <td>{state[0].companyPostCode}</td>
            </tr>
          </tbody>
        </table>
        <p className={styles.tableHead}>About the Director</p>
        <table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>What is Your First Name ?</td>
              <td>{state[0].directorFirstName}</td>
            </tr>
            <tr>
              <td> What is Your Last Name ?</td>
              <td>{state[0].directorLastName}</td>
            </tr>
            <tr>
              <td> What is Your Title</td>
              <td>{state[0].directorTitle}</td>
            </tr>
            <tr>
              <td> When Were You Born ?</td>
              <td>{state[0].directorDateOfBirth}</td>
            </tr>
            <tr>
              <td>Address Line 1</td>
              <td>{state[0].directorAddress1}</td>
            </tr>
            <tr>
              <td>Address Line 2</td>
              <td>{state[0].directorAddress2}</td>
            </tr>
            <tr>
              <td>Town or City</td>
              <td>{state[0].directorTown}</td>
            </tr>
            <tr>
              <td>Postal code ?</td>
              <td>{state[0].directorPostCode}</td>
            </tr>
            <tr>
              <td>What is the Best Number To Contact You ?</td>
              <td>{state[0].directorNumber}</td>
            </tr>
            <tr>
              <td> Have you had any CCJ's or the Defaults in the last 6 years ?</td>
              <td>{state[0].defaultInSixYears}</td>
            </tr>
            <tr>
              <td> Have you ever been declared bankrupt ?</td>
              <td>{state[0].everDeclaredBankrupt}</td>
            </tr>
          </tbody>
        </table>
        <p className={styles.tableHead}>Additional Information</p>
        <table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Please tell us anything else that is relevant to the
                application?
              </td>
              <td>{state[0].otherMsg}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FormDetails;
