import React, { useEffect, useState } from "react";
import styles from "./UserProfile.module.scss";
import person from "../../assets/images/person.gif";
import { MdOutlineModeEdit } from "react-icons/md";
import loading from "../../assets/images/loading.gif";
import { database } from "../../FirebaseConfig";
import { collection, addDoc, getDocs } from "firebase/firestore";
import axios from 'axios';
import * as myConstClass from '../../components/FileWithConstants';
import $ from 'jquery'; 


const UserProfile = () => {
  const userId = localStorage.getItem("userId");
  const login_emailid = localStorage.getItem("login_emailid");
  const role = localStorage.getItem("role");
  const [showLoading, setShowLoading] = useState(false);
  const collectionRef = collection(database, "users");
  const loginurl = myConstClass.BASE_URL+""+myConstClass.LOGIN;
  let formData = new FormData();
  formData.append("login_emailid", login_emailid);
  
  var  first_name, last_name, contact, email, alternate_no, address, country, state, city, postalcode, profile_image,alternate_no;     
  axios.post(loginurl, formData)
  .then(res => {
           first_name = res.data.data.first_name;
           document.getElementById("first_name").value = first_name;

           last_name = res.data.data.last_name;
           document.getElementById("last_name").value = last_name;

           contact = res.data.data.contact;
           document.getElementById("contact").value = contact;

           alternate_no = res.data.data.alternate_no;
           document.getElementById("alternate_no").value = alternate_no;

           email = res.data.data.email;
           document.getElementById("email").value = email;

           address = res.data.data.address;
           document.getElementById("address").value = address; 

           country = res.data.data.country;
           document.getElementById("country").value = country; 

           state = res.data.data.state;
           document.getElementById("state").value = state; 

           city = res.data.data.city;
           document.getElementById("city").value = city; 

           postalcode = res.data.data.postalcode;
           document.getElementById("postalcode").value = postalcode; 

           profile_image = res.data.data.profile_image;
           if(profile_image != null)
           {
              document.getElementById("userprofileimg").src = profile_image;
           }
           else{

              document.getElementById("userprofileimg").src = person;
           }
  });



  useEffect(() => {
    document.title = "Bridging 365 | User";
    window.scrollTo(0, 0);
    getUserData();
    
    // eslint-disable-next-line
  }, []);
  const editImgHandler = () => { };
  const [receivedUserData, setReceivedUserData] = useState();
  const getUserData = () => {
    setShowLoading(true);
    setShowLoading(false);

  };









  const today = new Date();
  const date =
    today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  const time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  const [userData, setUserData] = useState({
    userId: userId,
    userImg: "",
    fName: "",
    lName: "",
    contact1: "",
    contact2: "",
    email: "",
    address: "",
    postalCode: "",
    country: "",
    state: "",
    city: "",
    joinedOnDate: date,
    joinedOnTime: time,
  });
  const formHandler = (e) => {
    e.preventDefault();

  


     if (
      !userId ||
      // !userData.userImg ||
      !document.getElementById("first_name").value ||
      !document.getElementById("last_name").value ||
      !document.getElementById("postalcode").value ||
      !document.getElementById("country").value ||
      !document.getElementById("state").value ||
      !document.getElementById("email").value ||
      !document.getElementById("address").value ||
      !document.getElementById("city").value ||
      !document.getElementById("contact").value
    ) {
      alert("Please fill all the details");
      return;
    }
    if (!document.getElementById("email").value.match(/[a-z0-9]+@[a-z]+.[a-z]{2,3}/)) {
      alert("Please enter valid email address");
      return;
    }
    if (!document.getElementById("postalcode").value.match(/^[a-zA-Z0-9 ]+$/)) {
      alert("Please enter valid postal code");
      return;
    }

    

    last_name = document.getElementById("last_name").value;
    postalcode = document.getElementById("postalcode").value;
    country = document.getElementById("country").value;
    state = document.getElementById("state").value;
    email = document.getElementById("email").value;
    address = document.getElementById("address").value;
    alternate_no = document.getElementById("alternate_no").value;
    city = document.getElementById("city").value;
    contact = document.getElementById("contact").value;
    first_name = document.getElementById("first_name").value;

    var file_data = $("#userprofileimgselect").prop("files")[0];
    

    

    var formDataUpdate = new FormData();
    formDataUpdate.append("login_emailid", login_emailid);
    formDataUpdate.append("first_name", first_name);
    formDataUpdate.append("last_name", last_name);
    formDataUpdate.append("postalcode", postalcode);
    formDataUpdate.append("country", country);
    formDataUpdate.append("state", state);
    formDataUpdate.append("email", email);
    formDataUpdate.append("address", address);
    formDataUpdate.append("alternate_no", alternate_no);
    formDataUpdate.append("city", city);
    formDataUpdate.append("contact", contact);

    if(file_data != "") 
    {
        formDataUpdate.append("file", file_data);
    }

  
    const updateprofile = myConstClass.BASE_URL+""+myConstClass.UPDATEPROFILE;     
    axios.post(updateprofile, formDataUpdate)
    .then(res => {
      console.log(res);

           document.getElementById("first_name").value = first_name;
           document.getElementById("last_name").value = last_name;
           document.getElementById("contact").value = contact;
           document.getElementById("email").value = email;
           document.getElementById("address").value = address; 
           document.getElementById("country").value = country; 
           document.getElementById("state").value = state; 
           document.getElementById("city").value = city; 
           document.getElementById("postalcode").value = postalcode;

           var img = res.data.img;
           if(img == 1)
           {
              var path = res.data.q;
              document.getElementById("userprofileimg").src = path;
           }
           else{

           }


      alert("Profile updated successfully successfully ! ");
        setShowLoading(false);
    });


     
  };
  // eslint-disable-next-line
  const [isEditMode, setIsEditMode] = useState();
  const editHandler = (e) => {
    e.preventDefault();
    setIsEditMode(true);
    alert("Edit functionality to be added soon !");
  };
  return (
    <div className={styles.userProfile}>
      <p className={styles.welComeText}>Welcome {role} !</p>
      <form action="" className={styles.form} enctype="multipart/form-data">
        <div className={styles.left}>
          <div className={styles.UserBox}>
            <div className={styles.imgContainer}>
              
              <img id="userprofileimg" src={person} alt="" onClick={editImgHandler} />
            </div>
          </div>
        </div>
        <div className={styles.inputBox}>
              <label htmlFor="">Select Profile Image</label>
              <input
                type="file"
                id="userprofileimgselect"
                name="image"
                accept="image/*"
              />
            </div>
        <div className={styles.right}>
          <div className={styles.inputBoxContainer}>
            <div className={styles.inputBox}>
              <label htmlFor="">First name</label>
              <input
                type="text"
                placeholder="Enter first name"
                id="first_name"
              />
            </div>
            <div className={styles.inputBox}>
              <label htmlFor="">Last name</label>
              <input
                type="text"
                id="last_name"
                placeholder="Enter last name"
              />
            </div> </div>

          <div className={styles.inputBox}>
            <label htmlFor="">Conact number</label>
            <input
              type="text"
              placeholder="Enter contact number"
              id="contact"
            />
          </div>
          <div className={styles.inputBox}>
            <label htmlFor="">Alternate conact number (optional)</label>
            <input
              type="text"
              placeholder="Enter contact number"
              id="alternate_no"
            />
          </div>
          <div className={styles.inputBox}>
            <label htmlFor="">Email</label>
            <input
              readonly
              type="email"
              placeholder="Enter email"
              id="email"
            />
          </div>
          <div className={styles.inputBox}>
            <label htmlFor="">Address</label>
            <input
              type="text"
              placeholder="Enter address"
              id="address"
            />
          </div>
          <div className={styles.inputBoxContainer}>
            <div className={styles.inputBox}>
              <label htmlFor="">Country</label>
              <input
                type="text"
                placeholder="Enter Country"
                id="country"
              />
            </div>
            <div className={styles.inputBox}>
              <label htmlFor="">State/region</label>
              <input
                type="text"
                placeholder="Enter State/region"
                id="state"
              />
            </div>
          </div>
          <div className={styles.inputBoxContainer}>
            <div className={styles.inputBox}>
              <label htmlFor="">City</label>
              <input
                type="text"
                placeholder="Enter city"
                id="city"
              />
            </div>
            <div className={styles.inputBox}>
              <label htmlFor="">Postal code</label>
              <input
                type="text"
                placeholder="Enter postal code"
                id="postalcode"
              />
            </div>
          </div>




           
            <button
              type="submit"
              className={styles.saveBtn}
              onClick={formHandler}
            >
              Save profile
            </button>
          
        </div>
      </form>
      <div className={styles.loadingContainer}>
        {showLoading && (
          <img src={loading} className={styles.loadingImg} alt="" />
        )}
      </div>
    </div>
  );
};

export default UserProfile;
