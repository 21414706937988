import { useState } from "react";
import styles from "./Answer.module.scss";
import classNames from "classnames";
import pounds from "../../../../assets/images/pound_icon.svg";
import NumberFormat from "react-number-format";

const AnswerText = ({
  placeholder,
  type,
  value,
  onChange,
  isMoney,
  ...props
}) => {

  if (isMoney) {
    return (
      <div className={styles.answerBlock}>
        <div className={styles.money_icon_input}>
          <img src={pounds} alt="" />
          <NumberFormat
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            thousandSeparator={true}
            className={styles.answer}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.answerBlock}>
      <input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={styles.answer}
        required={true}
        {...props}
      />
    </div>
  );
};

const AnswerDropdown = ({ options, value, onChange, ...props }) => {
  return (
    <div className={styles.answerBlock}>
      <select
        className={styles.answer}
        value={value}
        onChange={onChange}
        required={true}
        {...props}
      >
        <option value="" selected={true} disabled>
          Select option
        </option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

const AnswerCheckBox = ({ onChange, value, name, text, ...props }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className={styles.radioBoxBlock}>
      <input
        className={classNames(styles.answer, styles.radioBox)}
        type="checkbox"
        checked={isChecked}
        name={name}
        value={value}
        style={{ flexShrink: "0" }}
        required={true}
        onChange={() => {
          setIsChecked(!isChecked);
          onChange(text);
        }}
        {...props}
      />
      <span>{text}</span>
    </div>
  );
};

const AnswerBlock = ({ children }) => {
  return <div className={styles.answerBlock}>{children}</div>;
};

const AnswerTextarea = ({ value, onChange, placeholder, ...props }) => {
  return (
    <div className={styles.answerBlock}>
      <textarea
        rows="5"
        cols="40"
        onChange={onChange}
        placeholder={placeholder}
        required={true}
        {...props}
      >
        {value}
      </textarea>
    </div>
  );
};

export {
  AnswerText,
  AnswerDropdown,
  AnswerCheckBox,
  AnswerBlock,
  AnswerTextarea,
};
