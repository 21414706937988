import styles from "./Tooltip.module.scss";

const Tooltip = () => {
  return (
    <div className={styles.tooltip}>
      <p>Eg:</p>
      <ul>
        <li>Project Management</li>
        <li>S106</li>
        <li>CIL</li>
        <li>Utilities</li>
        <li>Quantity Surveyor</li>
        <li>Warranties</li>
        <li>Architecture</li>
        <li>Planning</li>
        <li>Site Security / Running Costs</li>
        <li>Demolition</li>
        <li>Party Wall</li>
        <li>Landscaping</li>
        <li>Marketing</li>
      </ul>
    </div>
  );
};

export default Tooltip;
