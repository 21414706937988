import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerB5 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Barclays Bridging Loans{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li>Commercial and Individual Applicants Welcome</li>
          <li>Rates from 0.45%</li>
          <li>
            Up to 80% LTV with 100% Achievable through Additional Security
          </li>
          <li>Fast Paced Application and Release of Funds</li>
          <li>
            Both Regulated and Non-Regulated Funders on Wide Bridging Loans
            Panel
          </li>
          <li>Specialist Funders to Cover All Applicant backgrounds</li>
        </ul>
        <div className={styles.text}>
          <p>
            Barclays bridging loans service might be something that you have
            searched for because it is a brand that you trust, and as a high
            street brand with bags of experience you’d be right to do so.
          </p>
          <p>
            In recent years however there has been a tightening of restrictions
            from traditional lending streams and at Bridging 365 we wanted to be in
            a position to help the average applicant of a bridging loan to
            achieve what they were looking for.
          </p>
          <p>
            {" "}
            <strong>Our bridging loans service can help if you are:</strong>
            <ul>
              <li>
                Stuck in a property chain and don’t want to miss out on a dream
                property as a result
              </li>
              <li>
                Looking to pay off a large and unexpected VAT or Tax bill as a
                freelancer, sole trader or business owner
              </li>
              <li>
                In need of paying an inheritance tax bill after being given
                property in the will of a family member
              </li>
              <li>
                Days away from losing your home to a repossession order and need
                a short-term financial plug to stave off the worst-case scenario
              </li>
              <li>
                In the process of planning the refurbishment of an existing
                property in order to lease it out, sell it on to buyers, or
                refinance and continue to live in the property
              </li>
            </ul>
          </p>
          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerB5;
