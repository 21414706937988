import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerB9 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Fast Bridging Loans{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li>Perfect for those needing fast access to bridging finance</li>
          <li>Suitable bridging loans for new property purchases</li>
          <li>Quick and simple process to fund refurbishment projects</li>
          <li>Application decision within 3-hours in majority of cases</li>
          <li>48-hour turnaround on funding in urgent cases</li>
          <li>Flexibility of service and competitive rates from 0.45%</li>
        </ul>
        <div className={styles.text}>
          <p>
            Bridging loans are often required for many different purposes.
            Sometimes however, time is of the essence, and in those
            circumstances you need to work with a bridging finance company that
            has expertise, a proven track record and most importantly can
            deliver you the funds you need as soon as possible.
          </p>
          <p>
            In the majority of cases applicants for a bridging loan will need
            the funds over the course of a few weeks or a month, in order to
            bridge the financial gap for a property purchase to go through, for
            example. Delays are always possible, but what happens if you need
            the funds in your account within a day or two and can’t risk a
            delay?
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerB9;
