import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/LoanApplication.module.scss";
import phoneGreenImg from "../../assets/images/phone-green.png";
import phoneBlueImg from "../../assets/images/phone.png";
import phoneOrangeImg from "../../assets/images/phone-orange.png";
import classNames from "classnames";
const LoanApplication4 = ({ title }) => {
  return (
    <div className={styles.loanApplication}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
Development Finance from Bridging365      </p>
      <div
        className={classNames(
          styles.content,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <div className={styles.left}>
          <p>
          Alternatively, please feel free to contact our development finance specialists, for help filling out the application form and for more information relating to your specific development project, required funds and future prospects. We look forward to assisting you.

          </p>
       
        </div>
        <div
          className={classNames(
            styles.right,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : ""
          )}
        >
          <p className={styles.rightHeader}>Confused by the options?</p>
          <p className={styles.rightText}>
            Choosing the right Bridge for you can be really tricky. At Bridging 365
            our expert advisers guide you through the process. Simple and
            efficient.
          </p>
          <Link
            to="#"
            className={classNames(
              styles.link,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            Start Online
          </Link>
          <p className={styles.callText}>Call free from mobile or landline</p>
          <p
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            {" "}
            <img
              src={
                title === "Bridging Loans"
                  ? phoneGreenImg
                  : title === "Development Finance"
                  ? phoneBlueImg
                  : title === "Stop Repossessions"
                  ? phoneOrangeImg
                  : ""
              }
              alt=""
            />{" "}
            0208 146 9888
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanApplication4;
