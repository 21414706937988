import { useEffect } from "react";
import styles from "../../FormPage.module.scss";
import classNames from "classnames";

const AdditionalInfo = ({
  formdata,
  setFormdata,
  setAdditionalInfoProgress,
}) => {
  useEffect(() => {
    let score = 0;
    if (formdata.otherMsg !== "") {
      score += 1;
    }

    setAdditionalInfoProgress(score * 100);
  }, [formdata, setAdditionalInfoProgress]);

  return (
    <form action="" className={styles.form1}>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section5)}
        >
          <span className={styles.questionText}>
            {" "}
            Please tell us anything else that is relevant to the application?
          </span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <textarea
            name=""
            placeholder="Let us know here"
            id=""
            cols="40"
            rows="5"
            className={classNames(styles.answer, styles.textarea)}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                otherMsg: e.target.value,
              });
            }}
          >
            {formdata.otherMsg}
          </textarea>
        </div>
      </div>
    </form>
  );
};

export default AdditionalInfo;
