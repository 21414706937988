import React from "react";
import styles from "../CalculatorPage.module.scss";
const StopRepoCalculator = () => {
  return (
    <div className={styles.StopRepoCalculator}>
      <p
        style={{ textAlign: "center", paddingTop: "2rem", fontWeight: "bold" }}
      >
        Coming soon...
      </p>
    </div>
  );
};

export default StopRepoCalculator;
