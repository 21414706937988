import React, { useEffect } from "react";
import styles from "./DevelopmentFinanceBlogs.module.scss";
import Footer from "../../components/Footer";
import CompareContainer4 from "../../components/CompareContainer/CompareContainer4";
import TypeOfRequirements from "../../components/TypeOfRequirements";
import NewsContainer from "../../components/NewsContainer";
import LoanApplication4 from "../../components/LoanApplication/LoanApplication4";
import HeroSection from "../../components/HeroSection/HeroSection";
import TestimonialsContainer from "../../components/TestimonialsContainer";
const DevelopmentFinanceBlogs4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.developmentFinanceBlogs}>
      <HeroSection title="Development Finance" />
      <CompareContainer4 title="Development Finance" />
      <TypeOfRequirements title="Development Finance" />
      <NewsContainer title="Development Finance" />
      <LoanApplication4 title="Development Finance" />
      <TestimonialsContainer title="Development Finance" />
      <Footer title="Development Finance" />
    </div>
  );
};

export default DevelopmentFinanceBlogs4;
