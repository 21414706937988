import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesB10 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Do I Need Help Paying Inheritance Tax?{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          There is no better way to deal with the complexities and urgent nature
          of most inheritance tax bills than to seek out bridging loans. With
          flexibility and speed of access to funds, bridging loans for inherited
          property are the perfect solution.
        </p>
        <p>
          <strong> UK Inheritance Tax Levels </strong>In the UK tax (at 40%) is
          expected to be paid on any estate worth over £325,000. This figure
          rises to £425,000 if a home is being passed on to the children of the
          deceased.
        </p>
        <p>
          <strong>Large Bills to Pay </strong>
          This means that for many people there is the prospect of a large
          inheritance tax bill to pay within a set 6-month period, at a time
          when emotions are running high in the aftermath of a death in the
          family.
        </p>
        <p>
          <strong>Sale of Assets </strong>
          In most cases the only way to quickly settle an inheritance tax bill
          is to liquidate the assets. This means selling a property in order to
          meet the demands of the taxman.
        </p>
        <p>
          <strong>Fast Payment of Tax Bill</strong>
          An inherited property bridging loan is the perfect answer in this
          scenario. It allows the tax bill to be paid, in full, whilst the sale
          of the assets is conducted in a thorough and effective manner (to
          allow for the loan to be paid back at a later date).
        </p>
        <p>
          <strong> Effectiveness of a Bridging Loan for Inheritance Tax</strong>
          There are always options available when looking to settle an
          inheritance tax bill. If property has been inherited you know that
          there is a 6-month timeframe in which the bill can be paid. For some
          people the option of refinancing through a second mortgage will be
          looked at. When compared with bridging loans, this option can be
          cumbersome and take some time to come to fruition. The other reason to
          choose a bridging loan is that the total amount will be paid off in
          full once the sale of the inherited asset has gone through at a later
          date, whereas a second mortgage or loan could take up to 25 years to
          pay back (with interest added on top).
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesB10;
