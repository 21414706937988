import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/LoanApplication.module.scss";
import phoneGreenImg from "../../assets/images/phone-green.png";
import phoneBlueImg from "../../assets/images/phone.png";
import phoneOrangeImg from "../../assets/images/phone-orange.png";
import phonePurpleImg from "../../assets/images/phone-purple.png";

import classNames from "classnames";
const LoanApplicationC3 = ({ title }) => {
  return (
    <div className={styles.loanApplication}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        Splitting Title Deeds in a Commercial Interest{" "}
      </p>
      <div
        className={classNames(
          styles.content,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <div className={styles.left}>
          <p>
            Things can become complicated when two owners of a company wish to
            part ways. If the business in question owns the property that they
            work out of, it can be tricky to separate the two parties interests
            in a fair way that enables both to move on with their lives,
            especially if one is staying to continue running the business.
            Bridging loans for commercial interests can be used to help a single
            member of a partnership on a split commercial title deed to gain
            control of the entire property under his or her name. This can be
            achieved with a view to either refinancing or selling the property
            in order to continue the business in future as a sole owner.
          </p>
          <p>
            It is easy to get started with bridging finance through our service.
            With access to such a wide and varied selection of bridging loan
            funders from the industry, you can find out quite quickly whether we
            can help you secure the short-term funding you need to help with
            your property situation. All you have to do is take a look at our
            bridging loan calculator page for some pointers as to what to expect
            in terms of interest and repayment terms (rates start from 0.29% and
            we offer LTV between 70% and 100% depending on the amount of
            security you have available to add to your application).
          </p>
          <p>
            Once you have applied for bridging loans for splitting deeds, we
            like to provide you with an answer as soon as possible. Most of our
            bridging loan applications are decided upon within a 3-hour period,
            and in urgent cases we can even deliver your funds within 48-hours
            of the loan being awarded. Browse our simple to use website, or
            contact us for more specific information based on your
            circumstances.
          </p>
        </div>
        <div
          className={classNames(
            styles.right,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : title === "Resources"
              ? styles.reSources
              : ""
          )}
        >
          <p className={styles.rightHeader}>Confused by the options?</p>
          <p className={styles.rightText}>
            Choosing the right Bridge for you can be really tricky. At Bridging
            365 our expert advisers guide you through the process. Simple and
            efficient.
          </p>
          <Link
            to="#"
            className={classNames(
              styles.link,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Resources"
                ? styles.reSources
                : ""
            )}
          >
            Start Online
          </Link>
          <p className={styles.callText}>Call free from mobile or landline</p>
          <p
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Resources"
                ? styles.reSources
                : ""
            )}
          >
            {" "}
            <img
              src={
                title === "Bridging Loans"
                  ? phoneGreenImg
                  : title === "Development Finance"
                  ? phoneBlueImg
                  : title === "Stop Repossessions"
                  ? phoneOrangeImg
                  : title === "Resources"
                  ? phonePurpleImg
                  : ""
              }
              alt=""
            />{" "}
            0208 146 9888
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanApplicationC3;
