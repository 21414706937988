import "./App.css";
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import Header from "./components/Header";
import Resources from "./pages/Resources/Resources";
import DevelopmentFinanceBlogs1 from "./pages/DevelopmentFinanceBlogs/DevelopmentFinanceBlogs1";
import BreadCrum from "./components/BreadCrum";
import Guide from "./components/Guide";
import DevelopmentFinanceBlogs2 from "./pages/DevelopmentFinanceBlogs/DevelopmentFinanceBlogs2";
import DevelopmentFinanceBlogs3 from "./pages/DevelopmentFinanceBlogs/DevelopmentFinanceBlogs3";
import DevelopmentFinanceBlogs4 from "./pages/DevelopmentFinanceBlogs/DevelopmentFinanceBlogs4";
import DevelopmentFinanceBlogs5 from "./pages/DevelopmentFinanceBlogs/DevelopmentFinanceBlogs5";
import DevelopmentFinanceBlogs6 from "./pages/DevelopmentFinanceBlogs/DevelopmentFinanceBlogs6";
import DevelopmentFinanceBlogs7 from "./pages/DevelopmentFinanceBlogs/DevelopmentFinanceBlogs7";
import StopRepoBlogs1 from "./pages/StopRepoBlogs/StopRepoBlogs1";
import StopRepoBlogs2 from "./pages/StopRepoBlogs/StopRepoBlogs2";
import CaseStudies1 from "./pages/CaseStudies/CaseStudies1";
import CaseStudies2 from "./pages/CaseStudies/CaseStudies2";
import CaseStudies3 from "./pages/CaseStudies/CaseStudies3";
import CaseStudies4 from "./pages/CaseStudies/CaseStudies4";
import BridgingLoanBlog1 from "./pages/BridgingLoanBlog/BridgingLoanBlog1";
import BridgingLoanBlog2 from "./pages/BridgingLoanBlog/BridgingLoanBlog2";
import BridgingLoanBlog3 from "./pages/BridgingLoanBlog/BridgingLoanBlog3";
import BridgingLoanBlog4 from "./pages/BridgingLoanBlog/BridgingLoanBlog4";
import BridgingLoanBlog5 from "./pages/BridgingLoanBlog/BridgingLoanBlog5";
import BridgingLoanBlog6 from "./pages/BridgingLoanBlog/BridgingLoanBlog6";
import BridgingLoanBlog7 from "./pages/BridgingLoanBlog/BridgingLoanBlog7";
import BridgingLoanBlog8 from "./pages/BridgingLoanBlog/BridgingLoanBlog8";
import BridgingLoanBlog9 from "./pages/BridgingLoanBlog/BridgingLoanBlog9";
import BridgingLoanBlog10 from "./pages/BridgingLoanBlog/BridgingLoanBlog10";
import BridgingLoanBlog11 from "./pages/BridgingLoanBlog/BridgingLoanBlog11";
import CaseStudies from "./pages/CaseStudies/CaseStudies";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import FormPage from "./pages/FormPage/FormPage";
import FAQPage from "./pages/FAQPage/FAQPage";
import ContactPage from "./pages/Contact/ContactPage";
import UserProfile from "./pages/UserProfile/UserProfile";
import Dashboard from "./pages/Dashboard/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import BridgingLonGuide from "./components/BridgingLonGuide";
import DevelopmentFinanceGuide from "./components/DevelopmentFinanceGuide";
import StopRepossessionsGuide from "./components/StopRepossessionsGuide";
import CaseStudiesGuide from "./components/CaseStudiesGuide";
import FormDetails from "./pages/FormDetails/FormDetails";
import UserDashBoard from "./pages/UserDashBoard/UserDashBoard";
import Documents from "./pages/Documents/Documents";
import SubmittedForms from "./pages/SubmittedForms/SubmittedForms";
import FormSuccessPage from "./pages/FormSuccessPage/FormSuccessPage";
import CalculatorPage from "./pages/CalculatorPage/CalculatorPage";
import AcceptedDocuments from "./pages/AcceptedDocuments/AcceptedDocuments.js";
import BridgingLoanCalculator from "./pages/CalculatorPage/components/BridgingLoanCalculator";
import RefinanceCalculator from "./pages/CalculatorPage/components/RefinanceCalculator";
import StopRepoCalculator from "./pages/CalculatorPage/components/StopRepoCalculator";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./pages/CookiesPolicy/CookiesPolicy";
import EditForm from "./pages/EditForm/EditForm";
import UploadedDocuments from "./pages/UploadedDocuments/UploadedDocuments";
import UploadedDocumentsDashboard from "./pages/UploadedDocumentsDashboard/UploadedDocumentsDashboard";
import DevFinanceForm from "./pages/DevFinanceForm/DevFinanceForm";

const LazyBridgingLoans = React.lazy(() =>
  import("./pages/BridgingLoans/BridgingLoans")
);
const LazyDevelopmentFinance = React.lazy(() =>
  import("./pages/DevelopmentFinance/DevelopmentFinance")
);
const LazyStopReposession = React.lazy(() =>
  import("./pages/StopRepossessions/StopRepossessions")
);
const LazyReBridging = React.lazy(() =>
  import("./pages/ReBridging/ReBridging")
);
const LazyAboutUsPage = React.lazy(() => import("./pages/About/AboutUsPage"));
const LazySignInPage = React.lazy(() =>
  import("./pages/SignInPage/SignInPage")
);

const LazyLoginModalPage = React.lazy(() =>
  import("./pages/LoginModal/LoginModalPage")
);

function App() {
  const location = useLocation().pathname;
  return (
    <>
      <Header location={location} />
      <BreadCrum location={location} />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route
          path="/bridgingloans"
          element={
            <React.Suspense fallback="Loading...">
              <LazyBridgingLoans />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/developmentfinance"
          element={
            <React.Suspense fallback="Loading...">
              <LazyDevelopmentFinance />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/stoprepossessions"
          element={
            <React.Suspense fallback="Loading...">
              <LazyStopReposession />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/rebridgingloans"
          element={
            <React.Suspense fallback="Loading...">
              <LazyReBridging />
            </React.Suspense>
          }
        ></Route>
        <Route path="/casestudies" element={<CaseStudies />}></Route>
        <Route path="/bridgingloans/guide" element={<Guide />}></Route>
        <Route path="/developmentfinance/guide" element={<Guide />}></Route>
        <Route path="/stoprepossessions/guide" element={<Guide />}></Route>
        <Route path="/rebridgingloans/guide" element={<Guide />}></Route>
        <Route path="/casestudies/guide" element={<Guide />}></Route>
        <Route path="/guide" element={<Guide />}>
          <Route path="bridgingloans" element={<BridgingLonGuide />}>
            {" "}
          </Route>
          <Route
            path="developmentfinance"
            element={<DevelopmentFinanceGuide />}
          >
            {" "}
          </Route>
          <Route path="stoprepossessions" element={<StopRepossessionsGuide />}>
            {" "}
          </Route>
          <Route path="casestudies" element={<CaseStudiesGuide />}>
            {" "}
          </Route>
        </Route>
        <Route
          path="/bridgingloansblogs/blog1"
          element={<BridgingLoanBlog1 />}
        ></Route>
        <Route
          path="/bridgingloansblogs/blog2"
          element={<BridgingLoanBlog2 />}
        ></Route>
        <Route
          path="/bridgingloansblogs/blog3"
          element={<BridgingLoanBlog3 />}
        ></Route>
        <Route
          path="/bridgingloansblogs/blog4"
          element={<BridgingLoanBlog4 />}
        ></Route>
        <Route
          path="/bridgingloansblogs/blog5"
          element={<BridgingLoanBlog5 />}
        ></Route>
        <Route
          path="/bridgingloansblogs/blog6"
          element={<BridgingLoanBlog6 />}
        ></Route>
        <Route
          path="/bridgingloansblogs/blog7"
          element={<BridgingLoanBlog7 />}
        ></Route>
        <Route
          path="/bridgingloansblogs/blog8"
          element={<BridgingLoanBlog8 />}
        ></Route>
        <Route
          path="/bridgingloansblogs/blog9"
          element={<BridgingLoanBlog9 />}
        ></Route>
        <Route
          path="/bridgingloansblogs/blog10"
          element={<BridgingLoanBlog10 />}
        ></Route>
        <Route
          path="/bridgingloansblogs/blog11"
          element={<BridgingLoanBlog11 />}
        ></Route>
        <Route
          path="/developmentfinanceblogs/blog1"
          element={<DevelopmentFinanceBlogs1 />}
        ></Route>
        <Route
          path="/developmentfinanceblogs/blog2"
          element={<DevelopmentFinanceBlogs2 />}
        ></Route>
        <Route
          path="/developmentfinanceblogs/blog3"
          element={<DevelopmentFinanceBlogs3 />}
        ></Route>
        <Route
          path="/developmentfinanceblogs/blog4"
          element={<DevelopmentFinanceBlogs4 />}
        ></Route>
        <Route
          path="/developmentfinanceblogs/blog5"
          element={<DevelopmentFinanceBlogs5 />}
        ></Route>
        <Route
          path="/developmentfinanceblogs/blog6"
          element={<DevelopmentFinanceBlogs6 />}
        ></Route>
        <Route
          path="/developmentfinanceblogs/blog7"
          element={<DevelopmentFinanceBlogs7 />}
        ></Route>
        <Route
          path="/stoprepossessionsblogs/blog1"
          element={<StopRepoBlogs1 />}
        ></Route>
        <Route
          path="/stoprepossessionsblogs/blog2"
          element={<StopRepoBlogs2 />}
        ></Route>
        <Route path="/casestudies1" element={<CaseStudies1 />}></Route>
        <Route path="/casestudies2" element={<CaseStudies2 />}></Route>
        <Route path="/casestudies3" element={<CaseStudies3 />}></Route>
        <Route path="/casestudies4" element={<CaseStudies4 />}></Route>
        <Route path="/form" element={<FormPage />}></Route>
        <Route path="/formsuccess" element={<FormSuccessPage />}></Route>
        <Route path="/formdetails/:formId" element={<FormDetails />}></Route>
        <Route path="/editform/:formId" element={<EditForm />}></Route>
        <Route path="/faq" element={<FAQPage />}></Route>
        <Route path="/contact" element={<ContactPage />}></Route>

        <Route
          path="/uploadeddocumentslist/:formId"
          element={<UploadedDocumentsDashboard />}
        />

        <Route
          path="/about"
          element={
            <React.Suspense fallback="Loading...">
              <LazyAboutUsPage />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/signin"
          element={
            <React.Suspense fallback="Loading...">
              <LazySignInPage />
            </React.Suspense>
          }
        ></Route>

        <Route
          path="/loginmodal"
          element={
            <React.Suspense fallback="Loading...">
              <LazyLoginModalPage />
            </React.Suspense>
          }
        ></Route>

        <Route
          path="/dashboard"
          element={<ProtectedRoute Component={Dashboard} />}
        ></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/cookies-policy" element={<CookiesPolicy />}></Route>
        <Route
          path="/user"
          element={<ProtectedRoute Component={UserDashBoard} />}
        >
          <Route index element={<ProtectedRoute Component={UserProfile} />} />
          <Route
            path="profile"
            element={<ProtectedRoute Component={UserProfile} />}
          />
          <Route
            path="forms"
            element={<ProtectedRoute Component={SubmittedForms} />}
          />
          <Route
            path="upload-documents"
            element={<ProtectedRoute Component={Documents} />}
          />
          <Route
            path="uploaded-documents"
            element={<ProtectedRoute Component={UploadedDocuments} />}
          />
          <Route
            path="uploaded-documents-list/:formId"
            element={<ProtectedRoute Component={UploadedDocumentsDashboard} />}
          />
          <Route
            path="accepted-documents"
            element={<ProtectedRoute Component={AcceptedDocuments} />}
          />
        </Route>
        <Route
          path="/calculator"
          element={<ProtectedRoute Component={CalculatorPage} />}
        >
          <Route
            index
            element={<ProtectedRoute Component={BridgingLoanCalculator} />}
          />
          <Route
            path="bridgingloan"
            element={<ProtectedRoute Component={BridgingLoanCalculator} />}
          />
          <Route
            path="refinance"
            element={<ProtectedRoute Component={RefinanceCalculator} />}
          />
          <Route
            path="developmentfinance"
            element={<ProtectedRoute Component={StopRepoCalculator} />}
          />
        </Route>
        <Route path="/resources" element={<Resources />}></Route>
        <Route path="*" element={<PageNotFound />}></Route>
        <Route path="/devfinanceform" element={<DevFinanceForm />}></Route>
      </Routes>
    </>
  );
}
export default App;
