import React, { useEffect } from "react";
import styles from "./DevelopmentFinanceBlogs.module.scss";
import Footer from "../../components/Footer";
import CompareContainer5 from "../../components/CompareContainer/CompareContainer5";
import TypeOfRequirements from "../../components/TypeOfRequirements";
import MatchingMortgages5 from "../../components/MatchingMortgages/MatchingMortgages5";
import NewsContainer from "../../components/NewsContainer";
import LoanApplication5 from "../../components/LoanApplication/LoanApplication5";
import HeroSection from "../../components/HeroSection/HeroSection";
import TestimonialsContainer from "../../components/TestimonialsContainer";
const DevelopmentFinanceBlogs5 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.developmentFinanceBlogs}>
      <HeroSection title="Development Finance" />
      <CompareContainer5 title="Development Finance" />
      <TypeOfRequirements title="Development Finance" />
      <MatchingMortgages5 title="Development Finance" />
      <NewsContainer title="Development Finance" />
      <LoanApplication5 title="Development Finance" />
      <TestimonialsContainer title="Development Finance" />
      <Footer title="Development Finance" />
    </div>
  );
};

export default DevelopmentFinanceBlogs5;
