import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerB10 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Inherited Property Bridging Loan{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li>Access to Bridging Loans from £25,000 to £50 million</li>
          <li>Fast Decisions on All Applications</li>
          <li>Funds Available within 48-hours in Some Cases</li>
          <li>Pay Urgent and Outstanding Inheritance Tax Bills</li>
          <li>Rates from 0.75% per month on Flexible Repayment Terms</li>
        </ul>
        <div className={styles.text}>
          <p>
            When a person dies the executor of the estate in question has to
            juggle many different aspects of the finances of the deceased. This
            includes the inheritance tax due and it is important that for a
            family member that has been given property in a will, that they are
            not at risk of losing that property due to a struggle to pay of
            inheritance tax.
          </p>
          <p>
            Bridging loans can help with inherited property and associated tax,
            becoming an effective solution to grieving family members and
            friends of the deceased, who have myriad other problems and emotions
            to deal with at such an upsetting time.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerB10;
