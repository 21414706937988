import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainer4 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Development Finance Application Form
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li>        Large Option of Funders for Development Finance Projects
</li>
          <li>Specific Financing for Each Client
</li>
          <li>Flexible Rates and Terms of Repayment (1-36 Months and Rates from 0.75%)
</li>
          <li>£25,000 to £50 million Development Loans Available
</li>
          <li>Up to 75% GDV and 100% Where Additional Security is an Option
</li>
          
        </ul>
        <div className={styles.text}>

<p>Below you’ll see a simple-to-fill-out development finance application form. If you have further questions you can speak to a member of our customer service team. They will be able to offer up-to-date advice and guidance in relation to our wide panel of funders, offering flexible bridging finance from 1-36 months, up to 75% GDV and 100% construction and development costs where additional security is available.</p>
</div>
      </div>
    </div>
  );
};

export default CompareContainer4;
