import classNames from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../styles/ToolsContainer.module.scss";
const ToolsContainer = ({ title }) => {
  const location = useLocation().pathname;
  const toolsList1 = [
    {
      id: 1,
      head: "How much will it cost to take out a bridging loan?",
      subHead: "You’re ready for your new project, so what will it cost?",
      btnText: "Total Costs",
      path: "/calculator/bridgingloan"
    },
    {
      id: 2,
      head: "How much will it cost to settle the bridging loan early?",
      subHead:
        "You’re ahead of the game and finished your project early... so what are the costs?",
      btnText: "Exiting Early",
      path: "/calculator/bridgingloan",

    },
    {
      id: 3,
      head: "How much will it cost if I default?",
      subHead:
        "If your initial period has ended or is about to, find out the cost of doing nothing.",
      btnText: "Defaulting Cost",
      path: "/calculator/bridgingloan",

    },
  ];
  const toolsList2 = [
    {
      id: 1,
      head: "Bridging Loan Calculator",
      subHead:
        "Our easy to use Bridging Loan Calculator gives instant detailed quotes showing interest payable, charges and other costs associated with taking out a Bridging Loan.",
      btnText: "Bridging Cost Calculator",
      path: "/calculator/bridgingloan",
      img: "./images/bCalc.png"
    },
    {
      id: 2,
      head: "Re-Bridging Loan Calculator",
      subHead:
        "If your current bridging loan term is coming to an end and you require a little more time, we are here to help. We have exclusive partnerships with specialist fund providers designed to act quickly to stop you from defaulting on your existing loan. ",
      btnText: "Re-Bridging Cost Calculator",
      path: "/calculator/refinance",
      img: "./images/reBCalc.png"
    },
    {
      id: 3,
      head: "Development Finance Calculator",
      subHead:
        "This tool allows you to breakdown the individual costs of your development project. Simply plug in your postcode, the type of build as well as the level of finishing that you’d like on the development and this calculator will estimate the costs of the build for you.",
      btnText: "Development Cost Calculator",
      path: "/calculator/developmentfinance",
      img: "./images/dCalc.png"

    },
  ];
  const toolsList3 = [
    {
      id: 1,
      head: "How much will it cost to move your Bridging loan to a new lender? ",
      subHead:
        "If your initial period has ended or is about to, find out the cost of doing nothing.",
      btnText: "Re-Bridging Cost",
      path: "/calculator/refinance"

    },
    {
      id: 2,
      head: "How much will it cost to do nothing?",
      subHead: "Want to see what kind of nightmare we can save you from.",
      btnText: "Default Calculator",
      path: "/calculator/refinance"

    },
    {
      id: 3,
      head: "Comparison Calculator",
      subHead:
        "Compare  the cost of moving to a new lender VS defaulting with your existing lender.",
      btnText: "Comparison Calculator",
      path: "/calculator/refinance"

    },
  ];
  const toolsList4 = [
    {
      id: 1,
      head: "Calculate the LTC and LTGDV",
      subHead:
        "Let us help you with this basic calculator to walk you through how to work out the Loan to Costs (LTC) and the Loan to Gross Development Value.",
      btnText: "LTC & LTGDV",
      path: "/calculator/developmentfinance",

    },
    {
      id: 2,
      head: "Development Finance Calculator",
      subHead:
        "Looking to know how much it will all cost. Here is a calculator to help you work out the cost of the entire project",
      btnText: "Development Finance Calculator",
      path: "/calculator/developmentfinance",

    },
    {
      id: 3,
      head: "How much will it cost to move you to a new Development finance lender?",
      subHead:
        "If your initial period has ended or is about to, find out the cost of doing nothing and how much to move the funding, creating more time",
      btnText: "Development Finance Calculator",
      path: "/calculator/developmentfinance",

    },
  ];
  const toolsList5 = [
    {
      id: 1,
      head: "A practical guide to your rights",
      subHead:
        "All you need to know is to arm yourself against lenders who wish to repossess your property",
      btnText: "Your Rights",
      path: "/calculator"
    },
    {
      id: 2,
      head: "How do I stop a repossession?",
      subHead:
        "Here we share a step by step guide on what to do when you are faced with this difficult situation.",
      btnText: "Stop Repo Guide",
      path: "/calculator"

    },
    {
      id: 3,
      head: "Repo Calculator",
      subHead:
        "Ever wondered how much money you will save or lose depending on your actions. Essentially the calculator will break down the costs of a repo vs re-bridge.",
      btnText: "Repo Calculator",
      path: "/calculator"

    },
  ];
  const finalToolsList =
    title === "Bridging Loans"
      ? toolsList1
      : title === "Re-Bridging Loans"
        ? toolsList3
        : title === "home"
          ? toolsList2
          : title === "Development Finance"
            ? toolsList4
            : title === "Stop Repossessions"
              ? toolsList5
              : title === "Resources"
                ? toolsList3
                : " ";
  return (
    <div className={styles.toolsContainer}>
      <div className={styles.top}>
        <p className={styles.header}>Tools and calculators</p>
        <p className={styles.subHeader}>
          Practical gadgets to help keep things simple
        </p>
      </div>
      <div className={styles.middle}>
        {finalToolsList.map((item) => {
          return (
            <div
              className={classNames(
                styles.tool,
                location === "/calculator/bridgingloan"
                  ? styles.reBridging
                  : location === "/stoprepossessions"
                    ? styles.sRepo
                    : location === "/developmentfinance"
                      ? styles.dFinance
                      : location === "/bridgingloans"
                        ? styles.bLoans
                        : location === "/" &&
                          finalToolsList === toolsList2 &&
                          item.id === 1
                          ? styles.home1
                          : location === "/" &&
                            finalToolsList === toolsList2 &&
                            item.id === 2
                            ? styles.home2
                            : location === "/" &&
                              finalToolsList === toolsList2 &&
                              item.id === 3
                              ? styles.home3
                              : ""
              )}
            >
              <div className={styles.header}>
                <p
                  className={classNames(
                    styles.head,
                    location === "/calculator/refinance"
                      ? styles.reBridging
                      : location === "/stoprepossessions"
                        ? styles.sRepo
                        : location === "/developmentfinance"
                          ? styles.dFinance
                          : location === "/bridgingloans"
                            ? styles.bLoans
                            : // : location === "/"
                            // ? styles.home
                            location === "/" &&
                              finalToolsList === toolsList2 &&
                              item.id === 1
                              ? styles.home1
                              : location === "/" &&
                                finalToolsList === toolsList2 &&
                                item.id === 2
                                ? styles.home2
                                : location === "/" &&
                                  finalToolsList === toolsList2 &&
                                  item.id === 3
                                  ? styles.home3
                                  : ""
                  )}
                >
                  {item.head}
                </p>
                {item.img && <img src={item.img} alt="" />}
              </div>

              <p className={styles.subHead}>{item.subHead}</p>
              <Link
                to={item.path}
                className={classNames(
                  styles.link,
                  location === "/rebridgingloans"
                    ? styles.reBridging
                    : location === "/stoprepossessions"
                      ? styles.sRepo
                      : location === "/developmentfinance"
                        ? styles.dFinance
                        : location === "/bridgingloans"
                          ? styles.bLoans
                          : location === "/" &&
                            finalToolsList === toolsList2 &&
                            item.id === 1
                            ? styles.home1
                            : location === "/" &&
                              finalToolsList === toolsList2 &&
                              item.id === 2
                              ? styles.home2
                              : location === "/" &&
                                finalToolsList === toolsList2 &&
                                item.id === 3
                                ? styles.home3
                                : ""
                )}
              >
                {item.btnText}
              </Link>
            </div>
          );
        })}
      </div>
      <div className={styles.bottom}>
        <Link
          to="/calculator"
          className={classNames(
            styles.allToolsLink,
            location === "/rebridgingloans"
              ? styles.reBridging
              : location === "/stoprepossessions"
                ? styles.sRepo
                : location === "/developmentfinance"
                  ? styles.dFinance
                  : location === "/bridgingloans"
                    ? styles.bLoans
                    : location === "/"
                      ? styles.home
                      : ""
          )}
        >
          See all Calculator Tools
        </Link>
      </div>
    </div>
  );
};

export default ToolsContainer;
