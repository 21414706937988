import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerS2 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
How to Stop Your Mortgage Lender from Repossessing Your Home    </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
    
        <li>169 lenders available to assist with bad credit applications</li>
        <li>48-hour turnaround in funding for successful applicants</li>
        <li>Rates from 0.75%</li>
        <li>Short-term funding to help halt a repossession order on your home</li>
        <li>Mortgage arrears not considered by all lenders</li>
        
        </ul>
        <div className={styles.text}>

<p>There are a number of processes that your mortgage lender must go through, and factors to consider, before a court order is issued to repossess your home. It is important to understand these processes and to try to stay calm in a stressful and financially difficult position. 
</p>
<p>If you are faced with a court order to repossess your home, it can be a difficult and stressful time as you put together all of the options available to you. Bridging 365 has the team and the experience in place to help you at this critical time, putting together bridging finance to help stop a repossession order against your home. 
</p>
<p>We understand how a repossession order is a time sensitive issue, and you’ll need answers as soon as possible to stop your home from being lost due to mortgage arrears. With nearly 20 years’ of experience within the industry, our team has built a wide and varied network of lending connections, ensuring that no matter what situations our clients bring to us, we are confident that we are in a position to help.
</p>
<p> <strong>Reasons for a Court Order to Repossess Your Home</strong>
A mortgage lender will start to worry about a homeowner if payments are missed consistently on the repayment of a mortgage. If you are in mortgage arrears you will be asked to clear them. If you fail to do so the mortgage lender can begin the process of asking a court to call a possession action, where your home is repossessed.
</p>
<p>This is a last resort option however, so it is important that you understand that even in a case where court orders have begun, it is not too late to save your home from being repossessed. There are a number of things that you can do to help stave off this threat. 
</p>
</div>
      </div>
    </div>
  );
};

export default CompareContainerS2;
