import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesC3 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        Splitting Title Deeds with Partners{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <p>
          Bridging loans for splitting titles deeds provides the part owner of a
          house the chance to move on with their lives at the quickest
          opportunity. It provides much needed short-term financing to help
          either purchase the other person’s share of the property, with a view
          to refinancing the property under your own name.
        </p>
        <p>
          The other option is to secure bridging finance in order to help pay
          towards a brand new property of your own, whilst waiting for the old
          property you shared to be sold.
        </p>
        <p>
          {" "}
          In both cases it allows for a little bit of calm and measured
          responses at a time that can often be fraught with emotion and
          difficulties.
        </p>
        <p>
          {" "}
          <strong>Bridging Loans for Inherited Property</strong>
          Splitting title deeds on a property isn’t always a case of a romantic
          relationship, or platonic partnership going awry, quite often bridging
          loans can be used to help those who are related and have been left
          property in a will.
        </p>
        <p>
          If you have been left part ownership of a property by a family member
          or loved one, and share that property with a sibling, or other
          relative, it is imperative that you act quickly and decisively in
          order to pay off any inheritance tax owed, and sell the property at
          its maximum value. A bridging loan can help to secure the property as
          a whole, paying one party for their share, and/or can be used to pay
          associated bills whilst waiting for the right time to sell the
          property in question for maximum value.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesC3;
