import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesB4 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        When is a Large Bridging Loans Required?{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <strong>
          If you are looking for financial assistance to fund the following
          projects, Bridging 365 is in a position to help:
        </strong>

        <p>
          <strong>High Value Residential Properties </strong> In certain cases
          large bridging loans can be used (on a regulated basis) for
          residential properties of a high value, those that are to be leased as
          a HMO.{" "}
        </p>
        <p>
          <strong>Commercial Properties </strong> For businesses looking to
          expand into new, larger properties to work out of, a large bridging
          loan can provide much needed capital to move forward with a venture
          whilst funds are secured.
        </p>
        <p>
          <strong>Large Scale Development Projects </strong> Funding can be
          acquired to assist with the commencement and completion of new build
          properties for both domestic and commercial purposes, as well as
          conversion of existing structures.
        </p>
        <p>
          <strong> Refurbishment Projects </strong> There are two types of
          refurbishment projects that can benefit from large bridging loans.
          Light refurbishment, where a property is uninhabitable and requires
          renovating, and a heavy refurbishment project where structural
          modifications are required, or there is the need for conversion of
          rooms, building of extensions etc.
        </p>
        <p>
          <strong>Business Purposes </strong> Sometimes, there is a need for
          businesses to acquire large bridging finance in order to help pay a
          tax bill, develop the business by growing into a new property or for
          other reasons, whilst longer-term funding is in the process of being
          completed.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesB4;
