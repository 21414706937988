import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerB2 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
Bridging Loans Bad Credit     </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
      <li>Vast panel of funders allows for those with bad credit to get help</li>
      <li>Bridging loans provided within 48-hours in urgent cases</li>
      <li>Help with preventing home repossession</li>
      <li>Plug the financial gap within a property chain</li>
      <li>No requirement for credit check in some cases</li>
      <li>CCJ’s and defaults not considered with certain funders</li>
      <li>Mortgage arrears not considered with certain funders</li>
      <li>Rates from 0.75% for non-status bridging loans</li>
        </ul>
        <div className={styles.text}>

<p>If you are searching for bridging loans, bad credit might be a problem for you, but it doesn’t have to be. Bridging 365 has access to a vast number of funders, ensuring that even those applicants with adverse credit have a chance to acquire bridging capital to assist with the purchase of a home, for a refurbishment project or to halt a repossession court order due to mortgage arrears (for either the property you live in our a rental property you own).
</p>
<p>A bridging loan can help to plug the gap in finances that you need in an urgent situation, and bad credit shouldn’t be a barrier to you finding the help you need.
</p>
</div>
      </div>
    </div>
  );
};

export default CompareContainerB2;
