import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/StepsContainer.module.scss";
import LazyLoad from "react-lazyload";

const StepsContainer = () => {
  const stepsArray = [
    {
      id: 1,
      imgPath: "./images/apply_online.png",
      heading: "Apply Online",
      subHead:
        "We are using an award-winning online application form to gather all of the information we need to make you a fee free offer.",
    },
    {
      id: 2,
      imgPath: "./images/offer_letter.png",
      heading: "Offer Letter",
      subHead:
        "We will go through the application & send you an email with the Decision in Principle (DIP) or Offer in Principle (OIP).",
    },
    {
      id: 3,
      imgPath: "./images/val_legal.png",
      heading: "Valuations & Legals",
      subHead:
        "We will only engage RICS registered Surveyors to do an open market valuation of the property and get the Solicitors talking.",
    },
    {
      id: 4,
      imgPath: "./images/release_funds.png",
      heading: "Release of Funds",
      subHead:
        "Once the documents are in order, the funds  are sent to your solicitor. They either pay off any outstanding debts on the asset or send you the money.",
    },
  ];
  return (
    <div className={styles.stepsContainer}>
      <p className={styles.header}>
        <p className={styles.headerText}>
          Market Leading Bridging Loans in 4 Simple Steps
        </p>
      </p>
      <div className={styles.steps}>
        {stepsArray.map((step) => {
          return (
            <div className={styles.stepItem}>
              <LazyLoad height={150} once>
                <img src={step.imgPath} alt="" className={styles.img} />
              </LazyLoad>
              <Link
                to={step.id === 1 ? "/form" : "/faq</div>"}
                className={classNames(
                  styles.heading,
                  step.id === 1
                    ? styles.bLoans
                    : step.id === 2
                    ? styles.dFinance
                    : step.id === 3
                    ? styles.sRepo
                    : step.id === 4
                    ? styles.reSources
                    : ""
                )}
              >
             {step.id}.   {step.heading}
              </Link>
              <p className={styles.subhead}>{step.subHead}</p>
              <Link
                to="/faq"
                className={classNames(
                  styles.link,
                  step.id === 1
                    ? styles.bLoans
                    : step.id === 2
                    ? styles.dFinance
                    : step.id === 3
                    ? styles.sRepo
                    : step.id === 4
                    ? styles.reSources
                    : ""
                )}
              >
                Learn more
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepsContainer;
