export const blogListArray1 = [
    {
      id: 1,
      title: "Large Bridging Loans",
      path: "/bridgingloansblogs/blog1",
      section: "bLoans",
    },
    {
      id: 2,
      title: "Bridging Loans bad credit",
      path: "/bridgingloansblogs/blog2",
      section: "bLoans",
    },
    {
      id: 3,
      title: "Bridging Loans Nationwide",
      path: "/bridgingloansblogs/blog3",
      section: "bLoans",
    },
    {
      id: 4,
      title: "Bridging Loans HSBC",
      path: "/bridgingloansblogs/blog4",
      section: "bLoans",
    },
    {
      id: 5,
      title: "Barclays Bridging Loans",
      path: "/bridgingloansblogs/blog5",
      section: "bLoans",
    },
    {
      id: 6,
      title: "Bridging Loan example",
      path: "/bridgingloansblogs/blog6",
      section: "bLoans",
    },
    {
      id: 7,
      title: "Bridging Loans for bad credit",
      path: "/bridgingloansblogs/blog7",
      section: "bLoans",
    },
    {
      id: 8,
      title: "Commercial Bridging Loans",
      path: "/bridgingloansblogs/blog8",
      section: "bLoans",
    },
    {
      id: 9,
      title: "Fast Bridging Loans",
      path: "/bridgingloansblogs/blog9",
      section: "bLoans",
    },
    {
      id: 10,
      title: "Inherited Property Bridging Loans",
      path: "/bridgingloansblogs/blog10",
      section: "bLoans",
    },
    {
      id: 11,
      title: "Difficult Bridging Loans",
      path: "/bridgingloansblogs/blog11",
      section: "bLoans",
    },
  ];
  export const blogListArray2 = [
    {
      id: 1,
      title: "100% Development Funding",
      path: "/developmentfinanceblogs/blog1",
      section: "dFinanace",
    },
    {
      id: 2,
      title: "Large Development Finance",
      path: "/developmentfinanceblogs/blog2",
      section: "dFinanace",
    },
    {
      id: 3,
      title: "Development Finance with bad credit",
      path: "/developmentfinanceblogs/blog3",
      section: "dFinanace",
    },
    {
      id: 4,
      title: "Development Finance application form",
      path: "/developmentfinanceblogs/blog4",
      section: "dFinanace",
    },
    {
      id: 5,
      title: "Development Loan First Time Developer",
      path: "/developmentfinanceblogs/blog5",
      section: "dFinanace",
    },
    {
      id: 6,
      title: "Splitting Deeds",
      path: "/developmentfinanceblogs/blog6",
      section: "dFinanace",
    },
    {
      id: 7,
      title: "Converting your home into flats",
      path: "/developmentfinanceblogs/blog7",
      section: "dFinanace",
    },
  ];
  export const blogListArray3 = [
    {
      id: 1,
      title: "Bridging Loans for Bankruptcy",
      path: "/casestudies1",
      section: "caseStudies",
    },
    {
      id: 2,
      title: "Bridging Loan for over 60's",
      path: "/casestudies2",
      section: "caseStudies",
    },
    {
      id: 3,
      title: "Bridging Loan for splitting title deeds",
      path: "/casestudies3",
      section: "caseStudies",
    },
    {
      id: 4,
      title: "Bridging loan for paying off sibling in an inherited Property",
      path: "/casestudies4",
      section: "caseStudies",
    },
  ];
 export  const blogListArray4 = [
    {
      id: 1,
      title: "Apply now to stop a repossession",
      path: "/stoprepossessionsblogs/blog1",
      section: "sRepo",
    },
    {
      id: 2,
      title: "How to stop your mortgage lender from repossessing your home",
      path: "/stoprepossessionsblogs/blog2",
      section: "sRepo",
    },
  ];
