import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainer7 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Converting your home into flats{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li>Bridging Finance for HMO Funding</li>
          <li>Large Panel of Bridging Loan Funders</li>
          <li>Fast and Simple Application Process</li>
          <li>Rates from 0.29%</li>
          <li>LTV from 70% +</li>
          <li>100% Construction Costs (with additional security)</li>
        </ul>
        <div className={styles.text}>
          <p>
            For many landlords and property developers the best route towards
            creating an effective and legitimate property portfolio is to take
            one property and turn it into multiple properties. Over recent years
            Houses of Multiple Occupation (or HMOs) have become more popular
            amongst landlords, as houses are converted from one property into
            multiple flats and apartments. This offers great benefit to the
            landlord in terms of potential rental yield and selling potential,
            but how difficult is it to acquire financing that will help you
            convert a property into a HMO?
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainer7;
