import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styles from "../styles/Header.module.scss";
import logoGreen from "../assets/images/logo-green.png";
import logoBlue from "../assets/images/logo-blue.png";
import logoOrgane from "../assets/images/logo-orange.png";
import logoPurple from "../assets/images/logo-purple.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { AiOutlineArrowUp } from "react-icons/ai";
import classNames from "classnames";
import person from "../assets/images/person.gif";
import loading from "../assets/images/loading.gif";
import LazyLoad from "react-lazyload";
import { animateScroll as scroll } from 'react-scroll'
import { BiPhoneCall } from 'react-icons/bi';
import threeDots from "../assets/images/threedots.gif"
import axios from 'axios';
import * as myConstClass from './FileWithConstants';
import $ from 'jquery';

const Header = ({ location }) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(true);
  const [showItems, setShowItems] = useState(false);
  const loggedIn = localStorage.getItem("loggedIn");
  const role = localStorage.getItem("role");
  const [showLoading, setShowLoading] = useState(false);
  const scrollToTop = () => {
    scroll.scrollToTop();
  }
  const ItemHandler = () => {
    setShowItems(!showItems);
  };
  let mql = window.matchMedia("(max-width:1000px)");
  const menuClickHandler = () => {
    if (mql.matches) {
      setShowMenu(!showMenu);
    }
    setShowItems(false);
  };


  const callMeNowMailSendHandler = () => {
      setShowLoading(true);
      var callmebackurl = myConstClass.BASE_URL+""+myConstClass.CALLMEFUNCTION;

      var contactNumberSubmitedForCallBack = document.getElementById("contactNumberSubmitedForCallBack").value;

      var jsondata = {"phoneno" : contactNumberSubmitedForCallBack, "requesttime" : "Now"};

      var saveDataFormLater = $.ajax({
      type: 'POST',
      url: callmebackurl,
      data: jsondata,
      success: function (resultData) {
        console.log(resultData);
        setShowLoading(false);
        window.location.reload();
      }
    });
     
  };

  const callMeLaterMailSendHandler = () => {
      var callmebackurl = myConstClass.BASE_URL+""+myConstClass.CALLMEFUNCTION;
      setShowLoading(true);
      var contactNumberSubmitedForCallBack = document.getElementById("contactNumberSubmitedForCallBack").value;

      var jsondata = {"phoneno" : contactNumberSubmitedForCallBack, "requesttime" : "Call Back Later"};

      var saveDataFormLater = $.ajax({
      type: 'POST',
      url: callmebackurl,
      data: jsondata,
      success: function (resultData) {
        console.log(resultData);
        setShowLoading(false);
        window.location.reload();
      }
    });
     
  };






  useEffect(() => {
    if (mql.matches) {
      setShowMenu(false);
    }
  }, [mql.matches]);
  const logoClickHandler = () => {
    if (mql.matches) {
      setShowMenu(false);
    }
    setShowItems(false);
    navigate("/");
  };
  
  const logoutHandler = () => {
    setShowLoading(true);
    setTimeout(() => {
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("role");
      localStorage.removeItem("userId");
      localStorage.removeItem("login_emailid");
      localStorage.setItem("profileurl", person);
      navigate("/");
      setShowLoading(false);
      menuClickHandler();
    }, 2000);
  };
  const [showCallText, setShowCallText] = useState(false);
  const [isCallTextBack, setIsCallTextBack] = useState(true);
  const [islowestRatesShow, setIslowestRatesShow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsCallTextBack(false);
      setIslowestRatesShow(false);
    }, 20000);
    return () => { setShowCallText(false); }
  }, []);
  const startTime = '09:00:00';
  const endTime = '18:00:00';
  const currentDate = new Date()
  const startDate = new Date(currentDate.getTime());
  startDate.setHours(startTime.split(":")[0]);
  startDate.setMinutes(startTime.split(":")[1]);
  startDate.setSeconds(startTime.split(":")[2]);
  const endDate = new Date(currentDate.getTime());
  endDate.setHours(endTime.split(":")[0]);
  endDate.setMinutes(endTime.split(":")[1]);
  endDate.setSeconds(endTime.split(":")[2]);
  const validTime = startDate < currentDate && endDate > currentDate;
  return (
    <>
      {" "}
      {location === "/signin" ? null : (
        <div
          className={classNames(
            styles.header,
            location === "/"
              ? styles.homeHeader
              : location === "/bridgingloans"
                ? styles.bLoans
                : location === "/developmentfinance"
                  ? styles.dFinance
                  : location === "/stoprepossessions"
                    ? styles.sRepo
                    : location === "/dashboard"
                      ? styles.sRepo
                      : location === "/rebridgingloans"
                        ? styles.reBridging
                        : ""
          )}
        >
          {showMenu ? (
            <ImCross onClick={menuClickHandler} className={styles.crossMenu} />
          ) : (
            <GiHamburgerMenu
              onClick={menuClickHandler}
              className={styles.mobileMenu}
            />
          )}
          <p onClick={scrollToTop} className={styles.scrollToTop}><AiOutlineArrowUp className={styles.icon} /></p>
          <div className={styles.callButton} >
            {showCallText &&
              <p className={styles.top}>

                {
                  validTime ? <>   <span>Questions?</span>
                    <span>Speak to one of our experts</span>
                    <span>Get a free & immediate callback, enter your number below and we will call you in 60 seconds.</span>
                    <input type="number" placeholder="Enter your contact number" id="contactNumberSubmitedForCallBack"/>
                    <button onClick={callMeNowMailSendHandler} >Call me now</button>
                    <button onClick={callMeLaterMailSendHandler} >Call me later</button>


                  </> : <>
                    <span>Questions?</span>
                    <span>Speak to one of our experts</span>
                    <span>Apologies, we are currently out of hours. Can we call you back? Enter your number below and let us know when is best for you on the next screen.</span>
                    <input type="number" placeholder="Enter your contact number" id="contactNumberSubmitedForCallBack"/>
                    <button onClick={callMeNowMailSendHandler}>Scedule callback</button>


                  </>
                }

              </p>}
            <div className={styles.bottom} >
              {!showCallText && isCallTextBack && <p className={styles.callBackText}> Would you like a <br /> free  callback in <br /> 10 seconds ?  </p>}
              <p onClick={() => { setShowCallText(!showCallText); setIsCallTextBack(false); }}>{
                showCallText ? <ImCross className={styles.cross} /> : <BiPhoneCall className={styles.call} />
              } </p>


            </div>
          </div>
          {islowestRatesShow && <div
            className={styles.lowestRates}
          >
            <p>Lowest  rates  ever at <strong>0.35% </strong>  per month ! </p>
            <img src={threeDots} alt="" />

          </div>}
          <LazyLoad height={65} once>
            <img
              src={
                location === "/casestudies"
                  ? logoPurple
                  : location === "/casestudies/guide"
                    ? logoPurple
                    : location === "/casestudies1"
                      ? logoPurple
                      : location === "/casestudies2"
                        ? logoPurple
                        : location === "/casestudies3"
                          ? logoPurple
                          : location === "/casestudies4"
                            ? logoPurple
                            : location === "/rebridgingloans"
                              ? logoPurple
                              : location === "/rebridgingloans/guide"
                                ? logoPurple
                                : location === "/stoprepossessions"
                                  ? logoOrgane
                                  : location === "/stoprepossessions/guide"
                                    ? logoOrgane
                                    : location === "/stoprepossessionsblogs/blog1"
                                      ? logoOrgane
                                      : location === "/stoprepossessionsblogs/blog2"
                                        ? logoOrgane
                                        : location === "/developmentfinance"
                                          ? logoBlue
                                          : location === "/developmentfinance/guide"
                                            ? logoBlue
                                            : location === "/developmentfinanceblogs/blog1"
                                              ? logoBlue
                                              : location === "/developmentfinanceblogs/blog2"
                                                ? logoBlue
                                                : location === "/developmentfinanceblogs/blog3"
                                                  ? logoBlue
                                                  : location === "/developmentfinanceblogs/blog4"
                                                    ? logoBlue
                                                    : location === "/developmentfinanceblogs/blog5"
                                                      ? logoBlue
                                                      : location === "/developmentfinanceblogs/blog6"
                                                        ? logoBlue
                                                        : location === "/developmentfinanceblogs/blog7"
                                                          ? logoBlue
                                                          : logoGreen
              }
              className={styles.logo}
              onClick={logoClickHandler}
              alt="logo"
            />
          </LazyLoad>

          {showMenu && (
            <>
              <div className={styles.center}>
                <NavLink
                  to="/"
                  className={styles.homeMenu}
                  onClick={menuClickHandler}
                >
                  Home
                </NavLink>
                <NavLink
                  to="/bridgingloans"
                  className={styles.bLoans}
                  onClick={menuClickHandler}
                >
                  Bridging Loans
                </NavLink>
                <NavLink
                  to="/developmentfinance"
                  className={styles.dFinance}
                  onClick={menuClickHandler}
                >
                  Development Finance
                </NavLink>
                <NavLink
                  to="/stoprepossessions"
                  className={styles.sRepo}
                  onClick={menuClickHandler}
                >
                  Stop Repossessions
                </NavLink>
                <NavLink
                  to="/rebridgingloans"
                  className={styles.caseStudies}
                  onClick={menuClickHandler}
                >
                  Re-Bridging
                </NavLink>

                <NavLink
                  to="/faq"
                  className={styles.guideMenu}
                  onClick={menuClickHandler}
                >
                  FAQs
                </NavLink>
                <NavLink
                  to="/bridgingloans/guide"
                  className={styles.guideMenu}
                  onClick={menuClickHandler}
                >
                  Guide
                </NavLink>

                <NavLink
                  to="/about"
                  className={styles.guideMenu}
                  onClick={menuClickHandler}
                >
                  About
                </NavLink>
                <NavLink
                  to="/contact"
                  className={styles.guideMenu}
                  onClick={menuClickHandler}
                >
                  Contact
                </NavLink>


                {showItems && (
                  <div className={styles.linkItems}>
                    <div className={styles.leftItems}>
                      <p className={styles.leftItemsHead}>Bridging Loans Guide</p>
                      <Link
                        to="/bridgingloansblogs/blog1"
                        onClick={ItemHandler}
                      >
                        Large Bridging Loans
                      </Link>
                      <Link
                        to="/bridgingloansblogs/blog2"
                        onClick={ItemHandler}
                      >
                        Bridging Loans bad credit
                      </Link>
                      <Link
                        to="/bridgingloansblogs/blog3"
                        onClick={ItemHandler}
                      >
                        Bridging Loans Nationwide
                      </Link>
                      <Link
                        to="/bridgingloansblogs/blog4"
                        onClick={ItemHandler}
                      >
                        Bridging Loans HSBC
                      </Link>
                      <Link
                        to="/bridgingloansblogs/blog5"
                        onClick={ItemHandler}
                      >
                        Barclays Bridging Loans
                      </Link>
                      <Link
                        to="/bridgingloansblogs/blog6"
                        onClick={ItemHandler}
                      >
                        Bridging Loan example
                      </Link>
                      <Link
                        to="/bridgingloansblogs/blog7"
                        onClick={ItemHandler}
                      >
                        Bridging Loans for bad credit
                      </Link>
                      <Link
                        to="/bridgingloansblogs/blog8"
                        onClick={ItemHandler}
                      >
                        Commercial Bridging Loans
                      </Link>
                      <Link
                        to="/bridgingloansblogs/blog9"
                        onClick={ItemHandler}
                      >
                        Fast Bridging Loans
                      </Link>
                      <Link
                        to="/bridgingloansblogs/blog10"
                        onClick={ItemHandler}
                      >
                        Inherited Property Bridging Loans
                      </Link>
                      <Link
                        to="/bridgingloansblogs/blog11"
                        onClick={ItemHandler}
                      >
                        Difficult Bridging Loans
                      </Link>
                    </div>
                    <div className={styles.centerItems}>
                      <div className={styles.centerTop}>
                        <p className={styles.centerTopHead}>
                          Development Loans Guide
                        </p>
                        <Link
                          to="/developmentfinanceblogs/blog1"
                          onClick={ItemHandler}
                        >
                          100% Development Funding
                        </Link>
                        <Link
                          to="/developmentfinanceblogs/blog2"
                          onClick={ItemHandler}
                        >
                          Large Development Finance
                        </Link>
                        <Link
                          to="/developmentfinanceblogs/blog3"
                          onClick={ItemHandler}
                        >
                          Development Finance with bad credit
                        </Link>
                        <Link
                          to="/developmentfinanceblogs/blog4"
                          onClick={ItemHandler}
                        >
                          Development Finance application form
                        </Link>
                        <Link
                          to="/developmentfinanceblogs/blog5"
                          onClick={ItemHandler}
                        >
                          Development Loan First Time Developer
                        </Link>
                        <Link
                          to="/developmentfinanceblogs/blog6"
                          onClick={ItemHandler}
                        >
                          Splitting Deeds
                        </Link>
                        <Link
                          to="/developmentfinanceblogs/blog7"
                          onClick={ItemHandler}
                        >
                          Converting your home into flats
                        </Link>
                      </div>
                      <div className={styles.centerBottom}>
                        <p className={styles.rightTopHead}>Case Studies Guide</p>
                        <Link to="/casestudies1" onClick={ItemHandler}>
                          Bridging Loans for Bankruptcy
                        </Link>
                        <Link to="/casestudies2" onClick={ItemHandler}>
                          Bridging Loan for over 60's
                        </Link>
                        <Link to="/casestudies3" onClick={ItemHandler}>
                          Bridging Loan for splitting title deeds
                        </Link>
                        <Link to="/casestudies4" onClick={ItemHandler}>
                          Bridging loan for paying off sibling in an inherited
                          Property
                        </Link>
                      </div>
                    </div>
                    <div className={styles.rightItems}>
                      <div className={styles.rightTop}>
                        <p className={styles.centerBottomHead}>
                          Stop Repossession Guide
                        </p>
                        <Link
                          to="/stoprepossessionsblogs/blog1"
                          onClick={ItemHandler}
                        >
                          Apply now to stop a repossession
                        </Link>
                        <Link
                          to="/stoprepossessionsblogs/blog2"
                          onClick={ItemHandler}
                        >
                          How to stop your mortgage lender from repossessing
                          your home
                        </Link>
                      </div>
                      <div className={styles.rightMiddle}>
                        <p className={styles.rightMiddleHead}>Apply Now</p>
                        <Link to="/form" onClick={ItemHandler}>
                          Individual application
                        </Link>
                        <Link to="/form" onClick={ItemHandler}>
                          Company application{" "}
                        </Link>
                      </div>
                      <div className={styles.rightBottom}>
                        <p className={styles.rightBottomHead}>Resources</p>
                        <Link to="/form" onClick={ItemHandler}>
                          Download Application Form
                        </Link>
                        <Link to="/calculator/developmentfinance" onClick={ItemHandler}>
                          Development Viability Calcuator
                        </Link>
                      </div>
                      <div className={styles.rightEnd}>
                        <p className={styles.rightEndHead}>Others</p>
                        <Link to="/faq" onClick={ItemHandler}>
                          FAQs
                        </Link>
                        <Link to="/about" onClick={ItemHandler}>
                          About Us
                        </Link>
                        <Link to="/contact" onClick={ItemHandler}>
                          Contact
                        </Link>
                        <Link to="/guide" onClick={ItemHandler}>
                          Guide
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.right}>
                {loggedIn ? (
                  <Link to="#" className={styles.userProfile}>
                    {" "}
                    <div className={styles.top}>
                      {" "}
                      <img src={localStorage.getItem("profileurl")} alt="" />{" "}
                      <p className={styles.userName}>
                        {" "}
                        <span>Welcome</span> <span>{localStorage.getItem("userNameDisplay")} !</span>{" "}
                      </p>{" "}
                    </div>
                    <div className={styles.bottom}>
                      <Link to="/user" onClick={menuClickHandler}>
                        Profile
                      </Link>
                      {role === "admin" && (
                        <Link to="/dashboard" onClick={menuClickHandler}>
                          Dashboard
                        </Link>
                      )}

                      <Link to="#" onClick={logoutHandler}>
                        Log out
                      </Link>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to="/signin"
                    className={styles.signin}
                    onClick={menuClickHandler}
                  >
                    Sign in
                  </Link>
                )}
                <Link to="#" className={styles.menu} onClick={ItemHandler}>
                  {showItems ? (
                    <ImCross className={styles.hamMenu} />
                  ) : (
                    <GiHamburgerMenu className={styles.hamMenu} style={{ fontSize: "1.5rem" }} />
                  )}
                </Link>
              </div>
            </>
          )}
          {showLoading && (
            <img src={loading} className={styles.loadingImg} alt="" />
          )}
        </div>
      )}
    </>
  );
};

export default Header;
