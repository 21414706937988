import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerB8 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Bridging Loans for Bad Credit{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li>75% LTV for commercial bridging loans</li>
          <li>Bridging loans available for retail and business purposes</li>
          <li>
            Quick Bridging loans for Buy-to-Let Landlords of commercial property
          </li>
          <li>Key funding for commercial construction developments</li>
          <li>Loans up to 36-months & Rates starting from 0.75% per month</li>
          <li>Up to 100% funding available with additional security</li>
          <li>Commercial lending for owner occupiers </li>
          <li>
            Move your commercial properties into an LTD i.e. a SPV (Special
            Payment Vehicle) company
          </li>
          <li>
            Applications from Individuals, UK Limited Companies, LLP’s,
            Partnerships, Trusts, Pension schemes, Ex-pats and foreign nationals
          </li>
        </ul>
        <div className={styles.text}>
          <p>
            Commercial bridging loans are similar to residential bridging
            finance, the difference being that they are required to help fill
            the financial gap for a commercial venture. In the business world
            things can happen very quickly, and if you are not in a position to
            act quickly and decisively, the matter may be taken out of your
            hands and the opportunity lost. With the option to complete quickly
            on a commercial bridging loan, you can have the funds you need in
            your account within 48 hours.
          </p>
          <p>
            That’s where a bridge in commercial finance can come in very handy,
            providing business owners with the chance to grow, purchase new
            property, renovate and expand, whilst other funding sources come to
            fruition. We can arrange a commercial bridging loan for up to 3
            years if needed.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerB8;
