import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import * as myConstClass from '../../components/FileWithConstants';
import styles from "./SubmittedForms.module.scss"

import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { GrFormView } from 'react-icons/gr';
import { AiOutlineDownload, AiFillEdit } from 'react-icons/ai';

const SubmittedForms = () => {
  useEffect(() => {
        getData();
        document.title = "Bridging 365 | Forms";
        window.scrollTo(0, 0);
  }, []);



  const navigate = useNavigate();
   
const viewFormHandler = (formid, formToDownload) => {
    navigate(`/formdetails/${formid}`, {
      state: formToDownload,
    });
  };

const editHandler = (formId, formToDownload) => {
        // navigate("/formdetails");
        //navigate(`/editform/${formId}`);
        navigate(`/editform/${formId}`, {
          state: formToDownload,
        });
}

const refreshHandler = () => {
        getFormsHandler()
}

const getFormsHandler = () => {
        //here api call to get all submitted forms and setFormdata function to be called
}

const downLoadTableHandler = (formId, formToDownload) => {
        
        const doc = new jsPDF();
        autoTable(doc, {
            head: [["Question", "Answer"]],
            body: [
                ["Name", formToDownload[0].userName],
                ["Email", formToDownload[0].userEmail],
                ["Contact", formToDownload[0].userContact],
                ["Email", formToDownload[0].userEmail],
                [
                    "Form submitted on",
                    `${formToDownload[0].submittedOnDate} | ${formToDownload[0].submittedOnTime}`,
                ],
                [
                    "Will you live in the property or is it an investment?",
                    formToDownload[0].propertyUse,
                ],
                ["How quickly do you need the money?", formToDownload[0].whenMoneyNeeded],
                ["What is the purpose of the bridging loan?", formToDownload[0].purpose],
                [
                    "Is your current loan from a bridging lender?",
                    formToDownload[0].currentLoanFromBridgingLender
                        ? formToDownload[0].currentLoanFromBridgingLender
                        : "NA",
                ],
                [
                    "When do you need the loan to be completed by?",
                    formToDownload[0].whenLoanCompleted ? formToDownload[0].whenLoanCompleted : "NA",
                ],
                ["How much would you like to borrow?", formToDownload[0].howMuchToBorrow],
                [
                    "Number of months you would like the loan for",
                    formToDownload[0].numOfMonthsLoanFor,
                ],
                [
                    "Would you like to make monthly payments or simply pay the full balance due on exiting the loan? ",
                    formToDownload[0].monthlyOrTermPay,
                ],
                ["How will you be paying the loan back?", formToDownload[0].howPaybackLoan],
                ["Address Line 1", formToDownload[0].addressLine1],
                ["Address Line 2", formToDownload[0].addressLine2],
                ["Which city or town is the property located in ?", formToDownload[0].city],
                ["What is the postcode ?", formToDownload[0].postcode],
                [
                    "What is the estimated property valuation ?",
                    formToDownload[0].propertyValuation,
                ],
                [
                    "Best description of the property below",
                    formToDownload[0].propertyDescription,
                ],
                [
                    "How many bedrooms does the property have ?",
                    formToDownload[0].numberOfBedrooms,
                ],
                ["How many floors are in the building ?", formToDownload[0].numberOfFloors],
                ["Is it an ex-council property ?", formToDownload[0].isExCouncilProperty],
                [
                    "Is property of standard construction? Please select below and tell us more.",
                    formToDownload[0].isStandardConstruction,
                ],
                [
                    "Additional information about standard construction",
                    formToDownload[0].constructionDescription,
                ],
                ["Do you know the tenure of the property ?", formToDownload[0].tenureOfProperty],
                ["Please describe the asset", formToDownload[0].assetDescription],
                [
                    "Would you like to add another property ?",
                    formToDownload[0].addAnotherProperty,
                ],
                [
                    "What is the name of the company you are borrowing in ?",
                    formToDownload[0].nameOfCompayForBorrowingIn,
                ],
                [
                    "What is the company registration number ?",
                    formToDownload[0].companyRegistrationNumber,
                ],
                ["What is the registered company address ?", formToDownload[0].companyAddress1],
                ["Address Line 2", formToDownload[0].companyAddress2],
                ["City or Town", formToDownload[0].companyCity],
                ["Postcode ?", formToDownload[0].companyPostCode],
                ["What is Your First Name ?", formToDownload[0].directorFirstName],
                ["What is Your Last Name ?", formToDownload[0].directorLastName],
                ["What is Your Title", formToDownload[0].directorTitle],
                ["When Were You Born ?", formToDownload[0].directorDateOfBirth],
                ["Address Line 1", formToDownload[0].directorAddress1],
                ["Address Line 2", formToDownload[0].directorAddress2],
                ["Town or City", formToDownload[0].directorTown],
                ["Postal code ?", formToDownload[0].directorPostCode],
                ["What is the Best Number To Contact You ?", formToDownload[0].directorNumber],
                [
                    "Have you had any CCJ's or the Defaults in last 6 years ?",
                    formToDownload[0].defaultInSixYears,
                ],
                [
                    "Have you ever been declared bankrupt ?",
                    formToDownload[0].everDeclaredBankrupt,
                ],
                [
                    "Please tell us anything else that is relevant to the application?  ",
                    formToDownload[0].otherMsg,
                ],
            ],
        });
        doc.save(`form_details-${formId}.pdf`);
    };

const getData = () => {
        const login_emailid_m1 = localStorage.getItem("login_emailid");
        const loginurl = myConstClass.BASE_URL+""+myConstClass.LOGIN;
        let formData = new FormData();
        formData.append("login_emailid_send", login_emailid_m1);
        const submitedformsurl = myConstClass.BASE_URL+""+myConstClass.SUBMITEDFORMS;

        axios.post(submitedformsurl, formData).then(res => {
            var data = res.data.data;
            setData(data);
        })
      }





const [data, setData] = useState({});



  return (
    <div className={styles.submittedForms}>
            <p className={styles.header}>Forms submitted till date</p>
            {data.length > 0 ? data.map(d => 
            (
                 <div className={styles.formItem}>
                    <div className={styles.left}>
                        <p>Form {d.loanform_id}</p>
                        <p>Purpose : {d.purpose} </p>
                        <p>Submitted on :{d.form_fill_date} </p>
                    </div>
                    
                    
                      <div className={styles.right}>
                          

                          <span title="view" onClick={() => viewFormHandler(d.loanform_id, [d])}>
                              <GrFormView title="View" className={styles.icon} />
                        </span>



                        <span><AiFillEdit title="Edit" onClick={() => editHandler(d.loanform_id, [d])} className={styles.icon} /></span>

                        <span><AiOutlineDownload title="Download" onClick={() => downLoadTableHandler(d.loanform_id, [d])} className={styles.icon} /> </span>

                        {/* <span><AiFillDelete title="Delete" onClick={deleteHandler} className={styles.icon} /> </span> */}
                        <span className={styles.deleteModal} title="delete">
                            <DeleteModal
                                id={d.loanform_id}
                                onClick={refreshHandler}
                            // name={props.cName}
                            />
                        </span>
                      </div>
                      
                    

                </div>
            )) : <p className={styles.header}>No Records found</p>}
        </div>
  );
};

export default SubmittedForms;
