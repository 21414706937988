import React, { useEffect, useRef, useState } from "react";
import styles from "./Documents.module.scss"
import UploadIcon from "../../assets/images/Upload_icon.png"
import { AiFillDelete } from 'react-icons/ai';
import _, { size } from 'lodash';
import API from "../../Services";
import axios from 'axios';
import * as myConstClass from '../../components/FileWithConstants';
import $ from 'jquery';
import {useDropzone} from 'react-dropzone';
import { useNavigate } from "react-router-dom";


const Documents = () => {
    const personalDocumentsUrl = myConstClass.BASE_URL+""+myConstClass.DOCUMENTSPERSONALFILE;

    const propertyDocumentsUrl = myConstClass.BASE_URL+""+myConstClass.DOCUMENTSPROPERTYFILES;

    const companyDocumentsUrl = myConstClass.BASE_URL+""+myConstClass.DOCUMENTSCOMPANYFILES;

    const otherDocumentsUrl = myConstClass.BASE_URL+""+myConstClass.DOCUMENTSOTHERFILES;

    useEffect(() => {
        document.title = "Bridging 365 | User Documents";
        window.scrollTo(0, 0);
    }, []);

    const login_emailid = localStorage.getItem("login_emailid");



    const [personalFiles, setPersonalFiles] = useState([])
    const [propertyFiles, setPropertyFiles] = useState([])
    const [companyFiles, setCompanyFiles] = useState([])
    const [otherFiles, setOtherFiles] = useState([])
    const [files1, setFiles1] = useState([])
    const [files2, setFiles2] = useState([])
    const [files3, setFiles3] = useState([])
    const [files4, setFiles4] = useState([])
    console.log(files1, files2, files3, files4)
    console.log("personalFiles", personalFiles);
    console.log("page rendered")
    const inputFile1 = useRef(null)
    const browseHandler1 = (event) => {
        inputFile1.current.click();
    }
    const fileChangeHandler1 = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        setPersonalFiles([...personalFiles, file.name])
        let { files } = e.target;
        const formData = new FormData();
        _.forEach(files, file => {
            formData.append('personalFiles', file);
        });
        let { data } = await API.post(personalDocumentsUrl, formData);
        
         setFiles1([...files1, data])
    }
    const deleteHandler1 = (item) => {
        setPersonalFiles(personalFiles.filter((x) => x !== item))
    }
    const inputFile2 = useRef(null)
    const browseHandler2 = (event) => {
        inputFile2.current.click();
    }
    const fileChangeHandler2 = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        setPropertyFiles([...propertyFiles, file.name])
        let { files } = e.target;
        const formData = new FormData();
        _.forEach(files, file => {
            formData.append('propertFiles', file);
        });


        let { data } = await API.post(propertyDocumentsUrl, formData);
       
        setFiles2([...files2, data])
    }
    const deleteHandler2 = (item) => {
        setPropertyFiles(propertyFiles.filter((x) => x !== item))
    }
    const inputFile3 = useRef(null)
    const browseHandler3 = (event) => {
        inputFile3.current.click();
    }
    const fileChangeHandler3 = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        setCompanyFiles([...companyFiles, file.name])
        let { files } = e.target;
        const formData = new FormData();
        _.forEach(files, file => {
            formData.append('companyFiles', file);
        });
        let { data } = await API.post(companyDocumentsUrl, formData);
        
        setFiles3([...files3, data])
    }
    const deleteHandler3 = (item) => {
        setCompanyFiles(companyFiles.filter((x) => x !== item))
    }
    const inputFile4 = useRef(null)
    const browseHandler4 = (event) => {
        inputFile4.current.click();
    }
    const fileChangeHandler4 = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        setOtherFiles([...otherFiles, file.name])
        let { files } = e.target;
        const formData = new FormData();
        _.forEach(files, file => {
            formData.append('otherFiles', file);
        });
        let { data } = await API.post(otherDocumentsUrl, formData);
        setFiles4([...files4, data])
    }
    const deleteHandler4 = (item) => {
        setOtherFiles(otherFiles.filter((x) => x !== item))
    }

    const [showLoading, setShowLoading] = useState(false);

    const uploadHandler = () => {
        
        setShowLoading(true);

        let formDataUploadDocuments = new FormData();
        formDataUploadDocuments.append("login_emailid", login_emailid);

        var i = 0;
        for(i = 0; i < files1.length; i++)
        {
            formDataUploadDocuments.append("personalFiles[]", files1[i]);
        }


        for(i = 0; i < files2.length; i++)
        {
            formDataUploadDocuments.append("propertyFiles[]", files2[i]);
        }

        for(i = 0; i < files3.length; i++)
        {
            formDataUploadDocuments.append("companyFiles[]", files3[i]);
        }
        
        for(i = 0; i < files4.length; i++)
        {
            formDataUploadDocuments.append("otherFiles[]", files4[i]);
        }
        
        

        var uploaddocumentsurl = myConstClass.BASE_URL+""+myConstClass.UPLOADDOCUMENTS;

        axios.post(uploaddocumentsurl, formDataUploadDocuments)
          .then(res => {
                   console.log(res);
                console.log("redirect to success page...");
                alert("document uploaded successfully");
                
                //setShowLoading(false);
          });

       



    }

    return (
        <div className={styles.documents}>
            <div className={styles.header}>Upload Documents</div>
            <div className={styles.content}>
                <div className={styles.section}>
                    <p className={styles.sectionHead}style={{ backgroundColor: '#ff7232'}} >Personal </p>
                    <div className={styles.sectionContent}>
                        <img src={UploadIcon} alt="" srcset="" />
                        <input type='file' id='file' onChange={(e) => fileChangeHandler1(e)} ref={inputFile1} style={{ display: 'none' }} />
                        <p>Drag & drop files or <span className={styles.browse} onClick={browseHandler1}>Browse</span>  </p>
                        <p className={styles.supportText}>Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</p>
                    </div>
                    <div className={styles.uploadedFiles}>
                        {/* <input type="file" name="" value={personalFiles[0]} id="personalFiles"  className={styles.uploadedItem} /> */}
                        {personalFiles.length > 0 ? personalFiles.map((item) => {
                            return <div className={styles.uploadedItem} key={item}>
                                <p >{item} </p>
                                <span><AiFillDelete onClick={() => deleteHandler1(item)} className={styles.deleteIcon} /></span>
                            </div>
                        }) : ""}
                    </div>
                </div>


                <div className={styles.section}>
                    <p className={styles.sectionHead} style={{ backgroundColor: '#673ab7'}}> About Property </p>
                    <div className={styles.sectionContent}>
                        <img src={UploadIcon} alt="" srcset="" />
                        <input type='file' id='file' onChange={(e) => fileChangeHandler2(e)} ref={inputFile2} style={{ display: 'none' }} />
                        <p>Drag & drop files or <span className={styles.browse} onClick={browseHandler2}>Browse</span>  </p>
                        <p className={styles.supportText}>Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</p>
                    </div>
                    <div className={styles.uploadedFiles}>
                        {/* <input type="file" name="" value={personalFiles[0]} id="propertyFiles"  className={styles.uploadedItem} /> */}
                        {propertyFiles.length > 0 ? propertyFiles.map((item) => {
                            return <div className={styles.uploadedItem} key={item}>
                                <p >{item} </p>
                                <span><AiFillDelete onClick={() => deleteHandler2(item)} className={styles.deleteIcon} /></span>
                            </div>
                        }) : ""}
                    </div>
                </div>


                <div className={styles.section}>
                    <p className={styles.sectionHead} style={{ backgroundColor: '#4caf50'}}>Company Documents  </p>
                    <div className={styles.sectionContent}>
                        <img src={UploadIcon} alt="" srcset="" />
                        <input type='file' id='file' onChange={(e) => fileChangeHandler3(e)} ref={inputFile3} style={{ display: 'none' }} />
                        <p>Drag & drop files or <span className={styles.browse} onClick={browseHandler3}>Browse</span>  </p>
                        <p className={styles.supportText}>Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</p>
                    </div>
                    <div className={styles.uploadedFiles}>
                        {/* <input type="file" name="" value={companyFiles[0]} id="companyFiles"  className={styles.uploadedItem} /> */}
                        {companyFiles.length > 0 ? companyFiles.map((item) => {
                            return <div className={styles.uploadedItem} key={item}>
                                <p >{item} </p>
                                <span><AiFillDelete onClick={() => deleteHandler3(item)} className={styles.deleteIcon} /></span>
                            </div>
                        }) : ""}
                    </div>
                </div>

                <div className={styles.section}>
                    <p className={styles.sectionHead}style={{ backgroundColor: '#002d72'}}>Other Documents </p>
                    <div className={styles.sectionContent}>
                        <img src={UploadIcon} alt="" srcset="" />
                        <input type='file' id='file' onChange={(e) => fileChangeHandler4(e)} ref={inputFile4} style={{ display: 'none' }} />
                        <p>Drag & drop files or <span className={styles.browse} onClick={browseHandler4}>Browse</span>  </p>                   <p className={styles.supportText}>Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</p>
                    </div>
                    <div className={styles.uploadedFiles}>
                        {/* <input type="file" name="" value={otherFiles[0]} id="otherFiles"  className={styles.uploadedItem} /> */}
                        {otherFiles.length > 0 ? otherFiles.map((item) => {
                            return <div className={styles.uploadedItem} key={item}>
                                <p >{item} </p>
                                <span><AiFillDelete onClick={() => deleteHandler4(item)} className={styles.deleteIcon} /></span>
                            </div>
                        }) : ""}
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <button className={styles.btn} onClick={uploadHandler}>Upload Files</button>
            </div>
        </div>
    );
};

export default Documents;
