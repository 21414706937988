import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesS2 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        How to Delay a Court Ordered Repossession Notice
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          There are a few reasons you can give to delay a court order and ensure
          that your home is not repossessed. Let’s take a look through the
          options available.
        </p>
        <p>
          <strong>You Have Made a Claim for Assistance</strong>
          Making a claim through a mortgage payment protection policy or for
          universal credit to assist with mortgage costs will halt a lender from
          taking the issue to court for the time being. In some cases a local
          council can assist through mortgage rescue schemes or you might be
          able to put forward a case that your wage is due to increase. Other
          options include taking in a lodger that will help to cover the
          mortgage repayments on an on-going basis.
        </p>
        <p>
          <strong> You are Attempting to Sell the Property</strong>A court order
          to repossess your home can be stopped if you are showing willing to
          sell your property at a reasonable price and within a set period of
          time. Any offers that you receive must be forwarded to the lender to
          prove it is an on-going process, as well as proof that you have an
          up-to-date Energy Performance Certificate for the property.
        </p>
        <p>
          <strong>Reach Agreement for Structured Repayments </strong>
          In some cases it might be possible to come to a new agreement with
          your mortgage lender in order to restructure your payments, covering
          both the arrears and future mortgage repayments.
        </p>
        <p>
          All of these options are only available if you have the necessary
          financial incomings to be able to repay the arrears in the future. A
          bridging loan is one way to make sure you have the chance to do so.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesS2;
