import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerB1 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
Large Bridging Loans  </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>

<li>Large bridging loans up to £50 million in value</li>
<li>Lowest rates from 0.45%</li>
<li>LTV up to 100% where additional security is available</li>
<li>Loans for residential, commercial and investment properties</li>
<li>Funds for large construction and development projects</li>
<li>3-Hour decision time on most applications – with funding within 48-hours in some cases</li>
<li>Large panel of funders  (169 in total)</li>
        </ul>
        <div className={styles.text}>

<p>When it comes to securing finances for large construction, property development and commercial ventures, it is important to appreciate exactly how quickly an answer is required. Bridging 365 understands how necessary it is to have a framework in place to secure high value, large bridging loans. Our team has access to a wide and varied panel of funders, and will source and arrange the most effective bridging loan for your circumstances.</p>
</div>
      </div>
    </div>
  );
};

export default CompareContainerB1;
