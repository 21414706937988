import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/LoanApplication.module.scss";
import phoneGreenImg from "../../assets/images/phone-green.png";
import phoneBlueImg from "../../assets/images/phone.png";
import phoneOrangeImg from "../../assets/images/phone-orange.png";
import classNames from "classnames";
const LoanApplication6 = ({ title }) => {
  return (
    <div className={styles.loanApplication}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
Bridging Loan Advice from Specialists in the Field      </p>
      <div
        className={classNames(
          styles.content,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <div className={styles.left}>
          <p>
          When it comes to assisting applicants with bridging finance for splitting deeds on a property, Bridging 365 has access to a large number of funders with experience in this niche area of the industry. Whether you are looking to pay off debt associated with an inherited property, divorcing or breaking up with a partner that you live with, or wish to secure the property in a commercial prospect where your business partner is leaving, we have the means to help you with short-term finance.

          </p>
          <p>
          Take a look at the simple bridging loan calculator page to see how we can help, and for more information you can speak to a member of our experienced team. We help provide the short-term funding that you need to help split the deeds on a property you own, no matter the circumstances you face. For difficult relationship breakups, to amicable splits for couple with a poor credit history, our connections allow us to provide the perfect funding match for you.

          </p>
          <p>
           <strong>Access to Unregulated Loan Funders</strong>
          </p>
          <p>
          Bridging 365 has built up a large panel of funders within the bridging finance industry. This means that even if you are looking to split deeds on an inherited property with a sibling (or other family member), we can pair you with a funder that can provide you with the funds you need. Currently we have connections with 13 funders that can provide bridging loans for inherited property purposes.
          </p>
       
        </div>
        <div
          className={classNames(
            styles.right,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : ""
          )}
        >
          <p className={styles.rightHeader}>Confused by the options?</p>
          <p className={styles.rightText}>
            Choosing the right Bridge for you can be really tricky. At Bridging 365
            our expert advisers guide you through the process. Simple and
            efficient.
          </p>
          <Link
            to="#"
            className={classNames(
              styles.link,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            Start Online
          </Link>
          <p className={styles.callText}>Call free from mobile or landline</p>
          <p
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            {" "}
            <img
              src={
                title === "Bridging Loans"
                  ? phoneGreenImg
                  : title === "Development Finance"
                  ? phoneBlueImg
                  : title === "Stop Repossessions"
                  ? phoneOrangeImg
                  : ""
              }
              alt=""
            />{" "}
            0208 146 9888
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanApplication6;
