import React, { useEffect } from "react";
import NewsContainer from "../../components/NewsContainer";
import TestimonialsContainer from "../../components/TestimonialsContainer";
import Footer from "../../components/Footer";
import CompareContainerS2 from "../../components/CompareContainer/CompareContainerS2";
import MatchingMortgagesS2 from "../../components/MatchingMortgages/MatchingMortgagesS2";
import LoanApplicationS2 from "../../components/LoanApplication/LoanApplicationS2";
import HeroSection from "../../components/HeroSection/HeroSection";
import CallAdviser from "../../components/CallAdviser";
const StopRepoBlogs2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeroSection title="Stop Repossessions" />
      <CompareContainerS2 title="Stop Repossessions" />
      <CallAdviser title="Stop Repossessions" />
      <MatchingMortgagesS2 title="Stop Repossessions" />
      <NewsContainer title="Stop Repossessions" />
      <LoanApplicationS2 title="Stop Repossessions" />
      <TestimonialsContainer title="Stop Repossessions" />
      <Footer title="Stop Repossessions" />
    </div>
  );
};

export default StopRepoBlogs2;
