import React from "react";
import styles from "../styles/TypeOfRequirements.module.scss";
import phoneBlueImg from "../assets/images/phone.png";
import phoneGreenImg from "../assets/images/phone-green.png";
import phoneOrangeImg from "../assets/images/phone-orange.png";
import { Link } from "react-router-dom";
import classNames from "classnames";
const TypeOfRequirements = ({ title }) => {
  return (
    <div
      className={classNames(
        styles.typeOfRequirements,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Types of Large Development that Require Bridging Loans
      </p>
      <div
        className={classNames(
          styles.content,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <div className={styles.left}>
          <p className={styles.leftHead}>Call our expert advisors now</p>
          <p className={styles.callText}>Call Us !</p>
          <p
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            {" "}
            <img
              src={
                title === "Bridging Loans"
                  ? phoneGreenImg
                  : title === "Development Finance"
                  ? phoneBlueImg
                  : title === "Stop Repossessions"
                  ? phoneOrangeImg
                  : ""
              }
              alt=""
            />{" "}
            0208 146 9888
          </p>
          <p>or</p>
          <Link
            to="#"
            className={classNames(
              styles.link,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            Continue Online
          </Link>
        </div>
        <div className={styles.right}>
          <p className={styles.rightHead}>
            {" "}
            We can assist with large development finance for:
          </p>
          <ul className={styles.list}>
            <li>New-Build Homes</li>
            <li>Hotel Complexes</li>
            <li>Large Residential Apartment Buildings</li>
            <li>Office and Commercial Space</li>
            <li>Large Factory Units</li>
            <li>Retail Projects</li>
            <li>Large-Scale Refurbishments</li>
            <li>Restoration of Classical Property</li>
            <li>Expansion of Existing Structures</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TypeOfRequirements;
