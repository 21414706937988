import styles from "./Question.module.scss";
import classNames from "classnames";

const Question = ({ question, section }) => {
  return (
    <label htmlFor="" className={classNames(styles.question, styles[section])}>
      <span className={styles.questionText}>{question}</span>
    </label>
  );
};

export default Question;
