import React from "react";
import styles from "../styles/BredCrum.module.scss";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { Link } from "react-router-dom";
import classNames from "classnames";

const BreadCrum = ({ location }) => {
  // console.log(location);
  let section =
    location === "/bridgingloans"
      ? "Bridging Loans"
      : location === "/bridgingloans/guide"
        ? "Bridging Loans"
        : location === "/bridgingloansblogs/blog1"
          ? "Bridging Loans"
          : location === "/bridgingloansblogs/blog2"
            ? "Bridging Loans"
            : location === "/bridgingloansblogs/blog3"
              ? "Bridging Loans"
              : location === "/bridgingloansblogs/blog4"
                ? "Bridging Loans"
                : location === "/bridgingloansblogs/blog5"
                  ? "Bridging Loans"
                  : location === "/bridgingloansblogs/blog6"
                    ? "Bridging Loans"
                    : location === "/bridgingloansblogs/blog7"
                      ? "Bridging Loans"
                      : location === "/bridgingloansblogs/blog8"
                        ? "Bridging Loans"
                        : location === "/bridgingloansblogs/blog9"
                          ? "Bridging Loans"
                          : location === "/bridgingloansblogs/blog10"
                            ? "Bridging Loans"
                            : location === "/bridgingloansblogs/blog11"
                              ? "Bridging Loans"
                              : location === "/developmentfinance"
                                ? "Development Finance"
                                : location === "/developmentfinance/guide"
                                  ? "Development Finance"
                                  : location === "/developmentfinanceblogs/blog1"
                                    ? "Development Finance"
                                    : location === "/developmentfinanceblogs/blog2"
                                      ? "Development Finance"
                                      : location === "/developmentfinanceblogs/blog3"
                                        ? "Development Finance"
                                        : location === "/developmentfinanceblogs/blog4"
                                          ? "Development Finance"
                                          : location === "/developmentfinanceblogs/blog5"
                                            ? "Development Finance"
                                            : location === "/developmentfinanceblogs/blog6"
                                              ? "Development Finance"
                                              : location === "/developmentfinanceblogs/blog7"
                                                ? "Development Finance"
                                                : location === "/stoprepossessions"
                                                  ? "Stop Repossessions"
                                                  : location === "/stoprepossessions/guide"
                                                    ? "Stop Repossessions"
                                                    : location === "/stoprepossessionsblogs/blog1"
                                                      ? "Stop Repossessions"
                                                      : location === "/stoprepossessionsblogs/blog2"
                                                        ? "Stop Repossessions"
                                                        : location === "/rebridgingloans"
                                                          ? "Re-Bridging"
                                                          : location === "/rebridgingloans/guide"
                                                            ? "Re-Bridging"
                                                            : location === "/casestudies"
                                                              ? "Case studies"
                                                              : location === "/casestudies/guide"
                                                                ? "Case studies"
                                                                : location === "/casestudies1"
                                                                  ? "Case studies"
                                                                  : location === "/casestudies2"
                                                                    ? "Case studies"
                                                                    : location === "/casestudies3"
                                                                      ? "Case studies"
                                                                      : location === "/casestudies4"
                                                                        ? "Case studies"
                                                                        : "";

  const sectionPath =
    location === "/bridgingloans/guide"
      ? "/bridgingloans"
      : location === "/developmentfinance/guide"
        ? "/developmentfinance"
        : location === "/stoprepossessions/guide"
          ? "/stoprepossessions"
          : location === "/casestudies/guide"
            ? "/casestudies"
            : location === "/rebridgingloans/guide"
              ? "/rebridgingloans"
              : "#";
  const guidePath =
    location === "/bridgingloans"
      ? "/bridgingloans/guide"
      : location === "/bridgingloansblogs/blog1"
        ? "/bridgingloans/guide"
        : location === "/bridgingloansblogs/blog2"
          ? "/bridgingloans/guide"
          : location === "/bridgingloansblogs/blog3"
            ? "/bridgingloans/guide"
            : location === "/bridgingloansblogs/blog4"
              ? "/bridgingloans/guide"
              : location === "/bridgingloansblogs/blog5"
                ? "/bridgingloans/guide"
                : location === "/bridgingloansblogs/blog6"
                  ? "/bridgingloans/guide"
                  : location === "/bridgingloansblogs/blog7"
                    ? "/bridgingloans/guide"
                    : location === "/bridgingloansblogs/blog8"
                      ? "/bridgingloans/guide"
                      : location === "/bridgingloansblogs/blog9"
                        ? "/bridgingloans/guide"
                        : location === "/bridgingloansblogs/blog10"
                          ? "/bridgingloans/guide"
                          : location === "/bridgingloansblogs/blog11"
                            ? "/bridgingloans/guide"
                            : location === "/developmentfinance"
                              ? "/developmentfinance/guide"
                              : location === "/developmentfinanceblogs/blog1"
                                ? "/developmentfinance/guide"
                                : location === "/developmentfinanceblogs/blog2"
                                  ? "/developmentfinance/guide"
                                  : location === "/developmentfinanceblogs/blog3"
                                    ? "/developmentfinance/guide"
                                    : location === "/developmentfinanceblogs/blog4"
                                      ? "/developmentfinance/guide"
                                      : location === "/developmentfinanceblogs/blog5"
                                        ? "/developmentfinance/guide"
                                        : location === "/developmentfinanceblogs/blog6"
                                          ? "/developmentfinance/guide"
                                          : location === "/developmentfinanceblogs/blog7"
                                            ? "/developmentfinance/guide"
                                            : location === "/stoprepossessionsblogs/blog1"
                                              ? "/stoprepossessions/guide"
                                              : location === "/stoprepossessionsblogs/blog2"
                                                ? "/stoprepossessions/guide"
                                                : location === "/stoprepossessions"
                                                  ? "/stoprepossessions/guide"
                                                  : location === "/casestudies"
                                                    ? "/casestudies/guide"
                                                    : location === "/rebridgingloans"
                                                      ? "/rebridgingloans/guide"
                                                      : location === "/casestudies1"
                                                        ? "/casestudies/guide"
                                                        : location === "/casestudies2"
                                                          ? "/casestudies/guide"
                                                          : location === "/casestudies3"
                                                            ? "/casestudies/guide"
                                                            : location === "/casestudies4"
                                                              ? "/casestudies/guide"
                                                              : "#";
  return (
    <>
      {location === "/" ||
        location === "/signin" ||
        location === "/form" ||
        location === "/pagenotfound" ||
        location === "/calculator" ||
        location === "/contact" ||
        location === "/about" ||
        location === "/signin/:user" ||
        location === "/signin/:admin" ||
        location === "/admin" ||
        location.includes("/user") ||
        location.includes("/editform") ||
        location.includes("guide") ||
        location.includes("uploadeddocumentslist") ||
        // location === "/guide/bridgingloans" ||
        // location === "/guide/developmentfinance" ||
        // location === "/guide/stoprepossessions" ||
        // location === "/guide/casestudies" ||
        location === "/dashboard" ||
        location === "/formsuccess" ||
        location.includes("formdetails") ||
        location.includes("calculator") ||
        location.includes("policy") ||
        location === "/faq" ? null : (
        <div
          className={classNames(
            styles.bredCrum,
            location === "/bridgingloans"
              ? styles.bLoans
              : location === "/bridgingloans/guide"
                ? styles.bLoans
                : location === "/bridgingloansblogs/blog1"
                  ? styles.bLoans
                  : location === "/bridgingloansblogs/blog2"
                    ? styles.bLoans
                    : location === "/bridgingloansblogs/blog3"
                      ? styles.bLoans
                      : location === "/bridgingloansblogs/blog4"
                        ? styles.bLoans
                        : location === "/bridgingloansblogs/blog5"
                          ? styles.bLoans
                          : location === "/bridgingloansblogs/blog6"
                            ? styles.bLoans
                            : location === "/bridgingloansblogs/blog7"
                              ? styles.bLoans
                              : location === "/bridgingloansblogs/blog8"
                                ? styles.bLoans
                                : location === "/bridgingloansblogs/blog9"
                                  ? styles.dFinan
                                  : location === "/bridgingloansblogs/blog10"
                                    ? styles.bLoans
                                    : location === "/bridgingloansblogs/blog11"
                                      ? styles.bLoans
                                      : location === "/developmentfinance"
                                        ? styles.dFinance
                                        : location === "/developmentfinance/guide"
                                          ? styles.dFinance
                                          : location === "/developmentfinanceblogs/blog1"
                                            ? styles.dFinance
                                            : location === "/developmentfinanceblogs/blog2"
                                              ? styles.dFinance
                                              : location === "/developmentfinanceblogs/blog3"
                                                ? styles.dFinance
                                                : location === "/developmentfinanceblogs/blog4"
                                                  ? styles.dFinance
                                                  : location === "/developmentfinanceblogs/blog5"
                                                    ? styles.dFinance
                                                    : location === "/developmentfinanceblogs/blog6"
                                                      ? styles.dFinance
                                                      : location === "/developmentfinanceblogs/blog7"
                                                        ? styles.dFinance
                                                        : location === "/stoprepossessions"
                                                          ? styles.sRepo
                                                          : location === "/stoprepossessions/guide"
                                                            ? styles.sRepo
                                                            : location === "/stoprepossessionsblogs/blog1"
                                                              ? styles.sRepo
                                                              : location === "/stoprepossessionsblogs/blog2"
                                                                ? styles.sRepo
                                                                : location === "/rebridgingloans"
                                                                  ? styles.reSources
                                                                  : location === "/casestudies"
                                                                    ? styles.reSources
                                                                    : location === "/casestudies/guide"
                                                                      ? styles.reSources
                                                                      : location === "/rebridgingloans/guide"
                                                                        ? styles.reSources
                                                                        : location === "/casestudies1"
                                                                          ? styles.reSources
                                                                          : location === "/casestudies2"
                                                                            ? styles.reSources
                                                                            : location === "/casestudies3"
                                                                              ? styles.reSources
                                                                              : location === "/casestudies4"
                                                                                ? styles.reSources
                                                                                : ""
          )}
        >
          <Link
            to={sectionPath}
            className={classNames(
              styles.section,
              location === "/bridgingloans"
                ? styles.bLoans
                : location === "/bridgingloans/guide"
                  ? styles.bLoans
                  : location === "/bridgingloansblogs/blog1"
                    ? styles.bLoans
                    : location === "/bridgingloansblogs/blog2"
                      ? styles.bLoans
                      : location === "/bridgingloansblogs/blog3"
                        ? styles.bLoans
                        : location === "/bridgingloansblogs/blog4"
                          ? styles.bLoans
                          : location === "/bridgingloansblogs/blog5"
                            ? styles.bLoans
                            : location === "/bridgingloansblogs/blog6"
                              ? styles.bLoans
                              : location === "/bridgingloansblogs/blog7"
                                ? styles.bLoans
                                : location === "/bridgingloansblogs/blog8"
                                  ? styles.bLoans
                                  : location === "/bridgingloansblogs/blog9"
                                    ? styles.dFinan
                                    : location === "/bridgingloansblogs/blog10"
                                      ? styles.bLoans
                                      : location === "/bridgingloansblogs/blog11"
                                        ? styles.bLoans
                                        : location === "/developmentfinance"
                                          ? styles.dFinance
                                          : location === "/developmentfinance/guide"
                                            ? styles.dFinance
                                            : location === "/developmentfinanceblogs/blog1"
                                              ? styles.dFinance
                                              : location === "/developmentfinanceblogs/blog2"
                                                ? styles.dFinance
                                                : location === "/developmentfinanceblogs/blog3"
                                                  ? styles.dFinance
                                                  : location === "/developmentfinanceblogs/blog4"
                                                    ? styles.dFinance
                                                    : location === "/developmentfinanceblogs/blog5"
                                                      ? styles.dFinance
                                                      : location === "/developmentfinanceblogs/blog6"
                                                        ? styles.dFinance
                                                        : location === "/developmentfinanceblogs/blog7"
                                                          ? styles.dFinance
                                                          : location === "/stoprepossessions"
                                                            ? styles.sRepo
                                                            : location === "/stoprepossessions/guide"
                                                              ? styles.sRepo
                                                              : location === "/stoprepossessionsblogs/blog1"
                                                                ? styles.sRepo
                                                                : location === "/stoprepossessionsblogs/blog2"
                                                                  ? styles.sRepo
                                                                  : location === "/rebridgingloans"
                                                                    ? styles.reSources
                                                                    : location === "/rebridgingloans/guide"
                                                                      ? styles.reSources
                                                                      : location === "/casestudies"
                                                                        ? styles.reSources
                                                                        : location === "/casestudies/guide"
                                                                          ? styles.reSources
                                                                          : location === "/casestudies1"
                                                                            ? styles.reSources
                                                                            : location === "/casestudies2"
                                                                              ? styles.reSources
                                                                              : location === "/casestudies3"
                                                                                ? styles.reSources
                                                                                : location === "/casestudies4"
                                                                                  ? styles.reSources
                                                                                  : ""
            )}
          >
            {section}
          </Link>
          <MdOutlineDoubleArrow
            className={classNames(
              styles.arrowIcon,
              location === "/bridgingloans"
                ? styles.bLoans
                : location === "/bridgingloans/guide"
                  ? styles.bLoans
                  : location === "/bridgingloansblogs/blog1"
                    ? styles.bLoans
                    : location === "/bridgingloansblogs/blog2"
                      ? styles.bLoans
                      : location === "/bridgingloansblogs/blog3"
                        ? styles.bLoans
                        : location === "/bridgingloansblogs/blog4"
                          ? styles.bLoans
                          : location === "/bridgingloansblogs/blog5"
                            ? styles.bLoans
                            : location === "/bridgingloansblogs/blog6"
                              ? styles.dFinance
                              : location === "/bridgingloansblogs/blog7"
                                ? styles.bLoans
                                : location === "/bridgingloansblogs/blog8"
                                  ? styles.bLoans
                                  : location === "/bridgingloansblogs/blog9"
                                    ? styles.bLoans
                                    : location === "/bridgingloansblogs/blog10"
                                      ? styles.bLoans
                                      : location === "/bridgingloansblogs/blog11"
                                        ? styles.bLoans
                                        : location === "/developmentfinance"
                                          ? styles.dFinance
                                          : location === "/developmentfinance/guide"
                                            ? styles.dFinance
                                            : location === "/developmentfinanceblogs/blog1"
                                              ? styles.dFinance
                                              : location === "/developmentfinanceblogs/blog2"
                                                ? styles.dFinance
                                                : location === "/developmentfinanceblogs/blog3"
                                                  ? styles.dFinance
                                                  : location === "/developmentfinanceblogs/blog4"
                                                    ? styles.dFinance
                                                    : location === "/developmentfinanceblogs/blog5"
                                                      ? styles.dFinance
                                                      : location === "/developmentfinanceblogs/blog6"
                                                        ? styles.dFinance
                                                        : location === "/developmentfinanceblogs/blog7"
                                                          ? styles.dFinance
                                                          : location === "/stoprepossessions"
                                                            ? styles.sRepo
                                                            : location === "/stoprepossessions/guide"
                                                              ? styles.sRepo
                                                              : location === "/stoprepossessionsblogs/blog1"
                                                                ? styles.sRepo
                                                                : location === "/stoprepossessionsblogs/blog2"
                                                                  ? styles.sRepo
                                                                  : location === "/rebridgingloans"
                                                                    ? styles.reSources
                                                                    : location === "/rebridgingloans/guide"
                                                                      ? styles.reSources
                                                                      : location === "/casestudies"
                                                                        ? styles.reSources
                                                                        : location === "/casestudies/guide"
                                                                          ? styles.reSources
                                                                          : location === "/casestudies1"
                                                                            ? styles.reSources
                                                                            : location === "/casestudies2"
                                                                              ? styles.reSources
                                                                              : location === "/casestudies3"
                                                                                ? styles.reSources
                                                                                : location === "/casestudies4"
                                                                                  ? styles.reSources
                                                                                  : ""
            )}
          />
          <Link
            to={guidePath}
            className={classNames(
              styles.subSection,
              location === "/bridgingloans"
                ? styles.bLoans
                : location === "/bridgingloans/guide"
                  ? styles.bLoans
                  : location === "/bridgingloansblogs/blog1"
                    ? styles.bLoans
                    : location === "/bridgingloansblogs/blog2"
                      ? styles.bLoans
                      : location === "/bridgingloansblogs/blog3"
                        ? styles.bLoans
                        : location === "/bridgingloansblogs/blog4"
                          ? styles.bLoans
                          : location === "/bridgingloansblogs/blog5"
                            ? styles.bLoans
                            : location === "/bridgingloansblogs/blog6"
                              ? styles.bLoans
                              : location === "/bridgingloansblogs/blog7"
                                ? styles.bLoans
                                : location === "/bridgingloansblogs/blog8"
                                  ? styles.bLoans
                                  : location === "/bridgingloansblogs/blog9"
                                    ? styles.dFinan
                                    : location === "/bridgingloansblogs/blog10"
                                      ? styles.bLoans
                                      : location === "/bridgingloansblogs/blog11"
                                        ? styles.bLoans
                                        : location === "/developmentfinance"
                                          ? styles.dFinance
                                          : location === "/developmentfinance/guide"
                                            ? styles.dFinance
                                            : location === "/developmentfinanceblogs/blog1"
                                              ? styles.dFinance
                                              : location === "/developmentfinanceblogs/blog2"
                                                ? styles.dFinance
                                                : location === "/developmentfinanceblogs/blog3"
                                                  ? styles.dFinance
                                                  : location === "/developmentfinanceblogs/blog4"
                                                    ? styles.dFinance
                                                    : location === "/developmentfinanceblogs/blog5"
                                                      ? styles.dFinance
                                                      : location === "/developmentfinanceblogs/blog6"
                                                        ? styles.dFinance
                                                        : location === "/developmentfinanceblogs/blog7"
                                                          ? styles.dFinance
                                                          : location === "/stoprepossessions"
                                                            ? styles.sRepo
                                                            : location === "/stoprepossessions/guide"
                                                              ? styles.sRepo
                                                              : location === "/stoprepossessionsblogs/blog1"
                                                                ? styles.sRepo
                                                                : location === "/stoprepossessionsblogs/blog2"
                                                                  ? styles.sRepo
                                                                  : location === "/rebridgingloans"
                                                                    ? styles.reSources
                                                                    : location === "/rebridgingloans/guide"
                                                                      ? styles.reSources
                                                                      : location === "/casestudies"
                                                                        ? styles.reSources
                                                                        : location === "/casestudies/guide"
                                                                          ? styles.reSources
                                                                          : location === "/casestudies1"
                                                                            ? styles.reSources
                                                                            : location === "/casestudies2"
                                                                              ? styles.reSources
                                                                              : location === "/casestudies3"
                                                                                ? styles.reSources
                                                                                : location === "/casestudies4"
                                                                                  ? styles.reSources
                                                                                  : ""
            )}
          >
            Guide
          </Link>
        </div>
      )}
    </>
  );
};

export default BreadCrum;
