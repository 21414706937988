import React from "react";
import styles from "../styles/SubHeroSection.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";

const SubHeroSection = () => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate("/form");
  };
  return (
    <div className={styles.subHeroSection}>
      <p className={styles.topText}>Call us to start saving today</p>
      <p className={styles.subHeadText}>
        We’ll focus on getting you the perfect loan to
        fit your needs
      </p>
      <p className={styles.number}>
        {" "}
        {/* <FiPhoneCall /> 0208 146 9888 */}
        <a href="tel:0208 146 9888">
          <FiPhoneCall className={styles.phoneIcon} />
          0208 146 9888
        </a>
      </p>
      <p className={styles.applyOnline} onClick={clickHandler}>
        Or Apply Online
      </p>
      <div className={styles.btns}>
        <Link to="/form" className={styles.loans}>
          Bridging Loans
        </Link>
        <Link to="/form" className={styles.finance}>
          Development Finance
        </Link>
      </div>
    </div>
  );
};

export default SubHeroSection;
