import React from "react";
import styles from "../../styles/BridgingHeroSection.module.scss";
import { GoTriangleDown } from "react-icons/go";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
const HeroSection = ({ title, mainTitle }) => {
  const location = useLocation().pathname;
  console.log(location);
  const heading =
    location === "/stoprepossessionsblogs/blog1"
      ? "Apply now to stop a repossession"
      : location === "/stoprepossessionsblogs/blog2"
      ? "How to stop your mortgage lender from repossessing your home"
      : location === "/casestudies1"
      ? "Bridging Loans for Bankruptcy"
      : location === "/casestudies2"
      ? "Bridging Loan for over 60's"
      : location === "/casestudies3"
      ? "Bridging Loan for splitting title deeds"
      : location === "/casestudies4"
      ? "Bridging loan for paying off sibling in an inherited Property"
      : location === "/developmentfinanceblogs/blog1"
      ? "100% Development Funding"
      : location === "/developmentfinanceblogs/blog2"
      ? "Large Development Finance"
      : location === "/developmentfinanceblogs/blog3"
      ? "Development Finance with bad credit"
      : location === "/developmentfinanceblogs/blog4"
      ? "Development Finance application form"
      : location === "/developmentfinanceblogs/blog5"
      ? "Development Loan First Time Developer"
      : location === "/developmentfinanceblogs/blog6"
      ? "Splitting Deeds"
      : location === "/developmentfinanceblogs/blog7"
      ? "Converting your home into flats"
      : location === "/bridgingloansblogs/blog1"
      ? "Large Bridging Loans"
      : location === "/bridgingloansblogs/blog2"
      ? "Bridging Loans Bad Credit"
      : location === "/bridgingloansblogs/blog3"
      ? "Bridging Loans Nationwide"
      : location === "/bridgingloansblogs/blog4"
      ? "Bridging Loans HSBC"
      : location === "/bridgingloansblogs/blog5"
      ? "Barclays Bridging Loans"
      : location === "/bridgingloansblogs/blog6"
      ? "Bridging Loan Example"
      : location === "/bridgingloansblogs/blog7"
      ? "Bridging Loans for Bad Credit"
      : location === "/bridgingloansblogs/blog8"
      ? "Commercial Bridging Loans"
      : location === "/bridgingloansblogs/blog9"
      ? "Fast Bridging Loans"
      : location === "/bridgingloansblogs/blog10"
      ? "Inherited Property Bridging Loan"
      : location === "/bridgingloansblogs/blog11"
      ? "Difficult Bridging Loan"
      : "";
  return (
    <div className={styles.bridgningHeroSetion}>
      <p className={styles.header}>{heading}</p>
      <div className={styles.mainContent}>
        <div className={styles.left}>
          <p className={styles.leftHead}>
            <span>Call our expert</span>
            <span>advisers now</span>
            {/* Call our expert advisers now */}
          </p>
          <p
            className={classNames(
              styles.leftNumber,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Resources"
                ? styles.reSources
                : ""
            )}
          >
            0208 146 9888
          </p>
          <p className={styles.leftText}>
            <span>We’ll take you through the</span>
            <span>entire process</span>
          </p>
        </div>
        <div className={styles.center}>
          <p className={styles.borderTop}></p>
          <p className={styles.text}>or</p>
          <p className={styles.borderBottom}></p>
        </div>
        <div className={styles.right}>
          <p className={styles.rightHead}>
            <span>Start your</span>
            <span>remortgage online</span>
          </p>
          <p
            className={classNames(
              styles.rightNumber,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Resources"
                ? styles.reSources
                : ""
            )}
          >
            continue online
          </p>
          <p className={styles.rightText}>
            <span> See the deals you qualify for &</span>
            <span>how much you could borrow</span>
          </p>
        </div>
      </div>
      <div
        className={classNames(
          styles.mainborderBottom,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <GoTriangleDown
          className={classNames(
            styles.traingleIcon,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : title === "Resources"
              ? styles.reSources
              : ""
          )}
        />
      </div>
    </div>
  );
};

export default HeroSection;
