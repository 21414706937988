import React, { useEffect, useState } from "react";
import styles from "./FormPage.module.scss";
import { HiChevronDoubleUp, HiChevronDoubleDown } from "react-icons/hi";
import classNames from "classnames";
import { database } from "../../FirebaseConfig";
import { collection, addDoc } from "firebase/firestore";
// import logo from "../../assets/images/logo-green.png";
import loanPng from "../../assets/images/loan.png";
import loanGif from "../../assets/images/loan.gif";
import loanGif1 from "../../assets/images/loan1.gif";
import securityPng from "../../assets/images/security.png";
import securityGif from "../../assets/images/security.gif";
import companyPng from "../../assets/images/company.svg";
import companyGif from "../../assets/images/company.gif";
import directorPng from "../../assets/images/director.png";
import directorGif from "../../assets/images/director.gif";
import otherPng from "../../assets/images/other.svg";
import otherGif from "../../assets/images/other1.gif";
import otherGif1 from "../../assets/images/other.gif";
import loading from "../../assets/images/loading.gif";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import * as myConstClass from "../../components/FileWithConstants";
import $ from "jquery";
import LoginModal from "../../components/LoginModal";
import Greencheck from "../../assets/images/green_tick.gif";
import Formimg from "../../assets/images/form_front_page.gif";
// import About_loan from "./animations/About_loan";
import loanpng from "../../assets/images/loan.png";
import directorpng from "../../assets/images/director.png";
import securitypng from "../../assets/images/security.png";
import companysvg from "../../assets/images/company.png";
import othersvg from "../../assets/images/other1.png";
import directorGif1 from "../../assets/images/About_director1.gif";
import securityGif1 from "../../assets/images/About_security1.gif";
import companyGif1 from "../../assets/images/About_company1.gif";
import { default as NumberFormat } from "react-number-format";
import AboutLoan from "./components/AboutLoan";
import AboutSecurity from "./components/AboutSecurity";
import AboutCompany from "./components/AboutCompany";
import AboutDirector from "./components/AboutDirector";
import AdditionalInfo from "./components/AdditionalInfo";
import ProgressBar from "../DevFinanceForm/components/ProgressBar";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const FormPage = () => {
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    const accountCreated = query.get("accountCreated");
    if (accountCreated && Boolean(accountCreated) === true) {
      setStep("final");
    }
  }, [query]);

  // const loggedIn = localStorage.getItem("loggedIn");

  useEffect(() => {
    document.title = "Bridging 365 | Form";
    window.scrollTo(0, 0);

    // console.log("loggedIn = "+loggedIn);
    // if(loggedIn == null || loggedIn == "")
    // {
    //     console.log("called this");
    //     navigate("/signin", { replace: true });
    // }
    // else{

    // }
    // return () => {
    //   localStorage.removeItem("initialObj");
    // };
  }, []);

  const blurHandler = () => {
    if (
      formdata.purpose &&
      formdata.howMuchToBorrow &&
      formdata.numOfMonthsLoanFor &&
      formdata.monthlyOrTermPay &&
      formdata.howPaybackLoan &&
      formdata.addressLine1 &&
      formdata.city &&
      formdata.postcode &&
      formdata.propertyValuation
    ) {
      setShowCalc(true);
    }
  };

  const today = new Date();
  const date =
    today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  const time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  // const [purpose, setPurpose] = useState();
  const collectionRef = collection(database, "forms");
  const initialValue = {
    userName: "",
    userContact: "",
    userEmail: "",
    propertyUse: "",
    whenMoneyNeeded: "",
    purpose: "",
    currentLoanFromBridgingLender: "",
    whenLoanCompleted: "",
    howMuchToBorrow: "",
    numOfMonthsLoanFor: "",
    monthlyOrTermPay: "",
    howPaybackLoan: "",
    howPaybackLoanOtherOptionDescription: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: "",
    propertyValuation: "",
    propertyDescription: "",
    numberOfBedrooms: "",
    numberOfFloors: "",
    isExCouncilProperty: "",
    isStandardConstruction: "",
    constructionDescription: "",
    tenureOfPropertyAnswer: "",
    otherLeaseInfo: "",
    remainingYearsForLease: "",
    assetDescription: "",
    addAnotherProperty: "",
    addressLine1A: "",
    addressLine2A: "",
    city1A: "",
    postcode1A: "",
    propertyValuation1A: "",
    propertyDescription1A: "",
    numberOfBedrooms1A: "",
    numberOfFloors1A: "",
    isExCouncilProperty1A: "",
    isStandardConstruction1A: "",
    constructionDescription1A: "",
    tenureOfProperty1A: "",
    tenureOfPropertyAnswer1A: "",
    otherLeaseInfo1A: "",
    remainingYearsForLease1A: "",
    assetDescription1A: "",
    nameOfCompayForBorrowingIn: "",
    companyRegistrationNumber: "",
    companyAddress1: "",
    companyAddress2: "",
    companyCity: "",
    companyPostCode: "",
    directorFirstName: "",
    directorLastName: "",
    directorTitle: "",
    directorDateOfBirth: "",
    directorAddress1: "",
    directorAddress2: "",
    directorTown: "",
    directorPostCode: "",
    directorNumber: "",
    defaultInSixYears: "",
    defaultInSixYearsDescription: "",
    everDeclaredBankrupt: "",
    everDeclaredBankruptDescription: "",
    addAnotherDirector: "",
    directorFirstName1A: "",
    directorLastName1A: "",
    directorTitle1A: "",
    directorDateOfBirth1A: "",
    directorAddress1A: "",
    directorAddress2A: "",
    directorTown1A: "",
    directorPostCode1A: "",
    directorNumber1A: "",
    defaultInSixYears1A: "",
    defaultInSixYearsDescription1A: "",
    everDeclaredBankrupt1A: "",
    everDeclaredBankruptDescription1A: "",
    addAnotherDirector1A: "",
    directorFirstName1B: "",
    directorLastName1B: "",
    directorTitle1B: "",
    directorDateOfBirth1B: "",
    directorAddress1B: "",
    directorAddress2B: "",
    directorTown1B: "",
    directorPostCode1B: "",
    directorNumber1B: "",
    defaultInSixYears1B: "",
    defaultInSixYearsDescription1B: "",
    everDeclaredBankrupt1B: "",
    everDeclaredBankruptDescription1B: "",
    // addAnotherDirector1B: "",
    otherMsg: "",
    submittedOnDate: date,
    submittedOnTime: time,
  };
  const [formdata, setFormdata] = useState(initialValue);
  const [showLoading, setShowLoading] = useState(false);
  const [showCalc, setShowCalc] = useState(true);
  const value = formdata.howMuchToBorrow
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  console.log("howmuch to borrow", value);

  const submitHandler = () => {
    setShowLoading(true);
    formdata.lastid = document.getElementById("lastid").value;
    const login_emailid_m1 = localStorage.getItem("login_emailid");
    formdata.login_emailid_send = localStorage.getItem("login_emailid_m1");
    formdata.save_for_later = 0;
    console.log(JSON.stringify(formdata));
    const formsaveforlater =
      myConstClass.BASE_URL + "" + myConstClass.FORMDATASAVELATER;
    var saveDataFormLater = $.ajax({
      type: "POST",
      url: formsaveforlater,
      data: formdata,
      dataType: "json",
      success: function (resultData) {
        console.log(resultData);
        var lastid = resultData.lastid;
        document.getElementById("lastid").value = lastid;
      },
    });

    addDoc(collectionRef, formdata)
      .then((response) => {
        // alert("Form submitted successfully ! ");
        setShowLoading(false);
        setFormdata(initialValue);
        navigate(
          "/formsuccess",
          {
            state: {
              title: "Thank you for your application",
              subhead:
                "We received your application and will reveiew it in consideration for lenders that are suitable. We will contact you if we need any further details",
            },
          },
          { replace: true }
        );
      })
      .catch((error) => {
        alert("Oops !! Something went wrong. ");
        setShowLoading(false);
      });
  };
  const saveFomrForLaterHandler = () => {
    // if (
    //   !formdata.purpose ||
    //   // !formdata.currentLoanFromBridgingLender ||
    //   // !formdata.whenLoanCompleted ||
    //   !formdata.howMuchToBorrow ||
    //   !formdata.numOfMonthsLoanFor ||
    //   !formdata.monthlyOrTermPay ||
    //   !formdata.howPaybackLoan ||
    //   !formdata.addressLine1 ||
    //   // !formdata.addressLine2 ||
    //   !formdata.city ||
    //   !formdata.postcode ||
    //   !formdata.propertyValuation ||
    //   !formdata.propertyDescription ||
    //   !formdata.numberOfBedrooms ||
    //   !formdata.numberOfFloors ||
    //   !formdata.isExCouncilProperty ||
    //   !formdata.isStandardConstruction ||
    //   !formdata.constructionDescription ||
    //   !formdata.tenureOfProperty ||
    //   !formdata.assetDescription ||
    //   // !formdata.addAnotherProperty ||
    //   !formdata.nameOfCompayForBorrowingIn ||
    //   !formdata.companyRegistrationNumber ||
    //   !formdata.companyAddress1 ||
    //   // !formdata.companyAddress2 ||
    //   !formdata.companyCity ||
    //   !formdata.companyPostCode ||
    //   !formdata.directorFirstName ||
    //   !formdata.directorLastName ||
    //   !formdata.directorDateOfBirth ||
    //   !formdata.directorAddress1 ||
    //   // !formdata.directorAddress2 ||
    //   !formdata.directorTown ||
    //   !formdata.directorNumber ||
    //   !formdata.defaultInSixYears ||
    //   !formdata.everDeclaredBankrupt ||
    //   !formdata.otherMsg
    // ) {
    //   alert("Please fill all the details to continue");
    //   return;
    // }
    setShowLoading(true);
    formdata.lastid = document.getElementById("lastid").value;
    console.log(JSON.stringify(formdata));
    const login_emailid_m1 = localStorage.getItem("login_emailid");
    formdata.login_emailid_send = localStorage.getItem("login_emailid_m1");
    formdata.save_for_later = 1;

    const formsaveforlater =
      myConstClass.BASE_URL + "" + myConstClass.FORMDATASAVELATER;
    var saveDataFormLater = $.ajax({
      type: "POST",
      url: formsaveforlater,
      data: formdata,
      dataType: "json",
      success: function (resultData) {
        console.log(resultData);
        var lastid = resultData.lastid;
        document.getElementById("lastid").value = lastid;
      },
    });

    addDoc(collectionRef, formdata)
      .then((response) => {
        // alert("Form submitted successfully ! ");
        setShowLoading(false);
        setFormdata(initialValue);
        navigate(
          "/formsuccess",
          {
            state: {
              title: "form saved successfully",
              subhead:
                "You can visit user profile section to edit,delete and download form",
            },
          },
          { replace: true }
        );
      })
      .catch((error) => {
        alert("Oops !! Something went wrong. ");
        setShowLoading(false);
      });
  };
  const emailFormHandler = () => {
    alert("Functionality to be added soon...");
  };
  const [step, setStep] = useState("initial");
  const intialSubmitHandler = () => {
    // if (
    //   !formdata.userName ||
    //   !formdata.userContact ||
    //   !formdata.userEmail ||
    //   !formdata.propertyUse ||
    //   !formdata.whenMoneyNeeded
    // ) {
    //   alert("Please fill all the details to continue");
    //   return;
    // }

    const isLoggedIn = localStorage.getItem("loggedIn");
    if (!isLoggedIn) {
      navigate(`/loginmodal`, {
        state: formdata,
      });

      // setShowLoginPopup(true);
      return;
    }

    const formsubmit = myConstClass.BASE_URL + "" + myConstClass.FORMDATA;
    //axios.post(formsubmit, formdata)
    //.then(res => {
    //         console.log(res);
    //});

    formdata.login_emailid_send = localStorage.getItem("login_emailid");

    var saveData = $.ajax({
      type: "POST",
      url: formsubmit,
      data: formdata,
      dataType: "json",
      success: function (resultData) {
        console.log(resultData);
        var lastid = resultData.lastid;
        document.getElementById("lastid").value = lastid;
      },
    });

    localStorage.setItem("initialObj", JSON.stringify(formdata));
    setStep("final");
    window.scrollTo(0, 0);
  };
  const SaveForLaterHandler = () => {
    alert("Feature to be added soon.");
  };
  const [resultsShown, setResultsShown] = useState(false);
  let mql = window.matchMedia("(max-width:800px)");
  const simpleInterest =
    (formdata.howMuchToBorrow * 0.69 * formdata.numOfMonthsLoanFor) / 1200;
  console.log("simpleInterest", simpleInterest);
  const amountDue = Number(
    Number(formdata.howMuchToBorrow) + Number(simpleInterest)
  ).toFixed(0);
  console.log("amountDue", amountDue);
  const monthlyPayment = Number(
    amountDue / formdata.numOfMonthsLoanFor
  ).toFixed(0);
  console.log("monthlyPayment", monthlyPayment);
  const maxBorrowLimit = (0.65 * formdata.propertyValuation).toFixed(2);
  const lTV = (formdata.howMuchToBorrow / formdata.propertyValuation).toFixed(
    2
  );
  console.log(lTV);
  console.log(isNaN(lTV));

  const [loanProgress, setLoanProgress] = useState(0);
  const [securityProgress, setSecurityProgress] = useState(0);
  const [companyProgress, setCompanyProgress] = useState(0);
  const [directorProgress, setDirectorProgress] = useState(0);
  const [additionalInfoProgresss, setAdditionalInfoProgress] = useState(0);

  const finalFormUI = () => {
    return (
      <div className={styles.formPage}>
        <span
          title="form results"
          className={styles.finalResults}
          onClick={() => setResultsShown(!resultsShown)}
        >
          {" "}
          <span> Your results</span>
          <span>
            {" "}
            {!resultsShown ? (
              <HiChevronDoubleUp className={styles.docIcon} />
            ) : (
              <HiChevronDoubleDown className={styles.docIcon} />
            )}
          </span>
        </span>{" "}
        <div className={styles.left}>
          {/* <img src={logoImg} alt="" /> */}
          <ul>
            <li>
              {" "}
              <a href="#section1" style={{ boxShadow: "0 0 0 2px #ff7232 " }}>
                <div>
                  <span
                    style={{
                      backgroundColor: "#ff7232",
                      paddingTop: "0.7rem",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    About the Loan{" "}
                  </span>
                  <span style={{ paddingTop: "3px" }}>
                    <img src={loanPng} alt="" />
                  </span>{" "}
                </div>
                <ProgressBar width={loanProgress} name="loan" />
              </a>
            </li>
            <li>
              <a href="#section2" style={{ boxShadow: "0 0 0 2px #673ab7 " }}>
                <div>
                  <span
                    style={{
                      backgroundColor: "#673ab7",
                      paddingTop: "0.7rem",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    About the Security
                  </span>{" "}
                  <span style={{ paddingTop: "3px" }}>
                    {" "}
                    <img src={securityPng} alt="" />
                  </span>{" "}
                </div>
                <ProgressBar width={securityProgress} name="security" />
              </a>
            </li>
            <li>
              {" "}
              <a href="#section3" style={{ boxShadow: "0 0 0 2px #4ccb14 " }}>
                <div>
                  <span
                    style={{
                      backgroundColor: "#4ccb14",
                      paddingTop: "0.7rem",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    About the Company
                  </span>{" "}
                  <span style={{ paddingTop: "3px" }}>
                    {" "}
                    <img src={companyPng} alt="" />
                  </span>{" "}
                </div>
                <ProgressBar width={companyProgress} name="company" />
              </a>
            </li>
            <li>
              {" "}
              <a href="#section4" style={{ boxShadow: "0 0 0 2px #042c74 " }}>
                <div>
                  <span
                    style={{
                      backgroundColor: "#042c74",
                      paddingTop: "0.7rem",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    About the Director
                  </span>{" "}
                  <span style={{ paddingTop: "3px" }}>
                    {" "}
                    <img src={directorPng} alt="" />
                  </span>
                </div>
                <ProgressBar width={directorProgress} name="director" />
              </a>
            </li>
            <li>
              {" "}
              <a href="#section5" style={{ boxShadow: "0 0 0 2px #f8ab17 " }}>
                <div>
                  <span
                    style={{
                      backgroundColor: "#f8ab17",
                      paddingTop: "0.7rem",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Additional Information
                  </span>{" "}
                  <span style={{ paddingTop: "3px" }}>
                    {" "}
                    <img src={otherPng} alt="" />
                  </span>{" "}
                </div>
                <ProgressBar
                  width={additionalInfoProgresss}
                  name="additionalInfo"
                />
              </a>
            </li>
          </ul>
          <div className={styles.btns}>
            <button className={styles.btn1} onClick={saveFomrForLaterHandler}>
              Save for later
            </button>
            <button className={styles.btn2} onClick={emailFormHandler}>
              Email me
            </button>
            <button
              className={styles.btn1}
              onClick={() => {
                setStep("initial");
                window.scrollTo(0, 0);
              }}
            >
              Go back
            </button>
          </div>
        </div>
        <div className={styles.center}>
          {" "}
          <div className={styles.section} id="section1">
            <div>
              <div className={styles.banner}>
                <span style={{ padding: "1rem 0rem 1rem 2rem" }}>
                  {" "}
                  <img className={styles.formHeader} src={loanGif} />
                </span>
                <p
                  className={styles.head}
                  style={{ flex: "1 1 100%", textAlign: "center" }}
                >
                  ABOUT THE LOAN{" "}
                  <div style={{ color: "#002d72" }}>SECTION 1 OF 5</div>{" "}
                </p>
                <span style={{ padding: "1rem 2rem 1rem 0rem" }}>
                  {" "}
                  <img className={styles.formHeader} src={loanpng} />
                </span>
              </div>
            </div>
            {/* <img src={loanPng} alt="" srcset="" className={styles.png} /> */}
            <div className={styles.gifContainer}>
              <img src={loanGif1} alt="" className={styles.gif} />
            </div>
            <p
              className={styles.text}
              style={{ backgroundColor: "#ff7232", marginBottom: "2rem" }}
            >
              Here we will be asking you about how much you want to borrow and
              for how long, followed by a little about your property and then
              details about you and your company. It should not take more than
              10 mins...
            </p>

            <AboutLoan
              formdata={formdata}
              setFormdata={setFormdata}
              setLoanProgress={setLoanProgress}
            />
          </div>
          <div className={styles.section} id="section2">
            <div
              className={styles.banner}
              style={{ backgroundColor: "#673ab7" }}
            >
              <span style={{ padding: "1rem 0rem 1rem 2rem" }}>
                {" "}
                <img className={styles.formHeader} src={securityGif1} />
              </span>
              <p
                className={styles.head}
                style={{ flex: "1 1 100%", textAlign: "center" }}
              >
                ABOUT THE SECURITY{" "}
                <div style={{ color: "#4caf50" }}>SECTION 2 OF 5</div>{" "}
              </p>
              <span style={{ padding: "1rem 2rem 1rem 0rem" }}>
                {" "}
                <img className={styles.formHeader} src={securitypng} />
              </span>
            </div>

            <div className={styles.gifContainer}>
              <img src={securityGif} alt="" className={styles.gif} />
            </div>
            <p
              className={styles.text}
              style={{ backgroundColor: "#673ab7", marginBottom: "2rem" }}
            >
              Now we understand how much you’re looking to borrow, can you now
              tell us about the asset you are borrowing against?
            </p>
            <AboutSecurity
              formdata={formdata}
              setFormdata={setFormdata}
              setSecurityProgress={setSecurityProgress}
            />
            {formdata.addAnotherProperty === "yes" && (
              <form action="" className={styles.form1}>
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "1.5rem",
                    fontWeight: "bold",
                    fontSize: "2rem",
                    fontFamily: "Nunito,sans-serif",
                  }}
                >
                  Tell us about the second property
                </p>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                    Name={styles.question}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Address of the security{" "}
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Enter your address"
                      className={styles.answer}
                      value={formdata.addressLine1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          addressLine1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Address Line 2 (optional)
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Enter your address"
                      className={styles.answer}
                      value={formdata.addressLine2A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          addressLine2A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Which city or town is the property located in?{" "}
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Enter city or town name"
                      className={styles.answer}
                      value={formdata.city1A}
                      onChange={(e) => {
                        setFormdata({ ...formdata, city1A: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      What is the postcode?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <input
                      type="text"
                      placeholder="Enter postcode"
                      className={styles.answer}
                      value={formdata.postcode1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          postcode1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      What is the estimated property valuation?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <NumberFormat
                      placeholder="Enter valuation in GBP"
                      className={styles.answer}
                      value={formdata.propertyValuation1A}
                      thousandSeparator={true}
                      onChange={(e) => {
                        blurHandler();
                        setFormdata({
                          ...formdata,
                          propertyValuation1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Please select the best description of the property below
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.propertyDescription1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          propertyDescription1A: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="House">House</option>
                      <option value="Flat">Flat</option>
                      <option value="HMO (House in Multiple Occupation)">
                        HMO (House in Multiple Occupation)
                      </option>
                      <option value="Commercial property">
                        Commercial property
                      </option>
                      <option value="Land with or without planning permission">
                        Land with or without planning permission
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      How many bedrooms does the property have?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="number"
                      placeholder="Number of bedrooms"
                      className={styles.answer}
                      value={formdata.numberOfBedrooms1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          numberOfBedrooms1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      How many floors are in the building?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="number"
                      placeholder="Enter number of floors"
                      className={styles.answer}
                      value={formdata.numberOfFloors1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          numberOfFloors1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Is it an ex-council property?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.isExCouncilProperty1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          isExCouncilProperty1A: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Is property of standard construction? Please select below
                      and tell us more.
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.isStandardConstruction1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          isStandardConstruction1A: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  {/* <div className={styles.answerBlock}></div> */}

                  <div className={styles.answerBlock}>
                    {" "}
                    <textarea
                      name=""
                      placeholder="Tell us more"
                      id=""
                      cols="40"
                      rows="5"
                      className={classNames(styles.answer, styles.textarea)}
                      value={formdata.constructionDescription1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          constructionDescription1A: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Do you know the tenure of the property?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.tenureOfProperty1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          tenureOfProperty1A: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
                {formdata.tenureOfProperty1A === "Yes" && (
                  <div className={styles.inputBox}>
                    <div className={styles.answerBlock}>
                      <select
                        name=""
                        id=""
                        className={styles.answer}
                        value={formdata.tenureOfPropertyAnswer1A}
                        onChange={(e) => {
                          setFormdata({
                            ...formdata,
                            tenureOfPropertyAnswer1A: e.target.value,
                          });
                        }}
                      >
                        <option value="" selected disabled>
                          select lease type
                        </option>
                        <option value="Freehold">Freehold</option>
                        <option value="Leasehold">Leasehold</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                )}
                {formdata.tenureOfProperty1A === "Yes" &&
                  formdata.tenureOfPropertyAnswer1A === "Other" && (
                    <div className={styles.inputBox}>
                      <div className={styles.answerBlock}>
                        <textarea
                          name=""
                          id=""
                          value={formdata.otherLeaseInfo1A}
                          onChange={(e) => {
                            setFormdata({
                              ...formdata,
                              otherLeaseInfo1A: e.target.value,
                            });
                          }}
                          className={classNames(styles.answer, styles.textarea)}
                          cols="40"
                          rows="5"
                          placeholder="Lease description"
                        ></textarea>
                      </div>
                    </div>
                  )}
                {formdata.tenureOfProperty1A === "Yes" &&
                  formdata.tenureOfPropertyAnswer1A === "Leasehold" && (
                    <div className={styles.inputBox}>
                      <div className={styles.answerBlock}>
                        <input
                          type="number"
                          placeholder="Number of years remanining on the lease"
                          className={styles.answer}
                          value={formdata.remainingYearsForLease1A}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return false;
                            }
                            setFormdata({
                              ...formdata,
                              remainingYearsForLease1A: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section2)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Please describe the asset:
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <textarea
                      name=""
                      placeholder="Describe asset"
                      id=""
                      cols="40"
                      rows="5"
                      className={classNames(styles.answer, styles.textarea)}
                      value={formdata.assetDescription1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          assetDescription1A: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
              </form>
            )}
          </div>
          <div className={styles.section} id="section3">
            <div
              className={styles.banner}
              style={{ backgroundColor: "#4caf50" }}
            >
              <span style={{ padding: "1rem 0rem 1rem 2rem" }}>
                {" "}
                <img className={styles.formHeader} src={companyGif1} />
              </span>
              <p
                className={styles.head}
                style={{ flex: "1 1 100%", textAlign: "center" }}
              >
                ABOUT THE COMPANY{" "}
                <div style={{ color: "#002d72" }}>SECTION 3 OF 5</div>{" "}
              </p>
              <span style={{ padding: "1rem 2rem 1rem 0rem" }}>
                {" "}
                <img
                  className={styles.formHeader}
                  style={{ width: "80%" }}
                  src={companysvg}
                />
              </span>
            </div>

            <div className={styles.gifContainer}>
              <img src={companyGif} alt="" className={styles.gif} />
            </div>
            <p
              className={styles.text}
              style={{ backgroundColor: "#4caf50", marginBottom: "2rem" }}
            >
              Thanks for telling us about how much you want to borrow and the
              security asset. Now would you mind telling us about the company we
              will be lending to?
            </p>
            <AboutCompany
              formdata={formdata}
              setFormdata={setFormdata}
              setCompanyProgress={setCompanyProgress}
            />
          </div>
          <div className={styles.section} id="section4">
            <div
              className={styles.banner}
              style={{ backgroundColor: "#042c74" }}
            >
              <span style={{ padding: "1rem 0rem 1rem 2rem" }}>
                {" "}
                <img className={styles.formHeader} src={directorGif1} />
              </span>
              <p
                className={styles.head}
                style={{ flex: "1 1 100%", textAlign: "center" }}
              >
                ABOUT THE DIRECTOR{" "}
                <div style={{ color: "#4caf50" }}>SECTION 4 OF 5</div>{" "}
              </p>
              <span style={{ padding: "1rem 2rem 1rem 0rem" }}>
                {" "}
                <img className={styles.formHeader} src={directorpng} />
              </span>
            </div>

            <div className={styles.gifContainer}>
              <img src={directorGif} alt="" className={styles.gif} />
            </div>
            <p
              className={styles.text}
              style={{ backgroundColor: "#002d72", marginBottom: "2rem" }}
            >
              We're getting excited as we are close to the end, now can you tell
              us about yourself?
            </p>
            <AboutDirector
              formdata={formdata}
              setFormdata={setFormdata}
              setDirectorProgress={setDirectorProgress}
            />
            {formdata.addAnotherDirector === "yes" && (
              <form action="" className={styles.form1}>
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  Enter details about another Director
                </p>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      What is the Director's first name?{" "}
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <input
                      type="text"
                      placeholder="Director's first name?"
                      className={styles.answer}
                      value={formdata.directorFirstName1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorFirstName1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      What is the Director's last name?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Director's last name"
                      className={styles.answer}
                      value={formdata.directorLastName1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorLastName1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      What is your title
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.directorTitle1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorTitle1A: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Dr">Dr</option>
                      <option value="Miss">Miss </option>
                    </select>
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      When were you born?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="date"
                      placeholder="Select your birthdate"
                      className={styles.answer}
                      value={formdata.directorDateOfBirth1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorDateOfBirth1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      1st line of the Director's address
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Director's address"
                      className={styles.answer}
                      value={formdata.directorAddress1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorAddress1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Enter the Director's address line 2 (optional)
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Director's address"
                      className={styles.answer}
                      value={formdata.directorAddress2A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorAddress2A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}> Town or City</span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Enter your town or City"
                      className={styles.answer}
                      value={formdata.directorTown1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorTown1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}> Postcode?</span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Enter postal code"
                      className={styles.answer}
                      value={formdata.directorPostCode1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorPostCode1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      What is the Best Number To Contact You?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="number"
                      placeholder="Contact number"
                      className={styles.answer}
                      value={formdata.directorNumber1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorNumber1A: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Have you had any CCJ's or the Defaults in the last 6
                      years?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.defaultInSixYears1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          defaultInSixYears1A: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {formdata.defaultInSixYears1A === "Yes" && (
                    <div className={styles.inputBox}>
                      <div className={styles.answerBlock}>
                        <textarea
                          name=""
                          placeholder="Please tell us more."
                          id=""
                          cols="40"
                          rows="5"
                          className={classNames(styles.answer, styles.textarea)}
                          value={formdata.defaultInSixYearsDescription1A}
                          onChange={(e) => {
                            setFormdata({
                              ...formdata,
                              defaultInSixYearsDescription1A: e.target.value,
                            });
                          }}
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Have you ever been declared bankrupt?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.everDeclaredBankrupt1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          everDeclaredBankrupt1A: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {formdata.everDeclaredBankrupt1A === "Yes" && (
                    <div className={styles.inputBox}>
                      <div className={styles.answerBlock}>
                        <textarea
                          name=""
                          placeholder="Please tell us more."
                          id=""
                          cols="40"
                          rows="5"
                          className={classNames(styles.answer, styles.textarea)}
                          value={formdata.everDeclaredBankruptDescription1A}
                          onChange={(e) => {
                            setFormdata({
                              ...formdata,
                              everDeclaredBankruptDescription1A: e.target.value,
                            });
                          }}
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Would you like to add another Director?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.addAnotherDirector1A}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          addAnotherDirector1A: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
              </form>
            )}
            {formdata.addAnotherDirector1A === "yes" && (
              <form action="" className={styles.form1}>
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  Enter details about another director
                </p>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      What is the Director's first name?{" "}
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <input
                      type="text"
                      placeholder="Director's first name?"
                      className={styles.answer}
                      value={formdata.directorFirstName1B}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorFirstName1B: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      What is the Director's last name?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Director's last name"
                      className={styles.answer}
                      value={formdata.directorLastName1B}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorLastName1B: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      What is your title
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.directorTitle1B}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorTitle1B: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Dr">Dr</option>
                      <option value="Miss">Miss </option>
                    </select>
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      When were you born?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="date"
                      placeholder="Select your birthdate"
                      className={styles.answer}
                      value={formdata.directorDateOfBirth1B}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorDateOfBirth1B: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      1st line of the Director's address
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Director's address"
                      className={styles.answer}
                      value={formdata.directorAddress1B}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorAddress1B: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Enter the Director's address line 2 (optional)
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Director's address"
                      className={styles.answer}
                      value={formdata.directorAddress2B}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorAddress2B: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}> Town or City</span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Enter your town or City"
                      className={styles.answer}
                      value={formdata.directorTown1B}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorTown1B: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}> Postcode?</span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="text"
                      placeholder="Enter postal code"
                      className={styles.answer}
                      value={formdata.directorPostCode1B}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorPostCode1B: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      What is the Best Number To Contact You?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      type="number"
                      placeholder="Contact number"
                      className={styles.answer}
                      value={formdata.directorNumber1B}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          directorNumber1B: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Have you had any CCJ's or the Defaults in the last 6
                      years?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.defaultInSixYears1B}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          defaultInSixYears1B: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {formdata.defaultInSixYears1B === "Yes" && (
                    <div className={styles.inputBox}>
                      <div className={styles.answerBlock}>
                        <textarea
                          name=""
                          placeholder="Please tell us more."
                          id=""
                          cols="40"
                          rows="5"
                          className={classNames(styles.answer, styles.textarea)}
                          value={formdata.defaultInSixYearsDescription1B}
                          onChange={(e) => {
                            setFormdata({
                              ...formdata,
                              defaultInSixYearsDescription1B: e.target.value,
                            });
                          }}
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.inputBox}>
                  <label
                    htmlFor=""
                    className={classNames(styles.question, styles.section4)}
                  >
                    <span className={styles.questionText}>
                      {" "}
                      Have you ever been declared bankrupt?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.everDeclaredBankrupt1B}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          everDeclaredBankrupt1B: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {formdata.everDeclaredBankrupt1B === "Yes" && (
                    <div className={styles.inputBox}>
                      <div className={styles.answerBlock}>
                        <textarea
                          name=""
                          placeholder="Please tell us more."
                          id=""
                          cols="40"
                          rows="5"
                          className={classNames(styles.answer, styles.textarea)}
                          value={formdata.everDeclaredBankruptDescription1B}
                          onChange={(e) => {
                            setFormdata({
                              ...formdata,
                              everDeclaredBankruptDescription1B: e.target.value,
                            });
                          }}
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className={styles.inputBox}>
                  <label htmlFor="" className={classNames(styles.question, styles.section4)}>
                    <span className={styles.questionText}>
                      {" "}
                      Would you like to add another director?

                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.addAnotherDirector}
                      onChange={(e) => {
                        setFormdata({
                          ...formdata,
                          addAnotherDirector: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div> */}
              </form>
            )}
          </div>
          <div className={styles.section} id="section5">
            <div
              className={styles.banner}
              style={{ backgroundColor: "#f8ab17" }}
            >
              <span style={{ padding: "1rem 0rem 1rem 2rem" }}>
                {" "}
                <img className={styles.formHeader} src={otherGif1} />
              </span>
              <p
                className={styles.head}
                style={{ flex: "1 1 100%", textAlign: "center" }}
              >
                ANYTHING ELSE{" "}
                <div style={{ color: "#002d72" }}>SECTION 5 OF 5</div>{" "}
              </p>
              <span style={{ padding: "1rem 2rem 1rem 0rem" }}>
                {" "}
                <img
                  className={styles.formHeader}
                  style={{ width: "80%" }}
                  src={othersvg}
                />
              </span>
            </div>

            <div className={styles.gifContainer}>
              <img src={otherGif} alt="" className={styles.gif} />
            </div>
            <p className={styles.text} style={{ marginBottom: "2rem" }}>
              For this last section, here's your chance to tell us anything else
              you think we need to know about you or the application.
            </p>

            <AdditionalInfo
              formdata={formdata}
              setFormdata={setFormdata}
              setAdditionalInfoProgress={setAdditionalInfoProgress}
            />
          </div>
          <div className={styles.btns}>
            <button
              onClick={SaveForLaterHandler}
              className={styles.saveForLater}
            >
              Save for later
            </button>
            <button onClick={submitHandler} className={styles.submitBtn}>
              Submit
            </button>
          </div>
        </div>
        <div
          className={classNames(styles.right, !showCalc ? styles.showCalc : "")}
        >
          <div className={styles.table}>
            <p>Your Offer in Principle</p>
            <table>
              <tr>
                <td>You are looking to borrow</td>
                <td>
                  {formdata.howMuchToBorrow
                    ? "£ " +
                      formdata.howMuchToBorrow
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
              <tr>
                <td>Based on your property being valued at</td>
                <td>
                  {formdata.propertyValuation
                    ? "£ " +
                      formdata.propertyValuation
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
              <tr>
                <td>
                  For how many months with an interest rate of 0.69% Per Month
                </td>
                <td>
                  {formdata.numOfMonthsLoanFor
                    ? formdata.numOfMonthsLoanFor
                    : "NA"}
                </td>
              </tr>
              <tr>
                <td>Typical time to completion (days)</td>
                <td>10 </td>
              </tr>
            </table>
          </div>
          <div className={styles.table}>
            <p>Pay at end of term (Rolled)</p>
            <table>
              <tr>
                <td>Monthly Payments</td>
                <td>NA</td>
              </tr>
              <tr>
                <td>Amount Due</td>
                <td>
                  {" "}
                  {amountDue
                    ? "£ " +
                      amountDue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
              <tr>
                <td>Gross LTV</td>
                <td>{isNaN(lTV) ? "NA" : lTV === "Infinity" ? "NA" : lTV}</td>
              </tr>
              <tr>
                <td>Maximum you can borrow</td>
                <td>
                  {" "}
                  {maxBorrowLimit
                    ? "£ " +
                      maxBorrowLimit
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
            </table>
          </div>
          <div className={styles.table}>
            <p>Pay monthly (Serviced)</p>
            <table>
              <tr>
                <td>Monthly Payments</td>
                <td>
                  {" "}
                  {!monthlyPayment
                    ? "NA"
                    : monthlyPayment === "Infinity"
                    ? "NA"
                    : "£ " +
                      monthlyPayment
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr>
                <td>Amount Due</td>
                <td>
                  {amountDue
                    ? "£ " +
                      amountDue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
              <tr>
                <td>Gross LTV</td>
                <td>{isNaN(lTV) ? "NA" : lTV === "Infinity" ? "NA" : lTV}</td>
              </tr>
              <tr>
                <td>Maximum you can borrow</td>
                <td>
                  {maxBorrowLimit
                    ? "£ " +
                      maxBorrowLimit
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
            </table>
          </div>
        </div>
        {mql && step === "final" && resultsShown ? (
          <div
            className={classNames(
              styles.results,
              !showCalc ? styles.showCalc : ""
            )}
          >
            <div className={styles.table}>
              <p>Your Offer in Principle</p>
              <table>
                <tr>
                  <td>You are looking to borrow</td>
                  <td>
                    {" "}
                    {formdata.howMuchToBorrow
                      ? "£ " +
                        formdata.howMuchToBorrow
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "NA"}
                  </td>
                </tr>
                <tr>
                  <td>Based on your property being valued at</td>
                  <td>
                    {" "}
                    {formdata.propertyValuation
                      ? "£ " +
                        formdata.propertyValuation
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "NA"}
                  </td>
                </tr>
                <tr>
                  <td>
                    For how many months with an interest rate of 0.69% Per Month
                  </td>
                  <td>
                    {formdata.numOfMonthsLoanFor
                      ? formdata.numOfMonthsLoanFor
                      : "NA"}
                  </td>
                </tr>
                <tr>
                  <td>Typical time to completion (days)</td>
                  <td>10</td>
                </tr>
              </table>
            </div>
            <div className={styles.table}>
              <p>Pay at end of term (Rolled)</p>
              <table>
                <tr>
                  <td>Monthly Payments</td>
                  <td>NA</td>
                </tr>
                <tr>
                  <td>Amount Due</td>
                  <td>{amountDue ? "£ " + amountDue : "NA"}</td>
                </tr>
                <tr>
                  <td>Gross LTV</td>
                  <td>{isNaN(lTV) ? "NA" : lTV === "Infinity" ? "NA" : lTV}</td>
                </tr>
                <tr>
                  <td>Maximum you can borrow</td>
                  <td>
                    {maxBorrowLimit
                      ? "£ " +
                        maxBorrowLimit
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "NA"}
                  </td>
                </tr>
              </table>
            </div>
            <div className={styles.table}>
              <p>Pay monthly (Serviced)</p>
              <table>
                <tr>
                  <td>Monthly Payments</td>
                  <td>
                    {!monthlyPayment
                      ? "NA"
                      : monthlyPayment === "Infinity"
                      ? "NA"
                      : "£ " +
                        monthlyPayment
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td>Amount Due</td>
                  <td>
                    {amountDue
                      ? "£ " +
                        amountDue
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "NA"}
                  </td>
                </tr>
                <tr>
                  <td>Gross LTV</td>
                  <td>{isNaN(lTV) ? "NA" : lTV === "Infinity" ? "NA" : lTV}</td>
                </tr>
                <tr>
                  <td>Maximum you can borrow</td>
                  <td>
                    {maxBorrowLimit
                      ? "£ " +
                        maxBorrowLimit
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "NA"}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        ) : null}
        <div className={styles.loadingContainer}>
          {showLoading && (
            <img src={loading} className={styles.loadingImg} alt="" />
          )}
        </div>
      </div>
    );
  };
  const initialFormUI = () => {
    const userId = localStorage.getItem("userId");
    const login_emailid = localStorage.getItem("login_emailid");

    if (login_emailid != "" || login_emailid != null) {
      const loginurl = myConstClass.BASE_URL + "" + myConstClass.LOGIN;
      let formData = new FormData();
      formData.append("login_emailid", login_emailid);

      var first_name,
        last_name,
        contact,
        email,
        alternate_no,
        address,
        country,
        state,
        city,
        postalcode,
        profile_image,
        alternate_no;
      axios.post(loginurl, formData).then((res) => {
        first_name = res.data.data.first_name;
        last_name = res.data.data.last_name;

        if (first_name == null || last_name == null) {
          first_name = res.data.data.name;
          last_name = "";
        }

        document.getElementById("fullname").defaultValue =
          first_name + " " + last_name;
        formdata.userName = first_name + " " + last_name;
        contact = res.data.data.contact;
        document.getElementById("contact").defaultValue = contact;
        formdata.userContact = contact;
        email = res.data.data.email;
        document.getElementById("email").defaultValue = email;
        formdata.userEmail = email;
      });
    } else {
    }

    const closeModal = () => {
      setStep("final");
      window.scrollTo(0, 0);
      // setShowLoginPopup(false);
    };
    const closePopup = () => {
      // setShowLoginPopup(false);
      window.scrollTo(0, 0);
    };

    return (
      <div className={styles.formPage}>
        <div className={styles.left}></div>
        <div
          className={styles.center}
          style={{ borderLeft: "transparent", borderRight: "transparent" }}
        >
          {
            <div className={styles.section} id="section4">
              {/* <img src={logo} className={styles.logoImg} alt="" /> */}

              <p
                className={styles.text}
                style={{
                  backgroundColor: "#4caf50",
                  fontFamily: "Nunito,sans-serif",
                  fontSize: "1.8rem",
                  fontWeight: "bolder",
                  padding: "1rem",
                }}
              >
                BRIDGING LOAN APPLICATION
              </p>

              <div
                className={styles.text}
                style={{
                  backgroundColor: "transparent",
                  color: "#042c74",
                  padding: "0 1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>
                  <img src={Formimg} alt="" className={styles.checkbox} />
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "0.5rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    <img
                      src={Greencheck}
                      alt=""
                      style={{ paddingRight: 6, width: "30px", height: "30px" }}
                    />
                    No Credit Checks
                  </p>
                  <p
                    style={{
                      display: "flex",
                      marginBottom: "0.5rem",
                      marginTop: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Greencheck}
                      alt=""
                      style={{ paddingRight: 6, width: "30px", height: "30px" }}
                    />
                    No Commitment
                  </p>
                  <p
                    style={{
                      display: "flex",
                      marginBottom: "0.5rem",
                      marginTop: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Greencheck}
                      alt=""
                      style={{ paddingRight: 6, width: "30px", height: "30px" }}
                    />
                    No Applicatioin fee
                  </p>
                </div>
              </div>

              <p
                className={styles.text}
                style={{
                  backgroundColor: "#4caf50",
                }}
              >
                With rates starting at 0.35% p/m
              </p>
              <p
                className={styles.text}
                style={{
                  backgroundColor: "transparent",
                  color: "#042c74",
                  fontWeight: "bold",
                  marginBottom: "0rem",
                  lineHeight: "2rem",
                }}
              >
                Let's begin an application
                <br /> & get you an offer in 10 minutes.
              </p>

              <p
                style={{
                  height: "0.2rem",
                  width: "100%",
                  marginBottom: "2rem",
                  backgroundImage:
                    "linear-gradient(90deg, #ffffff,#002D72, #ffffff)",
                }}
              ></p>

              {/* We'll have the decision in principle ready for you in the next 10 minutes. */}

              <form action="" className={styles.form1}>
                <div className={styles.inputBox}>
                  <label htmlFor="" className={styles.question}>
                    <span className={styles.questionText}>
                      {" "}
                      What is your full name?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <input
                      id="fullname"
                      type="text"
                      placeholder="Enter your full name"
                      className={styles.answer}
                      defaultValue={formdata.name}
                      onChange={(e) =>
                        setFormdata({
                          ...formdata,
                          userName: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label htmlFor="" className={styles.question}>
                    <span className={styles.questionText}>
                      {" "}
                      What is the best number to contact you?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <input
                      id="contact"
                      type="number"
                      placeholder="Contact number"
                      className={styles.answer}
                      defaultValue={formdata.contact}
                      onChange={(e) =>
                        setFormdata({
                          ...formdata,
                          userContact: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label htmlFor="" className={styles.question}>
                    <span className={styles.questionText}>
                      {" "}
                      And your email address?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    <input
                      id="email"
                      type="email"
                      placeholder="Enter email"
                      className={styles.answer}
                      defaultValue={formdata.email}
                      onChange={(e) =>
                        setFormdata({
                          ...formdata,
                          userEmail: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label htmlFor="" className={styles.question}>
                    <span className={styles.questionText}>
                      {" "}
                      Will you live in the property or is it an investment?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.propertyUse}
                      onChange={(e) =>
                        setFormdata({
                          ...formdata,
                          propertyUse: e.target.value,
                        })
                      }
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="Investment">It is an investment</option>
                      <option value="ForLiving">
                        I'll be living in the property
                      </option>
                    </select>
                  </div>
                </div>
                <div className={styles.inputBox}>
                  <label htmlFor="" className={styles.question}>
                    <span className={styles.questionText}>
                      {" "}
                      How quickly do you need the money?
                    </span>
                  </label>
                  <div className={styles.answerBlock}>
                    {" "}
                    <select
                      name=""
                      id=""
                      className={styles.answer}
                      value={formdata.whenMoneyNeeded}
                      onChange={(e) =>
                        setFormdata({
                          ...formdata,
                          whenMoneyNeeded: e.target.value,
                        })
                      }
                    >
                      <option value="" selected disabled>
                        select option
                      </option>
                      <option value="Urgently">Urgently within days</option>
                      <option value="InFewWeeks">
                        Required within a few weeks but less than 3 months
                      </option>
                    </select>
                  </div>
                </div>
              </form>
              <div className={styles.btns}>
                <button
                  onClick={intialSubmitHandler}
                  className={styles.submitBtn}
                >
                  Let's begin
                </button>
              </div>
            </div>
          }
        </div>

        <div className={styles.right}></div>
      </div>
    );
  };
  return <>{step === "final" ? finalFormUI() : initialFormUI()}</>;
};

export default FormPage;
