import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import styles from "./CalculatorPage.module.scss";
import Bridgingcalculator from '../../components/BridgingCalculator/BridgingCalculator.jsx';

const CalculatorPage = () => {
    return (
        <div className={styles.calculatorPage}>
            <div className={styles.top}>
                <div className={styles.header}>Calculators</div>
                <div className={styles.links}>
                    <NavLink to="bridgingloan" className={(navData) => (navData.isActive ? styles.active : '')}>
                        Bridging Loan Calculator
                    </NavLink>
                    <NavLink to="refinance" className={(navData) => (navData.isActive ? styles.active : '')}>Re-Bridging Calculator</NavLink>
                    <NavLink to="developmentfinance" className={(navData) => (navData.isActive ? styles.active : '')}>Development Finace</NavLink>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className='min-h-full lg:min-h-screen bg-white p-2 md:p-6 md:px-12'>
                  <Bridgingcalculator/>
                </div>
            </div>
        </div>
    );
};

export default CalculatorPage;
