import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
// import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { AiOutlineDownload, AiFillEye, AiFillFile } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import DeleteModal from "../../components/DeleteModal";
import { collection, getDocs } from "firebase/firestore";
import { database } from "../../FirebaseConfig";
import { useNavigate } from "react-router-dom";
import loading from "../../assets/images/loading.gif";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import axios from 'axios';
import * as myConstClass from '../../components/FileWithConstants';
import $ from 'jquery';

const Dashboard = () => {
  const [topic, setTopic] = useState();
  const [formData, setFormData] = useState();
  const [newsLetterData, setNewsLetterData] = useState();
  const [contactsData, setContactsData] = useState();
  const [customersData, setCustomersData] = useState();
  const navigate = useNavigate();
  const collectionRef =
    topic === "forms"
      ? collection(database, "forms")
      : topic === "newsLetterSubmissions"
      ? collection(database, "newsLetterSubmissions")
      : topic === "contacts"
      ? collection(database, "contacts")
      : topic === "customers"
      ? collection(database, "users")
      : collection(database, "forms");
  const [showLoading, setShowLoading] = useState(false);

  const login_emailid = localStorage.getItem("login_emailid");
  const loginurl = myConstClass.BASE_URL+""+myConstClass.LOGIN;
  let formDataUser = new FormData();
  formDataUser.append("login_emailid", login_emailid);
  
    
  axios.post(loginurl, formDataUser)
  .then(res => {
      console.log(res.data.data);
           if(res.data.data.isadmin == 1)
           {

           }
           else{
              navigate('../user');
           }
           
  });


  useEffect(() => {
    setShowLoading(true);
    if (topic === "forms") {
      getFormData();
    } else if (topic === "newsLetterSubmissions") {
      getNewsLetterData();
    } else if (topic === "contacts") {
      getContactsData();
    } else if (topic === "customers") {
      getCustomersData();
    } else {
      getFormData();
    }
    // eslint-disable-next-line
  }, [topic]);
  const getFormData = () => {
    setShowLoading(true);

    const getformdataurl = myConstClass.BASE_URL+""+myConstClass.GETFORMDATA;
    var saveDataFormLater = $.ajax({
          type: 'GET',
          url: getformdataurl,
          dataType: "json",
          success: function(resultData) { 
            console.log(resultData.data);
            setFormData(
              resultData.data.map((item) => {
                return { ...item, id: item.loanform_id };
              })
            );
            setShowLoading(false);
           }
    });

  };
  const getNewsLetterData = () => {
    setShowLoading(true);
    getDocs(collectionRef)
      .then((response) => {
        setNewsLetterData(
          response.docs.map((item) => {
            return { ...item.data(), id: item.id };
          })
        );
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
      });
  };




  const getCustomersData = () => {
    setShowLoading(true);

    const getcustomerdataurl = myConstClass.BASE_URL+""+myConstClass.GETCUSTOMERDATA;
    var saveDataFormLater = $.ajax({
          type: 'GET',
          url: getcustomerdataurl,
          dataType: "json",
          success: function(resultDataCustomer) { 
            console.log(resultDataCustomer.data);
            setCustomersData(
              resultDataCustomer.data.map((item) => {
                return { ...item, id: item.loanform_id };
              })
            );
            setShowLoading(false);
           }
    });


    //getDocs(collectionRef)
    //  .then((response) => {
    //    setCustomersData(
    //      response.docs.map((item) => {
    //        return { ...item.data(), id: item.id };
    //      })
    //    );
    //    setShowLoading(false);
    //  })
    //  .catch((error) => {
    //    setShowLoading(false);
    //  });

  };




  const getContactsData = () => {
    setShowLoading(true);
    getDocs(collectionRef)
      .then((response) => {
        setContactsData(
          response.docs.map((item) => {
            return { ...item.data(), id: item.id };
          })
        );
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
      });
  };





  //view form code
  const viewFormHandler = (formid) => {
    navigate(`/formdetails/${formid}`, {
      state: formData.filter((item) => item.id === formid),
    });
  };


  const documentHandler = (formid) => {

    navigate(`/uploadeddocumentslist/${formid}`, {
      state: formData.filter((item) => item.id === formid),
    });

  };




  const downloadFormHandler = (formid) => {
    const formDataObj = formData.filter((item) => item.id === formid)[0];
    const doc = new jsPDF();
    autoTable(doc, {
      head: [["Question", "Answer"]],
      body: [
        ["Name", formDataObj.userName],
        ["Email", formDataObj.userEmail],
        ["Contact", formDataObj.userContact],
        ["Email", formDataObj.userEmail],
        [
          "Form submitted on",
          `${formDataObj.submittedOnDate} | ${formDataObj.submittedOnTime}`,
        ],
        [
          "Will you live in the property or is it an investment?",
          formDataObj.propertyUse,
        ],
        ["How quickly do you need the money?", formDataObj.whenMoneyNeeded],
        ["What is the purpose of the bridging loan?", formDataObj.purpose],
        [
          "Is your current loan from a bridging lender?",
          formDataObj.currentLoanFromBridgingLender
            ? formDataObj.currentLoanFromBridgingLender
            : "NA",
        ],
        [
          "When do you need the loan to be completed by?",
          formDataObj.whenLoanCompleted ? formDataObj.whenLoanCompleted : "NA",
        ],
        ["How much would you like to borrow?", formDataObj.howMuchToBorrow],
        [
          "Number of months you would like the loan for",
          formDataObj.numOfMonthsLoanFor,
        ],
        [
          "Would you like to make monthly payments or simply pay the full balance due on exiting the loan? ",
          formDataObj.monthlyOrTermPay,
        ],
        ["How will you be paying the loan back?", formDataObj.howPaybackLoan],
        ["Address Line 1", formDataObj.addressLine1],
        ["Address Line 2", formDataObj.addressLine2],
        ["Which city or town is the property located in ?", formDataObj.city],
        ["What is the postcode ?", formDataObj.postcode],
        [
          "What is the estimated property valuation ?",
          formDataObj.propertyValuation,
        ],
        [
          "Best description of the property below",
          formDataObj.propertyDescription,
        ],
        [
          "How many bedrooms does the property have ?",
          formDataObj.numberOfBedrooms,
        ],
        ["How many floors are in the building ?", formDataObj.numberOfFloors],
        ["Is it an ex-council property ?", formDataObj.isExCouncilProperty],
        [
          "Is property of standard construction? Please select below and tell us more.",
          formDataObj.isStandardConstruction,
        ],
        [
          "Additional information about standard construction",
          formDataObj.constructionDescription,
        ],
        [
          "Do you know the tenure of the property ?",
          formDataObj.tenureOfProperty,
        ],
        ["Please describe the asset", formDataObj.assetDescription],
        [
          "Would you like to add another property ?",
          formDataObj.addAnotherProperty,
        ],
        [
          "What is the name of the company you are borrowing in ?",
          formDataObj.nameOfCompayForBorrowingIn,
        ],
        [
          "What is the company registration number ?",
          formDataObj.companyRegistrationNumber,
        ],
        [
          "What is the registered company address ?",
          formDataObj.companyAddress1,
        ],
        ["Address Line 2", formDataObj.companyAddress2],
        ["City or Town", formDataObj.companyCity],
        ["Postcode ?", formDataObj.companyPostCode],
        ["What is Your First Name ?", formDataObj.directorFirstName],
        ["What is Your Last Name ?", formDataObj.directorLastName],
        ["What is Your Title", formDataObj.directorTitle],
        ["When Were You Born ?", formDataObj.directorDateOfBirth],
        ["Address Line 1", formDataObj.directorAddress1],
        ["Address Line 2", formDataObj.directorAddress2],
        ["Town or City", formDataObj.directorTown],
        ["Postal code ?", formDataObj.directorPostCode],
        [
          "What is the Best Number To Contact You ?",
          formDataObj.directorNumber,
        ],
        [
          "Have you had any CCJ's or the Defaults in the last 6 years ?",
          formDataObj.defaultInSixYears,
        ],
        [
          "Have you ever been declared bankrupt ?",
          formDataObj.everDeclaredBankrupt,
        ],
        [
          "Please tell us anything else that is relevant to the application?	",
          formDataObj.otherMsg,
        ],
      ],
    });
    doc.save(`form_details-${formDataObj.userName}.pdf`);
  };
  const refreshHandler = () => {
    if (topic === "forms") {
      getFormData();
    } else if (topic === "newsLetterSubmissions") {
      getNewsLetterData();
    } else if (topic === "contacts") {
      getContactsData();
    } else if (topic === "customers") {
      getCustomersData();
    } else {
      getFormData();
    }
  };
  const downLoadTableHandler = (id) => {
    const doc = new jsPDF();
    doc.autoTable({ html: `#${id}` });
    doc.save(
      id === "contact-table"
        ? "contact_list.pdf"
        : id === "customer-table"
        ? "customer_list.pdf"
        : id === "newsletter-table"
        ? "newsletter_list.pdf"
        : id === "form-table"
        ? "form_data.pdf"
        : "data.pdf"
    );
  };
  const formSubmissionUI = () => {
    return (
      <div className={styles.formSubmissionUI}>
        <div className={styles.header}>
          <p>Form submissions (Latest)</p>
          <div>
            <label htmlFor="">Filter records</label>
            <input
              type="date"
              //  value={date}
              //   onChange={dateFilterHandler}
            />
            <span
              className={styles.refreshIcon}
              title="refresh"
              onClick={refreshHandler}
            >
              <BiRefresh />
            </span>
          </div>
        </div>
        <div className={styles.content}>
          {formData &&
            formData.map((item) => {
              return (
                <div className={styles.formCard}>
                  <div className={styles.left} key={item.id}>
                    <p>Form id : {item.id} </p>
                    <p>Submitted by : {item.userName} </p>
                    <p>Phone : {item.userContact} </p>
                    <p>Email : {item.userEmail} </p>
                    <p>
                      Submitted on : {item.submittedOnDate} |{" "}
                      {item.submittedOnTime}{" "}
                    </p>
                  </div>
                  <div className={styles.right}>
                    <span title="view" onClick={() => viewFormHandler(item.id)}>
                      <AiFillEye className={styles.icon} />
                    </span>

                    <span
                      // onClick={editHandler}
                      onClick={() => downloadFormHandler(item.id)}
                      title="download"
                    >
                      <AiOutlineDownload className={styles.icon} />
                    </span>

                    <span title="documents" onClick={() => documentHandler(item.id)}>
                      <AiFillFile className={styles.icon} />
                    </span>

                    <span className={styles.deleteModal} title="delete">
                      <DeleteModal
                        id={item.id}
                        onClick={refreshHandler}
                        // name={props.cName}
                      />
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };
  const contactSubmissionUI = () => {
    const headerArray = [
      "Sr. No.",
      "Name",
      "Contact Number",
      "Email",
      "Message",
    ];
    return (
      <div className={styles.contactSubmissionUI}>
        <div className={styles.header}>
          <p>Contact submissions (Latest)</p>
          <div>
            <label htmlFor="">Filter records</label>
            <input
              type="date"
              //  value={date}
              //   onChange={dateFilterHandler}
            />
            <span
              className={styles.refreshIcon}
              title="download"
              onClick={() => downLoadTableHandler("contact-table")}
            >
              <AiOutlineDownload />
            </span>
            <span
              className={styles.refreshIcon}
              title="refresh"
              onClick={refreshHandler}
            >
              <BiRefresh />
            </span>
          </div>
        </div>
        <div className={styles.content}>
          <table id="contact-table">
            {contactsData && (
              <thead>
                <tr>
                  {headerArray.map((item, index) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {contactsData &&
                contactsData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.phone}</td>
                      <td>{item.email}</td>
                      <td className={styles.message}>{item.message}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  const NewLetterSubmissionUI = () => {
    const headerArray = ["Sr. No.", "Email"];

    return (
      <div className={styles.newLetterSubmissionUI}>
        <div className={styles.header}>
          <p>Newsletter submissions (Latest)</p>
          <div>
            <label htmlFor="">Filter records</label>
            <input
              type="date"
              //  value={date}
              //   onChange={dateFilterHandler}
            />
            <span
              className={styles.refreshIcon}
              title="download"
              onClick={() => downLoadTableHandler("newsletter-table")}
            >
              <AiOutlineDownload />
            </span>
            <span
              className={styles.refreshIcon}
              title="refresh"
              onClick={refreshHandler}
            >
              <BiRefresh />
            </span>
          </div>
        </div>
        <div className={styles.content}>
          <table id="newsletter-table">
            {newsLetterData && (
              <thead>
                <tr>
                  {headerArray.map((item, index) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {newsLetterData &&
                newsLetterData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.email}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  const CustomerListUI = () => {
    const headerArray = [
      "Sr. No.",
      "User image",
      "Name",
      "Contact 1",
      "Contact 2",
      "Email",
      "Address",
      "Country",
      "State",
      "Postal Code",
      "Joined on",
    ];
    return (
      <div className={styles.customerListUI}>
        <div className={styles.header}>
          <p>Customers Details (Latest)</p>
          <div>
            <label htmlFor="">Filter records</label>
            <input
              type="date"
              //  value={date}
              //   onChange={dateFilterHandler}
            />
            <span
              className={styles.refreshIcon}
              title="download"
              onClick={() => downLoadTableHandler("customer-table")}
            >
              <AiOutlineDownload />
            </span>
            <span
              className={styles.refreshIcon}
              title="refresh"
              onClick={refreshHandler}
            >
              <BiRefresh />
            </span>
          </div>
        </div>
        <div className={styles.content}>
          <table id="customer-table">
            {customersData && (
              <thead>
                <tr>
                  {headerArray.map((item, index) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {customersData &&
                customersData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td><img src={item.profile_image} height="150px" /></td>
                      <td>
                        {item.name}
                      </td>
                      <td>{item.contact}</td>
                      <td>{item.alternate_no}</td>
                      <td>{item.email}</td>
                      <td>{item.address}</td>
                      <td>{item.country}</td>
                      <td>{item.state}</td>
                      <td>{item.postalcode}</td>
                      <td>
                        {item.joinedOnDate} | {item.joinedOnTime}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>Hey Admin. Welcome to dashboard ! </div>
      <div className={styles.top}>
        <div className={styles.card} onClick={() => setTopic("forms")}>
          <p> Form submissions</p>
          <p>
            <span className={styles.number}>
              {formData ? (
                formData.length
              ) : (
                <p style={{ fontSize: "1rem", fontWeight: "normal" }}>
                  loading...
                </p>
              )}
            </span>
            {/* <span>
              <MdKeyboardArrowUp />5
            </span> */}
          </p>
        </div>
        <div className={styles.card} onClick={() => setTopic("contacts")}>
          <p> Contact submissions</p>
          <p>
            <span className={styles.number}>
              {contactsData ? (
                contactsData.length
              ) : (
                <p style={{ fontSize: "1rem", fontWeight: "normal" }}>
                  loading...
                </p>
              )}
            </span>
            {/* <span>
              <MdKeyboardArrowDown />5
            </span> */}
          </p>
        </div>
        <div
          className={styles.card}
          onClick={() => setTopic("newsLetterSubmissions")}
        >
          <p> Newsletter submissions</p>
          <p>
            <span className={styles.number}>
              {" "}
              {newsLetterData ? (
                newsLetterData.length
              ) : (
                <p style={{ fontSize: "1rem", fontWeight: "normal" }}>
                  loading...
                </p>
              )}
            </span>
            {/* <span>
              <MdKeyboardArrowUp />5
            </span> */}
          </p>
        </div>
        <div className={styles.card} onClick={() => setTopic("customers")}>
          <p> Customer Details</p>
          <p>
            <span className={styles.number}>
              {customersData ? (
                customersData.length
              ) : (
                <p style={{ fontSize: "1rem", fontWeight: "normal" }}>
                  loading...
                </p>
              )}
            </span>
            {/* <span>
              <MdKeyboardArrowUp />5
            </span> */}
          </p>
        </div>
      </div>
      <div className={styles.bottom}>
        {topic === "forms"
          ? formSubmissionUI()
          : topic === "contacts"
          ? contactSubmissionUI()
          : topic === "newsLetterSubmissions"
          ? NewLetterSubmissionUI()
          : topic === "customers"
          ? CustomerListUI()
          : formSubmissionUI()}
      </div>
      <div className={styles.loadingContainer}>
        {showLoading && (
          <img src={loading} className={styles.loadingImg} alt="" />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
