export const BASE_URL = "https://demos24x7.xyz/1s/";
export const SIGNUP = 'signup.php';
export const LOGIN = 'login.php';
export const UPDATEPROFILE = 'update_profile.php';
export const FORMDATA = 'form_submit.php';
export const FORMDATASAVELATER = 'form_save_later.php';
export const DOCUMENTSPERSONALFILE = 'documents_personalfiles.php';
export const DOCUMENTSPROPERTYFILES = 'documents_propertyfiles.php';
export const DOCUMENTSCOMPANYFILES = 'documents_companyfiles.php';
export const DOCUMENTSOTHERFILES = 'documents_otherfiles.php';
export const UPLOADDOCUMENTS = 'upload_documents.php';
export const SUBMITEDFORMS = 'submited_forms.php';
export const GETFORMDATA = 'formdata.php';
export const GETCUSTOMERDATA = 'customerlist.php';
export const DELETEFORMDATE = 'deleteform.php';
export const DOCUMENTLISTFORUSER = 'document_listfor_user.php';
export const DELETEDOCUMENTS = 'delete_document.php';
export const SINGLEFORMDETAILS = 'single_form_details.php';
export const FORMDATAUPDATE = 'formdataupdate.php';
export const DOCUMENTSFORADMIN = 'documentsforadmin.php';

export const CALLMEFUNCTION = 'callme.php';

 
 