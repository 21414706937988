import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesB6 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Using Multiple Property As Security{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          As mentioned, the higher the number of properties that can be used as
          security on a bridging loan, the lower the potential interest rates
          applied to that loan. The best interest rates can be found on those
          loans with 50% LTV or below, with increased rates the higher the
          percentage, up to 70% LTV for regulated loans, and 75% unregulated
          bridging finance.
        </p>
        <p>
          Wherever possible it is advisable to choose as lengthy a bridging loan
          repayment term as possible, in order to give enough time for the exit
          strategy to come to fruition. Depending on the type of loan that has
          been taken out, monthly payments can either be made, or interest
          rolled and paid upon completion of the loan term when the principal
          loan sum is also being repaid.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesB6;
