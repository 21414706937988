import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesC4 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        A Fast and Effective Solution to Inherited Property Problems{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <p>
          In the UK 40% tax is due on any inherited property that is worth a
          value of £425,000 or more if the property in question is left in the
          will to the children of the deceased. This bill is required by law to
          be paid within a 6-month period, but if there are multiple owners of a
          property (2 or more children have inherited the property), it can
          quickly become a murky and complicated affair to settle.
        </p>
        <p>
          Our access to a range of bridging loan providers allows for a fast
          solution to your problems if you have inherited a property with your
          siblings. It provides the funds to buy your siblings share of the
          property, and to settle any debts, before selling the property at a
          later date.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesC4;
