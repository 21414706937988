import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./PageNotFound.module.scss";
import { BiError } from "react-icons/bi";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.pageNotFound}>
      <p>
        {" "}
        <BiError className={styles.icon} />
        This page is currently under construction !!!
      </p>

      <div className={styles.links}>
        <Link to="#" onClick={() => navigate(-1)}>
          Go back
        </Link>
        <Link to="/">Go to Home</Link>
      </div>
    </div>
  );
};

export default PageNotFound;
