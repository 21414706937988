import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgages2 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Benefits of Large Development Finance
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          When compared with other types of funding options, including smaller
          bridging loans, is that it provides the capability to secure
          large-scale projects up to a massive valuation. At the beginning of
          the application process, the funds will be secured against the value
          of the project as a whole (whether that is through the valuation of
          the plot of land or other security if that is available). In some
          cases it is possible to secure 100% of the costs required for
          construction, but otherwise there is an option of a staggered release
          of funds as a project progresses and the plot of land becomes more
          valuable.
        </p>
        <p>
          {" "}
          .As well as the obvious advantage of offering the largest possible
          funding options for large-scale development projects, this type of
          finance is perfect for uninhabitable and derelict property that would
          otherwise struggle to be considered as acceptable security.
        </p>
        <p>
          {" "}
          100% Development Finance – As mentioned there is the possibility of
          100% of the construction funds being released prior to the project
          beginning. This is an option for those projects where additional
          security can be offered. In most common cases, we arrange for 75% GDV
          and up to 100% of the land cost to be provided as development finance,
          or guide you to find the right type of joint venture funding or
          mezzanine finance.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgages2;
