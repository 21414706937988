import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/HeroSection.module.scss";
import { FiPhoneCall } from "react-icons/fi";

const HeroSection = () => {
  const navigate = useNavigate();
  const formLinkHandler = () => {
    navigate("/form");
  };
  const devformLinkHandler = () => {
    navigate("/devfinanceform");
  };
  return (
    <div className={styles.heroSection}>
      <div className={styles.top}>
        <div className={styles.topHead}>
          <p className={styles.adivceText}>GET EXPERT ADVICE</p>
          <p className={styles.mainText}>
            From The UK’s Most Dynamic & Versatile Specialist
          </p>
        </div>

        <p className={styles.subText}>
          Simply apply online now for a fast, accurate, dependable offer
        </p>
        <div className={styles.btns}>
          <p
            to="#"
            onClick={formLinkHandler}
            className={styles.loans}
            style={{ fontSize: "20px" }}
          >
            <span>
              {" "}
              <b>Bridging Loans</b>
            </span>
          </p>
          <p
            to="#"
            onClick={devformLinkHandler}
            className={styles.finance}
            style={{ fontSize: "20px" }}
          >
            <span>
              <b>Development Finance</b>
            </span>
          </p>
        </div>
        <p className={styles.weekText}>Or call us</p>

        <p className={styles.number}>
          {" "}
          <a href="tel:0208 146 9888">
            <FiPhoneCall className={styles.phoneIcon} />
            0208 146 9888
          </a>
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
