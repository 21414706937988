import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerC2 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : title === "Resources"
          ? styles.reSources
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        Bridging Loans for over 60s{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <ul>
          <li>Bridging Finance for Applicants Over the Age of 60</li>
          <li>
            Access to Niche Funders for All Financial and Personal Circumstances
          </li>
          <li>
            Loans Available for Many Different Purposes, such as Home
            Refurbishment and Property Purchase
          </li>
          <li>
            Some Funders Won’t Work with Bankrupt Individuals or Applicants with
            Mortgage Arrears
          </li>
          <li>
            Property Accepted as Security for First, Second, or even Third
            Charge{" "}
          </li>
          <li>Rates from 0.75% per Month</li>
          <li>Up to 85% LTV</li>
        </ul>
        <div className={styles.text}>
          <p>
            If you are over the age of 60 and have struggled to secure financial
            support from traditional lending streams as you are viewed as a
            high-risk borrower, why not call Bridging 365 today. Our friendly
            customer service team understands how best to help applicants for
            bridging loans, with a wide range of circumstances met. Bridging
            loans for over 60s shouldn’t be impossible, and that’s why we have
            teamed up with such a wide variety of funders within the bridging
            finance market.{" "}
          </p>
          <p>
            an extension in the term length and options to mitigate any monthly
            repayments by adding that amount to the loan itself. The option to
            apply for 100% of development is available in certain scenarios,
            however to achieve competitive rates it is important to keep the LTC
            to a reasonable level.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerC2;
