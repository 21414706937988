import React, { useEffect } from "react";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import styles from "./ContactPage.module.scss";
const ContactPage = () => {
  useEffect(() => {
    document.title = "Bridging 365 | Stop Repossessions";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.contactPage}>
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactPage;
