import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerC1 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : title === "Resources"
          ? styles.reSources
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        Bridging Loans for Bankrupt{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <ul>
          <li>
            Large Panel of Bridging Loan Funders to Cover All Applicant
            Scenarios
          </li>
          <li>3-Hour Turnaround on Decisions in Most Cases</li>
          <li>Funds Released within 2-days in Urgent Applications</li>
          <li>Rates from 0.75% for Unregulated Bridging Loans</li>
          <li>Financial Solution for Applicants with Bankruptcy </li>
        </ul>
        <div className={styles.text}>
          <p>
            Bridging loans for bankrupt individuals are possible, with Bridging
            365 having access to a large number of funders throughout the UK and
            abroad. Having built our reputation over a number of years and
            creating long-lasting relationships with a wide variety of funders,
            we know we can help those applicants with bad credit, or even
            bankruptcy, achieve their goals with the assistance of an
            unregulated bridging loan.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerC1;
