import React, { useEffect, useState } from "react";
// import About from "../../components/About";
import About from "../../components/About";
import Footer from "../../components/Footer";
import NewsLetterSignUp from "../../components/NewsLetterSignUp";
import styles from "./UploadedDocumentsDashboard.module.scss";
import axios from 'axios';
import * as myConstClass from '../../components/FileWithConstants';
import $ from 'jquery';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/DeleteModalForDocument";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { GrFormView } from 'react-icons/gr';
import { AiOutlineDownload, AiFillEdit } from 'react-icons/ai';



const UploadedDocumentsDashboard = () => {
    const { state } = useLocation();

    useEffect(() => {
        getData();
        document.title = "Bridging 365 | Stop Repossessions";
        window.scrollTo(0, 0);
    }, []);

    const refreshHandler = () => {
        window.location.reload();
    }

    const [personalDocuments, setPersonalDocuments] = useState([])
    const [propertyDocuments, setPropertyDocuments] = useState([])
    const [companyDocuments, setCompanyDocuments] = useState([])
    const [otherDocuments, setOtherDocuments] = useState([])


    const getData = () => {
        console.log("get data called");
        const userid = state[0].login_userid;
        let formDataUploadDocuments = new FormData();
        formDataUploadDocuments.append("userid", userid);
        var documetsforadmin = myConstClass.BASE_URL + "" + myConstClass.DOCUMENTSFORADMIN;

        axios.post(documetsforadmin, formDataUploadDocuments)
            .then(res => {

                var personalDocumentsVar = res.data.respersonal;
                console.log("personalDocumentsVar", personalDocumentsVar)
                setPersonalDocuments(personalDocumentsVar);


                var propertyDocumentsVar = res.data.resproperty;
                //this.setState({propertyDocuments : propertyDocumentsVar})
                setPropertyDocuments(propertyDocumentsVar);

                var companyDocumentsVar = res.data.rescompany;
                //this.setState({companyDocuments : companyDocumentsVar})
                setCompanyDocuments(companyDocumentsVar);

                var otherDocumentsVar = res.data.resothers;
                //this.setState({otherDocuments : otherDocumentsVar})
                setOtherDocuments(otherDocumentsVar);

                //personalDocuments = res.data.respersonal;
                //propertyDocuments = res.data.resproperty;
                //companyDocuments = res.data.rescompany;
                //otherDocuments = res.data.resothers;
            });
    }
    console.log(personalDocuments)



    return (
        <div className={styles.uploadedDocuments}>
            <p className={styles.header}>Uploaded Documents</p>
            <p className={styles.header1} style={{ backgroundColor: '#ff7232' }}>Personal</p>
            <div className={styles.documents}>
                {personalDocuments.length > 0 ?
                    personalDocuments.map(item => (
                        <div className={styles.document}>
                            <p className={styles.head}>
                                <a href={(item.document_url)} title="download" target="_blank" rel="noreferrer"><AiOutlineDownload /></a>
                                <DeleteModal title="delete" formId={item.document_id} redirecTo={1} onClick={refreshHandler} />
                            </p>
                            <p>Document {item.document_id}</p>
                        </div>
                    )) : <p className={styles.header}>No Records found</p>
                }
            </div>
            <p className={styles.header1} style={{ backgroundColor: '#673ab7' }}>Property Documents</p>

            <div className={styles.documents}>
                {
                    propertyDocuments.length > 0 ?
                        propertyDocuments.map(item => (<div className={styles.document}>
                            <p className={styles.head}>
                                <a href={(item.document_url)} title="download" target="_blank" rel="noreferrer"><AiOutlineDownload /></a>
                                <DeleteModal title="delete" formId={item.document_id} redirecTo={1} onClick={refreshHandler} />
                            </p>
                            <p>Document {item.document_id}</p>
                        </div>)) : <p className={styles.header}>No Records found</p>
                }
            </div>
            <p className={styles.header1} style={{ backgroundColor: '#4caf50' }}>Company Documents</p>

            <div className={styles.documents}>
                {
                    companyDocuments.length > 0 ?
                        companyDocuments.map(item => (<div className={styles.document}>
                            <p className={styles.head}>
                                <a href={(item.document_url)} title="download" target="_blank" rel="noreferrer"><AiOutlineDownload /></a>
                                <DeleteModal title="delete" formId={item.document_id} redirecTo={1} onClick={refreshHandler} />
                            </p>
                            <p>Document {item.document_id}</p>
                        </div>)) : <p className={styles.header}>No Records found</p>
                }
            </div>
            <p className={styles.header1} style={{ backgroundColor: '#002d72' }}>Other Documents</p>

            <div className={styles.documents}>
                {
                    otherDocuments.length > 0 ?
                        otherDocuments.map(item => (<div className={styles.document}>
                            <p className={styles.head}>

                                <a href={(item.document_url)} title="download" target="_blank" rel="noreferrer"><AiOutlineDownload /></a>
                                <DeleteModal title="delete" formId={item.document_id} redirecTo={1} onClick={refreshHandler} />
                            </p>
                            <p>Document {item.document_id}</p>
                        </div>)) : <p className={styles.header}>No Records found</p>
                }
            </div>
        </div>
    );
};

export default UploadedDocumentsDashboard;
