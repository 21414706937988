import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesS1 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Why A Bridging Loan Can Help You Stop a Repossession Order?{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          A bridging loan is the perfect answer to putting a stop on a
          repossession order against your home. Our team can put together an
          exhaustive financial plan of action that pays off the mortgage arrears
          on your home, keeping your original lender happy and giving you that
          breathing pace to take a step back and be proactive with your next
          steps regarding your property. Here, we look to answer some questions
          you may have:
        </p>
        <p>
          {" "}
          <strong>
            {" "}
            Do You Offer Financial Assistance to Applicants with Bad Credit?{" "}
          </strong>
          Although some of the funders on our panel do not agree to lend to
          those with mortgage arrears, the reason we have built up such a large
          selection of funders is that we wanted to be able to help those
          seeking a bridging loan with bad credit. We do have the ability to
          pair each client up with a suitable lender, even in the most seemingly
          dire financial situations.
        </p>
        <p>
          {" "}
          <strong>
            Can Bridging 365 Give You a Fast Decision on an Application?{" "}
          </strong>
          Almost certainly! With every application that lands on our desk we aim
          to provide an answer within a 3-hour window of receipt of the forms.
          There are occasions where we need to dig a little bit deeper and this
          is where longer conversations with you will come into play.
        </p>
        <p>
          <strong>
            {" "}
            How Quickly are Funds Released for a Successful Bridging Loan
            Application?
          </strong>
          Once we have stated that an application has been successful we aim to
          have the funds issued as soon as possible. When it comes to helping
          applicants stop a repossession order on a property, it is vital that
          funds are available urgently. In cases such as this we can put in
          motion a release of funds within a 48-hour period.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesS1;
