import styles from "../../DevFinanceForm.module.scss";
import { AnswerText, AnswerTextarea, AnswerDropdown } from "../Answer";
import Question from "../Question";

const AddProperty = ({ formData, setFormData, name, setSecurityProgress }) => {
  return (
    <>
      <div className={styles.inputBox}>
        <Question question="Address of the security?" section="section2" />
        <AnswerText
          type="text"
          placeholder="Address"
          value={formData[name].addressOfSecurity}
          onChange={(e) => {
            if (!formData[name].addressOfSecurity)
              setSecurityProgress((progress) => progress + 7);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                addressOfSecurity: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question question="Address line 2 (optional)" section="section2" />
        <AnswerText
          type="text"
          placeholder="Address line 2"
          value={formData[name].addresssLine2}
          onChange={(e) =>
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                addresssLine2: e.target.value,
              },
            })
          }
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Which city or town is the property located in?"
          section="section2"
        />
        <AnswerText
          type="text"
          placeholder="City/Town"
          value={formData[name].propertyCityOrTown}
          onChange={(e) => {
            if (!formData[name].propertyCityOrTown)
              setSecurityProgress((progress) => progress + 7);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                propertyCityOrTown: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question question="What is the postcode?" section="section2" />
        <AnswerText
          type="text"
          placeholder="postcode"
          value={formData[name].postcode}
          onChange={(e) => {
            if (!formData[name].postcode)
              setSecurityProgress((progress) => progress + 7);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                postcode: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="What is the tenure of the property?"
          section="section2"
        />
        <AnswerText
          type="text"
          placeholder="Tenure"
          value={formData[name].propertyTenure}
          onChange={(e) => {
            if (!formData[name].propertyTenure)
              setSecurityProgress((progress) => progress + 7);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                propertyTenure: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question question="Please describe the asset:" section="section2" />
        <AnswerTextarea
          placeholder="Asset Description"
          value={formData[name].assetDescription}
          onChange={(e) => {
            if (!formData[name].assetDescription)
              setSecurityProgress((progress) => progress + 7);
            setFormData({
              ...formData,
              [name]: {
                ...formData[name],
                assetDescription: e.target.value,
              },
            });
          }}
        />
      </div>
    </>
  );
};

export default AddProperty;
