import classNames from "classnames";
import React from "react";
import styles from "../styles/About.module.scss";
import Our_approach from "./animations/Our_approach";
import Our_process from "./animations/Our_process";
import Our_vision from "./animations/Our_vision";


const aboutDataArray = [
  {
    id: 1,
    imgPath: "./images/Our_Vision.gif",
    lottie: <Our_vision />,
    title: "Our Vision",
    para1:
      "We believe lending should be fast and reliable, with no catches or hidden fees. Not only that, but we aim to provide it in the quickest possible time. ",
  },
  {
    id: 2,
    title: "Our Approach",
    imgPath: "./images/Our_Approach.gif",
    lottie: <Our_approach />,
    para1:
      "We use a system of asset backed lending, with no Due Diligence. Therefore cutting down on time and providing painless access to short-term funding.",
  },
  {
    id: 3,
    title: "Our Process",
    imgPath: "./images/Our_Process.gif",
    lottie: <Our_process/>,
    para1:
      "It is a simple process to apply for a bridging loan through Bridging365. You will find an easy to use application form. If you wish to acquire a bridging loan to help purchase a property whilst stuck in a chain, buy a property at auction, pay for refurbishments of a property you own or live in, want to pay off inheritance tax or for many other reasons, we are here to help. Once you have filled out the above forms our team will check all the relevant calculations and hope to provide you with an answer within 3 hours. In urgent cases, we can even release the funds to successful applicants within 48-hours of the decision being made.",
  },
];

const About = () => {
 
  return (
    <div className={styles.about}>
      <p className={styles.heading}>About Us</p>
      <p className={styles.subheading}>
        We are proud to be the UK's no. 1 online principle bridging lender and a spearhead in lifting the veil for full transparency on loans. We offer industry leading rates and aim to provide you with funding faster than anywhere else. So you can get up and running asap.
      </p>

      {aboutDataArray.map((item) => {
        return (
          <div
            className={classNames(
              styles.aboutItem,
              item.id % 2 === 0 ? styles.even : styles.odd
            )}
            key={item.id}
          >
            {/* <img src={item.imgPath} alt="" /> */}
            <div style={{width: '40%'}}>
              {item.lottie}
            </div>
            <div className={styles.text}>
              <p className={styles.title}>{item.title}</p>
              <p>{item.para1}</p>
            </div>
          </div>
        );
      })}

    </div>
  );
};

export default About;