import React, { useEffect } from "react";
import styles from "./FormSuccessPage.module.scss";
import { useLocation, useNavigate } from "react-router-dom"
const FormSuccessPage = () => {
    useEffect(() => {
        document.title = "Bridging 365 | Form submitted successfully";
    }, []);
    const navigate = useNavigate()
    const location = useLocation();
    console.log(location.state)
    return (
        <div className={styles.formSuccessPage}>
            <p className={styles.head}>{location.state.title}</p>
            <p className={styles.subHead}>{location.state.subhead}</p>
            <p className={styles.link} onClick={() => navigate("/", { replace: true })} to="/">Back to Home</p>
        </div>
    );
};

export default FormSuccessPage;
