import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerC3 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : title === "Resources"
          ? styles.reSources
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        Bridging Loans for Splitting Title Deeds{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <ul>
          <li>Bridging Loans from 0.29%</li>
          <li>Huge Panel of over 90 Funders</li>
          <li>70% LTV + for Most Applicants</li>
          <li>Funds Released within 48-Hours</li>
          <li>Bridging Finance for Inherited Properties from 13 Funders</li>
        </ul>
        <div className={styles.text}>
          <p>
            Sometimes it just doesn’t work out when you are living with someone.
            If you have bought a house together and have separated, it can be
            hard to know what the next steps to take should be.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerC3;
