import React from "react";
import styles from "./AcceptedDocuments.module.scss";
import classNames from "classnames";

const AcceptedDocuments = () => {
  return (
    <div className={styles.acceptedDocuments}>
      <p className={styles.header}>Accepted documents</p>
      <div className={styles.section}>
        <p className={styles.sectionHead} style={{ backgroundColor:'#ff7233'}}>Personal </p>
        <div className={styles.sectionItems}>
          <div className={styles.leftItem}>
            <p className={styles.leftItemHead} style={{ backgroundColor:'#ff7233'}}>Identity Documets</p>
            <ul>
              <li>Passport </li>
              <li>Driving Licence </li>
              <li>Birth Certificate </li>
            </ul>
          </div>
          <div className={styles.rightItem}>
            <p className={styles.rightItemHead} style={{ backgroundColor:'#ff7233'}}>Proof of Address (POA )</p>
            <ul>
              <li>Bank Statements {"(<3 months old)"}</li>
              <li>Mortgage Statement {"(<3 months old)"}</li>
              <li>Council Tax Bill (This is the best form of POA) </li>
              <li>Utility Bills {"( < 3 months old )"}</li>
              <li className={styles.sublist}>
                <ul>
                  <li>Acceptable</li>
                  <li>Electric</li>
                  <li>Gas </li>
                  <li>Landline Phone </li>
                </ul>
                <ul>
                  <li>Not Acceptable</li>
                  <li>Mobile Phone Bill</li>
                  <li>Catalogue </li>
                  <li>Home Shopping </li>
                  <li>Home Shopping </li>
                  <li>Sky, Virgin </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <p className={styles.sectionHead} style={{ backgroundColor:'#673ab7'}}>About Property </p>
        <div className={styles.sectionItems}>
          <div className={styles.leftItem}>
            <p className={styles.leftItemHead} style={{ backgroundColor:'#673ab7'}}>Redemption Amount</p>
            <ul>
              <li className={styles.sublist}>
                <ul>
                  <li>Acceptable</li>
                  <li>
                    Redemption Letter (dated in the last 30 days with daily
                    rate)
                  </li>
                  <li>
                    Email with Redemeption amount (dated in the last 30 days
                    with daily rate){" "}
                  </li>
                </ul>
                <ul>
                  <li>Not Acceptable</li>
                  <li>Mortgage Statement</li>
                  <li>Original Loan Documents </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className={styles.rightItem}>
            <p className={styles.rightItemHead} style={{ backgroundColor:'#673ab7'}}>Valuation</p>
            <ul>
              <li className={styles.sublist}>
                <ul>
                  <li>Acceptable</li>
                  <li>
                    Valuation from RICS registered Surveyor {"(<3 months old)"}{" "}
                    issued from another lender.
                  </li>
                </ul>
                <ul>
                  <li>Not Acceptable</li>
                  <li>
                    Valuation instructed by the client with non RICS registered
                    surveyor
                  </li>
                  <li>Desktop valuations (Maybe used as a guide) </li>
                  <li>Estate Agent Valuation</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className={styles.leftItem}>
            <p className={styles.leftItemHead} style={{ backgroundColor:'#673ab7'}}>Certificates</p>
            <ul>
              <li>
                Gas Safety Certificates (CP12)
                <ul style={{ paddingLeft: "1rem" }}>
                  <li>Certified by registered Gas Safety Engineers </li>
                  <li>Dated in the last 12m</li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                Electric (EICRS)
                <ul style={{ paddingLeft: "1rem" }}>
                  <li>
                    Has to be a NICEIC, ELECSA or NAPIT registered electrician
                  </li>
                  <li>Done in the last 3 years</li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                Energy Performance Certificates (EPC){" "}
                <ul style={{ paddingLeft: "1rem" }}>
                  <li>Done every 10 years</li>
                  <li>Qualified Home Energy Inspectors</li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                House with Multiple Occupancy (HMO Licence){" "}
                <ul style={{ paddingLeft: "1rem" }}>
                  <li>Issued by the local Council</li>
                  <li>
                    Required if at least 3 people live in the property who are
                    not from 1 family
                  </li>
                  <li>Issued every 5 years</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className={styles.rightItem}>
            <p className={styles.rightItemHead} style={{ backgroundColor:'#673ab7'}}>Valuation</p>
            <ul>
              <li>
                Building Insurance{" "}
                <ul style={{ paddingLeft: "1rem" }}>
                  <li>
                    Building insurance must be equal to the rebuild cost stated
                    in the valuation or the loan amount, which ever is higher.
                  </li>
                  <li>
                    The lender is to be named as the interested party (Details
                    issued by the solicitor)
                  </li>
                  <li>Must run to the end of the loan period</li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                Life insurance{" "}
                <ul style={{ paddingLeft: "1rem" }}>
                  <li>Recommended but not required</li>
                  <li>Must be at least the loan amount</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className={styles.leftItem}>
            <p className={styles.leftItemHead} style={{ backgroundColor:'#673ab7'}}>Tenacy Agreements</p>
            <ul>
              <li>
                Assured Short Hold Tenanacy Agreements (AST) No more than 12m{" "}
              </li>
              <li>If HMO eash individual must have an AST </li>
            </ul>
          </div>
          <div className={styles.rightItem}>
            <p className={styles.rightItemHead} style={{ backgroundColor:'#673ab7'}}>Tenancy Deposit Scheme (TDP)</p>
            <ul>
              <li>Held for each AST if deposit is taken</li>
              <li>Not required if no deposit</li>
              <li>Must be held with a goverment approved TP scheme. </li>
              <li>
                Most Common: 1. Deposit Protection Service, 2. MyDeposits
                (including deposits that were held by Capita) & 3.Tenancy
                Deposit Scheme.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={classNames(styles.section, styles.center)}>
        <p className={styles.sectionHead} style={{ backgroundColor:'#4ccb14'}}>
          Company Documents (Not always required)
        </p>
        <div className={styles.sectionItems}>
          <div className={styles.leftItem}>
            <p className={styles.leftItemHead} style={{ backgroundColor:'#4ccb14'}}>Companies Documents</p>
            <ul>
              <li>Company Certificate</li>
              <li>Company Bank Statements </li>
              <li>Other charge details</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptedDocuments;
