import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerB3 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Bridging Loans Nationwide{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li>Flexible Rates Starting from 1%</li>
          <li>
            85% LTV (100% LTV with additional security added to application)
          </li>
          <li>Fast Turnaround on Application and Release of Funds</li>
          <li>
            Large Panel of Varied Bridging 365 Available – Alternative to
            Traditional High Street Bridging 365
          </li>
          <li>Access to Regulated and Unregulated Bridging Loans</li>
          <li>
            We Accept Applicants with Mortgage Arrears, CCJs, Bankruptcy,
            Foreign Nationals, Ex Pats
          </li>
          <li>Funding for Commercial and Residential Properties</li>
        </ul>
        <div className={styles.text}>
          <p>
            Searching for Bridging Loans Nationwide will have brought you to
            this page, and we wanted to give you the finer details of what you
            can expect from our service should you move forward with an
            application for a bridging loan. Our service offers short-term
            funding that plugs the gap for the following circumstances:
          </p>
          <p>
            <ul>
              <li>Applicants stuck in a property chain</li>
              <li>
                Individuals looking to finance refurbishment of house in order
                to refinance or sell the property at a later date
              </li>
              <li>
                Businesses attempting to grow by extending workplace or moving
                to larger premises
              </li>
              <li>Family members who have inherited property in a will</li>
              <li>
                Applicants looking to pay off a large or unexpected tax bill
              </li>
            </ul>
          </p>
          <p>
            A bridging loan can help to plug the gap in finances that you need
            in an urgent situation, and bad credit shouldn’t be a barrier to you
            finding the help you need.
          </p>
          <p>
            We offer flexible and competitive rates (starting from 1%),
            utilising our expansive panel of bridging loan Bridging 365 that
            includes some speciality and niche providers. The traditional high
            street brand might not be able to help in a similar manner as in the
            past, and we wanted all applicants, no matter their personal
            financial circumstances and history, to be able to move forward with
            funding for a number of projects, both commercial and residential.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerB3;
