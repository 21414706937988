import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/ContactComponent.module.scss";
import contactGif from "../assets/images/contactGif.gif"
import TestAnimation from "./animations/TestAnimation";
const ContactComponent = ({ title }) => {
  return (
    <div className={styles.contactComponent}>
      <div className={styles.top}>
        <div className={styles.left}>
          <p className={styles.header}>Contact Bridging365.com</p>
          <p className={styles.topText}>
            The next steps are simple. You can either go directly and apply for a
            bridging loan online or we have a Bridging Loans Calculator where you
            can instantly see a general quote based on your circumstances and
            desired bridging loan amount and term. If you are ready to go ahead and
            apply for bridging finance through our service, we have a simple and
            easy online bridging loan application.
            <br></br>
            <ul> </ul>
          </p>
          <p className={styles.midText}>
            Alternatively if you would first like to speak to a member of our team
            in order to discuss the finer details of your circumstances before
            committing to an application, we are happy to do so. Just head on over
            to our Contact Us page and find the most suitable option for you.
          </p>
          
          <p className={styles.bottomText}>
            Bridging loans don’t have to be difficult, Bridging365.com’s team is
            geared toward helping people from all financial backgrounds and
            limitations gain access to short-term funding that can make a real
            difference.
          </p>
        </div>
        <div className={styles.right}>
          {/* <img src={contactGif} alt="" /> */}
          {/* <div style={{width: 450, height:410}}> */}
          <TestAnimation />
          {/* </div> */}
        </div>
      </div>


      <div className={styles.links}>
        <Link
          to="/bridgingloans"
          className={classNames(
            styles.contactLink,

            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                  ? styles.sRepo
                  : title === "Resources"
                    ? styles.reSources
                    : styles.home1
          )}
        >
          Bridging Loans
        </Link>
        <Link
          to="/developmentfinance"
          className={classNames(
            styles.contactLink,

            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                  ? styles.sRepo
                  : title === "Resources"
                    ? styles.reSources
                    : styles.home2
          )}
        >
          Development Finance
        </Link>
        <Link
          to="/stoprepossessions"
          className={classNames(
            styles.contactLink,

            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                  ? styles.sRepo
                  : title === "Resources"
                    ? styles.reSources
                    : styles.home3
          )}
        >
          Stop Repossessions
        </Link>
        <Link
          to="/rebridgingloans"
          className={classNames(
            styles.contactLink,

            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                  ? styles.sRepo
                  : title === "Resources"
                    ? styles.reSources
                    : styles.home4
          )}
        >
          Re-Bridging Loans
        </Link>
      </div>
    </div>
  );
};

export default ContactComponent;




// <div> {showLoading && ( className={styles.loadingImg}>
//         {/* <img src={contactGif} alt="" /> */}
//         <div> style={{width: 450, height:410}}>
//         <TestAnimation />
//          </div>
//         // {/* <img src={loading} className={styles.loadingImg} alt="" srcset="" /> */})}
//       </div>