import React, { useState } from "react";
import FAQCard from "./FAQCard";
import styles from "../styles/FAQ.module.scss";
import classNames from "classnames";
const FAQ = ({ onClick }) => {
  const faqs1List = [
    {
      q: "Are Bridging loans expensive?",
      a: "The short-term nature of bridging loans means that the interest is typically higher than on a regular mortgage. Interest is charged at a monthly rate rather than an annual percentage rate (APR) because they are designed to last only a few months",
    },
    {
      q: "What can bridging loans be used for?",
      a: `<ul><li>•To ‘bridge’ the gap between the sale of an old house and the purchase of a new one. You might hear this being called chain-breaking when buying a property.</li>
      <li>•	To purchase a property quickly, for example at a property auction or a discounted sale. </li>
      <li>•	To purchase a property that cannot be mortgaged. This might imply that in order to offer a conventional mortgage, a property will have to be refurbished, converted, or expanded.  </li>
      <li>  •	To help with bad credit credit. As your credit profile doesn't really play a part in this type of borrowing, It allows individuals to purchase the property and give themselves time to repair their credit. It’s important to understand the risks involved, but this could allow customers to re-mortgage once their credit history is acceptable to mortgage lenders.   </li>
      <li> •	Self-employed customers can use bridging finance as they improve they wait for their company accounts. This time could help them meet requirements for a residential mortgage lender. </li>
     <li> •	Bridging is a common practice among property investors when purchasing and refurbishing a property. This might be to sell it on (flipping) or make it ready for a longer-term Buy-to-Let Mortgage. </li>
      </ul>`,
    },
    {
      q: "What can I use for security?",
      a: "The bridging loan will be secured on the property you need the loan for. As a result, if you fail to make your bridging debt repayments, or fail to pay the loan back, the lender might be able to reclaim the property. ",
    },
    {
      q: "Who can take out a bridging loan?",
      a: "Anyone over the age of 18 years old is eligible to take out a bridging loan. Neither your credit status, excluding the bankrupt of course are eligible. Most bridging loans are done under a Limited company or an SPV, for tax and simplicity. ",
    },
    {
      q: "Can I be declined for a bridging loan?",
      a: "We'll take a look at your situation and will present you with a responsible source of financing. Typically, you'll need to be at least 18 years old and own (or be purchasing) a property that the loan may be secured against. Because of the dedication of our specialists, we provide you with the greatest possible opportunity of being accepted for a bridging loan..",
    },
    {
      q: "Will applying for a bridging loan affect my credit?",
      a: "Our initial ‘soft’ search won’t impact your credit score. Once you’ve chosen the loan you want to apply for, then some funding lines do conduct a full credit check which will leave a footprint on your file. As with all loan products, if you take out a bridging loan and you stop making payments on your loan repayment, this will affect your credit score.",
    },
    {
      q: "Can I repay a bridging loan Early?",
      a: "You can repay your loan early. But, make sure you speak to us before you do, as some funding lines charge an early repayment charge (ERC). Typically, 1 months interest.",
    },
    {
      q: "How long does the process take?",
      a: "At Bridging 365, we pride ourselves on speed. Our quickest loan was done in 14 hours, but typically it can take up to 10 days.",
    },
    {
      q: "Are the interest rates fixed on my bridging loan?",
      a: "Typically Yes, we fix the interest rate at the start of the loan. ",
    },
    {
      q: "Is a bridging loan regulated?",
      a: "The Financial Conduct Authority (FCA) will regulate if you are using a bridging loan secured against your home or one that you are about to buy. If the borrower (you) or a related family member will live in the property to offer extra assurance in the event of a problem, a bridging loan will be regulated. The bridging loan will be unregulated if it is being used for commercial reasons and no one in your family will ever live in the house.",
    },
  ];
  const faqs2List = [
    {
      q: "What are the Average Interest Rates on Property Development Finance?",
      a: "Depending on the project, interest rates can change dramatically. Rates are dependent on a number of factors including, size of the development, the experience of the developer, and the amount of money you are putting in. In short the more risk associated with the loan, the higher the rate. Typically a first time developer on a small project, i.e. less than £500k would expect to pay around 1% a month. This in comparison to a season developer on a large scale project, i.e. over £10m would pay around 0.25% per month. Get in touch and we will help find a rate that works for you.Typical interest rates for the most viable projects start at 6%  pa.",
    },
    {
      q: "How Long Does a Development Finance Application Take?",
      a: "Applications time are dependent on how you work with us. If you have all the relevant documents e.g.. Schedule of works, Schedule of costs etc and you are organised, then we can do the initial underwrite in a few hours. However, the time taken for the surveyors and the time taken for the solicitors to finish talking can add up. So we do say, give yourself at least 3/4 weeks before you need the funds. Most clients apply well in advance, typically 3 months before they need the funds.",
    },
    {
      q: "When Do I Pay Back my Property Development Finance Loan?",
      a: `<ul> Funders offer varying terms to suit your needs. While some providers may allow interim interest payments, you usually do not have to pay back anything until the project is finished.
      Most clients prefer the interest to be rolled up into the loan. So the total capital plus interest is repaid when the development has been sold.
      Can I Borrow From Development Finance Lenders as a New Property Developer?
      We pride ourselves in being able to provide funding for all that need our help. So if you are a first time developer, please do call and we will be happy to guide your through the entire process. 
      What types of property developments schemes can I apply for? 
      We are happy to claim that due to our extensive access to various funding lines we cater for all types of developments, including, but not limited to: 
      <li>•	New builds </li>
      <li>•	Residential and commercial conversions</li>
      <li>•	Mixed-use properties</li>
      <li>•	Environmentally friendly builds</li>
      <li>•	Regulated and unregulated developments</li>
      <li> •	Refurbishment projects</li>
      <li>•	Farms</li>
      <li>•	Hotels</li>
      <li>•	Student Accommodations (PBSA) One of our favourites (Rates starting at 0.45% p/m)</li>
      <li>•	Guest Houses</li>
      <li> •	Pubs </li>
      <li>•	Shopping centres </li>
      And the list goes on 
      This is our day to day, so not only can we help, but we enjoy the process and love the positive praise we get. 
      </ul>`,
    },
    {
      q: "Can I Get Development Finance Even if I Don't Have Planning Permission?",
      a: `<ul><li>While it is simpler to arrange the loan with planning permission, obtaining a bridge or development loan without it does not have to be difficult. Just call us or simply apply online.</li>

      <li>Planning can increase the valuation estimate of a plot by as much as 30%. Therefore, you're likely to be able to borrow more, and a higher proportion of the cost of the development.
      <li></n></li>
      As approved planning can increase the value of the land by double or higher, property developers often use a bridge when buying the land, then flip it across to development finance once planning is granted.  The advantage of doing this is you will be able to borrow at a higher LTV (Loan To Value) thus decreasing your LTC (Loan To Cost). Talk to us today and we can guide you through this process.</li>
      </ul>`,
    },
    {
      q: "How Soon Should I Apply for Property Development Finance?",
      a: `In short, the sooner the better. By working with our time, we can help lay out the timetable of when you will need the funds.

      If you are buying the land, and there's no planning in place, then we recommend a bridge first. If not then organising the development loan is best, as you are probably ready to start the build.
      `,
    },
    {
      q: "What Project Costs Do I Need to Include on a Development Finance Application?",
      a: `<ul>Keeping tight control of your costs is essential - and any lender will want to see that you've built in contingencies, and included all of the overheads associated with financing a UK property development.
      <li>Those include:</li>
      <li>Arrangement fees anticipated from your lender - these are usually around one %, but a lot depends on how much deposit you're putting down, and the lender you choose.
      Exit fees, which can be chargeable by some development finance lenders depending on the amount of the loan, and the end value of the development.</li>
      <li>Interest charges - you'll pay these usually every month, but based only on the amount of borrowing you have drawn down from your development finance facility thus far.
      Professional fees include solicitors, architects, project managers and of course your contractors carrying out the physical building work.</li>
      Although you might not know the exact cost for every element of your project, it's essential to include provisions and estimates to ensure the development finance value covers the full requirement.</ul>
      `,
    },
    {
      q: "Should I Include a Contingency Figure in My Development Finance Budget?",
      a: `<ul>Absolutely! Contingencies are expected, since it's never possible to know every precise delay, material uplift or extra labour charge,
       <li><n>for example.</li>

      Most experienced property developers will build in between 15% and 20% across their whole budget to ensure they've covered any unforeseen expenses.

      Lenders will usually query any applicant who hasn't included a contingency, since this leaves zero wiggle room to cope with extra costs that typically occur at least once or twice.
      </ul>`,
    },
    {
      q: "How Do Building Regulations Impact a New Property Development Project?",
      a: `Building regulations are different from planning permission, and something you'll need to comply with separately.
      
      In short, the regulations mean that every new development or construction must be built to national standards to ensure they are safe for people living in them, and travelling nearby.

      Most buildings are covered, and you can apply for Building Regulations Approval via the relevant local council. Usually, that means submitting a full plans application, and then receiving visits from an inspector at key stages.

      Some contractors will manage the building regulations process, and provide certifications that everything is compliant - but you need to check that from the outset.

      If the builder isn't managing building regulations inspections, you will be liable as the property owner, and in the worst case scenario could be served with an enforcement notice to correct any aspects of the build that don't meet the health and safety standards.
      `,
    },
  ];
  const faqs3List = [
    {
      q: "Lender contacts you about mortgage arrears.",
      a: '<ul>They will give you an opportunity to make up the missed payments. And look at the following:</li> <li> •  your income and outgoings</li> <li> •  your debts</li> This is done to help you prepare a financial statement. You may be able to agree an affordable repayment plan with your lender. Alternatively, you might decide that your mortgage is unaffordable and ask the lender for time to sell your home.</ul>',
    },
    {
      q: "Lender starts court action ",
      a: "They must provide you with a list of all missed payments, the total level of arrears and the outstanding mortgage debt before they apply to court. As well as all communications and how they have worked with you to help resolve the debt.",
    },
    {
      q: "The court sends you paperwork",
      a: "<ul>There are 2 main documents to look out for: <li>•	Claim and defence form - Your lender has applied for a possession order.</li> <li>•	Notice of possession hearing  - The court has arranged a hearing for you to attend.</li> Make sure you complete and return these forms</ul>",
    },
    {
      q: "Possession hearing ",
      a: "It's very important to attend the hearing, If you do not turn up, the court will probably make an outright possession order and you could lose your home.",
    },
    {
      q: "The court decides",
      a: "<ul>There are 2 types of possession order: <li>•	An outright order - An outright possession order is very serious. It sets a date for you to leave your home. This could be as soon as 4 weeks after the hearing.</li> <li>•	A suspended order -  A suspended order allows you to stay in your home on the terms set by the court. You have to pay a set amount on top of your normal monthly mortgage payment.</li> Sometimes the lender's repossession action is dismissed or adjourned at the hearing.</ul>",
    },
    {
      q: "Bailiffs can be involved",
      a: "<ul>Your lender can ask bailiffs to carry out an eviction if the court has made: <li>•	an outright order and the date for possession has passed </li> <li>•	a suspended order and you break the terms of the order</li> Your lender must apply for an eviction warrant from the court. They must send a notice to your home to say they've done this. You could be evicted 14 days after this notice unless you act.</ul>",
    },
    {
      q: "Sale of your home by the lender",
      a: "If an eviction takes place, the mortgage lender will sell your home.After the sale, the lender and any other secured creditors get their money, and you receive anything left over.You may have to pay off any mortgage shortfall to the lender if what you owe is more than the amount the property sells for.",
    },
    {
      q: "How to suspend a bailiff's warrant",
      a: "<ul>You can ask the court to stop the eviction if you can show that you can:  <li>•	afford your monthly mortgage payment </li> <li> •	pay off the arrears by the end of the mortgage term.</li> You must apply to the court on Form N244 and provide evidence of a reasonable repayment plan. It costs £50 unless you apply for help with court fees because you're on a low income. <li>You should usually apply at least 3 days before the eviction date and tell your lender that you have done so.  The court may agree to suspend a warrant even on the actual day of the eviction but it's very risky to leave it this late. Phone the bailiffs first thing and tell them you've applied to suspend the warrant. If you do not tell them, the eviction could happen while you're at court.</li></ul>",
    },
    {
      q: "Repossession rules mortgage lenders must follow",
      a: `<ul>Lender must offer an alternatives to repossession. Only if you fail to come to an agreement they then proceed to court action. 
With your agreement the lender should consider whether to:
<li>•	delay interest payments</li>
<li>•	extend your mortgage term</li>
<li>•	change the type of mortgage you have</li>
<li>•	add the arrears onto your total mortgage debt</li>
They should allow you time to sell your home if you cannot come to a repayment arrangement.
They must keep records of their contact with you including phone calls where arrears or charges are discussed.</ul>
`,
    },
    {
      q: "What they must do before starting court action",
      a: `<ul>The lender must provide you with the following information:
      <li> •	a list of missed payments</li>
      <li>•	outstanding mortgage debt</li>
      <li> •	total amount of arrears and charges</li>
      They must inform you that they are starting repossession action and tell you to contact the council for homeless help.</ul>
      `,
    },
    {
      q: "How long does it take to repossess a house UK?",
      a: `Typically around 1 to 2 months for unregulated bridging loans and 5 to 6 months for regulated mortgage contracts.`,
    },
    {
      q: "How can we help?",
      a: `We can arrange an emergency bridging loan to pay off the debts in full or part dependant on your circumstances. We have arranged loans ion less than a day in the past. `,
    },
    {
      q: "How long does it take?",
      a: `We understand that speed is essential, so we pull out all the stops in getting the loan completed. As long as we have a lender who is looking to settle the debts and a borrow looking to borrow, we wil complete the loan in record time. Typically we take between 1-5 days to complete, but we stress, we will do everything we can to complete the loan in the time you have. `,
    },
    {
      q: "How much does it cost?",
      a: `As you’d expect to work on this type of deadline and to organise this type of loan, we would need to charge a little more. But we keep the costs down. We charge a 2% fee and keep the rate sub 1.25% a month. Typically lending at 1% a month, however we have had to lend at 1.5% per month on a case that needed 85% LTV and we had 3 days to complete. We did the deal and the client managed to restructure their finance and kept the property. `,
    },
  ];
  const faqs4List = [
    {
      q: "Are Bridging loans expensive?",
      a: "The short-term nature of bridging loans means that the interest is typically higher than on a regular mortgage. Interest is charged at a monthly rate rather than an annual percentage rate (APR) because they are designed to last only a few months",
    },
    {
      q: "What can bridging loans be used for?",
      a: `<ul><li>•	To ‘bridge’ the gap between the sale of an old house and the purchase of a new one. You might hear this being called chain-breaking when buying a property.</li>
      <li>•	To purchase a property quickly, for example at a property auction or a discounted sale. </li>
      <li>•	To purchase a property that cannot be mortgaged. This might imply that in order to offer a conventional mortgage, a property will have to be refurbished, converted, or expanded. </li> 
      <li>•	To help with bad credit credit. As your credit profile doesn't really play a part in this type of borrowing, It allows individuals to purchase the property and give themselves time to repair their credit. It’s important to understand the risks involved, but this could allow customers to re-mortgage once their credit history is acceptable to mortgage lenders.   </li>
      <li>•	Self-employed customers can use bridging finance as they improve they wait for their company accounts. This time could help them meet requirements for a residential mortgage lender.</li>
      <li>•	Bridging is a common practice among property investors when purchasing and refurbishing a property. This might be to sell it on (flipping) or make it ready for a longer-term Buy-to-Let Mortgage.</li></ul>
      `,
    },
    {
      q: "What can I use for security?",
      a: "The bridging loan will be secured on the property you need the loan for. As a result, if you fail to make your bridging debt repayments, or fail to pay the loan back, the lender might be able to reclaim the property. ",
    },
    {
      q: "Who can take out a bridging loan?",
      a: "Anyone over the age of 18 years old is eligible to take out a bridging loan. Neither your credit status, excluding the bankrupt of course are eligible. Most bridging loans are done under a Limited company or an SPV, for tax and simplicity. ",
    },
    {
      q: "Can I be declined for a bridging loan?",
      a: "We'll take a look at your situation and will present you with a responsible source of financing. Typically, you'll need to be at least 18 years old and own (or be purchasing) a property that the loan may be secured against. Because of the dedication of our specialists, we provide you with the greatest possible opportunity of being accepted for a bridging loan..",
    },
    {
      q: "Will applying for a bridging loan affect my credit?",
      a: "Our initial ‘soft’ search won’t impact your credit score. Once you’ve chosen the loan you want to apply for, then some funding lines do conduct a full credit check which will leave a footprint on your file. As with all loan products, if you take out a bridging loan and you stop making payments on your loan repayment, this will affect your credit score.",
    },
    {
      q: "Can I repay a bridging loan Early?",
      a: "You can repay your loan early. But, make sure you speak to us before you do, as some funding lines charge an early repayment charge (ERC). Typically, 1 months interest.",
    },
    {
      q: "How long does the process take?",
      a: "At Bridging 365, we pride ourselves on speed. Our quickest loan was done in 14 hours, but typically it can take up to 10 days.",
    },
    {
      q: "Are the interest rates fixed on my bridging loan?",
      a: "Typically Yes, we fix the interest rate at the start of the loan. ",
    },
    {
      q: "Is a bridging loan regulated?",
      a: "The Financial Conduct Authority (FCA) will regulate if you are using a bridging loan secured against your home or one that you are about to buy. If the borrower (you) or a related family member will live in the property to offer extra assurance in the event of a problem, a bridging loan will be regulated. The bridging loan will be unregulated if it is being used for commercial reasons and no one in your family will ever live in the house.",
    },
  ];
  const [section, setSection] = useState("");
  const finalListArray =
    section === "bLoans"
      ? faqs1List
      : section === "dFinance"
      ? faqs2List
      : section === "sRepo"
      ? faqs3List
      : section === "reBridging"
      ? faqs4List
      : faqs1List;
  return (
    <div>
      <section className={styles.faqsSecondary}>
        <div className={styles.faqsHeader}>
          <h1>Frequently Asked Questions</h1>
          <p>
            Answered all frequently asked questions, Still confused? feel free
            to contact us.
          </p>
        </div>
        <div className={styles.cards}>
          <p
            className={classNames(
              styles.bLoans,
              section === "bLoans" ? styles.selected : ""
            )}
            onClick={() => {
              onClick("bLoans");
              setSection("bLoans");
            }}
          >
            Bridging Loans
          </p>
          <p
            className={classNames(
              styles.dFinance,
              section === "dFinance" ? styles.selected : ""
            )}
            onClick={() => {
              onClick("dFinance");
              setSection("dFinance");
            }}
          >
            Development Finance
          </p>
          <p
            className={classNames(
              styles.sRepo,
              section === "sRepo" ? styles.selected : ""
            )}
            onClick={() => {
              onClick("sRepo");
              setSection("sRepo");
            }}
          >
            Stop Reposessions
          </p>
          <p
            className={classNames(
              styles.reBridging,
              section === "reBridging" ? styles.selected : ""
            )}
            onClick={() => {
              onClick("reBridging");
              setSection("reBridging");
            }}
          >
            Re-Bridging
          </p>
        </div>
        <div className={styles.faqsContainer}>
          {finalListArray &&
            finalListArray.map((item, idx) => (
              <FAQCard idx={idx} faqsList={item} section={section} />
            ))}
        </div>
      </section>
    </div>
  );
};

export default FAQ;
