import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import * as myConstClass from '../../components/FileWithConstants';
import $ from 'jquery';
import styles from "./UploadedDocuments.module.scss";

import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/DeleteModalForDocument";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { GrFormView } from 'react-icons/gr';
import { AiOutlineDownload, AiFillEdit } from 'react-icons/ai';


const refreshHandler = () => {
        window.location.reload();
}


export default class javascriptMap extends Component {
    


    constructor(props){
        super(props)
        this.state = {
          personalDocuments: [],
          propertyDocuments: [],
          companyDocuments: [],
          otherDocuments: [],
        }
      }



    
      getData(){
        const login_emailid = localStorage.getItem("login_emailid");
        let formDataUploadDocuments = new FormData();
        formDataUploadDocuments.append("login_emailid", login_emailid);
        var uploaddocumentsurl = myConstClass.BASE_URL+""+myConstClass.DOCUMENTLISTFORUSER;

        axios.post(uploaddocumentsurl, formDataUploadDocuments)
        .then(res => {
                
               var personalDocumentsVar = res.data.respersonal;
               this.setState({personalDocuments : personalDocumentsVar})

               var propertyDocumentsVar = res.data.resproperty;
               this.setState({propertyDocuments : propertyDocumentsVar})

               var companyDocumentsVar = res.data.rescompany;
               this.setState({companyDocuments : companyDocumentsVar})

               var otherDocumentsVar = res.data.resothers;
               this.setState({otherDocuments : otherDocumentsVar})
               
               
                
               
                
                
                             //personalDocuments = res.data.respersonal;
                             //propertyDocuments = res.data.resproperty;
                             //companyDocuments = res.data.rescompany;
                             //otherDocuments = res.data.resothers;
                });
      }

    

      componentDidMount(){
        this.getData()
      }

     

    render() {
    document.title = "Bridging 365 | Forms";
    window.scrollTo(0, 0);
    var countpersonaldoc = 0;
    var countpropertydoc = 0;
    var countcompanydoc = 0;
    var countotherdoc = 0;


    
        return (
        <div className={styles.uploadedDocuments}>
            <p className={styles.header}>Uploaded Documents</p>
            <p className={styles.header1}style={{ backgroundColor: '#ff7232'}}>Personal</p>
            <div className={styles.documents}>
                {
                    this.state.personalDocuments.map(item => (
                     <div className={styles.document}>
                        <p className={styles.head}>
                            <a href={(item.document_url)} target="_blank"><AiOutlineDownload /></a>
                            <DeleteModal formId={item.document_id} redirecTo={1} onClick={refreshHandler}/>
                        </p>
                        <p>Personal Document {countpersonaldoc = countpersonaldoc+1}</p>
                    </div>
                    ))
                }
            </div>
            <p className={styles.header1}style={{ backgroundColor: '#673ab7'}}>Property Documents</p>

            <div className={styles.documents}>
                {
                    this.state.propertyDocuments.map(item => ( <div className={styles.document}>
                        <p className={styles.head}>
                            <a href={(item.document_url)} target="_blank"><AiOutlineDownload /></a>
                            <DeleteModal formId={item.document_id} redirecTo={1} onClick={refreshHandler}/>
                        </p>
                        <p>Property Documents {countpropertydoc = countpropertydoc + 1}</p>
                    </div>))
                }
            </div>
            <p className={styles.header1}style={{ backgroundColor: '#4caf50'}}>Company Documents</p>

            <div className={styles.documents}>
                {
                    this.state.companyDocuments.map(item => ( <div className={styles.document}>
                        <p className={styles.head}>
                            <a href={(item.document_url)} target="_blank"><AiOutlineDownload /></a>
                            <DeleteModal formId={item.document_id} redirecTo={1} onClick={refreshHandler}/>
                        </p>
                        <p>Company Documents {countcompanydoc = countcompanydoc + 1}</p>
                    </div>))
                }
            </div>
            <p className={styles.header1}style={{ backgroundColor: '#002d72'}}>Other Documents</p>

            <div className={styles.documents}>
                {
                     this.state.otherDocuments.map(item => ( <div className={styles.document}>
                        <p className={styles.head}>
                            
                            <a href={(item.document_url)} target="_blank"><AiOutlineDownload /></a>
                            <DeleteModal formId={item.document_id} redirecTo={1} onClick={refreshHandler}/>
                        </p>
                        <p>Other Document {countotherdoc = countotherdoc + 1}</p>
                    </div>))
                }
            </div>
        </div>
    )       

     
        
    }
}

