import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/LoanApplication.module.scss";
import phoneGreenImg from "../../assets/images/phone-green.png";
import phoneBlueImg from "../../assets/images/phone.png";
import phoneOrangeImg from "../../assets/images/phone-orange.png";
import classNames from "classnames";
const LoanApplicationS2 = ({ title }) => {
  return (
    <div className={styles.loanApplication}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
Benefits of Bridging Finance to Prevent Repossession of Your Home     </p>
      <div
        className={classNames(
          styles.content,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <div className={styles.left}>
          <p>
          As well as the steps you can take legally to stop your mortgage lender from repossessing your home, there is the option of taking out a bridging loan to halt the repossession process.


          </p>
          <p>
          Provides Much Needed Time – Although you won’t be completely free of repossession a bridging loan gives you more time to focus on a way to deal with the issues over the longer term.
          </p>
          <p>
<strong>Restructure Payments – </strong>	One way is to provide that time to discuss long-term tailored options such as restructuring payments that are acceptable to your lender.
</p>
<p>
<strong> Clear Mortgage Arrears –</strong> Bridging finance can help to clear your mortgage arrears and provide time to sort out the funding to clear the bridging loan.

</p>
<p> <strong>Sell the Property –</strong>	 Putting in place measures to sell your property can help to ward off repossession, as well as providing the opportunity to sell in an organised fashion without having to sell below a realistic valuation.
</p>
<p><strong>Keep a Clear Credit File –</strong>	 Bridging finance helps you in the long-term from having the black mark of house repossession on your credit file.
</p>
<p>Stopping your mortgage lender from repossessing your home is likely to be an urgent matter. The team at Bridging 365 has access to a vast and varied panel of lenders (169 in total), located throughout the UK and beyond. With such variety in place it gives us the power to help prevent a repossession process from taking place in both a regulated and unregulated scenario. 
</p>
<p>In many cases our clients have come to us with urgent requirements for funds to help prevent repossession, sometimes needing the financial assistance within the course of a few days. Our relationship with a vast network of lenders ensures we can offer bridging finance within a 48-hour turnaround in the most urgent cases.
</p>
      
        </div>
        <div
          className={classNames(
            styles.right,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : ""
          )}
        >
          <p className={styles.rightHeader}>Confused by the options?</p>
          <p className={styles.rightText}>
            Choosing the right Bridge for you can be really tricky. At Bridging 365
            our expert advisers guide you through the process. Simple and
            efficient.
          </p>
          <Link
            to="#"
            className={classNames(
              styles.link,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            Start Online
          </Link>
          <p className={styles.callText}>Call free from mobile or landline</p>
          <p
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : ""
            )}
          >
            {" "}
            <img
              src={
                title === "Bridging Loans"
                  ? phoneGreenImg
                  : title === "Development Finance"
                  ? phoneBlueImg
                  : title === "Stop Repossessions"
                  ? phoneOrangeImg
                  : ""
              }
              alt=""
            />{" "}
            0208 146 9888
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanApplicationS2;
