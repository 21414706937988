import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgagesB11 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Bridging Finance When You Need it Most{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          <strong> Fast Paced Application Decision</strong>
          We understand that for many of you reading this, there is an urgent
          financial scenario that has led you to seek a bridging loan. That’s
          why we aim to make a decision on the majority of applications within a
          3-hour period. In some cases, we will require more information from
          you, but our friendly customer service team have the experience and
          know-how to conduct the process effortlessly and stress free from the
          client's point of view.
        </p>
        <p>
          <strong>Flexible Repayment Terms </strong> With such a large panel of
          169 funders, we have access to a wide range of options for each
          individual client. Even in the most difficult situations, our rates
          start from 0.75% per month and can be set out from 1-36 months, giving
          you ample time to put an exit strategy in motion effectively.
        </p>
        <p>
          <strong> Quick Funding</strong> Once a decision has been made and
          repayment terms agreed, we can release the funds within a short space
          of time to ensure that even in the most difficult scenario, you are
          reaping the benefits of a bridging loan. In urgent cases we can
          release funds within 48-hours of acceptance.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgagesB11;
