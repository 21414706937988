import React, { useEffect, useState } from "react";
import { HiChevronDoubleUp, HiChevronDoubleDown } from "react-icons/hi";
import classNames from "classnames";
import { database } from "../../FirebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import loanPng from "../../assets/images/loan.png";
import securityPng from "../../assets/images/security.png";
import companyPng from "../../assets/images/company.svg";
import directorPng from "../../assets/images/director.png";
import otherPng from "../../assets/images/other.svg";
import loading from "../../assets/images/loading.gif";
import { useLocation, useNavigate } from "react-router-dom";
import * as myConstClass from "../../components/FileWithConstants";
import $ from "jquery";
import styles from "./DevFinanceForm.module.scss";
import AboutLoan from "./components/AboutLoan";
import AboutSecurity from "./components/AboutSecurity";
import AboutCompany from "./components/AboutCompany";
import { defaultState } from "./utils/state";
import { useFormik } from "formik";
import ProgressBar from "./components/ProgressBar";
import InitialForm from "./components/InitialForm";
import AboutDirector from "./components/AboutDirector";
import AdditionalInfo from "./components/AdditionalInfo";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const FormPage = () => {
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    const accountCreated = query.get("accountCreated");
    if (accountCreated && Boolean(accountCreated) === true) {
      setStep("final");
    }
  }, [query]);

  useEffect(() => {
    document.title = "Bridging 365 | Form";
    window.scrollTo(0, 0);
  }, []);

  const blurHandler = () => {
    if (
      formdata.purpose &&
      formdata.howMuchToBorrow &&
      formdata.numOfMonthsLoanFor &&
      formdata.monthlyOrTermPay &&
      formdata.howPaybackLoan &&
      formdata.addressLine1 &&
      formdata.city &&
      formdata.postcode &&
      formdata.propertyValuation
    ) {
      setShowCalc(true);
    }
  };

  const today = new Date();
  const date =
    today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  const time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  // const [purpose, setPurpose] = useState();
  const collectionRef = collection(database, "forms");
  const initialValue = {
    userName: "",
    userContact: "",
    userEmail: "",
    propertyUse: "",
    whenMoneyNeeded: "",
    purpose: "",
    currentLoanFromBridgingLender: "",
    whenLoanCompleted: "",
    howMuchToBorrow: "",
    numOfMonthsLoanFor: "",
    monthlyOrTermPay: "",
    howPaybackLoan: "",
    howPaybackLoanOtherOptionDescription: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: "",
    propertyValuation: "",
    propertyDescription: "",
    numberOfBedrooms: "",
    numberOfFloors: "",
    isExCouncilProperty: "",
    isStandardConstruction: "",
    constructionDescription: "",
    tenureOfPropertyAnswer: "",
    otherLeaseInfo: "",
    remainingYearsForLease: "",
    assetDescription: "",
    addAnotherProperty: "",
    addressLine1A: "",
    addressLine2A: "",
    city1A: "",
    postcode1A: "",
    propertyValuation1A: "",
    propertyDescription1A: "",
    numberOfBedrooms1A: "",
    numberOfFloors1A: "",
    isExCouncilProperty1A: "",
    isStandardConstruction1A: "",
    constructionDescription1A: "",
    tenureOfProperty1A: "",
    tenureOfPropertyAnswer1A: "",
    otherLeaseInfo1A: "",
    remainingYearsForLease1A: "",
    assetDescription1A: "",
    nameOfCompayForBorrowingIn: "",
    companyRegistrationNumber: "",
    companyAddress1: "",
    companyAddress2: "",
    companyCity: "",
    companyPostCode: "",
    directorFirstName: "",
    directorLastName: "",
    directorTitle: "",
    directorDateOfBirth: "",
    directorAddress1: "",
    directorAddress2: "",
    directorTown: "",
    directorPostCode: "",
    directorNumber: "",
    defaultInSixYears: "",
    defaultInSixYearsDescription: "",
    everDeclaredBankrupt: "",
    everDeclaredBankruptDescription: "",
    addAnotherDirector: "",
    directorFirstName1A: "",
    directorLastName1A: "",
    directorTitle1A: "",
    directorDateOfBirth1A: "",
    directorAddress1A: "",
    directorAddress2A: "",
    directorTown1A: "",
    directorPostCode1A: "",
    directorNumber1A: "",
    defaultInSixYears1A: "",
    defaultInSixYearsDescription1A: "",
    everDeclaredBankrupt1A: "",
    everDeclaredBankruptDescription1A: "",
    addAnotherDirector1A: "",
    directorFirstName1B: "",
    directorLastName1B: "",
    directorTitle1B: "",
    directorDateOfBirth1B: "",
    directorAddress1B: "",
    directorAddress2B: "",
    directorTown1B: "",
    directorPostCode1B: "",
    directorNumber1B: "",
    defaultInSixYears1B: "",
    defaultInSixYearsDescription1B: "",
    everDeclaredBankrupt1B: "",
    everDeclaredBankruptDescription1B: "",
    // addAnotherDirector1B: "",
    otherMsg: "",
    submittedOnDate: date,
    submittedOnTime: time,
  };
  const [formdata, setFormdata] = useState(initialValue);
  const [showLoading, setShowLoading] = useState(false);
  const [showCalc, setShowCalc] = useState(true);
  const value = formdata.howMuchToBorrow
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  console.log("howmuch to borrow", value);

  const submitHandler = () => {
    setShowLoading(true);
    formdata.lastid = document.getElementById("lastid").value;
    const login_emailid_m1 = localStorage.getItem("login_emailid");
    formdata.login_emailid_send = localStorage.getItem("login_emailid_m1");
    formdata.save_for_later = 0;
    console.log(JSON.stringify(formdata));
    const formsaveforlater =
      myConstClass.BASE_URL + "" + myConstClass.FORMDATASAVELATER;
    var saveDataFormLater = $.ajax({
      type: "POST",
      url: formsaveforlater,
      data: formdata,
      dataType: "json",
      success: function (resultData) {
        console.log(resultData);
        var lastid = resultData.lastid;
        document.getElementById("lastid").value = lastid;
      },
    });

    addDoc(collectionRef, formdata)
      .then((response) => {
        // alert("Form submitted successfully ! ");
        setShowLoading(false);
        setFormdata(initialValue);
        navigate(
          "/formsuccess",
          {
            state: {
              title: "Thank you for your application",
              subhead:
                "We received your application and will reveiew it in consideration for lenders that are suitable. We will contact you if we need any further details",
            },
          },
          { replace: true }
        );
      })
      .catch((error) => {
        alert("Oops !! Something went wrong. ");
        setShowLoading(false);
      });
  };
  const saveFomrForLaterHandler = () => {
    // if (
    //   !formdata.purpose ||
    //   // !formdata.currentLoanFromBridgingLender ||
    //   // !formdata.whenLoanCompleted ||
    //   !formdata.howMuchToBorrow ||
    //   !formdata.numOfMonthsLoanFor ||
    //   !formdata.monthlyOrTermPay ||
    //   !formdata.howPaybackLoan ||
    //   !formdata.addressLine1 ||
    //   // !formdata.addressLine2 ||
    //   !formdata.city ||
    //   !formdata.postcode ||
    //   !formdata.propertyValuation ||
    //   !formdata.propertyDescription ||
    //   !formdata.numberOfBedrooms ||
    //   !formdata.numberOfFloors ||
    //   !formdata.isExCouncilProperty ||
    //   !formdata.isStandardConstruction ||
    //   !formdata.constructionDescription ||
    //   !formdata.tenureOfProperty ||
    //   !formdata.assetDescription ||
    //   // !formdata.addAnotherProperty ||
    //   !formdata.nameOfCompayForBorrowingIn ||
    //   !formdata.companyRegistrationNumber ||
    //   !formdata.companyAddress1 ||
    //   // !formdata.companyAddress2 ||
    //   !formdata.companyCity ||
    //   !formdata.companyPostCode ||
    //   !formdata.directorFirstName ||
    //   !formdata.directorLastName ||
    //   !formdata.directorDateOfBirth ||
    //   !formdata.directorAddress1 ||
    //   // !formdata.directorAddress2 ||
    //   !formdata.directorTown ||
    //   !formdata.directorNumber ||
    //   !formdata.defaultInSixYears ||
    //   !formdata.everDeclaredBankrupt ||
    //   !formdata.otherMsg
    // ) {
    //   alert("Please fill all the details to continue");
    //   return;
    // }
    setShowLoading(true);
    formdata.lastid = document.getElementById("lastid").value;

    const login_emailid_m1 = localStorage.getItem("login_emailid");
    formdata.login_emailid_send = localStorage.getItem("login_emailid_m1");
    formdata.save_for_later = 1;

    const formsaveforlater =
      myConstClass.BASE_URL + "" + myConstClass.FORMDATASAVELATER;
    var saveDataFormLater = $.ajax({
      type: "POST",
      url: formsaveforlater,
      data: formdata,
      dataType: "json",
      success: function (resultData) {
        var lastid = resultData.lastid;
        document.getElementById("lastid").value = lastid;
      },
    });

    addDoc(collectionRef, formdata)
      .then((response) => {
        // alert("Form submitted successfully ! ");
        setShowLoading(false);
        setFormdata(initialValue);
        navigate(
          "/formsuccess",
          {
            state: {
              title: "form saved successfully",
              subhead:
                "You can visit user profile section to edit,delete and download form",
            },
          },
          { replace: true }
        );
      })
      .catch((error) => {
        alert("Oops !! Something went wrong. ");
        setShowLoading(false);
      });
  };
  const emailFormHandler = () => {
    alert("Functionality to be added soon...");
  };

  const intialSubmitHandler = () => {
    // if (
    //   !formdata.userName ||
    //   !formdata.userContact ||
    //   !formdata.userEmail ||
    //   !formdata.propertyUse ||
    //   !formdata.whenMoneyNeeded
    // ) {
    //   alert("Please fill all the details to continue");
    //   return;
    // }

    const isLoggedIn = localStorage.getItem("loggedIn");
    if (!isLoggedIn) {
      navigate(`/loginmodal`, {
        state: formdata,
      });

      // setShowLoginPopup(true);
      return;
    }

    const formsubmit = myConstClass.BASE_URL + "" + myConstClass.FORMDATA;
    //axios.post(formsubmit, formdata)
    //.then(res => {
    //
    //});

    formdata.login_emailid_send = localStorage.getItem("login_emailid");

    var saveData = $.ajax({
      type: "POST",
      url: formsubmit,
      data: formdata,
      dataType: "json",
      success: function (resultData) {
        var lastid = resultData.lastid;
        document.getElementById("lastid").value = lastid;
      },
    });

    localStorage.setItem("initialObj", JSON.stringify(formdata));
    setStep("final");
    window.scrollTo(0, 0);
  };
  const SaveForLaterHandler = () => {
    alert("Feature to be added soon.");
  };
  const [resultsShown, setResultsShown] = useState(false);
  let mql = window.matchMedia("(max-width:800px)");
  const simpleInterest =
    (formdata.howMuchToBorrow * 0.69 * formdata.numOfMonthsLoanFor) / 1200;

  const amountDue = Number(
    Number(formdata.howMuchToBorrow) + Number(simpleInterest)
  ).toFixed(0);

  const monthlyPayment = Number(
    amountDue / formdata.numOfMonthsLoanFor
  ).toFixed(0);

  const maxBorrowLimit = (0.65 * formdata.propertyValuation).toFixed(2);
  const lTV = (formdata.howMuchToBorrow / formdata.propertyValuation).toFixed(
    2
  );

  const [formData, setFormData] = useState({ ...defaultState });
  const [step, setStep] = useState("initial");

  const [loanProgress, setLoanProgress] = useState(0);
  const [securityProgress, setSecurityProgress] = useState(0);
  const [companyProgress, setCompanyProgress] = useState(0);
  const [directorProgress, setDirectorProgress] = useState(0);
  const [additionalInfoProgresss, setAdditionalInfoProgress] = useState(0);

  return step === "initial" ? (
    <InitialForm
      step={step}
      setStep={setStep}
      intialSubmitHandler={intialSubmitHandler}
      formdata={formdata}
      setFormdata={setFormdata}
    />
  ) : (
    <>
      <div className={styles.formPage}>
        <span
          title="form results"
          className={styles.finalResults}
          onClick={() => setResultsShown(!resultsShown)}
        >
          {" "}
          <span> Your results</span>
          <span>
            {" "}
            {!resultsShown ? (
              <HiChevronDoubleUp className={styles.docIcon} />
            ) : (
              <HiChevronDoubleDown className={styles.docIcon} />
            )}
          </span>
        </span>{" "}
        <div className={styles.left}>
          {/* <img src={logoImg} alt="" /> */}
          <ul>
            <li>
              {" "}
              <a
                href="#section1"
                style={{
                  boxShadow: "0 0 0 2px #ff7232 ",
                }}
              >
                <div>
                  <span
                    style={{
                      backgroundColor: "#ff7232",
                      paddingTop: "0.7rem",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    About the Loan{" "}
                  </span>
                  <span style={{ paddingTop: "3px" }}>
                    <img src={loanPng} alt="" />
                  </span>{" "}
                </div>
                <ProgressBar width={loanProgress} name="loan" />
              </a>
            </li>
            <li>
              <a
                href="#section2"
                style={{
                  boxShadow: "0 0 0 2px #673ab7 ",
                }}
              >
                <div>
                  <span
                    style={{
                      backgroundColor: "#673ab7",
                      paddingTop: "0.7rem",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    About the Security
                  </span>{" "}
                  <span style={{ paddingTop: "3px" }}>
                    {" "}
                    <img src={securityPng} alt="" />
                  </span>{" "}
                </div>
                <ProgressBar width={securityProgress} name="security" />
              </a>
            </li>
            <li>
              {" "}
              <a
                href="#section3"
                style={{
                  boxShadow: "0 0 0 2px #4ccb14 ",
                }}
              >
                <div>
                  <span
                    style={{
                      backgroundColor: "#4ccb14",
                      paddingTop: "0.7rem",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    About the Company
                  </span>
                  <span style={{ paddingTop: "3px" }}>
                    <img src={companyPng} alt="" />
                  </span>
                </div>
                <ProgressBar width={companyProgress} name="company" />
              </a>
            </li>
            <li>
              {" "}
              <a href="#section4" style={{ boxShadow: "0 0 0 2px #042c74 " }}>
                <div>
                  <span
                    style={{
                      backgroundColor: "#042c74",
                      paddingTop: "0.7rem",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    About the Director
                  </span>{" "}
                  <span style={{ paddingTop: "3px" }}>
                    {" "}
                    <img src={directorPng} alt="" />
                  </span>
                </div>
                <ProgressBar width={directorProgress} name="director" />
              </a>
            </li>
            <li>
              {" "}
              <a href="#section5" style={{ boxShadow: "0 0 0 2px #f8ab17 " }}>
                <div>
                  <span
                    style={{
                      backgroundColor: "#f8ab17",
                      paddingTop: "0.7rem",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Additional Information
                  </span>{" "}
                  <span style={{ paddingTop: "3px" }}>
                    {" "}
                    <img src={otherPng} alt="" />
                  </span>{" "}
                </div>
                <ProgressBar
                  width={additionalInfoProgresss}
                  name="additionalInfo"
                />
              </a>
            </li>
          </ul>
          <div className={styles.btns}>
            <button className={styles.btn1} onClick={saveFomrForLaterHandler}>
              Save for later
            </button>
            <button className={styles.btn2} onClick={emailFormHandler}>
              Email me
            </button>
            <button
              className={styles.btn1}
              onClick={() => {
                setStep("initial");
                window.scrollTo(0, 0);
              }}
            >
              Go back
            </button>
          </div>
        </div>
        <div className={styles.center}>
          <AboutLoan
            formData={formData}
            setFormData={setFormData}
            setLoanProgress={setLoanProgress}
          />
          <AboutSecurity
            formData={formData}
            setFormData={setFormData}
            setSecurityProgress={setSecurityProgress}
          />
          <AboutCompany
            formData={formData}
            setFormData={setFormData}
            setCompanyProgress={setCompanyProgress}
          />
          <AboutDirector
            formData={formData}
            setFormData={setFormData}
            setDirectorProgress={setDirectorProgress}
          />
          <AdditionalInfo
            formData={formData}
            setFormData={setFormData}
            setAdditionalInfoProgress={setAdditionalInfoProgress}
          />
        </div>
        <div className={styles.btns}>
          <button onClick={SaveForLaterHandler} className={styles.saveForLater}>
            Save for later
          </button>
          <button onClick={submitHandler} className={styles.submitBtn}>
            Submit
          </button>
        </div>
        <div
          className={classNames(styles.right, !showCalc ? styles.showCalc : "")}
        >
          <div className={styles.table}>
            <p>Your Offer in Principle</p>
            <table>
              <tr>
                <td>You are looking to borrow</td>
                <td>
                  {formdata.howMuchToBorrow
                    ? "£ " +
                      formdata.howMuchToBorrow
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
              <tr>
                <td>Based on your property being valued at</td>
                <td>
                  {formdata.propertyValuation
                    ? "£ " +
                      formdata.propertyValuation
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
              <tr>
                <td>
                  For how many months with an interest rate of 0.69% Per Month
                </td>
                <td>
                  {formdata.numOfMonthsLoanFor
                    ? formdata.numOfMonthsLoanFor
                    : "NA"}
                </td>
              </tr>
              <tr>
                <td>Typical time to completion (days)</td>
                <td>10 </td>
              </tr>
            </table>
          </div>
          <div className={styles.table}>
            <p>Pay at end of term (Rolled)</p>
            <table>
              <tr>
                <td>Monthly Payments</td>
                <td>NA</td>
              </tr>
              <tr>
                <td>Amount Due</td>
                <td>
                  {" "}
                  {amountDue
                    ? "£ " +
                      amountDue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
              <tr>
                <td>Gross LTV</td>
                <td>{isNaN(lTV) ? "NA" : lTV === "Infinity" ? "NA" : lTV}</td>
              </tr>
              <tr>
                <td>Maximum you can borrow</td>
                <td>
                  {" "}
                  {maxBorrowLimit
                    ? "£ " +
                      maxBorrowLimit
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
            </table>
          </div>
          <div className={styles.table}>
            <p>Pay monthly (Serviced)</p>
            <table>
              <tr>
                <td>Monthly Payments</td>
                <td>
                  {" "}
                  {!monthlyPayment
                    ? "NA"
                    : monthlyPayment === "Infinity"
                    ? "NA"
                    : "£ " +
                      monthlyPayment
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr>
                <td>Amount Due</td>
                <td>
                  {amountDue
                    ? "£ " +
                      amountDue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
              <tr>
                <td>Gross LTV</td>
                <td>{isNaN(lTV) ? "NA" : lTV === "Infinity" ? "NA" : lTV}</td>
              </tr>
              <tr>
                <td>Maximum you can borrow</td>
                <td>
                  {maxBorrowLimit
                    ? "£ " +
                      maxBorrowLimit
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "NA"}
                </td>
              </tr>
            </table>
          </div>
        </div>
        {mql && step === "final" && resultsShown ? (
          <div
            className={classNames(
              styles.results,
              !showCalc ? styles.showCalc : ""
            )}
          >
            <div className={styles.table}>
              <p>Your Offer in Principle</p>
              <table>
                <tr>
                  <td>You are looking to borrow</td>
                  <td>
                    {" "}
                    {formdata.howMuchToBorrow
                      ? "£ " +
                        formdata.howMuchToBorrow
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "NA"}
                  </td>
                </tr>
                <tr>
                  <td>Based on your property being valued at</td>
                  <td>
                    {" "}
                    {formdata.propertyValuation
                      ? "£ " +
                        formdata.propertyValuation
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "NA"}
                  </td>
                </tr>
                <tr>
                  <td>
                    For how many months with an interest rate of 0.69% Per Month
                  </td>
                  <td>
                    {formdata.numOfMonthsLoanFor
                      ? formdata.numOfMonthsLoanFor
                      : "NA"}
                  </td>
                </tr>
                <tr>
                  <td>Typical time to completion (days)</td>
                  <td>10</td>
                </tr>
              </table>
            </div>
            <div className={styles.table}>
              <p>Pay at end of term (Rolled)</p>
              <table>
                <tr>
                  <td>Monthly Payments</td>
                  <td>NA</td>
                </tr>
                <tr>
                  <td>Amount Due</td>
                  <td>{amountDue ? "£ " + amountDue : "NA"}</td>
                </tr>
                <tr>
                  <td>Gross LTV</td>
                  <td>{isNaN(lTV) ? "NA" : lTV === "Infinity" ? "NA" : lTV}</td>
                </tr>
                <tr>
                  <td>Maximum you can borrow</td>
                  <td>
                    {maxBorrowLimit
                      ? "£ " +
                        maxBorrowLimit
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "NA"}
                  </td>
                </tr>
              </table>
            </div>
            <div className={styles.table}>
              <p>Pay monthly (Serviced)</p>
              <table>
                <tr>
                  <td>Monthly Payments</td>
                  <td>
                    {!monthlyPayment
                      ? "NA"
                      : monthlyPayment === "Infinity"
                      ? "NA"
                      : "£ " +
                        monthlyPayment
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                </tr>
                <tr>
                  <td>Amount Due</td>
                  <td>
                    {amountDue
                      ? "£ " +
                        amountDue
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "NA"}
                  </td>
                </tr>
                <tr>
                  <td>Gross LTV</td>
                  <td>{isNaN(lTV) ? "NA" : lTV === "Infinity" ? "NA" : lTV}</td>
                </tr>
                <tr>
                  <td>Maximum you can borrow</td>
                  <td>
                    {maxBorrowLimit
                      ? "£ " +
                        maxBorrowLimit
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "NA"}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        ) : null}
        <div className={styles.loadingContainer}>
          {showLoading && (
            <img src={loading} className={styles.loadingImg} alt="" />
          )}
        </div>
      </div>
    </>
  );
};

export default FormPage;
