import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/LoanApplication.module.scss";
import phoneGreenImg from "../../assets/images/phone-green.png";
import phoneBlueImg from "../../assets/images/phone.png";
import phoneOrangeImg from "../../assets/images/phone-orange.png";
import phonePurpleImg from "../../assets/images/phone-purple.png";
import classNames from "classnames";
const LoanApplicationC4 = ({ title }) => {
  return (
    <div className={styles.loanApplication}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        Access to Unregulated Bridging Loan Funders{" "}
      </p>
      <div
        className={classNames(
          styles.content,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <div className={styles.left}>
          <p>
            With Bridging 365 we have such a vast network of bridging loan
            funders that it is possible to acquire credit from sources that you
            might have struggled to find a connection with elsewhere.
          </p>
          <p>
            In terms of acquiring funding for assistance with an inherited
            property, whether you are looking to pay off an inheritance tax bill
            quickly, or secure the property through the paying of a sibling who
            co-owns the property, our team has access to 13 reliable and
            professional funders, each of which can offer unregulated bridging
            loans for inherited properties.
          </p>
          <p>
            Our loans start from £25,000 and can reach as high as £50 million,
            with 70% LTV or even 100% where you can offer additional security
            towards the loan. We are also happy to be as flexible as possible
            with repayments, taking each applicant on his or her specific
            merits. Contact us today and our friendly team will be able to guide
            you in the right direction for a bridging loan to pay a sibling in
            an inherited property scenario.
          </p>
        </div>
        <div
          className={classNames(
            styles.right,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : title === "Resources"
              ? styles.reSources
              : ""
          )}
        >
          <p className={styles.rightHeader}>Confused by the options?</p>
          <p className={styles.rightText}>
            Choosing the right Bridge for you can be really tricky. At Bridging
            365 our expert advisers guide you through the process. Simple and
            efficient.
          </p>
          <Link
            to="#"
            className={classNames(
              styles.link,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Resources"
                ? styles.reSources
                : ""
            )}
          >
            Start Online
          </Link>
          <p className={styles.callText}>Call free from mobile or landline</p>
          <p
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Resources"
                ? styles.reSources
                : ""
            )}
          >
            {" "}
            <img
              src={
                title === "Bridging Loans"
                  ? phoneGreenImg
                  : title === "Development Finance"
                  ? phoneBlueImg
                  : title === "Stop Repossessions"
                  ? phoneOrangeImg
                  : title === "Resources"
                  ? phonePurpleImg
                  : ""
              }
              alt=""
            />{" "}
            0208 146 9888
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanApplicationC4;
