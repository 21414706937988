import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgages7 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        What Type of HMO Do You Intend to Create?{" "}
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          If you are new to the development game it might seem a little daunting
          at first to put together a secure plan that funds your project. There
          are a number of options available to first time developers, but
          bridging finance provides the route that leads to most success in our
          opinion.
        </p>
        <p>
          There are two different types of HMO, and depending on which one you
          plan to own, the amount and type of financing that you require will
          differ. All Houses of Multiple Occupancy are defined as a household
          where there are three or more tenants. Within that property communal
          areas such as the kitchen area and the bathroom are shared, but
          individuals are not part of the same household. HMOs are typically
          large houses shared by groups of students or young professionals.
        </p>
        <p>
          <strong>Unlicensed HMOs </strong> An unlicensed HMO covers all houses
          that hold fewer than five tenants and who share communal areas.
        </p>
        <p>
          <strong>Licensed HMO </strong> Any property that houses six tenants or
          more will require a license from the local council where the property
          is located. There is an associated application fee.
        </p>
        <p>
          <strong> Financing a HMO </strong>
          Whether you are building a HMO from scratch, or converting an existing
          property into an HMO, you will require financing to find the purchase
          and construction costs of the development. Traditional high street
          funders are more reluctant than ever to provide funding to HMP
          landlords, scrutinising applications in fine detail and taking into
          account rental coverage (sometimes requiring up to 170%, and taking on
          board the valuation from a professional surveyor.
        </p>
        <p>
          One of the problems with this new level of scrutiny is that it can
          take weeks and months to hear from a funder in terms of an application
          decision.
        </p>
        <p>
          {" "}
          <strong>Bridging Loans to Help Convert a Home into Flats</strong> If
          you are considering turning your home into a flat, bridging finance
          provides you with a fast solution process that won’t leave you missing
          out on an opportunity due to the delays of a high street funder and
          the application processes they go through.
        </p>
      </div>
    </div>
  );
};

export default MatchingMortgages7;
