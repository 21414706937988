import styles from "./ProgressBar.module.scss";
import classNames from "classnames";

const ProgressBar = ({ width , name}) => {
  return (
    <div className={styles.progress_bar_container}>
      <div className={classNames(styles.progress_bar, styles[name])} style={{ width: `${width}%` }}></div>
    </div>
  );
};

export default ProgressBar;
