import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainer2 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
   Large Development Finance
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li>        Wide range of options for large development finance (169 funders)
</li>
          <li>Bespoke finance deals for each individual client
</li>
          <li>Fast-paced investment with flexible options (Flexible terms between 1 to 36-months)
</li>
          <li>Loans from £25,000 to £50 million
</li>
          <li>Up to 75% GDV and 100% of land costs with additional security
</li>
          <li>100% development and construction funding options available
</li>
          <li>Perfect for high-end residential and commercial property developments
</li>
          <li>Competitive rates of interest to maximise financial potential of project
</li>
        </ul>
        <div className={styles.text}>

          <p> Bridging365 offers bridging loans for a number of different purposes, including large development finance. The projects that we have worked on vary in size, scope and location, and can range from single structure properties, to high-end apartment projects, commercial developments and large-scale residential builds.</p>
          <p>Much like other types of bridging loans, development finance is used to secure funds for a wide variety of construction projects. It is always intended as a short-term funding option, to kick start a project and ensure that construction can run smoothly, though a funder will expect the finance to be returned through an agreed protocol. This could be once the project has been completed and sold on, leased or refinanced. An exit strategy has to be in place, the same as with any type of bridging finance. The difference with development finance is that it can work on a much larger scale and with bigger financial figures than a traditional bridging loan.</p>
          
        </div>
      </div>
    </div>
  );
};

export default CompareContainer2;
