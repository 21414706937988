import { useEffect } from "react";
import styles from "../../FormPage.module.scss";
import classNames from "classnames";
import NumberFormat from "react-number-format";

const AboutLoan = ({ formdata, setFormdata, setLoanProgress }) => {
  useEffect(() => {
    const values = ["purpose", "monthlyOrTermPay", "howPaybackLoan"];

    let score = 0;
    for (let i = 0; i < values.length; i++) {
      if (formdata[values[i]] !== "") {
        score += 0.99;
      }
    }

    setLoanProgress((score / values.length) * 100);
  }, [formdata, setLoanProgress]);

  return (
    <form action="" className={styles.form1}>
      <input type="hidden" id="lastid" />
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section1)}
        >
          <span className={styles.questionText}>
            {" "}
            What is the purpose of the bridging loan?{" "}
          </span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <select
            name=""
            id=""
            className={styles.answer}
            value={formdata.purpose}
            onChange={(e) => {
              setFormdata({ ...formdata, purpose: e.target.value });
            }}
          >
            <option value="" selected disabled className={styles.option}>
              select option
            </option>
            <option value="Purchase" className={styles.option}>
              New Bridge
            </option>
            <option value="Refinance" className={styles.option}>
              Re-Bridging
            </option>
            no
            <option value="CapitalRaise" className={styles.option}>
              Capital raise- home improvements
            </option>
            <option value="Development Finance" className={styles.option}>
              Development Finance
            </option>
            <option value="Stop Reposessions" className={styles.option}>
              Stop a repossession
            </option>
          </select>
        </div>
      </div>
      {formdata.purpose === "Refinance" && (
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section1)}
          >
            <span className={styles.questionText}>
              {" "}
              Is your current loan from a bridging lender?
            </span>
          </label>

          <div className={styles.answerBlock}>
            {" "}
            <select
              name=""
              id=""
              className={styles.answer}
              value={formdata.currentLoanFromBridgingLender}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  currentLoanFromBridgingLender: e.target.value,
                });
              }}
            >
              <option value="" selected disabled className={styles.option}>
                select option
              </option>
              <option value="Yes" className={styles.option}>
                Yes
              </option>
              <option value="No" className={styles.option}>
                No
              </option>
            </select>
          </div>
        </div>
      )}
      {formdata.purpose === "Stop Reposessions" && (
        <div className={styles.inputBox}>
          <label
            htmlFor=""
            className={classNames(styles.question, styles.section1)}
          >
            <span className={styles.questionText}>
              {" "}
              When do you need the loan to be completed by?
            </span>
          </label>

          <div className={styles.answerBlock}>
            {" "}
            <select
              name=""
              id=""
              className={styles.answer}
              value={formdata.whenLoanCompleted}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  whenLoanCompleted: e.target.value,
                });
              }}
            >
              <option value="" selected disabled className={styles.option}>
                select option
              </option>
              <option value="Under 48 hours" className={styles.option}>
                under 48 hours
              </option>
              <option value="Under a week" className={styles.option}>
                under a week
              </option>
              <option value="Under a month" className={styles.option}>
                under a month
              </option>
            </select>
          </div>
        </div>
      )}
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section1)}
        >
          <span className={styles.questionText}>
            {" "}
            How much would you like to borrow?
          </span>
        </label>

        <div className={styles.answerBlock}>
          {" "}
          <NumberFormat
            placeholder="Enter amount in GBP"
            className={styles.answer}
            value={formdata.howMuchToBorrow}
            thousandSeparator={true}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                howMuchToBorrow: e.target.value,
              });
            }}
          />
        </div>

        {/* <div className={styles.answerBlock} style={{position: 'relative'}}>
                  {" "}


                    <NumberFormat placeholder="Enter amount in GBP" className={styles.answer} value={formdata.howMuchToBorrow} thousandSeparator={true}

                    onChange={(e) => {
                      setFormdata({
                        ...formdata,
                        howMuchToBorrow: e.target.value,

                      });

                    }} />
                    <span style={{ position: 'absolute',fontSize:'1.5rem',left: '3.8rem', right:'1px', top:'31%', color:'white', fontWeight:'bold', fontFamily:'Nunito,sans-serif'}}> £ </span>

                </div> */}
      </div>

      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section1)}
        >
          <span className={styles.questionText}>
            {" "}
            Please select the number of months you would like the loan for
          </span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <select
            name=""
            id=""
            className={styles.answer}
            value={formdata.numOfMonthsLoanFor}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                numOfMonthsLoanFor: e.target.value,
              });
            }}
          >
            <option value="" selected disabled>
              select option
            </option>
            <option value="3">3 months</option>
            <option value="4">4 months</option>
            <option value="5">5 months</option>
            <option value="6">6 months</option>
            <option value="7">7 months</option>
            <option value="8">8 months</option>
            <option value="9">9 months</option>
            <option value="10">10 months</option>
            <option value="11">11 months</option>
            <option value="12">12 months</option>
            <option value="13">13 months</option>
            <option value="14">14 months</option>
            <option value="15">15 months</option>
            <option value="16">16 months</option>
            <option value="17">17 months</option>
            <option value="18">18 months</option>
          </select>
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section1)}
        >
          <span className={styles.questionText}>
            {" "}
            Would you like to make monthly payments or simply pay the full
            balance due on exiting the loan?
          </span>
        </label>
        <div className={classNames(styles.answerBlock, styles.radioBoxBlock)}>
          {" "}
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {" "}
              <input
                className={classNames(styles.answer, styles.radioBox)}
                type="radio"
                name="paymentTerm"
                value="Every month"
                style={{ flexShrink: "0" }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    monthlyOrTermPay: e.target.value,
                  });
                }}
              />
              <span>I'd like to pay the interest due every month</span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              className={classNames(styles.answer, styles.radioBox)}
              type="radio"
              name="paymentTerm"
              value="At the end"
              style={{ flexShrink: "0" }}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  monthlyOrTermPay: e.target.value,
                });
              }}
            />
            <span>I'd like to make one payment at the end of the loan</span>
          </div>
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section1)}
        >
          <span className={styles.questionText}>
            How will you be paying the loan back?{" "}
          </span>
        </label>
        <div className={classNames(styles.answerBlock, styles.radioBoxBlock)}>
          {" "}
          <span>
            {" "}
            <input
              className={classNames(styles.answer, styles.radioBox)}
              type="radio"
              name="payingVia"
              value="By selling property"
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  howPaybackLoan: e.target.value,
                });
              }}
            />
            By selling the property
          </span>
          <span>
            <input
              className={classNames(styles.answer, styles.radioBox)}
              type="radio"
              name="payingVia"
              value="Refinancing to a term lender"
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  howPaybackLoan: e.target.value,
                });
              }}
            />
            Refinancing to a term lender
          </span>
          <span>
            <input
              className={classNames(styles.answer, styles.radioBox)}
              type="radio"
              name="payingVia"
              value="other"
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  howPaybackLoan: e.target.value,
                });
              }}
            />
            Other-please specify{" "}
          </span>
        </div>
        {formdata.howPaybackLoan === "other" && (
          <div className={styles.inputBox}>
            <div className={styles.answerBlock}>
              <textarea
                name=""
                placeholder="Please tell us more."
                id=""
                cols="40"
                rows="5"
                className={classNames(styles.answer, styles.textarea)}
                value={formdata.howPaybackLoanOtherOptionDescription}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    howPaybackLoanOtherOptionDescription: e.target.value,
                  });
                }}
              ></textarea>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default AboutLoan;
