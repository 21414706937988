import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgages5 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Development Finance for First Time Developers
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
        If you are new to the development game it might seem a little daunting at first to put together a secure plan that funds your project. There are a number of options available to first time developers, but bridging finance provides the route that leads to most success in our opinion. 
        </p>
        <p>
       <strong> Why would we suggest bridging finance for a first time developer?</strong> <br />

Speed of Application – When it comes to development finance, you need to know how quickly you can get the funds you need to start a project. That’s why we aim to make a speedy decision on all applications for bridging and development finance, even for first time developers.
        </p>
        <p> <strong> 100% Development Finance –</strong> 
        As mentioned there is the possibility of 100% of the construction funds being released prior to the project beginning. This is an option for those projects where additional security can be offered. In most common cases, we arrange for 75% GDV and up to 100% of the land cost to be provided as development finance, or guide you to find the right type of joint venture funding or mezzanine finance.
        </p>
        <p>   <strong>Release of Funds – </strong>
In urgent cases we can have the funds delivered to successful applicants within 48-hours of the decision being made. This is vital where construction and renovation projects are concerned, as it allows for specific and detailed planning to go ahead with regards materials, tradespeople and equipment.</p>
     <p> <strong>Flexible Length of Loan –</strong>  Knowing that you have the flexibility to put together a development project utilising finance that can be paid back in a timescale that suits you, allows peace of mind. Our bridging finance runs from 1 to 36 months, with the longer terms used by developers to ensure that projects have that breathing space to complete the job and sell/lease the development to pay back the loan.
</p>
<p> <strong>Loans for First Time Developers –</strong>  One of the benefits of bridging finance for first time developers is that we have access to 75% GDV and up to 100% of land and construction costs, should you have the additional security in the form of property or land. We can guide you towards the right type of mezzanine funding or joint venture funding also.
</p>
      </div>
    </div>
  );
};

export default MatchingMortgages5;
