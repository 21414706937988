import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerS1 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Apply now to stop a repossession
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li>3-Hour Application Decision on Majority of Cases</li>
          <li>Funds Released within 48-Hours for Some Clients</li>
          <li>Non-Status Loans from 0.75% per month</li>
          <li>Specialists in Difficult Bridging Loans</li>
          <li> Up to 70% LTV</li>
          <li> Finance Options for Bad Credit Applicants</li>
          <li> Mortgage Arrears Not Considered by All Funders</li>
          <li>Large Panel of Funders Provides us With Choices to Help You</li>
        </ul>
        <div className={styles.text}>
          <p>
            If you are faced with a court order to repossess your home, it can
            be a difficult and stressful time as you put together all of the
            options available to you. Bridging 365 has the team and the
            experience in place to help you at this critical time, putting
            together bridging finance to help stop a repossession order against
            your home.
          </p>
          <p>
            We understand how a repossession order is a time sensitive issue,
            and you’ll need answers as soon as possible to stop your home from
            being lost due to mortgage arrears. With nearly 20 years’ of
            experience within the industry, our team has built a wide and varied
            network of lending connections, ensuring that no matter what
            situations our clients bring to us, we are confident that we are in
            a position to help.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerS1;
