import React, { useEffect } from "react";
import styles from "./Resources.module.scss";
import BridgningHeroSetion from "../../components/BridgningHeroSetion";
import DealsComponent from "../../components/DealsComponent";
import Boxes from "../../components/Boxes";
import LoanGuide from "../../components/LoanGuide";
import TestimonialsContainer from "../../components/TestimonialsContainer";
import ContactComponent from "../../components/ContactComponent";
import Footer from "../../components/Footer";
import CallAdviser from "../../components/CallAdviser";
import ToolsContainer from "../../components/ToolsContainer";
import NewsContainer from "../../components/NewsContainer";
const Resources = () => {
  useEffect(() => {
    document.title = "Bridging 365 | Resources";
  }, []);
  return (
    <div className={styles.resources}>
      <BridgningHeroSetion title="Resources" />
      <Boxes title="Resources" />
      <LoanGuide title="Resources" />
      <DealsComponent title="Resources" />
      <ToolsContainer title="Resources" />
      <NewsContainer title="Resources" />
      <TestimonialsContainer title="Resources" />
      <CallAdviser title="Resources" />
      <ContactComponent title="Resources" />
      <Footer title="Resources" />
    </div>
  );
};

export default Resources;
