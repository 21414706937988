import React, { useEffect } from "react";
import styles from "./BridgingLoanBlog.module.scss";
import CallAdviser from "../../components/CallAdviser";
import NewsContainer from "../../components/NewsContainer";
import TestimonialsContainer from "../../components/TestimonialsContainer";
import Footer from "../../components/Footer";
import CompareContainerB10 from "../../components/CompareContainer/CompareContainerB10";
import MatchingMortgagesB10 from "../../components/MatchingMortgages/MatchingMortgagesB10";
import LoanApplication from "../../components/LoanApplication/LoanApplication";
import HeroSection from "../../components/HeroSection/HeroSection";
const BridgingLoanBlog10 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.bridgingLoanBlog}>
      <HeroSection title="Bridging Loans" />
      <CompareContainerB10 title="Bridging Loans" />
      <CallAdviser title="Bridging Loans" />
      <MatchingMortgagesB10 title="Bridging Loans" />
      <NewsContainer title="Bridging Loans" />
      <LoanApplication title="Bridging Loans" />
      <TestimonialsContainer title="Bridging Loans" />
      <Footer title="Bridging Loans" />
    </div>
  );
};

export default BridgingLoanBlog10;
