import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/LoanGuide.module.scss";
import pointGreenImg from "../assets/images/point-green.png";
import pointBlueImg from "../assets/images/point-blue.png";
import pointOrangeImg from "../assets/images/point-orange.png";
import pointPurpleImg from "../assets/images/point-purple.png";
import classNames from "classnames";
const LoanGuide = ({ title }) => {
  const infoArray1 = [
    {
      id: 1,
      head: "Property Is King",
      text: "The property is the key ingredient. The APR will be determined by the type of property & where it is, but not so much the condition of it! As you’ll be adding your magic touch.",
      link1: "Know Your Property Type",
      link2: "Don’t Over Estimate The Value",
      link3: "Have An Exit Planned ",
    },
    {
      id: 2,
      head: "High LTV 85% ",
      text: "Most people take out the max 75% LTV on day one, so when borrowing has a solid exit planned.",
      link1: "Make sure you can exit the loan",
      link2: "Fees and Interest are added in",
      link3: "How much does it cost?",
    },
    {
      id: 3,
      head: "What else should I know?",
      text: "Other useful information about bridging loans.",
      link1: "Do I need Bridging Loan?",
      link2: "Different types of Bridging Loans?",
      link3: "How much does it cost?",
    },
  ];
  const infoArray2 = [
    {
      id: 1,
      head: "Timing is key",
      text: "The earlier you start the more likely it is that you will NOT default on your existing loan.  We believe 4 weeks covers most eventualities and leaves you with no stress and hassle",
      link1: "Apply as soon as possible ",
      link2: "Start Valuation & Legals Together",
      link3: "Never default",
    },
    {
      id: 2,
      head: "High LTV 85% ",
      text: "Most people take out the max 75% LTV on day one, so when rebridging you require a product that can lend you up to 85% of your assets value",
      link1: "Make sure you can exit the loan",
      link2: "Fees and Interest are added in",
      link3: "How much does it cost?",
    },
    {
      id: 3,
      head: "What else should I know?",
      text: "Other useful information about remortgaging your home.",
      link1: "Do I need Bridging Loan?",
      link2: "Different types of Bridging Loans?",
      link3: "How much does it cost?",
    },
  ];
  const infoArray3 = [
    {
      id: 1,
      head: "What is Re-Bridging?",
      text: "When your current bridging loan has run its term, we are here to help. By Re-Bridging you can save yourself £1000’s in default penalties, buying yourself more time to sell or refinance",
      link1: "Rates from 0.45% p/m",
      link2: "Up to 85% LTV",
      link3: "Up to 24 months terms",
    },
    {
      id: 2,
      head: "When should I Re-Bridge?",
      text: "Timing is everything, by applying sooner you get a new deal in place & can avoid you paying an expensive default rate and risking repossession of your property. ",
      link1: "Complete in 5 days* ",
      link2: "Urgent re-bridges in 24 hours*",
      link3: "Fees can be added to the loan",
    },
    {
      id: 3,
      head: "What Documents Do I Need?",
      text: "Documents are always kept to a minimum, with ID, proof of address and a valuation being almost all that you need to get the ball rolling. ",
      link1: "Solicitors with 3 SRA partners ",
      link2: "RICS registered valuers",
      link3: "Re-types can be accepted*",
    },
  ];
  const infoArray4 = [
    {
      id: 1,
      head: "Do I need to be experienced?",
      text: "Experience is key. The more builds you have under your belt, the keener the rates. That’s not to say we can’t help, with rates starting at 10% pa for our budding development partners.",
      link1: "Who is experienced?",
      link2: "Ways to get the rates down?",
      link3: "How much does it cost?",
    },
    {
      id: 2,
      head: "100% Development Finance",
      text: "Does it exist? Well, the short answer is no...! We can fund up to 95% LTC as long as it doesn’t breach 75% of the GDV. And we’ll even help with the purchase of the land, up to 85% LTV.",
      link1: "100% Development Finance",
      link2: "LTC, LTGDV explained",
      link3: "Other peoples equity",
    },
    {
      id: 3,
      head: "What documents do I need?",
      text: "The more information you give us the quicker and cheaper it will be for you. We are here to guide you through this potentially expensive arena and to make sure you put your best foot forward.",
      link1: "What documents do I need?",
      link2: "Who & when to instruct?",
      link3: "What stages to draw down?",
    },
  ];
  const infoArray5 = [
    {
      id: 1,
      head: "How quickly can it be done?",
      text: "Speed is king. So when the men come knocking, we are here for you. Every case is marked URGENT and the funds are rushed out of the door. Giving you room to sort out your finances.",
      link1: "Loans done in 24/48 hours",
      link2: "Direct access to the underwriter",
      link3: "No Valuation required*",
    },
    {
      id: 2,
      head: "How much does it cost?",
      text: "The rates charged do reflect urgency. With most of our cases needing no valuation, and with a higher LTV than  normal, our risks are high. But here we are, ready to help..!",
      link1: "Rates starting at 0.75% p/m",
      link2: "Up to 85% LTV",
      link3: "Fees can be included",
    },
    {
      id: 3,
      head: "What documents do we need?",
      text: "Documents are kept to a minimum. All we ask is that when we ask for the documents you work with us to get them as soon as possible. ",
      link1: "No credit checks*",
      link2: "No Valuations needed*",
      link3: "Lightning legals",
    },
  ];
  const finalArray =
    title === "Bridging Loans"
      ? infoArray1
      : title === "Re-Bridging Finance"
        ? infoArray3
        : title === "Development Finance"
          ? infoArray4
          : title === "Stop Repossessions"
            ? infoArray5
            : infoArray2;
  const anotherClassName = title === "Bridging Loans"
    ? styles.bLoans
    : title === "Re-Bridging Finance"
      ? styles.reBridging
      : title === "Development Finance"
        ? styles.dFinance
        : title === "Stop Repossessions"
          ? styles.sRepo
          : styles.bLoans;
  return (
    <div
      className={classNames(
        styles.loanGuide,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Re-Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Re-Bridging Finance"
                  ? styles.reSources
                  : ""
      )}
    >
      <p className={styles.header}>
        {title === "Bridging Loans"
          ? "The Key Things You Need To Know About Bridging Loans"
          : title === "Re-Bridging Loans"
            ? "The key things you need to know about Re-bridging"
            : title === "Development Finance"
              ? "The key things you need to know about Development Funding"
              : title === "Stop Repossessions"
                ? "The key things you need to know about Repossessions"
                : title === "Re-Bridging Finance"
                  ? "The key things you need to know about re-bridging"
                  : ""}
      </p>
      <div className={styles.InfoBox}>
        {finalArray.map((item) => {
          return (
            <div className={classNames(styles.info, anotherClassName)}>
              <p
                className={classNames(
                  styles.infoHead,
                  title === "Bridging Loans"
                    ? styles.bLoans
                    : title === "Re-Bridging Loans"
                      ? styles.bLoans
                      : title === "Development Finance"
                        ? styles.dFinance
                        : title === "Stop Repossessions"
                          ? styles.sRepo
                          : title === "Re-Bridging Finance"
                            ? styles.reSources
                            : ""
                )}
              >
                {item.head}
              </p>
              <p
                className={classNames(
                  styles.infoText,
                  title === "Bridging Loans"
                    ? styles.bLoans
                    : title === "Re-Bridging Loans"
                      ? styles.bLoans
                      : title === "Development Finance"
                        ? styles.dFinance
                        : title === "Stop Repossessions"
                          ? styles.sRepo
                          : title === "Re-Bridging Finance"
                            ? styles.reSources
                            : ""
                )}
              >
                {item.text}
              </p>
              <div
                className={classNames(
                  styles.links,
                  title === "Bridging Loans"
                    ? styles.bLoans
                    : title === "Re-Bridging Loans"
                      ? styles.bLoans
                      : title === "Development Finance"
                        ? styles.dFinance
                        : title === "Stop Repossessions"
                          ? styles.sRepo
                          : title === "Re-Bridging Finance"
                            ? styles.reSources
                            : ""
                )}
              >
                <Link to="#">
                  {" "}
                  <img
                    src={
                      title === "Bridging Loans"
                        ? pointGreenImg
                        : title === "Re-Bridging Loans"
                          ? pointGreenImg
                          : title === "Development Finance"
                            ? pointBlueImg
                            : title === "Stop Repossessions"
                              ? pointOrangeImg
                              : title === "Re-Bridging Finance"
                                ? pointPurpleImg
                                : ""
                    }
                    alt=""
                  />{" "}
                  {item.link1}
                </Link>
                <Link to="#">
                  {" "}
                  <img
                    src={
                      title === "Bridging Loans"
                        ? pointGreenImg
                        : title === "Re-Bridging Loans"
                          ? pointGreenImg
                          : title === "Development Finance"
                            ? pointBlueImg
                            : title === "Stop Repossessions"
                              ? pointOrangeImg
                              : title === "Re-Bridging Finance"
                                ? pointPurpleImg
                                : ""
                    }
                    alt=""
                  />{" "}
                  {item.link2}
                </Link>
                <Link to="#">
                  {" "}
                  <img
                    src={
                      title === "Bridging Loans"
                        ? pointGreenImg
                        : title === "Re-Bridging Loans"
                          ? pointGreenImg
                          : title === "Development Finance"
                            ? pointBlueImg
                            : title === "Stop Repossessions"
                              ? pointOrangeImg
                              : title === "Re-Bridging Finance"
                                ? pointPurpleImg
                                : ""
                    }
                    alt=""
                  />{" "}
                  {item.link3}
                </Link>
              </div>
              <Link
                to="/faq"
                className={classNames(
                  styles.learnMoreLink,
                  title === "Bridging Loans"
                    ? styles.bLoans
                    : title === "Re-Bridging Loans"
                      ? styles.bLoans
                      : title === "Development Finance"
                        ? styles.dFinance
                        : title === "Stop Repossessions"
                          ? styles.sRepo
                          : title === "Re-Bridging Finance"
                            ? styles.reSources
                            : ""
                )}
              >
                Learn more
              </Link>
            </div>
          );
        })}
      </div>
      <Link
        to="/bridgingloans/guide"
        className={classNames(
          styles.allGuideLink,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Re-Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                  ? styles.sRepo
                  : title === "Re-Bridging Finance"
                    ? styles.reSources
                    : ""
        )}
      >
        {title === "Bridging Loans"
          ? "See all Bridging Loan Guide"
          : title === "Re-Bridging Loans"
            ? "See all Re-Bridging Loan Guide"
            : title === "Development Finance"
              ? "See all Development Finance Guides"
              : title === "Stop Repossessions"
                ? "See our guide to  Stop Repo"
                : title === "Re-Bridging Finance"
                  ? "See all Bridging Loan Guide"
                  : ""}
      </Link>
    </div >
  );
};

export default LoanGuide;
