import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Footer.module.scss";
import classNames from "classnames";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import {
  AiFillFacebook,
  AiFillTwitterCircle,
  AiFillYoutube,
  AiFillInstagram,
} from "react-icons/ai";

const Footer = ({ title }) => {
  return (
    <div
      className={classNames(
        styles.footer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : title === "Resources"
          ? styles.reSources
          : ""
      )}
    >
      <div className={styles.footerContent}>
        <div className={styles.top}>
          <div className={styles.left}>
            <p className={styles.head}>About Company</p>
            <p className={styles.info}>
              Bridging 365.com is company registered in England and Wales. Our
              company registration number 14645083. Our ICO number is ZB509976.
              We are not governed by the FCA. Our Data Protection Licence is
              ZA136260.
            </p>
          </div>
          <div className={styles.center}>
            <div className={styles.centerTop}>
              <p className={styles.addressHead}>Address</p>
              <p className={styles.addressText}>
                30 Churchill Place, Canary Wharf London, E14 5EU
              </p>
            </div>
            <div className={styles.centerBottom}>
              <a href="tel:0208 146 9888">
                {" "}
                <strong>
                  {" "}
                  <FaPhoneSquareAlt
                    className={classNames(
                      styles.icon,
                      title === "Bridging Loans"
                        ? styles.bLoans
                        : title === "Development Finance"
                        ? styles.dFinance
                        : title === "Stop Repossessions"
                        ? styles.sRepo
                        : title === "Resources"
                        ? styles.reSources
                        : styles.home
                    )}
                  />{" "}
                </strong>{" "}
                0208 146 9888
              </a>
              <a href="mailto:admin@bridging365.com">
                {" "}
                <strong>
                  <MdEmail
                    className={classNames(
                      styles.icon,
                      title === "Bridging Loans"
                        ? styles.bLoans
                        : title === "Development Finance"
                        ? styles.dFinance
                        : title === "Stop Repossessions"
                        ? styles.sRepo
                        : title === "Resources"
                        ? styles.reSources
                        : styles.home
                    )}
                  />{" "}
                </strong>{" "}
                admin@bridging365.com
              </a>
              <span className={styles.socialIcons}>
                {" "}
                <AiFillFacebook
                  className={classNames(
                    styles.socialIcon,
                    title === "Bridging Loans"
                      ? styles.bLoans
                      : title === "Development Finance"
                      ? styles.dFinance
                      : title === "Stop Repossessions"
                      ? styles.sRepo
                      : title === "Resources"
                      ? styles.reSources
                      : ""
                  )}
                />
                <AiFillTwitterCircle
                  className={classNames(
                    styles.socialIcon,
                    title === "Bridging Loans"
                      ? styles.bLoans
                      : title === "Development Finance"
                      ? styles.dFinance
                      : title === "Stop Repossessions"
                      ? styles.sRepo
                      : title === "Resources"
                      ? styles.reSources
                      : ""
                  )}
                />
                <AiFillYoutube
                  className={classNames(
                    styles.socialIcon,
                    title === "Bridging Loans"
                      ? styles.bLoans
                      : title === "Development Finance"
                      ? styles.dFinance
                      : title === "Stop Repossessions"
                      ? styles.sRepo
                      : title === "Resources"
                      ? styles.reSources
                      : ""
                  )}
                />
                <AiFillInstagram
                  className={classNames(
                    styles.socialIcon,
                    title === "Bridging Loans"
                      ? styles.bLoans
                      : title === "Development Finance"
                      ? styles.dFinance
                      : title === "Stop Repossessions"
                      ? styles.sRepo
                      : title === "Resources"
                      ? styles.reSources
                      : ""
                  )}
                />
              </span>
            </div>
          </div>
          <div className={styles.right}>
            <div style={{ width: "100%" }}>
              <iframe
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=30%20Churchill%20Place,%20Canary%20Wharf%20London,%20E14%205EU+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                width="100%"
                height="200"
                frameBorder={"0"}
                style={{ border: "0" }}
                title="map"
              ></iframe>
            </div>
            {/* <div style={{ width: "100%", marginTop: "20px" }} >
              <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" width="100%" height="200" frameBorder={"0"} style={{ border: "0" }}></iframe>
            </div> */}
          </div>
        </div>
        <div className={styles.bottom}>
          <div
            className={classNames(
              styles.links,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Resources"
                ? styles.reSources
                : ""
            )}
          >
            {/* <Link to="#">Sitemap |</Link> */}
            {/* <Link to="#">Keyfacts |</Link> */}
            <Link to="/privacy-policy">Privacy policy |</Link>
            <Link to="/cookies-policy">Cookies policy |</Link>
            <Link to="#">Terms of business |</Link>
            <Link to="#">Modern slavery policy |</Link>
            {/* <Link to="#">Gender pay gap report |</Link> */}
            {/* <Link to="#">How to complain |</Link> */}
            <Link to="#">Complaints data </Link>
          </div>
          <p className={styles.copy}>
            <span className={styles.copyText}>
              &copy; 2023 Bridging365.com. All rights reserved.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
