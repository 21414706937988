import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerB7 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Bridging Loans for Bad Credit{" "}
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
          <li>0.75% interest rates for unregulated bridging loans</li>
          <li>169 panel of lenders with wide variety of specialities</li>
          <li>Financial assistance for a number of scenarios</li>
          <li>Application decision within 3-hours in most cases</li>
          <li>48-hour return on funds in urgent cases</li>
        </ul>
        <div className={styles.text}>
          <p>
            If you’ve been wondering how to look for bridging loans for bad
            credit, you’ve reached your destination with our experienced team at
            Bridging 365 Ltd. We know that it can be a stressful time if you
            have a poor credit history and require help with a bridging loan. In
            many cases it just won’t be possible, but we can help.
          </p>
          <p>
            {" "}
            <strong>How we can Help People with Bad Credit</strong>
            There are a few reasons why we can help you acquire bridging loans,
            even if you have an adverse credit history. Let’s first talk about
            how we have access to a large panel of lender. At current standing,
            we have connections with 169 lenders. Not all of these lenders will
            provide bridging finance to someone in mortgage arrears, or an
            individual with bad credit, but purely by connecting with so many
            lenders, with wide and varied industry specialities, we can ensure
            there is the perfect fit out there for you and your specific
            situation. With this in mind our rates for non-status bridging loans
            begin at 0.75%, and what’s even better is that we completely
            understand that time is of the essence. In most cases an application
            is reviewed and a decision made within 3-hours, with funds provided
            within a 48-hour period in urgent cases.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompareContainerB7;
