import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/NewsLetterSignUp.module.scss";
import { database } from "../FirebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import loading from "../assets/images/loading.gif";

const NewsLetterSignUp = () => {
  const [email, setEmail] = useState();
  const [showLoading, setShowLoading] = useState(false);

  const collectionRef = collection(database, "newsLetterSubmissions");

  const submitHandler = () => {
    console.log(email);
    if (!email) {
      alert("Please enter your email");
      return;
    } else if (!email.match(/[a-z0-9]+@[a-z]+.[a-z]{2,3}/)) {
      alert("Please enter valid email address");
      return;
    } else {
      setShowLoading(true);
      addDoc(collectionRef, { email: email })
        .then((response) => {
          console.log(response);
          alert("Subscribed to newletter successfully ! ");
          setShowLoading(false);
        })
        .catch((error) => {
          console.log(error);
          alert("Oops !! Something went wrong. ");
          setShowLoading(false);
        });
    }
  };
  return (
    <div className={styles.newsLetterSignUp}>
      {" "}
      <section className={styles.newsLetterBgPrimary}>
        <div className={styles.newsLetterContainer}>
          <div className={styles.newsletter}>
            <div className={styles.newsLetterHeader}>
              <h1>Subscribe to our newsletter</h1>
              <p>
                Stay up to date with the roadmap progress, announcements and
                exclusive discounts feel free to sign up with your email.
              </p>
            </div>
            <div className={styles.newsLetterFooter}>
              <form
                onSubmit={(e) => e.preventDefault()}
                className={styles.newsLetterForm}
              >
                <input
                  type="email"
                  placeholder="Enter your email"
                  className={styles.input}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className={styles.submitBtn} onClick={submitHandler}>
                  Subscribe
                </button>
              </form>
              <p className={styles.pNote}>
                No spam ever, we care about the protection of your data.
                Read our{" "}
                <Link to="#" className={styles.link}>
                  {" "}
                  Privacy Policy{" "}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className={styles.loadingContainer}>
        {showLoading && (
          <img src={loading} className={styles.loadingImg} alt="" />
        )}
      </div>
    </div>
  );
};

export default NewsLetterSignUp;
