import { useEffect } from "react";
import styles from "../../FormPage.module.scss";
import classNames from "classnames";

const AboutDirector = ({ formdata, setFormdata, setDirectorProgress }) => {
  useEffect(() => {
    const values = [
      "directorFirstName",
      "directorLastName",
      "directorTitle",
      "directorDateOfBirth",
      "directorAddress1",
      "directorAddress2",
      "directorTown",
      "directorPostCode",
      "directorNumber",
      "defaultInSixYears",
      "everDeclaredBankrupt",
      "addAnotherDirector",
    ];

    let score = 0;
    for (let i = 0; i < values.length; i++) {
      if (formdata[values[i]] !== "") {
        score += 1;
      }
    }

    setDirectorProgress((score / values.length) * 100);
  }, [formdata, setDirectorProgress]);

  return (
    <form action="" className={styles.form1}>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}>
            {" "}
            What is the Director's first name?{" "}
          </span>
        </label>
        <div className={styles.answerBlock}>
          <input
            type="text"
            placeholder="Director's first name?"
            className={styles.answer}
            value={formdata.directorFirstName}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                directorFirstName: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}>
            {" "}
            What is the Director's last name?
          </span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <input
            type="text"
            placeholder="Director's last name"
            className={styles.answer}
            value={formdata.directorLastName}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                directorLastName: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}> What is your title</span>
        </label>
        <div className={styles.answerBlock}>
          <select
            name=""
            id=""
            className={styles.answer}
            value={formdata.directorTitle}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                directorTitle: e.target.value,
              });
            }}
          >
            <option value="" selected disabled>
              select option
            </option>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Dr">Dr</option>
            <option value="Miss">Miss </option>
          </select>
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}> When were you born?</span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <input
            type="date"
            placeholder="Select your birthdate"
            className={styles.answer}
            value={formdata.directorDateOfBirth}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                directorDateOfBirth: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}>
            {" "}
            1st line of the Director's address
          </span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <input
            type="text"
            placeholder="Director's address"
            className={styles.answer}
            value={formdata.directorAddress1}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                directorAddress1: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}>
            {" "}
            Enter the Director's address line 2 (optional)
          </span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <input
            type="text"
            placeholder="Director's address"
            className={styles.answer}
            value={formdata.directorAddress2}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                directorAddress2: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}> Town or City</span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <input
            type="text"
            placeholder="Enter your town or City"
            className={styles.answer}
            value={formdata.directorTown}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                directorTown: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}> Postcode?</span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <input
            type="text"
            placeholder="Enter postal code"
            className={styles.answer}
            value={formdata.directorPostCode}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                directorPostCode: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}>
            {" "}
            What is the Best Number To Contact You?
          </span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <input
            type="number"
            placeholder="Contact number"
            className={styles.answer}
            value={formdata.directorNumber}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                directorNumber: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}>
            {" "}
            Have you had any CCJ's or the Defaults in the last 6 years?
          </span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <select
            name=""
            id=""
            className={styles.answer}
            value={formdata.defaultInSixYears}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                defaultInSixYears: e.target.value,
              });
            }}
          >
            <option value="" selected disabled>
              select option
            </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        {formdata.defaultInSixYears === "Yes" && (
          <div className={styles.inputBox}>
            <div className={styles.answerBlock}>
              <textarea
                name=""
                placeholder="Please tell us more."
                id=""
                cols="40"
                rows="5"
                className={classNames(styles.answer, styles.textarea)}
                value={formdata.defaultInSixYearsDescription}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    defaultInSixYearsDescription: e.target.value,
                  });
                }}
              ></textarea>
            </div>
          </div>
        )}
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}>
            {" "}
            Have you ever been declared bankrupt?
          </span>
        </label>
        <div className={styles.answerBlock}>
          {" "}
          <select
            name=""
            id=""
            className={styles.answer}
            value={formdata.everDeclaredBankrupt}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                everDeclaredBankrupt: e.target.value,
              });
            }}
          >
            <option value="" selected disabled>
              select option
            </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        {formdata.everDeclaredBankrupt === "Yes" && (
          <div className={styles.inputBox}>
            <div className={styles.answerBlock}>
              <textarea
                name=""
                placeholder="Please tell us more."
                id=""
                cols="40"
                rows="5"
                className={classNames(styles.answer, styles.textarea)}
                value={formdata.everDeclaredBankruptDescription}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    everDeclaredBankruptDescription: e.target.value,
                  });
                }}
              ></textarea>
            </div>
          </div>
        )}
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section4)}
        >
          <span className={styles.questionText}>
            {" "}
            Would you like to add another Director?
          </span>
        </label>
        <div className={styles.answerBlock}>
          <select
            name=""
            id=""
            className={styles.answer}
            value={formdata.addAnotherDirector}
            onChange={(e) => {
              setFormdata({
                ...formdata,
                addAnotherDirector: e.target.value,
              });
            }}
          >
            <option value="" selected disabled>
              select option
            </option>
            <option value="yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
    </form>
  );
};

export default AboutDirector;
