import classNames from "classnames";
import React from "react";
import styles from "../../styles/MatchingMortgages.module.scss";
const MatchingMortgages1 = ({ title }) => {
  return (
    <div className={styles.matchingMortgages}>
      <p
        className={classNames(
          styles.header,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        What is GDV?
      </p>
      <div
        className={classNames(
          styles.infoBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <p>
          GDV stands for Gross Development Value. It is worked out by adding all
          the excepted sales values of the development. For example if you are
          building 10 flats, each worth £500,000 each your GDV is 10 x £500,000
          = £5,000,000.
        </p>
        <p>
          {" "}
          It is a way of calculating whether or not a project will return a
          profit. The GDV of a property or property development should always be
          seen as a priority as it is a clear indicator of what you can expect a
          property to sell for on the current market. It has an impact on the
          construction costs and type, the purchasing of the structure and/or
          land in question and is a key factor in determining how much funding a
          project can successfully apply for.
        </p>
        <p>
          {" "}
          <strong>Development Profit Calculator </strong>
          It is arguably the most important metric. Most developers will not
          start a project unless there is a minimum of 15% developers margin in
          the project. Profit or developers margin is calculated by subtracting
          the total of the construction, fees and land cost from the Gross
          Development Value (GDV).
        </p>
        <p>
          {" "}
          <strong>
            Profit (Developers Margin) = GDV – Costs (Land + Construction +
            Fees)
          </strong>
          When looking to borrow money for a project most lending institutions
          look for a minimum developers margin or profit of 20%, here at
          Bridging 365 we understand that a project is still feasible as long as
          there’s 12% or more developers margin in the deal. This is because we
          understand the more you scale the development the higher the numbers
          and hence when working on a development project with a GDV of £100M,
          earning £10M is respec
        </p>

        {/* <div className={styles.qandA}>
          <p className={styles.question}>
            Do I Qualify for a Bridging Loan with Bad Credit?
          </p>
          <p className={styles.answer}>
            There could be a number of reasons why you have a bad credit score
            on your file. Don’t worry though; we have access to a number of
            funders who can assist, even if you have suffered bad credit for the
            following reasons:
          </p>
        </div>
        <div className={styles.qandA}>
          <p className={styles.question}>CCJ or Defaults </p>
          <p className={styles.answer}>
            Unfortunately, you might not even know that you have a CCJ against
            your name until you apply for credit. At Bridging 365 we have a
            panel of bad credit specialist bridging funders who understand that
            just because you have a CCJ, it does not mean you are not able to
            borrow.
          </p>
        </div>
        <div className={styles.qandA}>
          <p className={styles.question}>Mortgage Repayments </p>
          <p className={styles.answer}>
            It is a similar situation for missed mortgage repayments, but this
            does not necessarily affect your chances of securing a short term
            bridging loan to stop a repossession order. Whether you require a
            regulated bridging loan for bad credit or a unregulated bridging
            loan, we are here to help, with access to 22 regulated bridging
            funders and over 90 unregulated funders, we will find a funder to
            help you save your house.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default MatchingMortgages1;
