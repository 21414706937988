import React from "react";
import styles from "../styles/TestimonialsContainer.module.scss";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
// import { Link } from "react-router-dom";
import classNames from "classnames";

const TestimonialsContainer = ({ title }) => {
  const testimonialsArray = [
    {
      id: 1,
      text: "The team members are very helpful and started the application proceed instantly and completed it in time. Therefore I would recommend going with Bridging 365 as they offer a very good option for quick finance with lower rates. ",
      customerName: "Mr. Yuan Chang, London",
    },
    {
      id: 2,
      text: "Our very first using the bridging loans for property and I think I got the best rates and service with them. I Had a call with their advisors before taking the loan and they helped me with the best solution for my situation and provided me with the capital very quickly. I would highly recommend Bridging 365 services. ",
      customerName: "Mr. David Hollendback, London",
    },
    {
      id: 3,
      text: "Excellent team! The whole process was conducted in a friendly and professional manner. They have a very easy-to-follow application process. Their assigned manager helped us throughout the whole process and provided us with the required principal in a very short time.",
      customerName: "Mr. Michael Collin, London",
    },
  ];
  return (
    <div className={styles.testimonialsContainer}>
      <p className={styles.header}>See What’s Our Customers Says</p>
      <div className={styles.testimonies}>
        {testimonialsArray.map((item) => {
          return (
            <div
              className={classNames(
                styles.testimony,
                title === "Bridging Loans"
                  ? styles.bLoans
                  : title === "Development Finance"
                  ? styles.dFinance
                  : title === "Stop Repossessions"
                  ? styles.sRepo
                  : title === "Resources"
                  ? styles.reSources
                  : ""
              )}
            >
              <p className={styles.text}>
                <FaQuoteLeft className={styles.leftIcon} /> {item.text}{" "}
                <FaQuoteRight className={styles.rightIcon} />
              </p>
              <p className={styles.customerName}>{item.customerName}</p>
            </div>
          );
        })}
      </div>
      {/* <Link
        to="#"
        className={classNames(
          styles.allReviewsLink,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        See all customer reviews
      </Link> */}
    </div>
  );
};

export default TestimonialsContainer;
