import { useEffect, useState } from "react";
import styles from "../../DevFinanceForm.module.scss";
import Question from "../Question";
import {
  AnswerBlock,
  AnswerDropdown,
  AnswerCheckBox,
  AnswerText,
  AnswerTextarea,
} from "../Answer";
import classNames from "classnames";
import { calculateInterest } from "../../utils/calculateInterest";
import Tooltip from "../Tooltip";
import info from "../../../../assets/images/info_icon.svg";
import loanGif from "../../../../assets/images/loan.gif";
import loanGif1 from "../../../../assets/images/loan1.gif";
import loanpng from "../../../../assets/images/loan.png";

const AboutLoan = ({ formData, setFormData, setLoanProgress }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onCheckBoxChange = (value) =>
    setFormData({
      ...formData,
      casesThatApply: [...formData.casesThatApply, value],
    });

  useEffect(() => {
    const values = [
      "typeOfDevelopment",
      "endDevelopment",
      "developmentProposition",
      "experienceAsDeveloper",
      "GDV",
      "loanDuration",
      "landPurchasePrice",
      "constructionCost",
      "professionalCosts",
      "purchasesRelatedFees",
      "currentFinance",
    ];

    let score = 0;
    for (let i = 0; i < values.length; i++) {
      if (typeof formData[values[i]] === "object") {
        const result = Object.values(formData[values[i]]).filter((val) => val);

        let count = 0;
        result.forEach((v) => {
          if (v) count++;
        });

        score += count * 0.5;
      } else if (formData[values[i]] != 0 && formData[values[i]] != "") {
        score += 1;
      }
    }

    setLoanProgress((score / values.length) * 100);
  }, [formData, setLoanProgress]);

  const computeResults = (newState) => {
    const {
      constructionCost,
      professionalCosts,
      landPurchasePrice,
      purchasesRelatedFees,
    } = formData;

    const totalFundingRequired =
      constructionCost.fundingRequired +
      professionalCosts.fundingRequired +
      landPurchasePrice.fundingRequired +
      purchasesRelatedFees.fundingRequired;

    const totalActualCost =
      constructionCost.actualCost +
      professionalCosts.actualCost +
      landPurchasePrice.actualCost +
      purchasesRelatedFees.actualCost;

    const interest = calculateInterest({
      sum: totalFundingRequired,
      formData,
    });

    setFormData({
      ...formData,
      ...newState,
      interest,
      fundingFees: interest * 0.02,
      totalActualCost,
      totalFundingRequired,
      profit: totalActualCost + totalFundingRequired, // can be minus
      developersMargin: totalActualCost / formData.GDV,
      LTC: totalFundingRequired / totalActualCost,
    });
  };

  return (
    <section id="section1">
      <div className={styles.section} id="section1">
        <div>
          <div className={styles.banner}>
            <span style={{ padding: "1rem 0rem 1rem 2rem" }}>
              {" "}
              <img className={styles.formHeader} src={loanGif} />
            </span>
            <p
              className={styles.head}
              style={{ flex: "1 1 100%", textAlign: "center" }}
            >
              ABOUT THE LOAN{" "}
              <div style={{ color: "#002d72" }}>SECTION 1 OF 5</div>{" "}
            </p>
            <span style={{ padding: "1rem 2rem 1rem 0rem" }}>
              {" "}
              <img className={styles.formHeader} src={loanpng} />
            </span>
          </div>
        </div>
        {/* <img src={loanPng} alt="" srcset="" className={styles.png} /> */}
        <div className={styles.gifContainer}>
          <img src={loanGif1} alt="" className={styles.gif} />
        </div>
        <p
          className={styles.text}
          style={{ backgroundColor: "#ff7232", marginBottom: "2rem" }}
        >
          Here we will be asking you about how much you want to borrow and for
          how long, followed by a little about your property and then details
          about you and your company. It should not take more than 10 mins...
        </p>
      </div>
      <div className={styles.inputBox}>
        <Question question="Development Type" section="section1" />
        <AnswerDropdown
          placeholder="Type of Development"
          options={[
            "New Build",
            "Conversion",
            "Permitted Development",
            "Refurbishment",
            "Refinance",
          ]}
          value={formData.typeOfDevelopment}
          onChange={(e) => {
            if (!formData.typeOfDevelopment)
              setLoanProgress((progress) => progress + 10);
            setFormData({
              ...formData,
              typeOfDevelopment: e.target.value,
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="How do you classify your end development?"
          section="section1"
        />
        <AnswerDropdown
          placeholder="End Development"
          value={formData.endDevelopment}
          options={[
            "Residential",
            "Mixed Use",
            "Licenced HMO",
            "Hotel",
            "Leisure",
            "Care Home",
            "Medical",
            "Office",
            "Retail",
            "Indurstrial Light",
            "Student",
            "Industrial Heavy",
            "Land without Planning",
            "Land with Planning",
            "Retirement",
          ]}
          onChange={(e) => {
            if (!formData.endDevelopment)
              setLoanProgress((progress) => progress + 10);
            setFormData({ ...formData, endDevelopment: e.target.value });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        {formData.endDevelopment === "Residential" && (
          <>
            <Question question="House or Flats" section="section1" />
            <AnswerDropdown
              placeholder="Select One"
              value={formData.housesOrFlats}
              options={["House", "Flats", "House & Flats"]}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  housesOrFlats: e.target.value,
                })
              }
            />
            {formData.housesOrFlats === "Flats" ? (
              <>
                <Question question="How many Flats?" section="section1" />
                <AnswerText
                  type="number"
                  placeholder="no of flats"
                  value={formData.residential.noOfFlats}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      residential: {
                        ...formData.residential,
                        noOfFlats: e.target.value,
                      },
                    })
                  }
                />
              </>
            ) : (
              <>
                <Question question="How many houses?" section="section1" />
                <AnswerText
                  type="number"
                  value={formData.residential.noOfHouses}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      residential: {
                        ...formData.residential,
                        noOfHouses: e.target.value,
                      },
                    })
                  }
                />
                {formData.housesOrFlats === "House & Flats" && (
                  <>
                    <Question question="How many Flats?" section="section1" />
                    <AnswerText
                      type="number"
                      value={formData.residential.noOfFlats}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          resisdential: {
                            ...formData.residential,
                            noOfFlats: e.target.value,
                          },
                        })
                      }
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
        {formData.endDevelopment === "Mixed Use" && (
          <>
            <Question question="House or Flats" section="section1" />
            <AnswerDropdown
              value={formData.housesOrFlats}
              placeholder="Select One"
              options={["House", "Flats", "House & Flats"]}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  housesOrFlats: e.target.value,
                })
              }
            />
            {formData.housesOrFlats === "Flats" ? (
              <>
                <Question question="How many Flats?" section="section1" />
                <AnswerText
                  type="number"
                  value={formData.mixedUse.noOfFlats}
                  placeholder="How many Flats?"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      mixedUse: {
                        ...formData.mixedUse,
                        noOfFlats: e.target.value,
                      },
                    })
                  }
                />
              </>
            ) : (
              <>
                <Question question="How many houses?" section="section1" />
                <AnswerText
                  value={formData.mixedUse.noOfHouses}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      mixedUse: {
                        ...formData.mixedUse,
                        noOfHouses: e.target.value,
                      },
                    })
                  }
                />
                {formData.housesOrFlats === "House & Flats" && (
                  <>
                    <Question question="How many Flats?" section="section1" />
                    <AnswerText
                      type="number"
                      value={formData.mixedUse.noOfFlats}
                      placeholder="How many Flats?"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          mixedUse: {
                            ...formData.mixedUse,
                            noOfFlats: e.target.value,
                          },
                        })
                      }
                    />
                  </>
                )}
              </>
            )}
            <AnswerBlock>
              <span>Please select all the class uses that apply</span>
              <AnswerCheckBox
                AnswerCheckBox
                value="A1/E"
                text="Shops"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="A2/E"
                text="Financial and professional services"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="A3/E"
                text="Restaurents and cafes"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="A4/Sui Generis"
                text="Drinking establishments"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="A5/Sui Generis"
                text="Hot food takeaways"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="B1/E"
                text="Business"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="B2"
                text="General Industrial"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="B8"
                text="Storage or distribution"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="C1"
                text="Hotels"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="C2"
                text="Residential Institutions"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="C2A"
                text="Secure Residential Institutions"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="C3"
                text="Dwelling Houses"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="C4"
                text="Houses in multiple Occupation"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="D1/E(e)"
                text="Medical or health services"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="D1/E(f)"
                text="Creche, day nursery or day centre"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="D1/F1"
                text="Learning and non-residential instituions"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="D2/E(d)"
                text="Indoor sport, recreation or fitness"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="D2/F2(c)"
                text="Areas for outdoor sport or recreation"
                onChange={onCheckBoxChange}
              />
              <AnswerCheckBox
                AnswerCheckBox
                value="D2/F2(d)"
                text="Indoor or outdoor swimming pools or skating rinks"
                onChange={onCheckBoxChange}
              />
            </AnswerBlock>
            <Question
              question="Do you have pre-sale agreement?"
              section="section1"
            />
            <AnswerDropdown
              options={["Yes", "No"]}
              value={formData.preSaleAgreement}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  preSaleAgreement: e.target.value,
                })
              }
            />
            <Question
              question="Do you have pre-let agreement?"
              section="section1"
            />
            <AnswerDropdown
              options={["Yes", "No"]}
              value={formData.preLetAgreement}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  preLetAgreement: e.target.value,
                })
              }
            />
          </>
        )}
        {formData.endDevelopment === "Licenced HMO" && (
          <>
            <div>
              <Question
                question="How many lettable rooms?"
                section="section1"
              />
              <AnswerText
                type="number"
                placeholder="How many lettable rooms?"
                value={formData.noOfLettableRooms}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    noOfLettableRooms: +e.target.value,
                  })
                }
              />
            </div>
            <div>
              <Question
                question="Has planning consent been granted?"
                section="section1"
              />
              <AnswerDropdown
                options={["Yes", "No"]}
                value={formData.hasPlanningConsent}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    hasPlanningConsent: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <Question
                question="Has the HMO licence been issued?"
                section="section1"
              />
              <AnswerDropdown
                options={["Yes", "No"]}
                value={formData.isHMOIssued}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    isHMOIssued: e.target.value,
                  })
                }
              />
            </div>
          </>
        )}
        {formData.endDevelopment != "Residential" &&
          formData.endDevelopment != "Mixed Use" &&
          formData.endDevelopment != "Licenced HMO" && (
            <>
              <Question
                question="Do you have pre-sale agreement?"
                section="section1"
              />
              <AnswerDropdown
                options={["Yes", "No"]}
                value={formData.preSaleAgreement}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    preSaleAgreement: e.target.value,
                  })
                }
              />
              <Question
                question="Do you have pre-let agreement?"
                section="section1"
              />
              <AnswerDropdown
                options={["Yes", "No"]}
                value={formData.preLetAgreement}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    preLetAgreement: e.target.value,
                  })
                }
              />
            </>
          )}
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Briefly explain the development proposition"
          section="section1"
        />
        <AnswerTextarea
          placeholder="Development proposition"
          value={formData.developmentProposition}
          onChange={(e) => {
            if (!formData.developmentProposition)
              setLoanProgress((progress) => progress + 10);
            setFormData({
              ...formData,
              developmentProposition: e.target.value,
            });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Your experience as a developer"
          section="section1"
        />
        <AnswerDropdown
          value={formData.experienceAsDeveloper}
          onChange={(e) => {
            if (!formData.experienceAsDeveloper)
              setLoanProgress((progress) => progress + 10);
            setFormData({
              ...formData,
              experienceAsDeveloper: e.target.value,
            });
          }}
          options={[
            "First Time Developer",
            "Less than 5 projects of various types",
            "Less than 5 projects of similar size",
            "5+ various projects",
            "5+ projects like this",
          ]}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="What is Gross Development value (GDV) of the project?"
          section="section1"
        />
        <AnswerText
          isMoney={true}
          value={formData.GDV}
          placeholder="0"
          onChange={(e) => {
            if (!formData.GDV) setLoanProgress((progress) => progress + 10);
            setFormData({ ...formData, GDV: e.target.value });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Please select the number of months you would like the loan for?"
          section="section1"
        />
        <AnswerDropdown
          value={formData.loanDuration}
          options={[3, 6, 12, 18, 24, 36]}
          onChange={(e) => {
            if (!formData.loanDuration)
              setLoanProgress((progress) => progress + 10);
            setFormData({ ...formData, loanDuration: e.target.value });
          }}
        />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Purchase price of Land/Current site value"
          section="section1"
        />
        <div className={styles.grid}>
          <div className={styles.gridBox}>
            <h4>Actual Cost</h4>
            <AnswerText
              type="number"
              isMoney={true}
              placeholder="0"
              value={formData.landPurchasePrice.actualCost}
              onChange={(e) => {
                if (formData.landPurchasePrice.actualCost)
                  setLoanProgress((progress) => progress + 5);
                computeResults({
                  ...formData,
                  landPurchasePrice: {
                    ...formData.landPurchasePrice,
                    actualCost: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className={styles}>
            <div className={styles.gridBox}>
              <h4>Funding Required</h4>
              <AnswerText
                type="number"
                isMoney={true}
                placeholder="0"
                value={formData.landPurchasePrice.fundingRequired}
                onChange={(e) => {
                  if (!formData.landPurchasePrice.fundingRequired)
                    setLoanProgress((progress) => progress + 5);
                  computeResults({
                    landPurchasePrice: {
                      ...formData.landPurchasePrice,
                      fundingRequired: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.inputBox}>
        <Question question="Construction Cost" section="section1" />
        <div className={styles.grid}>
          <div className={styles.gridBox}>
            <h4>Actual Cost</h4>
            <AnswerText
              placeholder="0"
              isMoney={true}
              value={formData.constructionCost.actualCost}
              onChange={(e) => {
                if (!formData.constructionCost.actualCost)
                  setLoanProgress((progress) => progress + 5);
                computeResults({
                  ...formData,
                  constructionCost: {
                    ...formData.constructionCost,
                    actualCost: e.target.value
                  },
                });
              }}
            />
          </div>
          <div className={styles.gridBox}>
            <h4>Funding Required</h4>
            <AnswerText
              type="number"
              placeholder="0"
              isMoney={true}
              value={formData.constructionCost.fundingRequired}
              onChange={(e) => {
                if (!formData.constructionCost.fundingRequired)
                  setLoanProgress((progress) => progress + 5);
                computeResults({
                  constructionCost: {
                    ...formData.constructionCost,
                    fundingRequired: e.target.value
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.inputBox}>
        <label
          htmlFor=""
          className={classNames(styles.question, styles.section1)}
        >
          <span
            className={styles.questionText}
            style={{ display: "flex", position: "relative" }}
          >
            Professional Costs and charges e.g S106, CIL, Architecture
            <span
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <img src={info} className={styles.tooltip_icon} />
              {showTooltip && <Tooltip />}
            </span>
          </span>
        </label>
        <div className={styles.grid}>
          <div className={styles.gridBox}>
            <h4>Actual Cost</h4>
            <AnswerText
              type="number"
              placeholder="0"
              isMoney={true}
              value={formData.professionalCosts.actualCost}
              onChange={(e) => {
                if (formData.professionalCosts.actualCost === null)
                  setLoanProgress((progress) => progress + 5);
                computeResults({
                  ...formData,
                  professionalCosts: {
                    ...formData.professionalCosts,
                    actualCost: e.target.value
                  },
                });
              }}
            />
          </div>
          <div className={styles.gridBox}>
            <h4>Funding Required</h4>
            <AnswerText
              type="number"
              placeholder="0"
              isMoney={true}
              value={formData.professionalCosts.fundingRequired}
              onChange={(e) => {
                if (formData.professionalCosts.fundingRequired === null)
                  setLoanProgress((progress) => progress + 5);
                computeResults({
                  professionalCosts: {
                    ...formData.professionalCosts,
                    fundingRequired: e.target.value
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Purchase related fees eg: Stamp Duty, Legal Costs, Surveys"
          section="section1"
        />
        <div className={styles.grid}>
          <div className={styles.gridBox}>
            <h4>Actual Cost</h4>
            <AnswerText
              placeholder="0"
              isMoney={true}
              value={formData.purchasesRelatedFees.actualCost}
              onChange={(e) => {
                const callback = () => {
                  if (formData.purchasesRelatedFees.actualCost === null)
                    setLoanProgress((progress) => progress + 5);
                };
                computeResults({
                  ...formData,
                  purchasesRelatedFees: {
                    ...formData.purchasesRelatedFees,
                    actualCost: e.target.value
                  },
                });
                return callback;
              }}
            />
          </div>
          <div className={styles.gridBox}>
            <h4>Funding Required</h4>
            <AnswerText
              type="number"
              placeholder="0"
              isMoney={true}
              value={formData.purchasesRelatedFees.fundingRequired}
              onChange={(e) => {
                computeResults({
                  purchasesRelatedFees: {
                    ...formData.purchasesRelatedFees,
                    fundingRequired: e.target.value
                  },
                });
                if (!formData.purchasesRelatedFees.fundingFees) {
                  setLoanProgress((progress) => progress + 5);
                } else if (formData.purchasesRelatedFees.fundingFees === 0) {
                  setLoanProgress((progress) => progress - 5);
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Interest (added to the loan, & calculated for you)"
          section="section1"
        />
        <AnswerText isMoney={true} placeholder="0" value={formData.interest} />
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Funding fees (added to the loan, & calculated for you)"
          section="section1"
        />
        <AnswerText
          type="number"
          isMoney={true}
          placeholder="0"
          value={formData.fundingFees}
        />
      </div>
      <div className={styles.inputBox} style={{ marginTop: "1rem" }}>
        <div className={styles.grid}>
          <div style={{ marginBottom: "1rem" }}>
            <Question question="Total Actual Cost" section="section1" />
            <AnswerText
              type="number"
              isMoney={true}
              placeholder="0"
              value={formData.totalActualCost}
            />
          </div>
          <div>
            <Question question="Total Funding required" section="section1" />
            <AnswerText
              type="number"
              isMoney={true}
              placeholder="0"
              value={formData.totalFundingRequired}
            />
          </div>
        </div>
      </div>
      <div className={styles.inputBox}>
        <Question question="Profit" section="section1" />
        <AnswerText
          type="number"
          isMoney={true}
          placeholder="0"
          value={formData.profit}
        />
      </div>
      <div className={styles.inputBox}>
        <div>
          <Question question="Developers margin" section="section1" />
          <AnswerText
            type="number"
            placeholder="0"
            isMoney={true}
            value={formData.developersMargin}
          />
        </div>
        <div>
          <Question question="LTC" section="section1" />
          <AnswerText
            type="number"
            isMoney={true}
            value={formData.LTC}
            placeholder="0"
          />
        </div>
      </div>
      <div className={styles.inputBox}>
        <Question
          question="Is there any current finance we will be repaying?"
          section="section1"
        />
        <AnswerDropdown
          value={formData.currentFinance}
          options={["Yes", "No"]}
          onChange={(e) => {
            if (!formData.currentFinance)
              setLoanProgress((progress) => progress + 5);
            setFormData({ ...formData, currentFinance: e.target.value });
          }}
        />
      </div>
      {formData.currentFinance === "Yes" && (
        <>
          <div className={styles.inputBox}>
            <Question
              question="What is the lender's name?"
              section="section1"
            />
            <AnswerText
              type="text"
              onChange={(e) =>
                setFormData({ ...formData, lenderName: e.target.value })
              }
            />
          </div>
          <div className={styles.inputBox}>
            <Question
              question="When is the loan due to expire?"
              section="section1"
            />
            <AnswerText
              type="text"
              onChange={(e) =>
                setFormData({ ...formData, loanExpiry: e.targe.value })
              }
            />
          </div>
          <div className={styles.inputBox}>
            <Question
              question="How much is the total outstanding balance?"
              section="section1"
            />
            <AnswerText
              type="text"
              isMoney={true}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  totalOutstandingBalance: e.target.value,
                })
              }
            />
          </div>
        </>
      )}
      {formData.currentFinance === "No" && (
        <>
          <div className={styles.inputBox}>
            <Question
              question="What would be your exit stratgey?"
              section="section1"
            />
            <AnswerTextarea
              onChange={(e) =>
                setFormData({ ...formData, exitStrategy: e.target.value })
              }
            />
          </div>
        </>
      )}
    </section>
  );
};

export default AboutLoan;
