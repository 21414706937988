import classNames from "classnames";
import React from "react";
import styles from "../styles/Boxes.module.scss";
import calcGif from "../assets/images/calcGif.gif"
const Boxes = ({ title }) => {
  const bridgingLoanLink = "https://www.youtube.com/embed/FViZ2-oGTRU";
  const developmentFinanceLink = "https://www.youtube.com/embed/GP79BnI4yfE";
  const stopRepossessionLink = "https://www.youtube.com/embed/rYl2BjdZhBc";
  const resourcesLink = "https://www.youtube.com/embed/PUMd7SqUaUE";
  return (
    <div className={styles.boxes}>
      <div
        className={classNames(
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <p
          className={classNames(
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : title === "Resources"
              ? styles.reSources
              : ""
          )}
        >
          An expert’s guide to Bridging Loan
        </p>
        <div className={styles.video}>
          <iframe
            src={
              title === "Bridging Loans"
                ? bridgingLoanLink
                : title === "Development Finance"
                ? developmentFinanceLink
                : title === "Stop Repossessions"
                ? stopRepossessionLink
                : title === "Resources"
                ? resourcesLink
                : ""
            }
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
            title="video"
            className={styles.iframe}
          />
        </div>
      </div>
      <div
        className={classNames(
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        <p
          className={classNames(
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : title === "Resources"
              ? styles.reSources
              : ""
          )}
        >
          How much can I borrow?
        </p>
        <div className={styles.calculator}>
          {/* <p>calculator going to be here</p> */}
          <img src={calcGif} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Boxes;
