import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainer6 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        Splitting Deeds
      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
         <li>        Bridging Loans to Split Deeds with Partner after Divorce
</li>
         <li>Financial Assistance to Split Commercial Deeds when Business Partners Part Ways
</li>
         <li>Access to Wide Ranging Panel of Bridging Loan Funders
</li>
         <li>Fast-Paced Decision on all Applications
</li>
         <li>LTV up to 70%+ (100% with Additional Security)
</li>
         <li>Rates from 0.29% on Bridging Finance
</li>
         
        </ul>
        <div className={styles.text}>

<p>At Bridging365 we have experience of helping countless people find the short-term financing that can make a real, and positive, difference to their lives. When it comes to splitting deeds on a property that is owned with someone else, it can often be a difficult time. What our team can do, is help you split deeds and move on with your life with as little stress and complication as possible, allowing you to do so in as short a time as possible also.</p>
</div>
      </div>
    </div>
  );
};

export default CompareContainer6;
