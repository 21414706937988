export const infoArrayB1 = [
    {
      id: 1,
      para: "With access to such a vast panel of funders, we have the ability to put together a bespoke plan of action for each individual set of circumstances. Whether the bridging loan is required to cover a tax bill for a business, fund refurbishment of property for houses in multiple occupation, or for large scale export construction and development projects, we have the funders that can assist you.",
    },
    {
      id: 2,
      para: "In an area of finance where expertise is a necessity, working with a team that has access to specialist funders within the bridging market can make all the difference to the success of your project, no matter what sphere you are working within. We’ll work with you to put together a specific plan of action, ensuring that the funding plan is sustainable and works within industry standards, prior to agreeing to a large bridging loan.",
    },
    {
      id: 3,
      para: "The speed in which financing can be acquired, alongside a flexible set of terms, is a huge benefit of large bridging loans, particularly where it concerns large-scale property developers.",
    },
    {
      id: 4,
      para: "For large bridging loans within commercial and property development projects, look to a team of bridging loan specialists. Bridging 365 has access to 169 funders with experience of many different types of industries and scenarios. Offering to lend up to 100% LTV in certain circumstances, we can assess your portfolio and put together a bridging finance package that hits the spot, allowing you to complete transactions and keep pushing forward with developments that run to massive budgets and strict time export constraints.",
    },
    {
      id: 5,
      para: "Contact our customer service team for a consultation and find out more information about large bridging loans and how they can help your commercial or property development aspirations.",
    },
  ];
  export const infoArrayB2 = [
    {
      id: 1,
      para: "If you have found that you have suffered adverse credit due to any of the problems noted above, do not worry too much, as there is help available. Bad credit will damage applications for credit through traditional high street funders, but there are other options available.",
    },
    {
      id: 2,
      para: "A bridging loan can be provided to individuals with bad credit. Our team will take a thorough look at your financial history and projections, and help put together a plan of action. A non-status bridging loan is a possibility even if you have a poor credit history.",
    },
    {
      id: 3,
      para: "To qualify for a bridging loan with bad credit it is important that you have a property (or other asset) to offer as security against the loan, and an exit plan that is reasonable and achievable within a suitable timeframe, such as selling the property at a future date.",
    },
    {
      id: 4,
      para: "Bridging Finance Benefits -  The biggest benefit to bridging finance is that it is an acceptable form of lending for individuals who have previously suffered due to a poor credit file. Although there are higher costs associated than with a loan through traditional funders, the ability to acquire credit in dire financial circumstances provides hope for a brighter future to those who could previously see none.",
    },
    {
      id: 5,
      para: "Bridging finance ensures that you are not limited because of bad credit, helping to prevent a repossession notice against a property, allowing you to purchase a property at auction or take on board another short-term opportunity, and allowing you to purchase a new home whilst waiting for your current property to sell.",
    },
    {
      id: 6,
      para: "It doesn’t have to be difficult to acquire credit if you have bad credit, or an adverse credit history. At Bridging 365 we have a vast panel of funders offering a variety of bridging loan solutions to those applicants with bad credit.  We have a strong history of helping those who are unfortunately in financial difficulty and require some assistance in short-term finances.",
    },
    {
      id: 7,
      para: "Contact our customer service team and we’ll be happy to discuss your circumstances in full and put together some options for your future financial situation, including bridging loans to help plug short-term holes in your finances. We can help stop a repossession order in its tracks, and in some cases won’t even need to perform a credit check. Rates start from as low as 0.75% per month.",
    },
  ];
  export const infoArrayB3 = [
    {
      id: 1,
      para: "For more information on a bridging loans Nationwide application, contact the friendly customer support team here at Bridging 365. Alternatively, why not take a look at our easy to use Bridging Loans Calculator page, to see what we can offer you. As mentioned, our vast panel of Bridging 365 provides us with access to the best deals and flexible rates on the market today, including those away from the traditional high street Bridging 365. All applications are processed as soon as possible (usually within a 3-hour period), with funds released within 48-hours upon request. We look forward to assisting you with your bridging loan needs.",
    },
  ];
  export const infoArrayB4 = [
    {
      id: 1,
      para: "You might have arrived at this page looking for the comfort of a big brand such as HSBC, but the fact of the matter is that the traditional high street funders can no longer open doors for any applicant when it comes to short-term financing to bridge a financial gap. What we offer is a high quality and wide variety of bridging loan funders as part of our panel (we have 169 funders currently), which ensures that no matter what your circumstances are, we are more than likely able to assist you. Our large panel of funders is much more well versed in the intricacies of the bridging loan industry, and might be a far better fit for your personal circumstances than a traditional lender.",
    },
    {
      id: 2,
      para: "Our rates start at 0.45% and we cater for both commercial and residential property purposes, with 80% LTV (we can accommodate 100% LTV where additional security is available).",
    },
    {
      id: 3,
      para: "If you would like to find out more about how we can help you with our bridging loan service, please feel free to take a look at our simple to use Bridging Loans Calculator page, where you can get a fair idea of what to expect in terms of rates and repayment terms when applying for a loan through us. The application process itself is quick and easy and if successful we can have the funds delivered to you within 48 hours of the application being sent over to us. Bridging 365 looks forward to helping you with your bridging finance needs.",
    },
  ];
  export const infoArrayB5 = [
    {
      id: 1,
      para: "As you can see, our wide panel of bridging loan lines puts us in a unique position to give you options that Barclays, and other high street funders, might not be able to. No matter your circumstances, we can deliver the right fit for your financial needs, helping you to achieve your goals in a way that is tailored to you specifically, rather than a generic approach that the big guns might choose to take.",
    },
    {
      id: 2,
      para: "Our rates start from a competitive 0.45% and we can offer up to 80% LTV in the majority of cases. If you can offer additional security for the loan we can sometimes offer up to 100% LTV. Take a look at our bridging loan calculator page to see what you can expect for your requirements and if you wish to make an application it is simple and quick to do so. We’ll respond with a decision within a 3-hour period in most cases and release the funds within 2-working days wherever possible.",
    },
  ];
  export const infoArrayB6 = [
    {
      id: 1,
      para: "Contact our team today and we’ll be happy to run through the specific finances related to your own application for a bridging loan. We can ensure that you know exactly what to expect in terms of fees, interest rates and eventual repayments, to secure funding that can help you with your property purchase, refurbishment project or commercial venture. We look forward to assisting you.",
    },
  ];
  export const infoArrayB7 = [
    {
      id: 1,
      para: "If you are looking for bridging loans for bad credit the team at Bridging 365 Ltd is here to help. If you are in a position where you have suffered adverse credit there is no need to unduly worry. We are in a position to take advantage of connections to well over 150 lenders in the marketplace, and with that in mind we can offer you financial funding options that you might not have thought were open to you in terms of short-term bridging finance to assist with property purchases, stopping repossession of homes and paying tax bills.",
    },
    {
      id: 2,
      para: "Speak to our customer service team and we’ll be happy to put together a bespoke plan of action that includes specific details on the type of loan that you require and the exit strategy that you have in place. We can even offer a non-status loan for those with a bad credit score. Our rates start from 0.75% per month and in some cases we can have the funding over to you within 48-hours of your application being accepted.",
    },
  ];
  export const infoArrayB8 = [
    {
      id: 1,
      para: "If you are responsible for the next phase of a company’s development and require a conversation about commercial bridging finance to help plug the funding gap for a property purchase or refurbishment project, contact Bridging 365 today. Our team has vast experience with helping all sorts of businesses, within myriad industries, put together a plan of action that enables company growth and a smooth transition to maximising the potential of a group of employees through the careful application of funds.",
    },
    {
      id: 2,
      para: "Our large panel of funders is based throughout the UK and beyond, and it is with this variation that has allowed us to be able to create bespoke funding plans for our clients, no matter the situation they face. In the most urgent cases, we can even turn around a commercial bridging loan within a 48-hour period. Call us for a consultation with a friendly and effective member of our team and they’ll be happy to take on board all of your commercial requirements and guide you down the right path for the future of your company.",
    },
  ];
  export const infoArrayB9 = [
    {
      id: 1,
      para: "As our name suggest, our team of experts are on the case all year round, and with that in mind we should always be the assistance you look for when requiring bridging finance in a short space of time.",
    },
    {
      id: 2,
      para: "After an initial consultation, as quick as a conversation over the phone, we’ll look at all of the information in front of us, and be able to point you in the right direction for bridging finance as soon as possible. We can even help those looking for bridging loans with bad credit. Our approach is thorough and limits delays as we’re on your side from start to finish, throughout the application process.",
    },
    {
      id: 3,
      para: "Sometimes the need for cash, to ensure a transaction is completed, is a pressing one. At Bridging 365 we understand this entirely and that’s why we have grown over the years to include a wide and varied panel of bridging finance providers. We have access to 169 Bridging 365 throughout the UK and abroad, and if you come to us requiring urgent bridging finance, we can work towards your goal, with rates from 0.45%. Contact our customer service team today and we’ll be happy to put together a plan of action that suits your urgent need for access to fast bridging loans.",
    },
  ];
  export const infoArrayB10 = [
    {
      id: 1,
      para: "Bridging 365 has access to loans that can help plug the financial gap when looking to sell property and assets in order to pay an inheritance tax bill. We can offer loans from £25,000 up to £50 million, with loans up to 70% LTV (100% where additional security is an option), and flexible repayment terms.",
    },
    {
      id: 2,
      para: "If you have been provided for in the inheritance of a deceased family member of friend, and you are faced with an inheritance tax bill on a property or other asset, a bridging loan could be the answer to your problems to pay that bill on time. With the experience of the team at Bridging 365, and our connections to a large panel of funders, we have the ability to assist you in securing bridging finance that can pay an inheritance tax bill before you lose the assets in question.",
    },
  ];
  export const infoArrayB11 = [
    {
      id: 1,
      para: "If you have struggled to find a funder that can provide you with bridging finance due to your personal financial situation, whether that is a poor credit history, mortgage arrears or bankruptcy notice, we have the panel of funders in place to help us find you the right solution for your circumstances.",
    },
    {
      id: 2,
      para: "With rates on non-status loans beginning at 0.75% per month and 100% LTV in cases where additional security can be offered, our team is ready to help even those in the most difficult bridging loan scenario. Contact us today and we’ll be happy to put together a bespoke plan of action based on your specific requirements, loan value required, length of repayment and exit strategy. We look forward to helping you.",
    },
  ];
  export const infoArrayD1 = [
    {
      id: 1,
      para: "With access to such a vast panel of lenders across the UK, Bridging365 has the team and contacts in place to offer specialised development funding options for both commercial and residential construction projects. With rates starting from as low as 0.45% per month, up to a value of 100% of costs where extra security can be offered, you can acquire funding starting from £25,000 for a development project.",
    },
    {
      id: 2,
      para: "Contact our specialist development finance team and we can provide a thorough examination of your development proposals, and put together a specific plan of action that suits your requirements, offering advice and guidance towards the correct development funding for you.",
    },
  ];
  export const infoArrayD2 = [
    {
      id: 1,
      para: "Speak to a team of large development finance experts by contacting Bridging365. We understand how vital it is to secure fast and flexible funding for large-scale projects, providing the foundation with which successful projects can be completed. For large commercial and residential projects, new-build and heavy refurbishment, as well as for expanding a business, our wide panel of funders can be utilised to find your maximum potential.",
    },
    {
      id: 2,
      para: "Each project is taken on its specific merits and with our expertise and available options we can offer either staggered release of funds or up to 100% of the associated costs in some cases. Speak to our team today for large development finance up to £50 million.",
    },
  ];
  export const infoArrayD3 = [
    {
      id: 1,
      para: "Development finance ,bad Credit. You might not think that those two phrases go together and traditionally you’d be right. Bridging 365 has the answers however, to ensure that even a developer with bad credit can acquire the finance required to fund a development project. We offer up to 75% GDV and 100% construction and land costs where applicable, and our expertise in the area gives us the proven track record that supplies you with the tools you need to get the job done and to maximise your experience and profits.",
    },
    {
      id: 2,
      para: "Call our friendly customer service team today for more information, or fill out the simple-to-use development finance application form on this site and we’ll be in touch shortly.",
    },
  ];
  export const infoArrayD4 = [
    {
      id: 1,
      para: "Alternatively, please feel free to contact our development finance specialists, for help filling out the application form and for more information relating to your specific development project, required funds and future prospects. We look forward to assisting you.",
    },
 
  ];
  export const infoArrayD5 = [
    {
      id: 1,
      para: "The process of applying for development finance has never been easier. We have a simple development finance form that you can fill out in a matter of minutes, here on this very website. If you would like to speak in more detail with a member of our team, we can certainly help. With access to our vast network of funders, Bridging365 has all the tools to help you put together a successful first development project.",
    },
    {
      id: 2,
      para: "We appreciate that the first time is often the hardest, and that’s why we’ve put in place a service that matches the expectations of our clients, helping them succeed and build the strong foundations for a successful property development portfolio. Our rates start from 0.75% and we can offer up to 100% LTV where additional security, other than the primary property or plot of land, is available.",
    },
    {
      id: 3,
      para: "Contact our team today and we’ll happily put together some advice and guidance on development loans for a first time developer.",
    },
  ];
  export const infoArrayD6 = [
    {
      id: 1,
      para: "When it comes to assisting applicants with bridging finance for splitting deeds on a property, Bridging 365 has access to a large number of funders with experience in this niche area of the industry. Whether you are looking to pay off debt associated with an inherited property, divorcing or breaking up with a partner that you live with, or wish to secure the property in a commercial prospect where your business partner is leaving, we have the means to help you with short-term finance.",
    },
    {
      id: 2,
      para: "Take a look at the simple bridging loan calculator page to see how we can help, and for more information you can speak to a member of our experienced team. We help provide the short-term funding that you need to help split the deeds on a property you own, no matter the circumstances you face. For difficult relationship breakups, to amicable splits for couple with a poor credit history, our connections allow us to provide the perfect funding match for you.",
    },
    {
      id: 3,
      para: "Bridging 365 has built up a large panel of funders within the bridging finance industry. This means that even if you are looking to split deeds on an inherited property with a sibling (or other family member), we can pair you with a funder that can provide you with the funds you need. Currently we have connections with 13 funders that can provide bridging loans for inherited property purposes.",
    },
  ];
  export const infoArrayD7 = [
    {
      id: 1,
      para: "What this means for a landlord looking to convert a house into flats, is that funds can be released quickly for their needs. This could be solely for construction and renovation costs, or encompass the purchasing of the property/plot, as well as construction costs.",
    },
    {
      id: 2,
      para: "Bridging Ltd 365 has access to a large panel of bridging finance funders. This means that we can offer you the chance to connect with funding that can help transform a home into a selection of flats to let out to tenants. You might wish to lease the flats out to students, young professionals or families, but we can ensure you can do so with a faster turnaround than would have been possible through traditional lending streams. Contact us today and we’ll be happy to provide you with more information about how we can help you convert your home into flats with a bridging loan.",
    },
  ];
  export const infoArrayC1 = [
    {
      id: 1,
      para: "As you can see, Bridging 365 has a lot to offer even those with a bad credit history and even bankruptcy on their credit file. If you would like to speak with a member of our team to garner further information about our panel of bridging loan funders, please feel free to give us a call today. We understand that it is tough to secure funding for any sort of project or bill paying requirement when you have been bankrupt, and that’s why we endeavour to create a situation where everyone can find the financial assistance they need.",
    },
    {
      id: 2,
      para: "With rates from 0.75%, fast application decisions and quick release of funds from over 90 unregulated bridging loan funders, Bridging 365 are the team you can trust when you are searching for bridging loans for bankrupt individuals. We look forward to hearing from you.",
    },
  ];
  export const infoArrayC2 = [
    {
      id: 1,
      para: "What we set out to do at Bridging 365 was create a stable of bridging loan funders that was wide and varied. That way, when it came to opening our doors to borrowers, we could be confident that we had the match in mind for each specific need.",
    },
    {
      id: 2,
      para: "People aged 60 and over will struggle traditionally to find credit and large finance deals due to the traditional funders, such as the high street banks, viewing that age group as ‘high risk’. With our extensive network of funders, that has been built-up over a number of years, we have the ability to help find bridging loans for over 60s, utilising the specific funders in that niche category. Our rates start at 0.75% per month, with 85% LTV, and up to 100% LTV where additional security is applicable",
    },
    {
      id: 3,
      para: "If you are over 60 and need funding through a bridging loan, there is a simple to use application form on this very website. For more in-depth analysis and information relating to your specific needs, you can find contact information in order to speak with our friendly team.",
    },
  ];
  export const infoArrayC3 = [
    {
      id: 1,
      para: "Things can become complicated when two owners of a company wish to part ways. If the business in question owns the property that they work out of, it can be tricky to separate the two parties interests in a fair way that enables both to move on with their lives, especially if one is staying to continue running the business. Bridging loans for commercial interests can be used to help a single member of a partnership on a split commercial title deed to gain control of the entire property under his or her name. This can be achieved with a view to either refinancing or selling the property in order to continue the business in future as a sole owner.",
    },
    {
      id: 2,
      para: "It is easy to get started with bridging finance through our service. With access to such a wide and varied selection of bridging loan funders from the industry, you can find out quite quickly whether we can help you secure the short-term funding you need to help with your property situation. All you have to do is take a look at our bridging loan calculator page for some pointers as to what to expect in terms of interest and repayment terms (rates start from 0.29% and we offer LTV between 70% and 100% depending on the amount of security you have available to add to your application).",
    },
    {
      id: 3,
      para: "Once you have applied for bridging loans for splitting deeds, we like to provide you with an answer as soon as possible. Most of our bridging loan applications are decided upon within a 3-hour period, and in urgent cases we can even deliver your funds within 48-hours of the loan being awarded. Browse our simple to use website, or contact us for more specific information based on your circumstances.",
    },
  ];
  export const infoArrayC4 = [
    {
      id: 1,
      para: "With Bridging 365 we have such a vast network of bridging loan funders that it is possible to acquire credit from sources that you might have struggled to find a connection with elsewhere.",
    },
    {
      id: 2,
      para: "In terms of acquiring funding for assistance with an inherited property, whether you are looking to pay off an inheritance tax bill quickly, or secure the property through the paying of a sibling who co-owns the property, our team has access to 13 reliable and professional funders, each of which can offer unregulated bridging loans for inherited properties.",
    },
    {
      id: 3,
      para: "Our loans start from £25,000 and can reach as high as £50 million, with 70% LTV or even 100% where you can offer additional security towards the loan. We are also happy to be as flexible as possible with repayments, taking each applicant on his or her specific merits. Contact us today and our friendly team will be able to guide you in the right direction for a bridging loan to pay a sibling in an inherited property scenario.",
    },
  ];
  export const infoArrayS1 = [
    {
      id: 1,
      para: "It is a simple and straightforward process to begin a halt on the repossession of your home through a bridging loan. All you have to do, is either fill out the quick and easy bridging finance application form on our site, or pick up the phone and speak to a member of our team.",
    },
    {
      id: 2,
      para: "We understand exactly how to help in these crucial times, when you need financial assistance the most. Our rates on non-status loans start from 0.75% per month, with up to 70% LTV (or 100% if there are multiple properties offered as security) and we can provide funds to clear mortgage arrears within 48-hours of a successful application.",
    },
  ];
  export const infoArrayS2 = [
    {
      id: 1,
      para: "As well as the steps you can take legally to stop your mortgage lender from repossessing your home, there is the option of taking out a bridging loan to halt the repossession process.",
    },
    {
      id: 2,
      para: "Provides Much Needed Time – Although you won’t be completely free of repossession a bridging loan gives you more time to focus on a way to deal with the issues over the longer term.",
    },
    {
      id: 3,
      para: "Restructure Payments – One way is to provide that time to discuss long-term tailored options such as restructuring payments that are acceptable to your lender",
    },
    {
      id: 4,
      para: "Clear Mortgage Arrears – Bridging finance can help to clear your mortgage arrears and provide time to sort out the funding to clear the bridging loan.",
    },
    {
      id: 5,
      para: "Sell the Property – Putting in place measures to sell your property can help to ward off repossession, as well as providing the opportunity to sell in an organised fashion without having to sell below a realistic valuation.",
    },
    {
      id: 6,
      para: "Keep a Clear Credit File – Bridging finance helps you in the long-term from having the black mark of house repossession on your credit file.",
    },
    {
      id: 7,
      para: "Stopping your mortgage lender from repossessing your home is likely to be an urgent matter. The team at Bridging 365 has access to a vast and varied panel of lenders (169 in total), located throughout the UK and beyond. With such variety in place it gives us the power to help prevent a repossession process from taking place in both a regulated and unregulated scenario.",
    },
    {
      id: 8,
      para: "In many cases our clients have come to us with urgent requirements for funds to help prevent repossession, sometimes needing the financial assistance within the course of a few days. Our relationship with a vast network of lenders ensures we can offer bridging finance within a 48-hour turnaround in the most urgent cases.",
    },
  ];