import React from "react";
import styles from "../styles/BridgingHeroSection.module.scss";
import { GoTriangleDown } from "react-icons/go";
import classNames from "classnames";
import { FiPhoneCall } from "react-icons/fi";
import { Link } from "react-router-dom";

const BridgningHeroSetion = ({ title, mainTitle }) => {
  return (
    <div className={styles.bridgningHeroSetion}>
      <p className={classNames(styles.header, styles.heading)}>{title}</p>
      <div className={styles.mainContent}>
        <div className={styles.left}>
          <p className={styles.leftHead}>
            <span>Call our expert</span>
            <span>advisers now</span>
            {/* Call our expert advisers now */}
          </p>
          <a
            href="tel:0208 146 9888"
            className={classNames(
              styles.leftNumber,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Re-Bridging Finance"
                ? styles.reSources
                : ""
            )}
          >
            {/* <FiPhoneCall className={styles.phoneIcon} /> 0208 146 9888 */}
            {/* <a href="tel:0208 146 9888"> */}
            <FiPhoneCall className={styles.phoneIcon} />
            0208 146 9888
            {/* </a> */}
          </a>
          <p className={styles.leftText}>
            <span>We’ll take you through the </span>
            <span>entire process</span>
          </p>
        </div>
        <div className={styles.center}>
          <p className={styles.borderTop}></p>
          <p className={styles.text}>or</p>
          <p className={styles.borderBottom}></p>
        </div>
        <div className={styles.right}>
          <p className={styles.rightHead}>
            <span>Start your</span>
            <span>remortgage online</span>
            {/* Start your remortgage online */}
          </p>
          <Link
            to="/form"
            className={classNames(
              styles.rightNumber,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Re-Bridging Finance"
                ? styles.reSources
                : ""
            )}
          >
            continue online
          </Link>
          <p className={styles.rightText}>
            <span> See the deals you qualify for &</span>
            <span>how much you could borrow</span>
          </p>
        </div>
      </div>
      <div
        className={classNames(
          styles.mainborderBottom,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Re-Bridging Finance"
            ? styles.reSources
            : ""
        )}
      >
        <GoTriangleDown
          className={classNames(
            styles.traingleIcon,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : title === "Re-Bridging Finance"
              ? styles.reSources
              : ""
          )}
        />
      </div>
    </div>
  );
};

export default BridgningHeroSetion;
