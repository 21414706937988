import React from "react";
import styles from "../styles/CallAdviser.module.scss";
import callGreenImg from "../assets/images/call.png";
import callBlueImg from "../assets/images/call-blue.png";
import callOrangeImg from "../assets/images/call-orange.png";
import callPurpleImg from "../assets/images/call-purple.png";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { FiPhoneCall } from "react-icons/fi";

const CallAdviser = ({ title }) => {
  return (
    <div
      className={classNames(
        styles.callAdviser,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : title === "Resources"
          ? styles.reSources
          : ""
      )}
    >
      <div className={styles.top}>
        <div className={styles.left}>
          <p>Call Our expert</p>
          <p>advisor now</p>
        </div>
        <div className={styles.center}>
          <img
            src={
              title === "Bridging Loans"
                ? callGreenImg
                : title === "Development Finance"
                ? callBlueImg
                : title === "Stop Repossessions"
                ? callOrangeImg
                : title === "Resources"
                ? callPurpleImg
                : ""
            }
            alt=""
            className={styles.callImg}
          />
        </div>
        <div className={styles.right}>
          <p className={styles.rightTopText}>
            Call free from mobile or landline
          </p>
          <a
            href="tel:0208 146 9888"
            className={classNames(
              styles.number,
              title === "Bridging Loans"
                ? styles.bLoans
                : title === "Development Finance"
                ? styles.dFinance
                : title === "Stop Repossessions"
                ? styles.sRepo
                : title === "Resources"
                ? styles.reSources
                : ""
            )}
          >
            {/* 0208 146 9888  */}
            <FiPhoneCall className={styles.phoneIcon} />
            0208 146 9888
          </a>
          <p className={styles.rightBottomText}>Call free 7 days a week</p>
        </div>
      </div>
      <Link
        to="/form"
        className={classNames(
          styles.continueLink,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : ""
        )}
      >
        Continue Online
      </Link>
    </div>
  );
};

export default CallAdviser;
