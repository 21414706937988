import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/NewsContainer.module.scss";
const NewsContainer = ({ title }) => {
  return (
    <div
      className={classNames(
        styles.newsContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : title === "Resources"
          ? styles.reSources
          : styles.home
      )}
    >
      <div className={styles.left}>
        <p
          className={classNames(
            styles.topText,
            title === "Bridging Loans"
              ? styles.bLoans
              : title === "Development Finance"
              ? styles.dFinance
              : title === "Stop Repossessions"
              ? styles.sRepo
              : title === "Resources"
              ? styles.reSources
              : styles.home
          )}
        >
          NEW - Ground Breaking Rates
        </p>
        <p className={styles.midText}>
          {title === "Bridging Loans"
            ? "LOWEST EVER BRIDGING RATES"
            : title === "Development Finance"
            ? "LOWEST EVER DEVELOPMENT FINANCE RATES"
            : title === "Stop Repossessions"
            ? "OUR FASTEST 24hr BRIDGE COMPLETED "
            : title === "Re-Bridging Finance"
            ? "LOWEST EVER RE-BRIDGING RATES"
            : title === "Resources"
            ? "LOWEST EVER RE-BRIDGING RATES"
            : "LOWEST EVER RE-BRIDGING RATES - 0.35% per month"}
        </p>
        <p className={styles.bottomText}>
          {title === "Bridging Loans"
            ? "We have access to a new funding line allowing us to offer a rate of 0.35% per month on a Re-Bridge"
            : title === "Development Finance"
            ? "We have access to a new funding line allowing us to offer a rate of 4.8 pa for experienced developers"
            : title === "Stop Repossessions"
            ? "Rate applied was 1% per month with an LTV of 85% saving the client 6% in default penalties. Try us now and apply here"
            : title === "Re-Bridging Finance"
            ? "We have access to a new funding line allowing us to offer a rate of 0.65% per month on a Re-Bridge"
            : title === "Resources"
            ? "LOWEST EVER RE-BRIDGING RATES"
            : "We have access to a new funding line allowing us to offer a rate of 0.35% per month on a re-bridge. T&C apply"}
        </p>
      </div>
      <Link
        to="/bridgingloans/guide"
        className={classNames(
          styles.link,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : title === "Resources"
            ? styles.reSources
            : styles.home
        )}
      >
        Learn More
      </Link>
    </div>
  );
};

export default NewsContainer;
