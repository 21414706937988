import classNames from "classnames";
import React from "react";
import styles from "../../styles/CompareContainer.module.scss";
const CompareContainerB4 = ({ title, mainTitle }) => {
  return (
    <div
      className={classNames(
        styles.compareContainer,
        title === "Bridging Loans"
          ? styles.bLoans
          : title === "Development Finance"
          ? styles.dFinance
          : title === "Stop Repossessions"
          ? styles.sRepo
          : ""
      )}
    >
      <p
        className={classNames(
          styles.compareHead,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
Bridging Loans HSBC      </p>
      <div
        className={classNames(
          styles.listBox,
          title === "Bridging Loans"
            ? styles.bLoans
            : title === "Development Finance"
            ? styles.dFinance
            : title === "Stop Repossessions"
            ? styles.sRepo
            : ""
        )}
      >
        <ul>
 
        <li>Rates from 0.45% with Typical 85% LTV</li>
        <li>Simple Application Process with 3-Hour Decision</li>
        <li>48-Hour Release of Funds in Requested Cases</li>
        <li>Funding for both Residential and Commercial Applicants</li>
        <li>We Accept CCJs, Mortgage Arrears, Bankruptcy, Ex-Pats and Foreign Nationals</li>
        <li>Regulated and Unregulated Funders Available</li>
        <li>Large Panel of Niche Funders Better Versed in Bridging Loans than High Street Vendors</li>
        </ul>
        <div className={styles.text}>

<p>The traditional high street lender feels comfortable, somewhere that we have all visited since first opening a bank account in our teens, and to secure a mortgage on our first home. In more recent times, however, when bridging finance is required in order to plug a short-term gap in funds, the high street lender might not be the best place for you to search. 
</p>
<p>Searching for bridging loans HSBC might have just brought you to a place where you can access information about the entire industry, not just the big brands that you have known about for years. You can also take advantage of the incredible quality and specialist expertise that our own large panel of funders provides all of our clients, from many different backgrounds and circumstances.
</p>
</div>
      </div>
    </div>
  );
};

export default CompareContainerB4;
