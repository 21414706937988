import { useState } from "react";
import React from "react";

import Modal from "react-modal";

import { useNavigate } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
// import Toastify from "toastify-js";
// import { database } from "../firebaseConfig";
// import "toastify-js/src/toastify.css";
// import "react-toastify/dist/ReactToastify.css";
// import { deleteDoc, doc } from "firebase/firestore";
import styles from "../styles/DeleteModal.module.scss";
import { deleteDoc, doc } from "firebase/firestore";
import { database } from "../FirebaseConfig";
import loading from "../assets/images/loading.gif";
import axios from 'axios';
import * as myConstClass from './FileWithConstants';


const DeleteModal = (props) => {

  const customStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  
  const DeleteHandler = (e) => {
    setShowLoading(true);
    e.preventDefault();
    
    const deleteformdataurl = myConstClass.BASE_URL+""+myConstClass.DELETEDOCUMENTS;
    let formData = new FormData();
    formData.append("deleteid", props.formId);
    axios.post(deleteformdataurl, formData)
    .then(res => {
        console.log(res);
        setShowLoading(false);
        if(props.redirecTo == 1)
        {
          navigate("/user/uploaded-documents", { replace: true });
        }
        else{
          navigate("/dashboard", { replace: true });
        }
        props.onClick();
        setShowLoading(false);
    });
    setShowLoading(false);

  };
  return (
    <div className={styles.deleteModal}>
      <span onClick={openModal} className={styles.deleteIcon}>
        <AiFillDelete className={styles.icon} 
        title="delete"  /> 
      </span>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <form
          action=""
          style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <p htmlFor="" style={{ display: "flex", flexDirection: "column" }}>
            <span>Do you want to delete form with id : {props.formId} </span>
          </p>
          <div style={{ alignSelf: "flex-end" }}>
            <button
              onClick={closeModal}
              style={{
                marginRight: "20px",
                padding: "5px 10px",
                cursor: "pointer",
                backgroundColor: "black",
                border: "none",
                color: "white",
              }}
            >
              Cancel
            </button>
            <button
              onClick={DeleteHandler}
              style={{
                padding: "5px 10px",
                cursor: "pointer",
                backgroundColor: "Red",
                border: "none",
                color: "white",
              }}
            >
              Delete
            </button>
          </div>
        </form>
      </Modal>
      <div className={styles.loadingContainer}>
        {showLoading && (
          <img src={loading} className={styles.loadingImg} alt="" />
        )}
      </div>
    </div>
  );
};

export default DeleteModal;
