import React, { useEffect } from "react";
import styles from "./DevelopmentFinanceBlogs.module.scss";
import Footer from "../../components/Footer";
// import { useParams } from "react-router-dom";
import CompareContainer1 from "../../components/CompareContainer/CompareContainer1";
import TypeOfRequirements from "../../components/TypeOfRequirements";
import MatchingMortgages1 from "../../components/MatchingMortgages/MatchingMortgages1";
import NewsContainer from "../../components/NewsContainer";
import LoanApplication1 from "../../components/LoanApplication/LoanApplication1";
import HeroSection from "../../components/HeroSection/HeroSection";
import TestimonialsContainer from "../../components/TestimonialsContainer";
const DevelopmentFinanceBlogs1 = () => {
  // const { blogId } = useParams();
  // console.log(blogId);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.developmentFinanceBlogs}>
      <HeroSection title="Development Finance" />
      <CompareContainer1 title="Development Finance" />
      <TypeOfRequirements title="Development Finance" />
      <MatchingMortgages1 title="Development Finance" />
      <NewsContainer title="Development Finance" />
      <LoanApplication1 title="Development Finance" />
      {/* <TestimonialsContainer1 title="Development Finance" /> */}
      <TestimonialsContainer title="Development Finance" />
      <Footer title="Development Finance" />
    </div>
  );
};

export default DevelopmentFinanceBlogs1;
